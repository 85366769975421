import React, { Component } from 'react';
import { ContactForm } from './ContactForm';
import { Section } from '../../layout/Section';

class ContactFormSection extends Component {
    render() {

        return <Section color="white">
            <ContactForm />
        </Section>
    }
}

export { ContactFormSection };

