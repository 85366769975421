import React, { Component, Fragment } from 'react';
import {ContractModal} from "../../modals/ContractModal";
import rightBlueArrow from '../../../resources/assets/account/contractsVacations/rightBlueArrow.svg';

class ListContractItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    onClose = () => {
        this.setState({showModal: false})
    }

    render() {
        return (
            <div>
                <Fragment>
                    <li className={this.props.index % 2 === 0 ? 'list-my-contracts odd' : 'list-my-contracts even' } onClick={(e) => {
                        e.preventDefault();
                        this.setState({showModal: true})
                    }}>
                        <p className="resort-name">{this.props.contract.resort}</p>
                        <div className="contract-number-image">
                            <div className="contract-number">
                                <span>{this.props.contract.contract_number}</span>
                                <hr className="contract-border"></hr>
                            </div>
                            <img src={rightBlueArrow} alt="contract-number"/>
                        </div>
                       
                    </li>
                </Fragment>
                <ContractModal contract={this.props.contract} open={this.state.showModal} onClose={this.onClose} />
            </div>
        )
    }
}
ListContractItem.defaultProps = {
    contract:
        {
            id: 1,
            resort: "Grand Lux",
            contractNumber: "#9943249",
            status: "Current",
            usageFee: "$1,401.54",
            roomType: "Master Room",
            paymentMode: "Terms"
        }
}

export { ListContractItem }
