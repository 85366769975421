import React, { Component } from 'react';
import Page from './layout/Page';
import { HeaderImage } from "../../components/account/myVidaVacationsPayments/HeaderImage";
import { ContractCarousel } from "../../components/account/general/Carousel/ContractCarousel";
import { PaymentType } from "../../components/account/myVidaVacationsPayments/PaymentType";
import { getUserContracts } from "../../api/userContracts/userContracts";
import { ContractsComponent } from "../../components/account/general/Contracts";
import { checkDevice } from "../../helpers/checkDevice";
import { CONTRACT_STATUSES } from "../../config/constants/contracts";
import { Filters } from "../../components/layout/Navbars/header/Filters";

class MyVidaVacationsPayments extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            contracts: null,
            selectedContract: '',
            paymentTypes: [],
            device: checkDevice(),
        }
    }

    setDevice = () => {
        this.setState({
            device: checkDevice()
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.getUserContracts();
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.setDevice);
    }

    getUserContracts = () => {
        getUserContracts().then(data => {
            // Filter after active contracts
            data = Array.isArray(data) && data.length ? data.filter(item => item.contract_status === CONTRACT_STATUSES.ACTIVE || item.contract_status === CONTRACT_STATUSES.PENDING || item.contract_status === CONTRACT_STATUSES.BLOCKED) : [];

            var withPayments = data.filter(contract => contract.payment_types.cash_out.length > 0 || contract.payment_types.down.length > 0 || contract.payment_types.monthly.length > 0 || contract.payment_types.other_fees.length > 0 );
            var withOutPayments = data.filter(contract => contract.payment_types.cash_out.length == 0 && contract.payment_types.down.length == 0 && contract.payment_types.monthly.length  == 0 && contract.payment_types.other_fees.length == 0 );

            var merged = withPayments.concat(withOutPayments);

            if (this._isMounted) {

                var contract_id = this.props?.location?.state?.contract_id;
                if(contract_id){

                    const index = merged.findIndex(obj => obj.contract_id === contract_id);
                    const value = merged[index];
                    const contract_array = merged.filter(x => {return x.contract_id != contract_id});

                    if(merged.length == 3){
                        merged = this.arraySetValueByIndex ({
                            array: contract_array,
                            itemValue: value,
                            index: 1,
                          });
                    }else{
                        merged = this.arraySetValueByIndex ({
                            array: contract_array,
                            itemValue: value,
                            index: 0,
                          });
                    }
                   
                }

                this.setState({
                    contracts: merged
                });
            }
        });
    }

    arraySetValueByIndex = ({array, itemValue, index}) => {
        const startPart = array.slice(0, index);
        const endPart = array.slice(index);
        return [
            ...startPart,
            itemValue,
            ...endPart
        ];
    };

    setSelectedContract = (contract) => {
        // When is returned index of the contract
        if (typeof (contract) === 'number') {
            contract = this.state.contracts[contract];
        }

        this.setState({
            selectedContract: contract
        });
    }

    changeSelectedItem = (direction) => {
        // This method is used only when not carousel component is loaded, and the length of contracts is 3
        let contracts = this.state.contracts;
        let aux = contracts[0];

        if (direction === 'back') {
            contracts[0] = contracts[2];
            contracts[2] = contracts[1];
            contracts[1] = aux;
        } else {
            contracts[0] = contracts[1];
            contracts[1] = contracts[2];
            contracts[2] = aux;
        }

        this.setState({
            contracts: contracts
        }, () => {
            this.setSelectedContract(contracts[1]);
        });
    }

    renderContractsCarousel() {
        if ( !this.state.contracts) {
            return null
        }

        if (this.state.device.isMobile) {
            return <ContractCarousel 
                        contracts={ this.state.contracts } 
                        onChange={ this.setSelectedContract }
                        device={ this.state.device }/>
        } else {
            if (this.state.contracts.length <= 3) {
                return <ContractsComponent 
                            setActive={ this.setSelectedContract }
                            changeSelectedItem={ this.changeSelectedItem }
                            contracts={ this.state.contracts }
                            device={ this.state.device }/>
            }else{
                return <ContractCarousel 
                            onChange={ this.setSelectedContract }
                            contracts={ this.state.contracts } 
                            device={ this.state.device }/>
            }

        }
    }

    render() {
        return <Page class="account my-vida-vacations-payments">
             <Filters />
            <HeaderImage/>
            { this.renderContractsCarousel() }
            { this.state.selectedContract && <PaymentType contract={ this.state.selectedContract }/> }
        </Page>
    }
}

export { MyVidaVacationsPayments }
