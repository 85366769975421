import React, { Component } from 'react';
import {Section} from "../../layout/Section";
import { FormattedMessage } from "react-intl";

class AboutSection extends Component {
    render() {
        return (
            <Section color="white">
                <div className="about-section">
                    <h1><FormattedMessage id="components.account.about.about_section.title"/></h1>
                    <p><FormattedMessage id="components.account.about.about_section.subtitle"/></p>
                </div>
            </Section>
        )
    }
}

export {AboutSection}
