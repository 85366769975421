import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import {Section}  from "../../layout/Section";
import { FormattedMessage } from 'react-intl';

class ContractDetails extends Component {
    render() {
        return (
            <Section color="gray" className="contract-details">
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item xs={4} className="days remove-xs-space">
                        {this.props.contract ?
                            <h1 className="number">{this.props.contract.total_weeks || 0}</h1> :
                            <h1 className="number">0</h1>
                        }
                        <p className="label">
                            <FormattedMessage id="components.account.myAccount.contract_details.total_days"/>
                        </p>
                    </Grid>
                    <Grid item xs={4}  className="days remove-xs-space">
                        {this.props.contract ?
                            <h1 className="number">{this.props.contract.used_weeks || 0}</h1> :
                            <h1 className="number">0</h1>
                        }
                        <p className="label">
                            <FormattedMessage id="components.account.myAccount.contract_details.used_days"/>
                        </p>
                    </Grid>
                    <Grid item xs={4}  className="days remove-xs-space">
                        {this.props.contract ?
                            <h1 className="number">{this.props.contract.weeks_available || 0}</h1> :
                            <h1 className="number">0</h1>
                        }
                        <p className="label">
                            <FormattedMessage id="components.account.myAccount.contract_details.available_days"/>
                        </p>
                    </Grid>
                </Grid>
            </Section>
        );
    }
}

export { ContractDetails }
