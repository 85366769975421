import React, {useEffect, useState} from 'react';
import {Section} from "../../layout/Section";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {FormattedMessage} from 'react-intl';
import {DESTINATIONS_HIGHLIGHTS} from "../../../config/constants/destinationsHighlights";

const DestinationHighlightsSection = props => {
    const [buyBtnId, setBuyBtnId] = useState('components.account.destinationsHighlights.destination_highlights_section.buy_tickets');
    const [imgLogo, setImgLogo] = useState('');
    const {destinationHighlight} = props;

    useEffect(() => {
        switch (destinationHighlight.name) {
            case DESTINATIONS_HIGHLIGHTS.JUNGALA: {
                setImgLogo(`${process.env.PUBLIC_URL}/img/destinations/Jungala.png`);
                break;
            }
            case DESTINATIONS_HIGHLIGHTS.PALACIO: {
                setImgLogo("");
                setBuyBtnId('components.account.destinationsHighlights.destination_highlights_section.learn_more');
                break;
            }
            case DESTINATIONS_HIGHLIGHTS.BEACHLAND: {
                setImgLogo(`${process.env.PUBLIC_URL}/img/destinations/Beachland.png`);
                setBuyBtnId('components.account.destinationsHighlights.destination_highlights_section.learn_more');
                break;
            }
            case DESTINATIONS_HIGHLIGHTS.JOYA: {
                setImgLogo(`${process.env.PUBLIC_URL}/img/destinations/Joya.png`);
                setBuyBtnId('components.account.destinationsHighlights.destination_highlights_section.learn_more');
                break;
            }
            case DESTINATIONS_HIGHLIGHTS.RESERVE: {
                setImgLogo("");
                setBuyBtnId('components.account.destinationsHighlights.destination_highlights_section.learn_more');
                break;
            }
            default: {
                break;
            }
        }
    }, [destinationHighlight]);

    return (
        
        <div style={{width: '100%'}}>
            { imgLogo != "" && <Section color="grey">
                <div className="destination-highlights-section" style={{height: "151px"}}>
                    {<img className="destination-logo" src={imgLogo} alt=""/> }
                </div>
            </Section> }
            <Section color="white">
                <div className="destination-highlights-section">
                    <p className="destination-location">
                            <span>
                                <FormattedMessage
                                    id="components.account.destinationsHighlights.destination_highlights_section.title"/>
                            </span>
                        {destinationHighlight.location}</p>
                    <div className="destination-description">
                        {destinationHighlight.description}
                        {destinationHighlight.read_more_link !== '#' &&
                        <div className="read-more-container" onClick={() => window.open(
                            destinationHighlight.read_more_link,
                            '_blank'
                        )}>
                            <span className="read-more">
                                <FormattedMessage id="components.account.destinationsHighlights.destination_highlights_section.read_more"/>
                            </span>
                            <KeyboardArrowDownIcon/>
                        </div>
                        }
                    </div>
                    {destinationHighlight.buy_link !== '#' &&
                    <a className="buy-tickets" href={destinationHighlight.buy_link} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id={buyBtnId}/>
                        <ChevronRightIcon/>
                    </a>}
                </div>
            </Section>
        </div>
    )
}

export { DestinationHighlightsSection }
