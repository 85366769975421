import React, {Component} from 'react';
import {Section} from "../../layout/Section";
import Grid from '@material-ui/core/Grid';
import {withRouter} from "react-router";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {ROUTE_MY_RESERVATIONS} from "../../../config/routes";
import {RESERVATION_STATUSES} from "../../../config/constants/reservations";
import {history} from "../../../store/store";
import {FormattedMessage} from "react-intl";
import {getDestinationImage} from "../../../helpers/general";

class ReservationInfo extends Component {
    verify = () => {
        history.push(ROUTE_MY_RESERVATIONS);
    }

    writeTravellersNames = () => {
        if (!this.props.location.state.data.number_of_adults && !this.props.location.state.data.number_of_children) {
            return;
        }

        // TODO: Implement here the names of travellers
    }

    render() {
        return (
            <Section color="white" className="reservation-found-info">
                <div className="reservation-found-info-container">
                    <div className="image-and-info-container">
                        <Grid item xs={4} className="image-container">
                            <img src={getDestinationImage(this.props.location.state.data)} alt=""/>
                        </Grid>
                        <Grid item xs={8} className="info-container">
                            <div className={`reservation-status-container status-background${this.props.location.state.data.reservation_status}`}>
                                <h2 className="reservation-status">{RESERVATION_STATUSES[this.props.location.state.data.reservation_status]}</h2>
                            </div>
                            <div className="reservation-details-container">
                                <h3 className="destination">{this.props.location.state.data.hotel}</h3>
                                <h4 className="confirmation-label">
                                    <FormattedMessage id="components.account.reservationFound.reservationInfo.confirm_no"/>
                                </h4>
                                <p className="confirmation-number">{this.props.location.state.data.reservation_number}</p>
                            </div>
                        </Grid>
                    </div>
                    <Grid container className="travelers-and-unit-container">
                        <Grid item xs={12} md={6} className="travelers-container">
                            <p className="travelers-label">
                                {this.props.location.state.data.number_of_adults} <FormattedMessage id="components.account.reservationFound.reservationInfo.adults"/>,
                                {this.props.location.state.data.number_of_children} <FormattedMessage id="components.account.reservationFound.reservationInfo.children"/></p>
                            <p className="travelers-names">{this.writeTravellersNames()}</p>
                        </Grid>
                        <Grid item xs={12} md={6} className="unit-type-container">
                            <p className="unit-type-label"><FormattedMessage id="components.account.reservationFound.reservationInfo.unit_type"/></p>
                            <p className="unit-type">{this.props.location.state.data.room_type}</p>
                        </Grid>
                    </Grid>
                </div>

                <div className="verify-button-container">
                    <button className="verify-button" onClick={() => this.verify()}>
                        <FormattedMessage id="components.account.reservationFound.reservationInfo.verify"/>
                        <ChevronRightIcon/>
                    </button>
                </div>
            </Section>
        )
    }
}

export default withRouter(ReservationInfo);
