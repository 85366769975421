import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {FormattedMessage} from 'react-intl';

class Contract extends Component {
    renderButton() {
        const className = this.props.isActive ? "contract-button-active" : "contract-button";

        return (
            <button className={className} onClick={this.handleClick}>
                <p className="contract-text contract-number">
                    <FormattedMessage id="components.account.general.carousel.contract"/>
                    {this.props.contract.contract_number}
                </p>
                <p className="contract-text" title={this.props.contract.resort}>{this.props.contract.resort.substring(0, 20)}...</p>
            </button>
        )
    }

    handleClick = () => {
        if (this.props.isActive) {
            return null;
        }

        this.props.onClick();
    }

    getButtonContainerSpaceClass = (index, contractLength) => {
        if (contractLength === 1) {
            return 'single';
        }

        if (index === 0) {
            return 'first';
        }

        if (index === contractLength - 1) {
            return 'last';
        }
    }

    render() {
        let {index, contractLength} = this.props;

        return (
            <Grid item className={`general-contract-item ${this.getButtonContainerSpaceClass(index, contractLength)}`}>
                {this.renderButton()}
            </Grid>
        );
    }
}

export {Contract}
