import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';

export default function CustomPasswordField(props) {
    const [ visibility, setVisibility ] = useState( false );
    const toggleVisibility = () => setVisibility( ! visibility );

    return <Grid container className="custom-text-wrapper" alignItems="center">
        <Grid item xs={props.showIcon ? 11 : 12}>
            <TextField
                id={props.name}
                className="custom-text-input"
                fullWidth
                type={ visibility ? 'text' : 'password' }
                margin='normal'
                autoComplete="off"
                name={props.name}
                label={props.label}
                error={props.error}
                onChange={(e) => props.onChange( e )}
                placeholder={props.placeholder}
                onBlur={e => props.handleBlur(e)}
                value={props.value}
            />
        </Grid>
        {props.showIcon && <Grid item xs={1} className={`password-icon ${props.error ? 'error' : ''}`}>
            <span onClick={toggleVisibility} className="visibility">
                {! visibility && <Visibility/>}
                {visibility && <VisibilityOff/>}
            </span>
        </Grid>
        }
    </Grid>

}

