import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { ErrorMessage } from "formik";
import moment from 'moment';
import { MonthlyPaymentHeaderItem } from "./MonthlyPaymentHeaderItem";
import { ChooseMonths } from "./ChooseMonths";

class MonthlyPaymentHeader extends Component {
    constructor(props) {
        super(props);

        let paymentTypes = {};
        const selectedItem = "monthly";

        let initialStateValues = {};

        if (props.contract && props.contract.loan_payment_amount) {
            initialStateValues = this.onMonthlyTypeChangeMonthly(props.contract.loan_payment_amount ?? 0, selectedItem);
        }

        this.state = {
            paymentTypes: paymentTypes,
            selectedItem: selectedItem,
            ...initialStateValues,
            monthlyTotal: props.contract.loan_payment_amount,
            PayInFullTotal: props.contract.pay_in_full,
            months: [],
        };

        props.setStateOnMonthlyTypeChange(initialStateValues);
    }

    onMonthlyTypeChangeMonthly = (loan_payment_amount, selectedItem) => {
        let { dueDate, amountDue, lateFee, totalAmount } = this.props;

        let paymentTypeIds = [];

        if (
            selectedItem === 'monthly' && this.props.contract && this.props.contract.payment_types
            && this.props.contract.payment_types.monthly && Array.isArray(this.props.contract.payment_types.monthly)
        ) {
            let localAmountDue = 0, localLateFee = 0;

            this.props.contract.payment_types.monthly.forEach(item => {
                if ( !dueDate || !dueDate.length) {
                    dueDate = item.due_date;
                } else if (moment(item.due_date, 'YYYY-MM-DD').valueOf() < moment(dueDate, 'YYYY-MM-DD').valueOf()) {
                    dueDate = item.due_date;
                }

                if (moment().valueOf() >= moment(item.due_date, 'YYYY-MM-DD').valueOf()) {
                    localAmountDue += parseFloat(item.invoice_amount ?? 0);
                    localLateFee += parseFloat(item.late_fee ?? 0);
                    paymentTypeIds.push(item.id);
                }
            });

            amountDue = localAmountDue;
            lateFee = localLateFee;

            if ( !amountDue && !lateFee && this.props.contract.payment_types.monthly[0]) {
                amountDue = parseFloat(this.props.contract.payment_types.monthly[0].invoice_amount ?? 0);
                lateFee = parseFloat(this.props.contract.payment_types.monthly[0].late_fee ?? 0);
                dueDate = this.props.contract.payment_types.monthly[0].due_date;
                paymentTypeIds.push(this.props.contract.payment_types.monthly[0].id);
            }

            totalAmount = parseFloat((amountDue + lateFee).toFixed(2));
        }

        return {
            amountDue: parseFloat(amountDue.toFixed(2)),
            lateFee: parseFloat(lateFee.toFixed(2)),
            totalAmount: parseFloat(totalAmount.toFixed(2)),
            order_amount: parseFloat(totalAmount.toFixed(2)),
            dueDate: dueDate,
            paymentTypeIds: paymentTypeIds,
            monthlyType: "monthly",
            noOfMonths: 0,
        }
    }

    onMonthlyTypeChangePayInFull = (pay_in_full) => {
        pay_in_full = parseFloat(pay_in_full);

        return {
            amountDue: parseFloat(pay_in_full.toFixed(2)),
            lateFee: parseFloat("0.00"),
            totalAmount: parseFloat(pay_in_full.toFixed(2)),
            order_amount: parseFloat(pay_in_full.toFixed(2)),
            dueDate: "",
            paymentTypeIds: [],
            monthlyType: "full",
            noOfMonths: 0,
        }
    }

    onMonthlyTypeChangeMultiple = (months, setState) => {
        const _setState = setState || false;

        let amountDue = 0.00;
        let totalAmountDue = 0.00;
        let lateFee = 0.00;
        let dueDate = '';
        let paymentTypeIds = [];

        if (months && months.length) {
            months.forEach(
                paymentType => {
                    paymentTypeIds.push(paymentType.id);
                    amountDue += parseFloat(paymentType.invoice_amount ?? 0);
                    lateFee += parseFloat(paymentType.late_fee ?? 0);

                    if ( !dueDate.length) {
                        dueDate = paymentType.due_date;
                    } else if (moment(dueDate).format('x') > moment(paymentType.due_date).format('x')) {
                        dueDate = paymentType.due_date;
                    }
                });

            totalAmountDue = amountDue + lateFee;
        }

        const returnData = {
            amountDue: parseFloat(amountDue.toFixed(2)),
            lateFee: parseFloat(lateFee.toFixed(2)),
            totalAmount: parseFloat(totalAmountDue.toFixed(2)),
            order_amount: parseFloat(totalAmountDue.toFixed(2)),
            dueDate: dueDate,
            paymentTypeIds: paymentTypeIds,
            monthlyType: "multiple",
            noOfMonths: paymentTypeIds.length,
        };

        if (_setState) {
            this.setState(returnData, () => {
                this.props.setStateOnMonthlyTypeChange(returnData);
            });
        } else {
            return returnData;
        }
    }

    onMonthlyTypeChange = (props) => {
        let amountDue = 0.00;
        let totalAmountDue = 0.00;
        let lateFee = 0.00;
        let dueDate = '';
        let paymentTypeIds = [];

        if (props && props.length) {
            props.forEach(
                paymentType => {
                    paymentTypeIds.push(paymentType.id);
                    amountDue += parseFloat(paymentType.amount_due ?? 0);
                    lateFee += parseFloat(paymentType.late_fee ?? 0);

                    if ( !dueDate.length) {
                        dueDate = paymentType.due_date;
                    } else if (moment(dueDate).format('x') > moment(paymentType.due_date).format('x')) {
                        dueDate = paymentType.due_date;
                    }
                });

            totalAmountDue = amountDue + lateFee;
        }

        return {
            amountDue: parseFloat(amountDue.toFixed(2)),
            lateFee: parseFloat(lateFee.toFixed(2)),
            totalAmount: parseFloat(totalAmountDue.toFixed(2)),
            order_amount: parseFloat(totalAmountDue.toFixed(2)),
            dueDate: dueDate,
            paymentTypeIds: paymentTypeIds,
        }
    }

    changeMonthlyPaymentType = (type, amount) => {
        const { setStateOnMonthlyTypeChange, contract } = this.props;
        let months = [];

        this.setState({
            selectedItem: type,
        }, () => {
            let parentState = {};

            if (type === "monthly") {
                parentState = this.onMonthlyTypeChangeMonthly(contract.loan_payment_amount ?? 0, type);
            } else if (type === "full") {
                parentState = this.onMonthlyTypeChangePayInFull(contract.pay_in_full ?? 0);
            } else if (type === "multiple") {
                parentState = this.onMonthlyTypeChangeMultiple(months ?? {});
            }

            setStateOnMonthlyTypeChange(parentState);
        });
    }

    render() {
        const { contract } = this.props;
        const { monthlyAmount, fullAmount, selectedItem } = this.state;

        return <div className="monthly-payment-header">
            <span className="select-one">
                <FormattedMessage id="components.account.paymentMethod.monthly_payment_header.title"/>
            </span>
            <Grid container justify="center" alignItems="center">
                <MonthlyPaymentHeaderItem
                    text="components.account.paymentMethod.monthly_payment_header.monthly_payment"
                    value={ contract.loan_payment_amount ?? "" }
                    type="monthly"
                    changeMonthlyPaymentType={ type => this.changeMonthlyPaymentType(type, monthlyAmount) }
                    selectedItem={ selectedItem }
                />
                <MonthlyPaymentHeaderItem
                    text="components.account.paymentMethod.monthly_payment_header.pay_in_full"
                    value={ contract.pay_in_full ?? "" }
                    type="full"
                    changeMonthlyPaymentType={ type => this.changeMonthlyPaymentType(type, fullAmount) }
                    selectedItem={ selectedItem }
                />
                <MonthlyPaymentHeaderItem
                    text="components.account.paymentMethod.monthly_payment_header.multiple_payments"
                    type="multiple"
                    changeMonthlyPaymentType={ type => this.changeMonthlyPaymentType(type) }
                    selectedItem={ selectedItem }
                />

            </Grid>
            { selectedItem === 'multiple' && <ChooseMonths
                selectedItem={ selectedItem }
                setMonths={
                    months => {
                        this.onMonthlyTypeChangeMultiple(months, true);
                    }
                }
                contract={ contract }
            /> }

            <ErrorMessage name={ 'paymentType' } component="div" className="field-error"/>
            <ErrorMessage name={ 'months' } component="div" className="field-error"/>
        </div>
    }
}

export { MonthlyPaymentHeader }
