export const LOGO_PRIORITIES = [
    {
        name: 'The Estates',
        short_name: 'theestates',
    },
    {
        name: 'Grand Luxxe',
        short_name: 'grandluxxe'
    },
    {
        name: 'The Grand Bliss',
        short_name: 'thegrandbliss'
    },
    {
        name: 'The Grand Mayan',
        short_name: 'thegrandmayan'
    },
    {
        name: 'Sea Garden',
        short_name: 'seagarden'
    },
    {
        name: 'Mayan Palace',
        short_name: 'mayanpalace'
    },
];
