import * as types from '../store/actions/types';

export const getAlertType = (type) => {
    switch (type) {
        case types.NOTIFICATION_SUCCESS:
            return 'success';
        case types.NOTIFICATION_WARNING:
            return 'warning';
        case types.NOTIFICATION_ERROR:
            return 'error';
        default:
            return 'error';
    }
};
