import React from 'react';
import TextField from '@material-ui/core/TextField';

class CustomTextField extends React.Component {
    render() {
        return <TextField
            id={this.props.name}
            className={this.props.class}
            type={this.props.type || 'text'}
            fullWidth
            margin='normal'
            autoComplete="off"
            name={this.props.name}
            label={this.props.label}
            error={this.props.error}
            onChange={(e) => this.props.onChange(e)}
            placeholder={this.props.placeholder}
            onBlur={e => this.props.handleBlur(e)}
            value={this.props.value}
            disabled={this.props.disabled ? this.props.disabled : false}
        />
    }
}

export default CustomTextField;
