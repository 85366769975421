import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { isAuthenticated } from '../api/helper';
import { Routes } from '../config';
import { history } from '../store/store'

class NotAuthenticated extends Component {
    constructor(props) {
        super( props );

        if (isAuthenticated()) {
            history.push( Routes.ROUTE_DASHBOARD );
        }
    }

    render() {
        const { component: Component, ...props } = this.props;
        return (
            <Route {...props} render={props => ( <Component {...props} /> )}/>
        )
    }
}

export default withRouter( NotAuthenticated );
