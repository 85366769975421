import React from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import Page from '../auth/Page';
import { Text, Title } from '../../components';
import Grid from '@material-ui/core/Grid';

export const RegistrationSuccess = (props) => {
    let preexisting = null;

    if (props && props.location && props.location.state && props.location.state.pre_existing_account) {
        preexisting = <Text className='center auth-subtitle'>
            <FormattedHTMLMessage id={"registration_success.preexisting_account"}/>
        </Text>;
    }

    return (
        <Page showSwitchLanguage={false} class="register-success-container" middle="middle">
            <Grid container className="message" justify="center">
                <Title className="center auth-title">
                    <FormattedMessage id={"globals.thank_you"}/>
                </Title>
                <Text className='center auth-subtitle'>
                    <FormattedMessage id={"registration_success.text"}/>
                </Text>
                { preexisting }
            </Grid>
        </Page>
    )
};
