import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {FormattedMessage} from 'react-intl';
import {RESERVATION_STATUSES} from '../../../config/constants/reservations';
import PreRegistrationIcon from '../../../resources/assets/account/myReservations/PreRegistration.svg';
import SendConfirmationIcon from '../../../resources/assets/account/myReservations/SendConfirmation.svg';
import {ReservationActionItem} from './ReservationActionItem';
import {history, store} from '../../../store/store';
import {Routes} from '../../../config';
import {getDestinationImage} from "../../../helpers/general";
import {sendReservationConfirmation} from "../../../api/user";
import {displayGlobalNotification} from "../../../store/actions/globalNotification";
import {NOTIFICATION_SUCCESS} from "../../../store/actions/types";
import {Messages} from "../../general/Messages";
import {NOTIFICATION_SEND_EMAIL_CONFIRMATION} from "../../../config/notifications";
import moment from 'moment';
import { es, enUS } from 'date-fns/locale'


class ReservationItem extends Component {
    sendConfirmation = () => {
        const {reservation} = this.props;

        sendReservationConfirmation({
            data: {
                reservation_number: reservation.reservation_number,
            },
            notificationId: NOTIFICATION_SEND_EMAIL_CONFIRMATION + reservation.reservation_id
        }).then((response) => {
            if (response.status === 201) {
                store.dispatch(displayGlobalNotification(NOTIFICATION_SUCCESS, 'components.modals.send_confirmation_modal.success_message'));
            }
        });
    }

    writeTravellersNames = () => {
        // TODO: Implement here the names of travellers
        if (!this.props.reservation.number_of_adults && !this.props.reservation.number_of_children) {
            // TODO: Implement here the names of travellers
        }

        return null;
    }

    render() {
        const {reservation} = this.props;

        const locale = moment.locale();

        let arriving_date = moment(reservation.arriving_date).format(locale == "es" ? "DD-MM-YYYY" : "YYYY-MM-DD");
        let departing_date = moment(reservation.departing_date).format(locale == "es" ? "DD-MM-YYYY" : "YYYY-MM-DD");

        return <Grid item xs={12} sm={6} md={4} className="reservation-item">
            <Grid container direction="row" className="image-and-name-container">
                <Grid item xs={4} className="reservation-image-container">
                    <img className="reservation-image" src={getDestinationImage(reservation)} alt=''/>
                </Grid>

                <Grid item xs={8}>
                    <div className="details-container">
                        <div className={`reservation-status status-background${reservation.reservation_status}`}>
                            <span className="status-text">{RESERVATION_STATUSES[reservation.reservation_status]}</span>
                        </div>

                        <div className="name-and-number">
                            <h1 className="title">
                                {reservation.hotel}
                            </h1>

                            <h3 className="confirmation-nr-title">
                                <FormattedMessage id="components.account.myReservations.reservation_item.confirmation_nr"/>
                            </h3>

                            <h1 className="confirmation-nr">
                                {reservation.reservation_number}
                            </h1>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Grid container direction="column" className="guests-unit-container">
                <Grid item xs={12} className="guests">
                    <div className="label-text">
                        {reservation.number_of_adults} <FormattedMessage
                        id="components.account.myReservations.reservation_item.adults"/>,
                        {reservation.number_of_children} <FormattedMessage
                        id="components.account.myReservations.reservation_item.children"/>
                    </div>
                    <div className="label-desc">{reservation.guest}</div>
                </Grid>
                <Grid item xs={12} className="unit-type">
                    <div>
                        <div className="label-text">
                            <FormattedMessage id="components.account.myReservations.reservation_item.unit_type"/>
                        </div>
                    </div>
                    <div>
                        <div className="label-desc">{reservation.room_type}</div>
                    </div>
                </Grid>
                <Grid item xs={12} className="date">
                    <div>
                        <div className="label-text">
                            <FormattedMessage id="components.account.myReservations.reservation_item.date"/>
                        </div>
                    </div>
                    <div>
                        <div className="label-desc">{arriving_date} - {departing_date}</div>
                    </div>
                </Grid>
            </Grid>

            {!this.props.hideActionsButtons &&
            <Grid container direction="column" className="reservation-action-container">
                <ReservationActionItem
                    img={PreRegistrationIcon}
                    text="components.account.myReservations.reservation_action_item.pre_register"
                    action={() => history.push(Routes.ROUTE_PRE_REGISTRATION)}
                />
                <ReservationActionItem
                    img={SendConfirmationIcon}
                    text="components.account.myReservations.reservation_action_item.send_confirmation"
                    action={() => this.sendConfirmation()}
                />
            </Grid>}
            <Messages notificationId={`${NOTIFICATION_SEND_EMAIL_CONFIRMATION + this.props.reservation.reservation_number}`}/>
        </Grid>
    }
}

export {ReservationItem}
