import React from 'react';

class SimpleAlertComponent extends React.Component {
    constructor(props) {
        super( props );
        this.state = {
            isError: false
        };
    }

    render() {
        return <div className="simple-alert-container mt-3">
            <span className={this.props.color}>{this.props.children}</span>
        </div>
    }
};

export default SimpleAlertComponent;
