import React, { Component } from 'react';
import { Section } from '../../Section';
import {connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import logo from '../../../../resources/assets/logo.svg';
import myAccountIcon from '../../../../resources/assets/my-account-icon.svg';
import notificationsIcon from '../../../../resources/assets/notifications-icon.svg';
import Badge from '@material-ui/core/Badge';
import { Link } from "react-router-dom";
import { MenuComponent } from './Menu';
import { Routes } from '../../../../config';
import {FormattedMessage} from 'react-intl';
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import HeaderAccordion from "./HeaderAcoordion";
import { userLocale } from "../../../../store/actions/locale";
import { ENGLISH_LOCALE, SPANISH_LOCALE } from "../../../../languages";
import {displayNotification} from "../../../../store/actions/notification";
import {userDetails} from "../../../../store/actions/user";
import {requestUpdateProfile} from '../../../../store/actions/user';
import {NOTIFICATION_ERROR} from "../../../../store/actions/types";
import {NO_PROPERTY_WAS_CHANGED} from "../../../../config/messages";
import {updateProfile} from "../../../../api/user";

class HeaderComponent extends Component {
    constructor(props) {
        super(props);

        var element = document.getElementById("root");
            element.removeAttribute('class')
            element.classList.add( props.userProfile.language_id == 'es' ? 'lang-es' : 'lang-en');

        this.state = {
            expand: false,
        }
    }

    submit = (language, {...props}) => {
        
        const {userProfile} = this.props;

        var data = {
            email: userProfile.email || '',
            home_phone: userProfile.home_phone || '',
            mobile_phone: userProfile.mobile_phone || '',
            address_line_1: userProfile.address_line_1 || '',
            address_line_2: userProfile.address_line_2 || '',
            language_id: language,
            all_communications: userProfile.all_communications || false,
            monthly_communications: userProfile.monthly_communications || false
        }

        updateProfile(data).then((response) => {
            this.props.requestUpdateProfile(data, response);
        })
    };

    render() {
        const { expand } = this.state;
        return <Section color='black sticky'>
            <div className="no-margin-header-container">
                <Grid container className="account-header">
                    <Grid item xs={1}>
                        <MenuComponent unreadMessages={this.props.userProfile.unread_messages} payments={this.props.userProfile.payments}/>
                    </Grid>

                    <Grid item xs={3}>
                        {/* <div className={'beta-layout'}>
                            <div className="beta-ring">
                                <div className="beta-ring-inside">
                                    <div className={'beta-text'}>
                                        <FormattedMessage id="globals.beta_text"/>
                                    </div>
                                </div>
                            </div>
                            <div className={'beta-message'}>
                                <FormattedMessage id="globals.beta"/>
                            </div>
                        </div> */}
                    </Grid>

                    <Grid item xs={4} className="text-center">
                        <Link to={Routes.ROUTE_HOME}>
                            <img className='logo' src={logo} alt=''/>
                        </Link>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container className="header-profile" alignItems="center">
                            <span className="header-user-name">
                                <FormattedMessage id="components.layout.header.hi"/>
                                {(this.props.userProfile.first_name ? this.props.userProfile.first_name.toLowerCase() : this.props.userProfile.last_name ? this.props.userProfile.last_name.toLowerCase() : <FormattedMessage id="globals.unknown"/> )}
                            </span>
                            <Link to={Routes.ROUTE_MY_ACCOUNT}>
                                <img className="icon" src={myAccountIcon} alt=''/>
                            </Link>
                            <Link to={Routes.ROUTE_MESSAGES_CENTER}>
                                <Badge badgeContent={this.props.userProfile.unread_messages} className="vidanta-badge-small">
                                    <img className="icon notifications" src={notificationsIcon} alt=''/>
                                </Badge>
                            </Link>
                            <HeaderAccordion props={this.props} submit = {this.submit}/>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Section>
    }
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    userProfile: state.user.profile
});

const Header = connect(mapStateToProps,  {
    userDetails,
    displayNotification,
    requestUpdateProfile
 })(HeaderComponent);

export {Header, HeaderComponent};
