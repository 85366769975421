import React, {useEffect, useState} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export default function CustomSelect(props) {
    const [value, setValue] = useState(props.value ? props.value : '');
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setValue(props.value ? props.value : '');
    }, [props.value]);

    let checkIfMustBeOverHeader = () => {
        if (props.name !== 'destination' && props.name !== 'guests') {
            return;
        }

        setTimeout(() => {
            // Make select destination in header to be visible over menu popup when open
            let destinationSelect = document.getElementById('menu-destination');

            if (destinationSelect) {
                destinationSelect.style.zIndex = '1302';
            }

            let guestsSelect = document.getElementById('menu-guests');

            if (guestsSelect) {
                guestsSelect.style.zIndex = '1302';
            }
        }, 100);
    }

    let openSelect = () => {
        if (open) {
            return;
        }

        checkIfMustBeOverHeader();
        setOpen(true);
    }

    return <FormControl
        className={`custom-select-field-wrapper ${props.textColor} ${props.class} ${props.error ? 'error-border' : ''}`}
        error={props.error}
        variant={props.variant ? props.variant : 'standard'}
    >
        <InputLabel id="custom-select-label" className="custom-select-input-label">
            {props.label}
        </InputLabel>
        <Select
            onClick={() => openSelect()}
            label="props.label"
            name={props.name}
            className="custom-select-field"
            labelId={props.name}
            fullWidth
            open={open}
            autoComplete="off"
            id={props.name}
            value={value}
            onChange={(e) => {
                props.onChange(e);
                setValue(e.target.value);
            }}
            onOpen={checkIfMustBeOverHeader}
            onClose={() => setOpen(false)}
            IconComponent={() => (
                <KeyboardArrowDownIcon className={`cursor-pointer ${props.error ? 'icon-error' : ''}`}
                                       onClick={() => openSelect()}/>
            )}>
            {props.items.map(item => {
                return <MenuItem key={item.id} value={props.useNameAsValue ? item.name : item.id}>{item.name}</MenuItem>
            })}
        </Select>
    </FormControl>;
}

