import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {Section}  from "../../layout/Section";
import RightArrow  from "../../../resources/assets/account/myAccount/rigt-arrow.svg";
import { FormattedMessage } from 'react-intl';
import {history} from "../../../store/store";
import {Routes} from "../../../config";
import Voice from "../../../resources/assets/account/myAccount/voice.svg";

class UpcomingReservations extends Component {
    render() {
        return (
            <Section color="grey" className="upcoming-reservation">
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={6} className="upcoming-reservations-container">
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <h1 className="upcoming-reservation-text">
                                <FormattedMessage id="components.account.myAccount.upcoming_reservations.upcoming_reservations"/>
                            </h1>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            className="mtop"
                        >
                            <Button className="view-reservation" onClick={() => history.push(Routes.ROUTE_MY_RESERVATIONS)}>
                                <FormattedMessage id="components.account.myAccount.upcoming_reservations.view_my_reservations"/>
                                <img src={RightArrow} alt="view-my-reservation"/>
                            </Button>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid container direction="row" justify="center" alignItems="center">
                                <img className="voice-icon" src={Voice} alt="" />
                                <h1 className="upcoming-reservation-text">
                                    <FormattedMessage id="components.account.myAccount.upcoming_reservations.voice_title"/>
                                </h1>
                            </Grid>
                            <p className="upcoming-reservation-description">
                                <FormattedMessage id="components.account.myAccount.upcoming_reservations.voice_text"/>
                            </p>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            className="mtop"
                        >
                            <Button className="view-reservation" onClick={() => history.push(Routes.ROUTE_MY_RESERVATIONS)}>
                                <FormattedMessage id="components.account.myAccount.upcoming_reservations.call_now"/>
                                <img src={RightArrow} alt="view-my-reservation"/>
                            </Button>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Section>
        );
    }
}

export { UpcomingReservations }
