import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import { Register, Login } from '../../components/auth'
import Page from "./Page";
import { ROUTE_REGISTER } from "../../config/routes";
import { FormattedMessage } from 'react-intl';
import { Messages } from '../../components/general/Messages';
import { MessageModal } from '../../components/modals/MessageModal';
import { FormattedHTMLMessage } from "react-intl";

class Auth extends Component {
    constructor(props) {
        super( props );
        this.state = {
            activeTab: this.props.location.pathname === ROUTE_REGISTER ? 'register' : 'login',
            showModal: true,
            showed: false,
            alerted: localStorage.getItem('alerted') || false
        }

    }

    changeTab = (tab) => {
        if (tab === this.state.activeTab) {
            return;
        }

        this.setState( { activeTab: tab } );
        this.props.history.push( tab );
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            showed: true
        });
    }

    render() {
        return (
            <Page class={`${this.state.activeTab}-container`} locate={this.props.locale}>
                {/*TODO Change this TAB component with material*/}
                <Tabs
                    id="authTab"
                    activeKey={this.state.activeTab}
                    onSelect={this.changeTab}>
                    <Tab eventKey="login" title={<FormattedMessage id="auth.login"/>}>
                        <Login />
                    </Tab>
                    <Tab eventKey="register" title={<FormattedMessage id="auth.register"/>}>
                        <Register/>
                    </Tab>
                </Tabs>
                <Messages />
                <MessageModal
                    open={(this.state.showModal && !this.state.showed && !this.state.alerted)}
                    handleClose={this.closeModal}
                    message={<FormattedHTMLMessage id="messages.reset_password_alert"/>}
                />
            </Page>
        )
    }
}

const mapStateToProps = state => ( {
    locale: state.locale.lang,
    loading: state.loading.pendingRequests
} );

const AuthComponent = connect( mapStateToProps, null )( Auth );

export { AuthComponent as Auth };
