import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Grid } from '@material-ui/core';
import { StyledRadio } from '../../general/CustomRadio';

class SelectLanguage extends Component {
    constructor(props) {
        super( props );

        this.state = {
            value: this.props.value
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value !== prevState.value) {
            return {
                ...prevState,
                value: nextProps.value,
            }
        }

        return prevState;
    }

    onChange = (event) => {
        this.setState( {
            value: event.target.value
        } );

        this.props.onChange( event );
    }

    render() {
        return <Fragment>
            <FormControl component="fieldset">
                <RadioGroup aria-label="language" name="language_id" value={this.state.value} onChange={this.onChange}>
                    <Grid container alignItems="center" className="select-language-container">
                        <FormControlLabel
                            className="custom-radio"
                            value="en"
                            control={<StyledRadio/>}
                            label={<FormattedMessage id="components.account.editProfile.selectLanguage.en"/>}
                        />
                        <FormControlLabel
                            className="custom-radio"
                            value="es"
                            control={<StyledRadio/>}
                            label={<FormattedMessage id="components.account.editProfile.selectLanguage.es"/>}
                        />
                    </Grid>
                </RadioGroup>
            </FormControl>
        </Fragment>
    }
};

export { SelectLanguage }

