import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Grid } from '@material-ui/core';
import { StyledRadio } from '../../general/CustomRadio';

class SelectMember extends Component {
    constructor(props) {
        super( props );

        this.state = {
            value: this.props.value
        }
    }

    onChange = (event) => {
        this.setState( {
            value: event.target.value
        } );

        this.props.onChange( event );
    }

    render() {
        return <Fragment>
            <FormControl component="fieldset">
                <RadioGroup aria-label="member" name="member" value={this.props.value} onChange={this.onChange}>
                    <Grid container alignItems="center" className="select-member-container">
                        <FormControlLabel
                            className="custom-radio"
                            value={true}
                            control={<StyledRadio/>}
                            label={<FormattedMessage id="components.account.reservationSummary.oneMoreStep.vida_vacations_member"/>}
                        />
                        <FormControlLabel
                            className="custom-radio"
                            value={false}
                            control={<StyledRadio/>}
                            label={<FormattedMessage id="components.account.reservationSummary.oneMoreStep.non_member"/>}
                        />
                    </Grid>
                </RadioGroup>
            </FormControl>
        </Fragment>
    }
};

export { SelectMember }
