import {
    USER_LOCALE
} from './types';

export const userLocale = (locale) => ({
    type: USER_LOCALE,
    payload: {
        lang: locale.lang || 'en',
        messages: locale.messages
    }
});
