import React, { createRef } from "react";
import Grid from '@material-ui/core/Grid';
import { ErrorMessage, Form, Formik } from "formik";
import { connect } from "react-redux";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import { LoginSchema } from "../../helpers/schemas";
import { FormattedMessage } from "react-intl";
import CustomTextField from '../general/CustomTextField';
import CustomPasswordField from '../general/CustomPasswordField';
import { ROUTE_FORGOT_PASSWORD } from '../../config/routes';
import { requestLogin } from '../../store/actions/user';

import ReCAPTCHA from "react-google-recaptcha";
//import Recaptcha from "react-recaptcha";
import {GOOGLE_SITE_KEY} from "../../config/config";

import {loginUser} from '../../api/user';

class LoginComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            captcha: null,
            isSubmitting: false,
            showMemberVidaId: false
        }

        this._reCaptchaRef = createRef();
    }

    submit = (data) => {
        const { email, password, recaptcha, vidaMemberId } = data;

        this.setState({isSubmitting: false});

        loginUser({
            data: {
                email: email || null,
                password: password || null,
                lang: this.props.lang,
                'g-recaptcha-response': recaptcha || null,
                vida_member_id: vidaMemberId || null
            }
        }).then((response) => {
            if(response){
                
                if (response.status === 201) {
                    this.props.requestLogin(response);
                }

                if (response.status === 409) {
                    this.setState({showMemberVidaId: true});
                }
            }

            this._reCaptchaRef.current.reset();

        });
    };

    renderForm = (props) => {
        const { touched, errors, isValid } = props ;

        return (
            <Form className="login-form" autoComplete="off">
                <Grid container direction="column" spacing={3}>
                    <Grid item className="custom-field">
                        <FormattedMessage id="globals.email_address">
                            {email_address =>
                                <CustomTextField
                                    type="email"
                                    name="email"
                                    label={email_address}
                                    error={errors.email && touched.email}
                                    onChange={e => props.setFieldValue( 'email', e.target.value )}
                                    handleBlur={props.handleBlur}
                                />
                            }
                        </FormattedMessage>
                        <ErrorMessage data-testid="errors-email" name="email" component="div" className="field-error"/>
                    </Grid>

                    <Grid item className="custom-field">
                        <FormattedMessage id="globals.password">
                            {password =>
                                <CustomPasswordField
                                    name="password"
                                    label={password}
                                    error={errors.password && touched.password}
                                    onChange={e => props.setFieldValue( 'password', e.target.value )}
                                    handleBlur={props.handleBlur}
                                />
                            }
                        </FormattedMessage>
                        <ErrorMessage name="password" component="div" className="field-error"/>
                    </Grid>

                    { this.state.showMemberVidaId && 
                        <Grid item className="custom-field">
                            <FormattedMessage id="globals.vida_member_id">
                                {vidaMemberId =>
                                    <CustomTextField
                                        type="text"
                                        name="vidaMemberId"
                                        label={vidaMemberId}
                                        error={errors.vidaMemberId && touched.vidaMemberId}
                                        onChange={e => props.setFieldValue('vidaMemberId', e.target.value )}
                                        handleBlur={props.handleBlur}
                                    />
                                }
                            </FormattedMessage>
                            <ErrorMessage data-testid="errors-email" name="email" component="div" className="field-error"/>
                        </Grid>
                    }
                    
                    
                    <Grid item className="custom-field">
                        <ReCAPTCHA
                                ref={this._reCaptchaRef}
                                sitekey={GOOGLE_SITE_KEY}
                                onChange={(value) => {
                                    value != null ? props.setFieldValue("recaptcha", value) : props.setFieldValue("recaptcha", "");
                                }}
                            hl = {this.props.lang}
                        />
                        <FormattedMessage id="globals.recaptcha">
                            {recaptcha =>
                                <CustomTextField
                                        type="hidden"
                                        name="recaptcha"
                                        error={errors.recaptcha && touched.recaptcha}
                                        onChange={e => props.setFieldValue( 'recaptcha', e.target.value )}
                                        handleBlur={props.handleBlur}
                                />
                            }
                        </FormattedMessage>
                        <ErrorMessage name="recaptcha" component="div" className="field-error"/>
                    </Grid>

                    <Grid item className="custom-field">
                        <Link className="login-link" to={ROUTE_FORGOT_PASSWORD}>
                            <FormattedMessage id="login.forgot"/>
                        </Link>
                    </Grid>

                    <Grid container>
                        <button  name="login"  type="submit"  onClick={() => this.setState({isSubmitting: true}) } >
                            <FormattedMessage id="globals.submit"/>
                            <span><ChevronRightIcon fontSize="small"/></span>
                        </button>
                    </Grid>
                </Grid>
            </Form>
        );
    };

    render() {
        return (
            <div className='login-container'>
                <Formik
                    initialValues={{ email: '', password: '', recaptcha: '',  vida_member_id: '' }}
                    validationSchema={LoginSchema}
                    onSubmit={this.submit}
                    enableReinitialize={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                >
                    {this.renderForm}
                </Formik>
            </div>
        )
    }
};

const mapStateToProps = state => ({
    lang: state.locale.lang
});

const Login = connect(mapStateToProps, { requestLogin })(LoginComponent);

export { Login, LoginComponent };
