import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { ShowMessage } from "./ShowMessage";
import { ShortMessage } from "./ShortMessage";

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '0'
    },
}))(MuiAccordionDetails);

export default function ReadMessageItem(props) {
    const { from_name , date, subject, to_name, message } = props.message;

    return (
        <div className="message-content">
            <Accordion square expanded={'panel'+props.message.id === props.selectedItem} onChange={props.handleChange('panel'+props.message.id)}>
                { 'panel'+props.message.id !== props.selectedItem &&
                <AccordionSummary aria-controls={'panel'+props.message.id+'d-content'} id={'panel'+props.message.id+'d-header'}>
                    <ShortMessage from={from_name} date={date} message={message}/>
                </AccordionSummary>
                }
                <AccordionDetails>
                    <ShowMessage from={from_name} subject={subject} to={to_name} date={date} message={message}/>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}




