import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: 'white',
        color: '#444444',
        font: 'normal normal normal 13px/16px Source Sans Pro',
        maxWidth: 220,
        border: '1px solid #446DB4',
    },
    arrow: {
        color: "#fff",
        "&::before": {
            backgroundColor: "white",
            border: "1px solid #446DB4",
            borderBottom: 0
        }
    }
}));

export default function CustomizedTooltips(props) {
    const {title, content} = props;
    const classes = useStyles();

    return (
        <div className="custom-tool-tip">
            <Tooltip  classes={classes} arrow
                title={
                    <React.Fragment>
                        <p className="tooltip-title">{title}</p>
                        <p className="tooltip-content">{content}</p>
                    </React.Fragment>
                }
            >
                <div>i</div>
            </Tooltip>
        </div>
    );
}
