import React from "react";
import Grid from '@material-ui/core/Grid';
import { ErrorMessage, Form, Formik } from "formik";
import Page from "./Page";
import { Title, Text } from "../../components";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ForgotPasswordSchema } from "../../helpers/schemas";
import { FormattedMessage } from "react-intl";
import { goBack } from "../../helpers/location";
import CustomTextField from '../../components/general/CustomTextField';
import { forgotPassword } from '../../api/user';
import { history } from '../../store/store';
import { ROUTE_FORGOT_PASSWORD_SUCCESS } from '../../config/routes';
import { Messages } from '../../components/general/Messages';

class ForgotPassword extends React.Component {

    submit = (data) => {
        const { email } = data;

        forgotPassword( {
            data: {
                email: email,
            }
        } ).then( (response) => {
            if (response.status === 201) {
                history.push( {
                    pathname: ROUTE_FORGOT_PASSWORD_SUCCESS,
                    state: {
                        email: email,
                        token: response.data.data.token
                    }
                } )
            }
        } );
    };

    renderForm = (props) => {
        const { touched, errors, isValid } = props;

        return (
            <Form className="forgot-form" autoComplete="off">
                <Grid container direction="column">
                    <Grid item className="custom-field">
                        <FormattedMessage id="globals.email_address">
                            {emailAddress =>
                                <CustomTextField
                                    type="email"
                                    name="email"
                                    label={emailAddress}
                                    error={errors.email && touched.email}
                                    onChange={e => props.setFieldValue( 'email', e.target.value )}
                                    handleBlur={props.handleBlur}
                                />
                            }
                        </FormattedMessage>
                        <ErrorMessage name="email" component="div" className="field-error"/>
                    </Grid>
                    <Grid container className="two-buttons">
                        <button
                            className="icon-left"
                            onClick={this.props.goBack}
                            type="button">
                            <span><ChevronLeftIcon fontSize="small"/></span>
                            <FormattedMessage id="globals.back"/>
                        </button>
                        <button
                            name="submit"
                            type="submit"
                            disabled={! isValid}>
                            <FormattedMessage id="globals.submit"/>
                            <span><ChevronRightIcon fontSize="small"/></span>
                        </button>
                    </Grid>
                </Grid>
            </Form>
        );
    };

    render() {
        return <Page class="forgot-password-container">
            <Grid container direction="column" className="auth-smaller-container">
                <Title className="center auth-title">
                    <FormattedMessage id="forgot_password.title"/>
                </Title>
                <Text className='center auth-subtitle'>
                    <FormattedMessage id="forgot_password.message"/>
                </Text>
                <Formik
                    initialValues={{ email: '' }}
                    validationSchema={ForgotPasswordSchema}
                    onSubmit={this.submit}
                    enableReinitialize={true}
                >
                    {this.renderForm}
                </Formik>
                <Messages/>
            </Grid>
        </Page>
    }
};

ForgotPassword.defaultProps = {
    goBack
};

export { ForgotPassword }
