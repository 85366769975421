import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {hideGlobalNotification} from "../../store/actions/globalNotification";
import CloseIcon from '../../resources/assets/account/contractsVacations/onClose.svg';
import {NOTIFICATION_ERROR, NOTIFICATION_WARNING} from "../../store/actions/types";

class GlobalNotificationComponent extends Component {
    render() {
        if (!this.props.message) {
            return null;
        }

        return (
            <div className={`global-notification-container ${this.props.type === NOTIFICATION_ERROR ? 'error' : this.props.type === NOTIFICATION_WARNING ? 'warning' : ''}`}>
                <span className="global-text">{this.props.message.includes('_') ? <FormattedMessage id={this.props.message}/> : this.props.message }</span>
                <img className="global-icon" onClick={() => this.props.hideGlobalNotification()} src={CloseIcon}  alt=""/>
            </div>
        );
    }
}

GlobalNotificationComponent.defaultProps = {
    message: null
}

const mapStateToProps = (state) => ({
    message: state.globalNotification.message,
    type: state.globalNotification.type,
});

const GlobalNotification = connect(mapStateToProps, {hideGlobalNotification})(GlobalNotificationComponent);

export {GlobalNotification, GlobalNotificationComponent};
