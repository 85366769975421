export const FEE_TYPES = {
    'USAGE': 'usage',
    'RENOVATION': 'renovation',
    'EXTENSION': 'extension',
    'RENEWAL': 'renewal',
    'USAGE_FEE': 'Usage Fee',
    'RENOVATION_FEE': 'Renovation Fee',
    'EXTENSION_FEE': '10 Year Cert Extension Fee',
    'RENEWAL_FEE': 'Renewal Fee',
    'USAGE_FEE_ID': 1,
    'RENOVATION_FEE_ID': 2,
    'EXTENSION_FEE_ID': 3,
    'RENEWAL_FEE_ID': 4,
}
