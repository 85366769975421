import React, {Component} from "react";
import {FormattedMessage} from "react-intl";
import {Grid} from '@material-ui/core';
import AddCircle from '../../../resources/assets/account/requestReservation/add-circle.svg';
import {AddNewCreditCardModal} from "./AddNewCreditCardModal";
import {spacingCardNumber} from "../../../helpers/general";
import {getCreditCards, updateCreditCard} from "../../../api/creditCards/creditCards";
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Modal from '@material-ui/core/Modal';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from '@material-ui/icons/Close';

class PaymentType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cards: [],
            open: false,
            selectedCard: null,
            openMessage : false,
            onlydata : false
        }
    }

    componentDidMount() {
        this.getCreditCards();
    }

    getCreditCards = () => {
        let payload = {};
        payload.disablePagination = true;

        getCreditCards(payload)
            .then(data => {

                data = Array.isArray(data) && data.length ? data.filter(x => x.status === 1) : []

                this.setState({
                    cards: data
                });
        });
    }

    openModal = (card) => {
        let header = document.querySelector('.sticky');
        header.style.zIndex = '1298';
        this.setState({
            open: true,
            selectedCard: card
        });
    }

    handleClose = () => {
        let header = document.querySelector('.sticky');
        header.style.zIndex = '1301';
        this.setState({
            open: false,
            selectedCard: null
        });
    }

    handleCloseMessage = () => {
        
        this.setState({
            openMessage: false,
        });
    }

    makeDefaultCard = (card) => {
        if (card.is_default) {
            return;
        }

        let payload = {
            creditCard: {
                ...card,
                is_default: true
            }
        }

        updateCreditCard(payload).then(data => {
            if (data && data.id) {
                this.getCreditCards();
            }
        });
    }

    openModalMessage = (card) => {
        let header = document.querySelector('.sticky');
        header.style.zIndex = '1298';
        this.setState({
            openMessage: true,
            selectedCard: card
        });
    }

    deleteCard = () => {
        let payload = {
            creditCard: {
                ...this.state.selectedCard,
                status: false
            }
        }

        updateCreditCard(payload).then(data => {
            if (data && data.id) {
                this.getCreditCards();
                this.setState({
                    openMessage: false,
                });
            }
        });
    }

    renderCards = (card, index) => {
        let defaultId = card.is_default ? 'components.account.editProfile.editProfileForm.payment_method.default' : 'components.account.editProfile.editProfileForm.payment_method.make_default';
        return <Grid key={index} item xs={12} sm={6} className="card-container">
            
            <div className="card-content">
                <span className="card-index-text">
                    <FormattedMessage id="components.account.editProfile.editProfileForm.payment_method.card"/>{` ${index + 1}`}
                </span>
                <span className="card-text">
                    {card.exp_month}/{card.exp_year}
                </span>
            </div>
            <div className="card-details">
                <img src={`/img/payments/${card.card_type.toLowerCase()}.svg`} alt="" className="card-img"/>
                <span className="card-number-hidden"
                      onClick={() => {
                            this.setState({
                                onlydata: true,
                            });
                           this.openModal(card)}
                        }
                      >
                          {spacingCardNumber(card.card_number, true)}
                </span>
                <span
                    className={`default-text ${card.is_default ? 'is-default' : ''}`}
                    onClick={() => this.makeDefaultCard(card)}>
                    <FormattedMessage id={defaultId}/>
                </span>
                <button onClick={(e) => {
                    e.preventDefault();
                    this.openModalMessage(card);
                }}>
                    <DeleteIcon className="add-circle"/>
                </button>
            </div>
        </Grid>
    }

    render() {

        return <div className="payment-type">
            {this.state.cards.length > 0 && <Grid container direction="row" alignItems="center" spacing={4}>
                {this.state.cards.map((card, index) => {
                    return this.renderCards(card, index);
                })}
            </Grid>
            }
            <Grid
                container
                direction="row"
                alignItems="center"
                className="add-new-container"
            >
                <button onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                        onlydata: false,
                    });
                    this.openModal()
                }}>
                    <img
                        className="add-new-img"
                        src={AddCircle}
                        alt=''
                    />
                    <span className="add-new-text">
                        <FormattedMessage id="components.account.editProfile.editProfileForm.payment_method.add_new"/>
                    </span>
                </button>
            </Grid>

            <AddNewCreditCardModal
                userId={this.props.userId}
                open={this.state.open}
                handleClose={() => this.handleClose()}
                card={this.state.selectedCard}
                actionCallback={() => this.getCreditCards()}
                onlydata ={this.state.onlydata}
                affiliation={this.props.profile.affiliation}
            />

            <Modal
                className="add-new-credit-card-modal"
                open={this.state.openMessage}
                onClose={() => this.handleCloseMessage()}>
                <div className="add-new-credit-card-modal-container">
                    <Grid container alignItems="center" justify="space-between" className="modal-title">
                        <Grid item>
                            <span>
                                <FormattedMessage id="components.account.editProfile.editProfileForm.add_new_credit_card_modal.delete.title"/>
                            </span>
                        </Grid>
                        <Grid item>
                            <CloseIcon onClick={(e) => {e.preventDefault(); this.handleCloseMessage();}}/>
                        </Grid>
                    </Grid>
                    <div className="modal-body with-out-padding">
                        <div style = {{ marginBottom: '116px' }}>
                            <FormattedMessage id="components.account.editProfile.editProfileForm.add_new_credit_card_modal.delete.message"/>
                        </div>

                        <div>

                            <button type="submit" className="submit-save-card-btn"  onClick={(e) => {
                                    e.preventDefault();
                                    this.deleteCard();
                                }}>
                                <FormattedMessage
                                    id="components.account.editProfile.editProfileForm.add_new_credit_card_modal.delete.button"/>
                                <span>
                                    <ChevronRightIcon fontSize="small"/>
                                </span>
                            </button>

                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    }
};

export {PaymentType}

