import React, { Component } from 'react';
import { connect } from "react-redux";
import Page from './layout/Page';
import { FAQsHeaderComponent} from "../../components/account/FAQs/FAQsHeaderComponent";
import {FaqComponent} from "../../components/account/FAQs/FaqComponent";

class FAQsComponent extends Component {
    render() {
        return <Page class="account faqs-page">
            <FAQsHeaderComponent />
            {this.props.faqs && <FaqComponent faqs={this.props.faqs} language={this.props.userProfile.language_id}/>}
        </Page>
    }
}

const mapStateToProps = (state) => ( {
    userProfile: state.user.profile,
    faqs: state.faqs.faqs.faqs
} )

const FAQs = connect( mapStateToProps, null )( FAQsComponent );

export { FAQs, FAQsComponent }
