export const COMPANIES = {
    RCI: 'RCI',
    INTERVAL: 'Interval',
    DAE: 'DAE',
    ICE_GALLERY: 'ICE Gallery',
    SFX: 'SFX',
    THIRD_HOME_INTERNATIONAL: 'ThirdHome International',
    CLUB_FREEDOM: 'Club Freedom',
    REGISTRY_COLLECTION: 'The Registry Collection',
    VIDA_LIFESTYLE: 'VIDA LIFESTYLE',
}
