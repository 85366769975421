import React, {Fragment} from "react";
import Grid from '@material-ui/core/Grid';
import {ErrorMessage, Form, Formik} from "formik";
import {connect} from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import {GOOGLE_SITE_KEY} from "../../config/config";
import {Toggle} from "../general";
import {
    ROUTE_TERMS_AND_CONDITIONS,
    ROUTE_PRIVACY_POLICY,
    ROUTE_REGISTER_SUCCESS,
} from '../../config/routes';
import {Link} from "react-router-dom";
import {RegisterSchema} from "../../helpers/schemas";
import {FormattedMessage} from "react-intl";
import CustomTextField from '../general/CustomTextField';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CustomPasswordField from '../general/CustomPasswordField';
import {registerUser} from '../../api/user';
import {history} from '../../store/store';
import CustomAutoComplete from "../general/CustomAutoComplete";
import {getAllCountriesFormatted, getCountryByName} from "../../helpers/general";

class RegisterComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            captcha: null,
            termsAndConditions: false,
            isSubmitting: false,
        }
    }

    submit = (data) => {
        const {
            firstName, lastName, email, password, passwordConfirmation,
            home_phone, mobile_phone, country, vidaMemberId, termsAndConditions
        } = data;

        this.setState({isSubmitting: false});

        registerUser({
            data: {
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: password,
                password_confirmation: passwordConfirmation,
                home_phone: home_phone,
                mobile_phone: mobile_phone,
                country: country,
                'g-recaptcha-response': this.state.captcha || null,
                vida_member_id: vidaMemberId || null,
                language_id: this.props.lang,
                terms_and_conditions: termsAndConditions,
                any_of: undefined,
            }
        }).then((response) => {
            if (response.status === 201) {
                history.push(ROUTE_REGISTER_SUCCESS, { pre_existing_account: response.data.data.pre_existing_account });
            }

            if (this.captcha && typeof this.captcha.reset === 'function') {
                this.captcha.reset();
            }
        });
    };

    handleTerms = (event, props) => {
        this.setState({
            termsAndConditions: event.target.checked
        }, () => {
            props.setFieldValue('termsAndConditions', event.target.checked);
        });
        props.handleBlur(event);

    };

    getFormattedLabelRequired = (id, text) => {
        const notRequiredFields = ['register.home_phone'];
        if (notRequiredFields.indexOf(id) === -1) {
            text = text + '*';
        }

        return text;
    }

    renderField = (type, name, id, props) => {
        const {errors, touched} = props;

        return <Fragment>
            <FormattedMessage id={id}>
                {text =>
                    <CustomTextField
                        type={type}
                        name={name}
                        label={this.getFormattedLabelRequired(id,text)}
                        error={errors[name] && touched[name]}
                        onChange={e => props.setFieldValue(name, e.target.value)}
                        handleBlur={props.handleBlur}
                    />
                }
            </FormattedMessage>
            <ErrorMessage name={name} component="div" className="field-error"/>
        </Fragment>
    }

    renderForm = (props) => {
        const {errors, touched, isValid} = props;
        let countries = getAllCountriesFormatted(this.props.lang);

        return (
            <Form className="register-form" autoComplete="off">
                <Grid container direction="row" spacing={2}>
                    <Grid item sm={6} xs={12} className="custom-field">
                        {this.renderField('text', 'firstName', 'globals.first_name', props)}
                    </Grid>

                    <Grid item sm={6} xs={12} className="custom-field">
                        {this.renderField('text', 'lastName', 'globals.last_name', props)}
                    </Grid>

                    <Grid item sm={6} xs={12} className="custom-field">
                        {this.renderField('email', 'email', 'globals.email_address', props)}
                    </Grid>

                    <Grid item sm={6} xs={12} className="custom-field">
                        {this.renderField('text', 'vidaMemberId', 'globals.vida_member_id', props)}
                    </Grid>

                    <Grid item sm={6} xs={12} className="custom-field">
                        {this.renderField('text', 'home_phone', 'register.home_phone', props)}
                        <ErrorMessage name="any_of" component="div" className="field-error"/>
                    </Grid>

                    <Grid item sm={6} xs={12} className="custom-field">
                        {this.renderField('text', 'mobile_phone', 'register.mobile_phone', props)}
                        <ErrorMessage name="any_of" component="div" className="field-error"/>
                    </Grid>

                    <Grid item sm={12} xs={12} className="custom-field">
                        <FormattedMessage id="register.residence">
                            {residence =>
                                <CustomAutoComplete
                                    name="country"
                                    error={errors.country && touched.country}
                                    onChange={newValue => props.setFieldValue('country', newValue ? newValue.name : '')}
                                    handleBlur={props.handleBlur}
                                    label={`${residence}*`}
                                    items={countries}
                                    value={getCountryByName(props.values.country, this.props.lang)}
                                />
                            }
                        </FormattedMessage>
                        <ErrorMessage name="country" component="div" className="field-error"/>
                    </Grid>

                    <Grid item sm={6} xs={12} className="custom-field">
                        <FormattedMessage id="globals.password">
                            {password =>
                                <CustomPasswordField
                                    name="password"
                                    label={`${password}*`}
                                    error={errors.password && touched.password}
                                    onChange={e => props.setFieldValue('password', e.target.value)}
                                    handleBlur={props.handleBlur}
                                />
                            }
                        </FormattedMessage>
                        <ErrorMessage name="password" component="div" className="field-error"/>
                    </Grid>

                    <Grid item sm={6} xs={12} className="custom-field">
                        <FormattedMessage id="globals.confirm_password">
                            {passwordConfirmation =>
                                <CustomPasswordField
                                    name="passwordConfirmation"
                                    label={`${passwordConfirmation}*`}
                                    error={errors.passwordConfirmation && touched.passwordConfirmation}
                                    onChange={e => props.setFieldValue('passwordConfirmation', e.target.value)}
                                    handleBlur={props.handleBlur}
                                />
                            }
                        </FormattedMessage>
                        <ErrorMessage name="passwordConfirmation" component="div" className="field-error"/>
                    </Grid>

                    <Grid item xs={12} md={6} className="custom-field">
                        <Grid container direction="row" className="toggle-container register">
                            <Toggle
                                name="termsAndConditions"
                                onChange={e => this.handleTerms(e, props)}
                                value={this.state.termsAndConditions}
                                // isRequiredField={true}
                                text={
                                    <FormattedMessage
                                        id="register.i_agree_with_terms_and_privacy"
                                        values={{
                                            terms: (<Link className="register-links" to={ROUTE_TERMS_AND_CONDITIONS} target="_blank">
                                                <FormattedMessage id="globals.terms_and_conditions"/>
                                            </Link>),
                                            privacy: (<Link className="register-links" to={ROUTE_PRIVACY_POLICY} target="_blank">
                                                <FormattedMessage id="globals.privacy_policy"/>
                                            </Link>)
                                        }}
                                    />}
                            >
                            </Toggle>
                        </Grid>
                        <ErrorMessage name="termsAndConditions" component="div" className="field-error center"/>
                    </Grid>

                    <Grid item xs={12} md={6} className="custom-field captcha">
                        <ReCAPTCHA
                            ref={e => (this.captcha = e)}
                            sitekey={GOOGLE_SITE_KEY}
                            onChange={(value) => this.setState({captcha: value})}
                            hl = {this.props.lang}
                        />

                    </Grid>

                </Grid>

                <Grid container direction="row">
                    <button
                        name="register"
                        type="submit"
                        onClick={() =>
                            this.setState({isSubmitting: true})
                        }
                        disabled={!isValid}>
                        <FormattedMessage id="globals.submit"/>
                        <span><ChevronRightIcon fontSize="small"/></span>
                    </button>
                </Grid>
            </Form>
        );
    };

    render() {
        return (
            <div>
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                        passwordConfirmation: '',
                        home_phone: '',
                        mobile_phone: '',
                        country: '',
                        optIn: false,
                        vidaMemberId: '',
                        termsAndConditions: false,
                        any_of: false,
                    }}
                    validationSchema={RegisterSchema}
                    onSubmit={this.submit}
                    validateOnChange={true}
                    enableReinitialize={true}
                    validate={() => {
                        if (this.state.isSubmitting) {
                            if (this.captcha && typeof this.captcha.reset === 'function') {
                                this.captcha.reset();
                            }
                        }

                        this.setState({isSubmitting: false});
                    }}
                >
                    {this.renderForm}
                </Formik>
            </div>
        )
    }
};

const mapStateToProps = state => ({
    lang: state.locale.lang
});

const Register = connect(mapStateToProps)(RegisterComponent);

export {Register, RegisterComponent};

