import {
    SET_CONTACT_REASONS,
} from '../actions/types';

const initialState = {
    reasons: []
};

export default function(state = initialState, action) {

    switch(action.type) {
        case SET_CONTACT_REASONS:
            const { reasons } = action.payload;
            return {
                ...state,
                reasons: reasons
            };
        default:
            return state;
    }
}
