import React, {Component} from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import {Formik, Form} from "formik";
import {FormattedMessage} from 'react-intl';
import {CardBillingInformation} from "../paymentMethod/CardBillingInformation";
import {AddNewCreditCardSchema} from "../../../helpers/schemas";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {createCreditCard, updateCreditCard} from "../../../api/creditCards/creditCards";
import {Messages} from "../../general/Messages";
import {NOTIFICATION_CREDIT_CARD_MODAL} from "../../../config/notifications";
import {EditCardForm} from "./EditCardForm";

class AddNewCreditCardModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {open, card, userId, onlydata, affiliation} = this.props;
        
        return <Modal
            className="add-new-credit-card-modal"
            open={open}
            onClose={this.props.handleClose}>
            <div className="add-new-credit-card-modal-container">
                <Grid container alignItems="center" justify="space-between" className="modal-title">
                    <Grid item>
                        <span>
                            <FormattedMessage
                                id="components.account.editProfile.editProfileForm.add_new_credit_card_modal.title"/>
                        </span>
                    </Grid>
                    <Grid item>
                        <CloseIcon onClick={this.props.handleClose}/>
                    </Grid>
                </Grid>

                <div className="modal-body">

                  <EditCardForm userId={userId} card={card} onlydata={onlydata} affiliation={affiliation}/>

                </div>
            </div>

        </Modal>
    }
}

export {AddNewCreditCardModal}
