import axios from 'axios';
import { store } from '../store/store';
import { hideSpinner, showSpinner } from "../store/actions/loading";
import { hideNotification } from "../store/actions/notification";

export const getAxios = (shouldHideNotification = true) => {
    const state = store.getState();
    const locale = state.locale.lang;

    axios.defaults.headers[process.env.REACT_APP_API_LOCALE_HEADER] = locale;

    if (shouldHideNotification) {
        store.dispatch(hideNotification());
    }

    axios.interceptors.request.use(function (config) {
        store.dispatch(showSpinner());
        return config;
    }, function (error) {
        store.dispatch(showSpinner());
        return Promise.reject(error);
    });


    axios.interceptors.response.use(function (response) {
        store.dispatch(hideSpinner());
        return response;
    }, function (error) {
        store.dispatch(hideSpinner());

        return Promise.reject(error);
    });

    return axios;
};
