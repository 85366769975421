import {
    SET_HOTELS
} from "./types";

export const setHotels = (
    payload
) => ( {
    type: SET_HOTELS,
    payload: {
        hotels: payload || []
    }
} )
