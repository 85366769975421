import * as Yup from "yup";
import {
    emailRules,
    firstNameRules,
    lastNameRules,
    passwordRegisterRules,
    passwordConfirmationRules,
    passwordRules,
    verificationCodeRules,
    countryRules,
    mobilePhoneRules,
    homePhoneRules,
    termsAndConditionsRules,
    vidaMemberIdRules,
    selectDestinationRules,
    addressRules,
    messageRules,
    selectPropertyRules,
    confirmationNumberRules,
    checkInDateRules,
    travelerLastNameRules,
    selectUnitTypeRules,
    selectExpirationMonthRules,
    selectExpirationYearRules,
    cvvRules,
    cardNumberRules,
    cardNameRules,
    stateRules,
    cityRules,
    postalCodeRules,
    newPasswordRules,
    currentPasswordRules,
    newPasswordConfirmation,
    selectReasonRules,
    paymentTypeAllRules,
    phoneRules,
    selectArrivingDateRules,
    selectDepartingDateRules,
    cardIdRules,
    downPaymentAmountRules,
    paymentTypeIdsMonthly,
    emailRulesNoChild,
    recaptchaRules,
    addressRulesRequired
} from "./validation";

export const RegisterSchema = Yup.object().shape({
    firstName: firstNameRules,
    lastName: lastNameRules,
    email: emailRules,
    country: countryRules,
    home_phone: homePhoneRules,
    mobile_phone: mobilePhoneRules,
    password: passwordRegisterRules,
    passwordConfirmation: passwordConfirmationRules,
    termsAndConditions: termsAndConditionsRules,
    vidaMemberId: vidaMemberIdRules,
});

export const LoginSchema = Yup.object().shape({
    email: emailRules,
    password: passwordRules,
    recaptcha: recaptchaRules
});

export const ForgotPasswordSchema = Yup.object().shape({
    email: emailRules
});

export const ResendEmailVerificationSchema = Yup.object().shape({
    email: emailRules
});

export const ResetPasswordSchema = Yup.object().shape({
    verification_code: verificationCodeRules,
    password: passwordRegisterRules,
    password_confirmation: passwordConfirmationRules
});

export const EditProfileSchema = Yup.object().shape({
    email: emailRules,
    home_phone: homePhoneRules,
    mobile_phone: homePhoneRules,
    address_line_1: addressRulesRequired,
    country: countryRules,
    state: stateRules,
    city: cityRules,
    postal_code: postalCodeRules
});

export const SearchDestinationSchema = Yup.object().shape({
    destination: selectDestinationRules,
    arrivingDate: selectArrivingDateRules,
    departingDate: selectDepartingDateRules
});

export const SendConfirmationSchema = Yup.object().shape({
    email: emailRules,
});

export const SendMessageSchema = Yup.object().shape({
    message: messageRules
})

export const RequestReservationSchema = Yup.object().shape({
    destination: selectDestinationRules,
    arrivingDate: selectArrivingDateRules,
    departingDate: selectDepartingDateRules,
    unitType: selectUnitTypeRules,
});

export const ReservationSummarySchema = Yup.object().shape({
    firstName: firstNameRules,
    lastName: lastNameRules,
    email: emailRules,
    phone: phoneRules,
    country: countryRules
})

export const AdditionalTravelerSchema = Yup.object().shape({
    age: Yup.string().required(),
    first_name: firstNameRules,
    last_name: lastNameRules,
    email: emailRulesNoChild,
})

export const AddReservationValidationSchema = Yup.object().shape({
    property: selectPropertyRules,
    confirmationNumber: confirmationNumberRules,
    checkInDate: checkInDateRules,
    travelerLastName: travelerLastNameRules
});

export const ContactSchema = Yup.object().shape({
    reason: selectReasonRules,
    email: emailRules,
    phone: mobilePhoneRules,
    message: messageRules
});

export const AddNewCreditCardSchema = Yup.object().shape({
    // card_number: cardNumberRules,
    // card_holder_name: cardNameRules,
    // exp_month: selectExpirationMonthRules,
    // exp_year: selectExpirationYearRules,
    // cvv: cvvRules,
    // first_name: firstNameRules,
    // last_name: lastNameRules,
    // address: addressRules,
    // country: countryRules,
    // state: stateRules,
    // city: cityRules,
    // postal_code: postalCodeRules

    bill_to_forename: firstNameRules,
    bill_to_surname: lastNameRules,
    bill_to_address_line1: addressRules,
    bill_to_address_city: cityRules,
    bill_to_address_postal_code: postalCodeRules,
    country: countryRules,
    bill_to_address_country: countryRules,
    bill_to_address_state: stateRules,
    state: stateRules,
});

export const ChangePasswordSchema = Yup.object().shape({
    current_password: currentPasswordRules,
    new_password: newPasswordRules,
    confirm_new_password: newPasswordConfirmation
});

export const PaymentTypeSchema = Yup.object().shape({
    paymentType: paymentTypeAllRules,
});

export const DownPaymentWithExistingCard = Yup.object().shape({
    order_amount: downPaymentAmountRules,
    max_amount: Yup.string().required(),
    contract_id: Yup.string().required(),
    paymentTypeIds: Yup.array().required(),
    card_id: cardIdRules,
});

export const OtherFeesPaymentExistingCardSchema = Yup.object().shape({
    order_amount: downPaymentAmountRules,
    max_amount: Yup.string().required(),
    contract_id: Yup.string().required(),
    paymentTypeIds: Yup.array().required(),
    card_id: cardIdRules,
});

export const MonthlyPaymentExistingCardSchema = Yup.object().shape({
    order_amount: downPaymentAmountRules,
    max_amount: Yup.string().required(),
    contract_id: Yup.string().required(),
    paymentTypeIds: paymentTypeIdsMonthly,
    card_id: cardIdRules,
});

export const DownPaymentWithNewCard = Yup.object().shape({
    order_amount: downPaymentAmountRules,
    max_amount: Yup.string().required(),
    contract_id: Yup.string().required(),
    paymentTypeIds: paymentTypeIdsMonthly,

    bill_to_forename: firstNameRules,
    bill_to_surname: lastNameRules,
    bill_to_address_line1: addressRules,
    bill_to_address_city: cityRules,
    bill_to_address_postal_code: postalCodeRules,
    country: countryRules,
    bill_to_address_country: countryRules,
    bill_to_address_state: stateRules,
    state: stateRules,

    // card_number: cardNumberRules,
    // card_holder_name: cardNameRules,
    // exp_month: selectExpirationMonthRules,
    // exp_year: selectExpirationYearRules,
    // cvv: cvvRules,
    //first_name: firstNameRules,
    //last_name: lastNameRules,
    //address: addressRules,
    // country: countryRules,
    // state: stateRules,
    //city: cityRules,
    //postal_code: postalCodeRules,
});

export const OtherFeesPaymentNewCardSchema = Yup.object().shape({
    order_amount: downPaymentAmountRules,
    max_amount: Yup.string().required(),
    contract_id: Yup.string().required(),
    paymentTypeIds: paymentTypeIdsMonthly,

    bill_to_forename: firstNameRules,
    bill_to_surname: lastNameRules,
    bill_to_address_line1: addressRules,
    bill_to_address_city: cityRules,
    bill_to_address_postal_code: postalCodeRules,
    country: countryRules,
    bill_to_address_country: countryRules,
    bill_to_address_state: stateRules,
    state: stateRules,

    // card_number: cardNumberRules,
    // card_holder_name: cardNameRules,
    // exp_month: selectExpirationMonthRules,
    // exp_year: selectExpirationYearRules,
    // cvv: cvvRules,
    //first_name: firstNameRules,
    //last_name: lastNameRules,
    //address: addressRules,
    // country: countryRules,
    // state: stateRules,
    //city: cityRules,
    //postal_code: postalCodeRules,
});
export const MonthlyPaymentNewCardSchema = Yup.object().shape({
    order_amount: downPaymentAmountRules,
    max_amount: Yup.string().required(),
    contract_id: Yup.string().required(),
    paymentTypeIds: paymentTypeIdsMonthly,

    bill_to_forename: firstNameRules,
    bill_to_surname: lastNameRules,
    bill_to_address_line1: addressRules,
    bill_to_address_city: cityRules,
    bill_to_address_postal_code: postalCodeRules,
    country: countryRules,
    bill_to_address_country: countryRules,
    bill_to_address_state: stateRules,
    state: stateRules,

    // card_number: cardNumberRules,
    // card_holder_name: cardNameRules,
    // exp_month: selectExpirationMonthRules,
    // exp_year: selectExpirationYearRules,
    // cvv: cvvRules,
    //first_name: firstNameRules,
    //last_name: lastNameRules,
    //address: addressRules,
    // country: countryRules,
    // state: stateRules,
    //city: cityRules,
    //postal_code: postalCodeRules,

});
