import React, { Component } from 'react';
import {Section} from "../../layout/Section";
import { FormattedMessage } from "react-intl";
import AboutVPassportVideoEn from '../../../resources/assets/video/about-vpassport-en.mp4';
import AboutVPassportVideoEs from '../../../resources/assets/video/about-vpassport-es.mp4';
import Grid from '@material-ui/core/Grid';

class AboutVideoSection extends Component {
    render() {
        let { language } = this.props;
        return (
            <Section color="white">
                <Grid container direction={this.props.device.isMobile ? "column" : "row"} className="about-video-section">
                    <Grid item xs={12} sm={6} className="video-container">
                        <video className="video-frame" title="vidanta-video" controls poster={ process.env.PUBLIC_URL + '/img/whats_is_vpassport/what-is-mobile.jpg'} preload="metadata">
                            <source src={(language == "en" ? AboutVPassportVideoEn : AboutVPassportVideoEs) }/>
                        </video>
                    </Grid>
                    <Grid item xs={12} sm={6} className="text-container">
                        <h2><FormattedMessage id="components.account.about.about_video_section.title"/></h2>
                        <p><FormattedMessage id="components.account.about.about_video_section.sub_title"/></p>
                    </Grid>
                </Grid>
            </Section>
        )
    }
}

export {AboutVideoSection}
