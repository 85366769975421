import React, {Component} from 'react';
import Page from '../../../pages/account/layout/Page';
import {PrivacyPolicyList} from "./PrivacyPolicyList";
import {Section} from "../../layout/Section";

class PrivacyPolicyAuthenticated extends Component {
    render() {
        return (
            <Page
                class="account privacyPolicy"
                titleId="pages.basic.privacy_policy.title"
            >
                <Section color="white">
                    <PrivacyPolicyList color="black"/>
                </Section>
            </Page>
        );
    }
}

export {PrivacyPolicyAuthenticated};
