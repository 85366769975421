import React, {Component} from 'react';
import Modal from '@material-ui/core/Modal';
import {ErrorMessage, Form, Formik} from "formik";
import {connect} from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import {FormattedMessage} from 'react-intl';
import {ChangePasswordSchema} from "../../../helpers/schemas";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CustomPasswordField from "../../general/CustomPasswordField";
import {requestUpdateProfilePassword} from "../../../store/actions/user";
import {Messages} from "../../general/Messages";
import {NOTIFICATION_UPDATE_USER_MODAL} from "../../../config/notifications";
import {updateProfile} from "../../../api/user";

class ChangePasswordModalComponent extends Component {
    submit = (data) => {
        let payload = {
            current_password: data.current_password,
            new_password: data.new_password,
            new_password_confirmation: data.confirm_new_password,
            isModal: true,
        };

        updateProfile(payload).then((response) => {
            this.props.requestUpdateProfilePassword(payload, response);
        });
    }

    render() {
        let {open} = this.props;

        return <Modal
            className="update-user-modal"
            open={open}
            onClose={this.props.handleClose}>
            <div className="update-user-modal-container">
                <Grid container alignItems="center" justify="space-between" className="modal-title">
                    <Grid item>
                        <span>
                            <FormattedMessage
                                id="components.account.editProfile.editProfileForm.change_password_modal.title"/>
                        </span>
                    </Grid>
                    <Grid item>
                        <CloseIcon onClick={this.props.handleClose}/>
                    </Grid>
                    <Messages notificationId={NOTIFICATION_UPDATE_USER_MODAL}/>
                    <Messages/>
                </Grid>

                <div className="modal-body">
                    <Formik
                        initialValues={{
                            current_password: '',
                            new_password: '',
                            confirm_new_password: ''
                        }}
                        validationSchema={ChangePasswordSchema}
                        onSubmit={(values, props) => {
                            this.submit(values, props);

                            props.resetForm({
                                current_password: '',
                                new_password: '',
                                confirm_new_password: ''
                            });
                        }}
                        validateOnChange={true}
                        enableReinitialize={true}>
                        {props => (
                            <Form className="change-password-form" autocomplete="off">
                                <Grid container direction="row">
                                    <Grid item xs={12} className="custom-field black">
                                        <FormattedMessage id="globals.currentPassword">
                                            {currentPassword =>
                                                <CustomPasswordField
                                                    name="current_password"
                                                    label={currentPassword}
                                                    error={props.errors.current_password && props.touched.current_password}
                                                    onChange={e => {
                                                        props.setFieldValue('current_password', e.target.value)
                                                    }}
                                                    handleBlur={props.handleBlur}
                                                    showIcon={false}
                                                    value={props.values.current_password}
                                                />
                                            }
                                        </FormattedMessage>
                                        <ErrorMessage name="current_password" component="div" className="field-error"/>
                                    </Grid>
                                    <Grid item xs={12} className="custom-field black">
                                        <FormattedMessage id="globals.new_password">
                                            {new_password =>
                                                <CustomPasswordField
                                                    name="new_password"
                                                    label={new_password}
                                                    error={props.errors.new_password && props.touched.new_password}
                                                    onChange={e => {
                                                        props.setFieldValue('new_password', e.target.value)
                                                    }
                                                    }
                                                    handleBlur={props.handleBlur}
                                                    showIcon={false}
                                                    value={props.values.new_password}
                                                />
                                            }
                                        </FormattedMessage>
                                        <ErrorMessage name="new_password" component="div" className="field-error"/>
                                    </Grid>

                                    <Grid item xs={12} className="custom-field black">
                                        <FormattedMessage id="globals.confirm_new_password">
                                            {passwordConfirmation =>
                                                <CustomPasswordField
                                                    name="confirm_new_password"
                                                    label={passwordConfirmation}
                                                    error={props.errors.confirm_new_password && props.touched.confirm_new_password}
                                                    onChange={e => {
                                                        props.setFieldValue('confirm_new_password', e.target.value)
                                                    }}
                                                    handleBlur={props.handleBlur}
                                                    showIcon={false}
                                                    value={props.values.confirm_new_password}
                                                />
                                            }
                                        </FormattedMessage>
                                        <ErrorMessage name="confirm_new_password" component="div"
                                                      className="field-error"/>
                                    </Grid>
                                </Grid>
                                <button type="submit" className="submit-save-btn">
                                    <FormattedMessage
                                        id="components.account.editProfile.editProfileForm.change_password_modal.submit"/>
                                    <span>
                                        <ChevronRightIcon fontSize="small"/>
                                    </span>
                                </button>
                            </Form>
                        )}
                    </Formik>
                   
                </div>
            </div>
        </Modal>
    }
}

ChangePasswordModalComponent.defaultProps = {
    open: false,
};

const ChangePasswordModal = connect(null, {requestUpdateProfilePassword})(ChangePasswordModalComponent);

export {ChangePasswordModal, ChangePasswordModalComponent};
