import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Page from './layout/Page';
import { SecondNav } from "../../components/layout/Navbars/header/secondNav/SecondNav";
import { DownPaymentForm } from "../../components/account/paymentMethod/DownPayment/DownPaymentForm";
import { CashOutPaymentForm } from "../../components/account/paymentMethod/CashOutPayment/CashOutPaymentForm";
import { MonthlyPaymentForm } from "../../components/account/paymentMethod/MonthlyPayment/MonthlyPaymentForm";
import { OtherFeesPaymentForm } from "../../components/account/paymentMethod/OtherFeesPayment/OtherFeesPaymentForm";
import { history } from "../../store/store";
import { Section } from "../../components/layout/Section";
import { PAYMENT_TYPES } from "../../config/constants/payment_types";

class PaymentMethod extends Component {
    constructor(props) {
        super(props);

        this.formRef = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderPageContent = (paymentType) => {
        let { contract } = this.props.location.state;

        // Block the app crash of no data are coming from TSW -> TODO: remove this ?
        if ( !contract.payment_types[paymentType] || !contract.payment_types[paymentType].length) {
            console.log('Block the app crash of no data are coming from TSW');
            history.goBack();
            return;
        }

        switch (paymentType) {
            case PAYMENT_TYPES.DOWN:
                return <DownPaymentForm
                    title="components.account.paymentMethod.downPayment.title"
                    type={ paymentType }
                    contract={ contract }
                />

            case PAYMENT_TYPES.MONTHLY : {
                return <MonthlyPaymentForm
                    title="components.account.paymentMethod.monthly_payment.title"
                    type={ paymentType }
                    contract={ contract }
                />
            }

            case PAYMENT_TYPES.OTHER_FEES:
                return <OtherFeesPaymentForm
                    title="components.account.paymentMethod.other_fees.title"
                    type={ paymentType }
                    contract={ contract }
                />

            case PAYMENT_TYPES.CASH_OUT:
                return <CashOutPaymentForm
                    title="components.account.paymentMethod.cash_out.title"
                    type={ paymentType }
                    contract={ contract }
                />

            default:
                return <DownPaymentForm
                    title="components.account.paymentMethod.downPayment.title"
                    type={ paymentType }
                    contract={ contract }
                />
        }
    }

    render() {
        const paymentType = this.props.match.params.type;

        // Block the app crash if user enter this url, without data
        if ( !this.props.location.state) {
            return <Section color="black" className="account payment-method">
                <div className="text-center">
                    <FormattedMessage id="messages.no_data_available"/>
                </div>
            </Section>
        }

        return <Page class="account payment-method">
            <SecondNav
                linkText={
                    <FormattedMessage id="pages.account.payment_method.back_link_text"/>
                }
            >
            </SecondNav>
            { this.renderPageContent(paymentType) }
        </Page>
    }
}

export { PaymentMethod };

