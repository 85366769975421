import React, { Component } from 'react';
import { Section } from "../../layout/Section";
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import VidaVacationsLogo from "../../../resources/assets/account/payments/vida-vacations.svg";
import DownloadIcon from "../../../resources/assets/account/payments/download-icon.svg";
import { downloadOrder } from "../../../api/order/order";

class SuccessPaymentContent extends Component {
    downloadInvoice = () => {
        let orderId = this.props.orderId;

        downloadOrder({orderId: orderId});
    }

    render() {
        return (
            <Section color="white" className="success-payment-content">
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={4} sm="auto" className="logo-img">
                            <img src={VidaVacationsLogo} alt="vida-payments-logo"/>
                        </Grid>
                        <Grid item xs={8} sm="auto" className="title-right">
                            <h1 className="my-vida-vacations-payments-title">
                                <FormattedMessage
                                    id="components.account.myVidaVacationsPayments.PaymentType.my_vida_vacations_payments"/>
                            </h1>
                        </Grid>
                    </Grid>

                    <div className="payment-received">
                        <span className="payment-received-title">
                            <FormattedMessage
                                id="components.account.myVidaVacationsPayments.successPaymentContent.title"/>
                        </span>
                    </div>

                    <div className="thank-container">
                        <span className="thank-text">
                            <FormattedMessage
                                id="components.account.myVidaVacationsPayments.successPaymentContent.thanks"/>
                        </span>
                    </div>
                </Grid>
                <button className="submit download-invoice" onClick={() => this.downloadInvoice()}>
                    <img className="download-icon" src={DownloadIcon} alt=""/>
                    <FormattedMessage id="components.account.myVidaVacationsPayments.successPaymentContent.download"/>
                </button>
            </Section>
        )
    }
}

export { SuccessPaymentContent }

