import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export default function CustomAutoComplete(props) {
    return (
        <Autocomplete
            className={`custom-autocomplete ${props.error ? 'has-error' : ''} ${props.class ? props.class : ''}`}
            id={props.name}
            name={props.name}
            onChange={(e, newValue) => {
                props.onChange(newValue);
            }}
            onBlur={e => props.handleBlur(e)}
            autoComplete={false}
            popupIcon={<KeyboardArrowDownIcon/>}
            options={props.items}
            getOptionLabel={(option) => option && option.name ? option.name : ''}
            renderInput={(params) => {
                return <TextField
                    autoComplete="off"
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                        form: {
                            autocomplete: "off",
                        },
                    }}
                    label={props.label}/>
            }}
            value={ props.value ? props.value : null }
        />
    );
}
