import React, {Component} from 'react';
import {Section} from "../../layout/Section";
import Grid from '@material-ui/core/Grid';
import {FormattedMessage} from "react-intl";
import {withRouter} from "react-router";
import {getDestinationImageRequest} from '../../../helpers/general';

class YourReservation extends Component {
    render() {
        return (
            <Section color="white">
                <div className="your-reservation">
                    <h1><FormattedMessage id="components.account.reservationSummary.oneMoreStep.your_reservation"/></h1>
                    <div className="reservation-summary-information-container">
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <img className="reservation-summary-image" src={getDestinationImageRequest(this.props.location.state.data)} alt=""/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="reservation-info">
                                    <div className="reservation-info-text">
                                        <h2>{this.props.location.state ? this.props.location.state.data.unitTypeName : ''}</h2>
                                        <h3>
                                            <FormattedMessage
                                                id="components.account.reservationSummary.yourReservation.resort"/>: 
                                            {this.props.location.state ? this.props.location.state.data.hotel : ''}
                                        </h3>
                                        <h4><FormattedMessage
                                            id="components.account.reservationSummary.yourReservation.promo_code"/> {this.props.location.state ? this.props.location.state.data.promoCode : ''}</h4>
                                    </div>

                                    <div className="reservation-summary-dates">
                                        <Grid container spacing={0}>
                                            <Grid item xs={6}>
                                                <div className="reservation-date-container">
                                                    <p className="arriving-departing">
                                                        <FormattedMessage
                                                            id="components.layout.header.filters.arriving"/>
                                                    </p>
                                                    <p className="reservation-date">
                                                        {this.props.location.state ? this.props.location.state.data.arrivingDate : ''}
                                                    </p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="reservation-date-container">
                                                    <p className="arriving-departing">
                                                        <FormattedMessage
                                                            id="components.layout.header.filters.departing"/>
                                                    </p>
                                                    <p className="reservation-date">
                                                        {this.props.location.state ? this.props.location.state.data.departingDate : ''}
                                                    </p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Section>
        );
    }
}

export default withRouter(YourReservation);
