import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Section } from '../../layout/Section';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ReservationItem} from "./ReservationItem";
import Grid from "@material-ui/core/Grid";
import { checkDevice } from "../../../helpers/checkDevice";
import { ReservationItemEmpty } from './ReservationItemEmpty';

class PastReservations extends Component {
    constructor(props) {
        super( props );

        this.state = {
            device: checkDevice(),
            expanded: ''
        }
    }

    handleChange = (panel) => (e, isExpanded) => {
        this.setState( {
            expanded: isExpanded ? panel : false
        } );
    }

    setDevice = () => {
        this.setState( {
            device: checkDevice()
        } );
    }

    componentDidMount() {
        window.addEventListener( 'resize', this.setDevice );
    }

    componentWillUnmount() {
        window.removeEventListener( 'resize', this.setDevice );
    }

    renderReservations = (reservations) => {
        if (! reservations.length) {
            return;
        }

        return reservations.map( (reservation, index) => {
            return <ReservationItem key={index} reservation={reservation} index={index} hideActionsButtons={true}/>
        } );
    }

    renderEmptyReservations = (reservations) => {
        const { innerWidth: width } = window;

        if (! reservations.length) {
            return;
        }
        
        var empties = [];
        var limitLines = 0;

        if (width > 500 && width < 959) {
            limitLines = 2;
        }
        
        if(width > 959){
            limitLines = 3;
        }

        var len =  reservations.length / limitLines;
        var lenText = len.toString();
        var lines = !(lenText?.indexOf(".") == -1) ?  Math.floor(len) + 1 : Math.floor(len);

        var items = ((lines * limitLines) - reservations.length);
        
        for(let x = 0; x <= items -1 ; x++){
            empties.push(0);
        }

        return empties.map( (empties, index) => {
            return  <ReservationItemEmpty key={index} uncoming ={ false }/> 
        } );

    }

    renderList = () => {
        let { pastReservations } = this.props;
        let { device } = this.state;

        return <Fragment>
            {pastReservations.length > 0 && <div style={{width: '100%'}}>

                <p className="past-reservations-title">
                    <FormattedMessage id="components.account.myReservations.past_reservations.past_text"/>
                </p>
                <Grid container direction="row" spacing={1} className="past-reservations-container">
                    { this.renderReservations(pastReservations) }
                    { this.renderEmptyReservations(pastReservations) }
                </Grid>

            </div> }
        </Fragment>

    }

    renderAccordion = () => {
        let { pastReservations } = this.props;
        let { expanded } = this.state;

        return (
            <div className="accordion">
                { pastReservations.length > 0 && <Accordion expanded={expanded === 'pastReservations'}
                           onChange={this.handleChange( 'pastReservations' )}>
                    <AccordionSummary
                        className="custom-title"
                        aria-controls="past-reservations-content"
                        id="past-reservations-header"
                    >
                        <div className="custom-accordion">
                        <span className="past-reservations-title pb-0 pt-0">
                            <FormattedMessage id="components.account.myReservations.past_reservations.past_text"/>
                        </span>
                        <span className={`expand-icon ${expanded === 'pastReservations' ? 'open' : 'closed'}`}>
                            <ExpandMoreIcon/>
                        </span>
                        </div>
                       
                    </AccordionSummary>
                    <div>
                        {this.renderReservations(pastReservations)}
                    </div>
                </Accordion> }
            </div>
        )
    }

    render() {
        const { device } = this.state;

        return <Section color="white past-reservations">
            { device.isMobile ? this.renderAccordion() : this.renderList() }
        </Section>
    }
}

export { PastReservations }
