import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';

class MonthlyPaymentHeaderItem extends Component {

    render() {
        const {text, value, type, selectedItem} = this.props;
        return <div
            className={`monthly-payment-header-item ${type === selectedItem ? 'selected' : ''}`}
            onClick={evt => {
                evt.preventDefault();
                this.props.changeMonthlyPaymentType(type)
            }}>
             <span className="label">
                <FormattedMessage id={text} />
            </span>
            {value && <span className="value">{`$${value}`}</span>}
        </div>
    }
}

export {MonthlyPaymentHeaderItem}
