import React, { Component, Fragment } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import {
    Auth,
    Dashboard,
    RegistrationSuccess,
    ForgotPassword,
    ResetPasswordSuccess,
    ResetPassword,
    VerifyEmail,
    VerifyEmailSuccess,
    ResendEmailVerification,
    TermsAndConditions,
    PrivacyPolicy,
    MyAccount,
    MyReservations,
    EditProfile,
    MessagesCenter,
    MyVidaVacationsPayments,
    MyVidaVacationsPaymentsHistory,
    ReadMessage,
    RequestReservation,
    AddReservationToProfile,
    PaymentMethod,
    FAQs,
    MyVidaVacationsContracts,
    Exchanges,
    About,
    Contact,
    ClubFreedomBenefits,
    PreRegistration,
    SuccessPayment,
    DestinationsHighlights,
    ReservationSummary,
    ReservationFound, 
    NotFoundPage,
    FailPayment,
    CancelledPayment,
    SuccessTokenization
} from '../pages/index';

import ProtectedRoute from "./ProtectedRoute";
import NotAuthenticated from "./NotAuthenticated";

import {
    ROUTE_HOME,
    ROUTE_REGISTER,
    ROUTE_REGISTER_SUCCESS,
    ROUTE_FORGOT_PASSWORD_SUCCESS,
    ROUTE_RESET_PASSWORD,
    ROUTE_RESET_PASSWORD_SUCCESS,
    ROUTE_LOGIN,
    ROUTE_DASHBOARD,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_VERIFY_EMAIL,
    ROUTE_VERIFY_EMAIL_SUCCESS,
    ROUTE_RESEND_EMAIL_VERIFICATION,
    ROUTE_TERMS_AND_CONDITIONS,
    ROUTE_PRIVACY_POLICY,
    ROUTE_MY_ACCOUNT,
    ROUTE_MY_RESERVATIONS,
    ROUTE_EDIT_PROFILE,
    ROUTE_MESSAGES_CENTER,
    ROUTE_MY_VIDA_VACATIONS_PAYMENTS,
    ROUTE_MY_VIDA_VACATIONS_PAYMENTS_HISTORY,
    ROUTE_REQUEST_RESERVATION,
    ROUTE_ADD_RESERVATION_TO_PROFILE,
    ROUTE_PAYMENT_METHOD,
    ROUTE_READ_MESSAGE,
    ROUTE_FAQS,
    ROUTE_MY_VIDA_VACATIONS_CONTRACTS,
    ROUTE_EXCHANGES,
    ROUTE_ABOUT_US,
    ROUTE_CONTACT_US,
    ROUTE_CLUB,
    ROUTE_PRE_REGISTRATION,
    ROUTE_SUCCESS_PAYMENT,
    ROUTE_DESTINATION_HIGHLIGHTS,
    ROUTE_RESERVATION_SUMMARY,
    ROUTE_RESERVATION_FOUND,
    ROUTE_FORGOT_PASSWORD_SUCCESS_TOKEN,
    ROUTE_FAIL_PAYMENT,
    ROUTE_CANCELLED_PAYMENT,
    ROUTE_SUCCESS_TOKENIZATION
} from '../config/routes';


class Router extends Component {

    render() {
        return (
            <Fragment>
                <Switch>

                    <NotAuthenticated path={ROUTE_HOME} exact component={Auth}/>
                    <NotAuthenticated path={ROUTE_REGISTER} exact component={Auth}/>
                    <NotAuthenticated path={ROUTE_FORGOT_PASSWORD_SUCCESS} exact component={ResetPassword}/>
                    <NotAuthenticated path={ROUTE_FORGOT_PASSWORD} exact component={ForgotPassword}/>
                    <NotAuthenticated path={ROUTE_LOGIN} exact component={Auth}/>

                    <NotAuthenticated path={ROUTE_REGISTER_SUCCESS} exact component={RegistrationSuccess}/>
                    <NotAuthenticated path={ROUTE_RESET_PASSWORD_SUCCESS} exact component={ResetPasswordSuccess}/>
                    <NotAuthenticated path={ROUTE_RESET_PASSWORD} exact component={ResetPassword}/>
                    <NotAuthenticated path={ROUTE_VERIFY_EMAIL} exact component={VerifyEmail}/>
                    <NotAuthenticated path={ROUTE_VERIFY_EMAIL_SUCCESS} exact component={VerifyEmailSuccess}/>
                    <NotAuthenticated path={ROUTE_RESEND_EMAIL_VERIFICATION} exact component={ResendEmailVerification}/>
                    <Route path={ROUTE_TERMS_AND_CONDITIONS} exact component={TermsAndConditions}/>
                    <Route path={ROUTE_PRIVACY_POLICY} exact component={PrivacyPolicy}/>

                    {/* Authenticated Routes */}
                    <ProtectedRoute path={ROUTE_DASHBOARD} exact component={Dashboard}/>
                    <ProtectedRoute path={ROUTE_MY_ACCOUNT} exact component={MyAccount}/>
                    <ProtectedRoute path={ROUTE_EDIT_PROFILE} exact component={EditProfile}/>
                    <ProtectedRoute path={ROUTE_MY_VIDA_VACATIONS_PAYMENTS} exact component={MyVidaVacationsPayments}/>
                    <ProtectedRoute path={ROUTE_MY_VIDA_VACATIONS_PAYMENTS_HISTORY} exact component={MyVidaVacationsPaymentsHistory}/>
                    <ProtectedRoute path={ROUTE_MY_RESERVATIONS} exact component={MyReservations}/>
                    <ProtectedRoute path={ROUTE_READ_MESSAGE} exact component={ReadMessage}/>
                    <ProtectedRoute path={ROUTE_MESSAGES_CENTER} exact component={MessagesCenter}/>
                    <ProtectedRoute path={ROUTE_REQUEST_RESERVATION} exact component={RequestReservation}/>
                    <ProtectedRoute path={ROUTE_ADD_RESERVATION_TO_PROFILE} exact component={AddReservationToProfile}/>
                    <ProtectedRoute path={ROUTE_PAYMENT_METHOD} exact component={PaymentMethod}/>
                    <ProtectedRoute path={ROUTE_FAQS} exact component={FAQs}/>
                    <ProtectedRoute path={ROUTE_MY_VIDA_VACATIONS_CONTRACTS} exact component={MyVidaVacationsContracts}/>
                    <ProtectedRoute path={ROUTE_EXCHANGES} exact component={Exchanges}/>
                    <ProtectedRoute path={ROUTE_ABOUT_US} exact component={About}/>
                    <ProtectedRoute path={ROUTE_CONTACT_US} exact component={Contact}/>
                    <ProtectedRoute path={ROUTE_CLUB} exact component={ClubFreedomBenefits}/>
                    <ProtectedRoute path={ROUTE_PRE_REGISTRATION} exact component={PreRegistration}/>
                    <ProtectedRoute path={ROUTE_SUCCESS_PAYMENT} exact component={SuccessPayment}/>
                    <ProtectedRoute path={ROUTE_DESTINATION_HIGHLIGHTS} exact component={DestinationsHighlights}/>
                    <ProtectedRoute path={ROUTE_RESERVATION_SUMMARY} exact component={ReservationSummary}/>
                    <ProtectedRoute path={ROUTE_RESERVATION_FOUND} exact component={ReservationFound}/>

                    <ProtectedRoute path={ROUTE_FAIL_PAYMENT} exact component={FailPayment}/>
                    <ProtectedRoute path={ROUTE_CANCELLED_PAYMENT} exact component={CancelledPayment}/>
                    <ProtectedRoute path={ROUTE_SUCCESS_TOKENIZATION} exact component={SuccessTokenization}/>                 

                    <Route path='*' exact component={NotFoundPage}/>
                </Switch>
            </Fragment>
        );
    }
}

export default withRouter( Router );
