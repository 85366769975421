import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import VidaVacationsLogo from '../../../resources/assets/account/payments/vida-vacations-white.svg';
import {FormattedMessage} from "react-intl";

class HeaderImage extends Component {

    render() {
        return (
            <div color="white" className="header-image">
                <Grid container className="payments-header-container" direction="column">
                    <Grid container className="logo-and-title-container">
                        <Grid item className="logo-container">
                            <img className="vida-payments-logo" src={VidaVacationsLogo} alt="vida-payments-logo" />
                        </Grid>
                        <Grid item className="title-container">
                            <h1 className="title-text">
                                <FormattedMessage id="components.account.myVidaVacationsPayments.PaymentType.my_vida_vacations_payments_title" />
                            </h1>
                            <hr className="border" />
                        </Grid>
                    </Grid>

                    <Grid item className="center-text">
                        <p className="welcome-text"><FormattedMessage id="components.account.myVidaVacationsPayments.PaymentType.welcome" /></p>
                    </Grid>
                </Grid>

                <div className="select-contract">
                    <p><FormattedMessage id="components.account.myVidaVacationsPayments.PaymentType.select" /></p>
                </div>

            </div>
        )
    }
}

export {HeaderImage}
