import React, { Component } from 'react';
import {Section} from "../../layout/Section";
import { ListContractPaymentItem } from "./ListContractPaymentItem";

class ContractsPaymentsListComponent extends Component {

    render() {
        return (
            <Section color="white" className="contracts-list">
                <ListContractPaymentItem contracts = {this.props.contracts} />
            </Section>
        )
    }
}

export { ContractsPaymentsListComponent }
