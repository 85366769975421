import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ROUTE_HOME } from "../config/routes";
import { isAuthenticated } from "../api/helper";

class ProtectedRoute extends Component {

    render() {
        const { component: Component, ...props } = this.props;

        return (
            <Route
                {...props}
                render={props => (
                    isAuthenticated() ?
                        <Component {...props} /> :
                        <Redirect to={{ pathname: ROUTE_HOME, state: { from: props.location } }} />
                )}
            />
        )
    }
}

export default withRouter(ProtectedRoute);
