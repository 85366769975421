import React, {Component} from 'react';
import {AddReservationValidationSchema} from "../../../../helpers/schemas";
import {Formik} from "formik";
import {connect} from "react-redux";
import moment from 'moment';
import {addReservationToProfile} from "../../../../api/user";
import {CustomDatePicker} from "../../../general/CustomDatePicker";
import {ErrorMessage, Form} from "formik";
import Grid from '@material-ui/core/Grid';
import CustomTextField from "../../../general/CustomTextField";
import {FormattedMessage} from 'react-intl';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {withRouter} from "react-router";
import CustomSelect from "../../../general/CustomSelect";
import {ROUTE_RESERVATION_FOUND} from "../../../../config/routes";
import {Messages} from "../../../general/Messages";
import {store} from "../../../../store/store";
import {displayGlobalNotification} from "../../../../store/actions/globalNotification";
import {NOTIFICATION_ERROR} from "../../../../store/actions/types";

class AddReservationToProfileComponent extends Component {
    submit = (data) => {
        const {property, confirmationNumber, checkInDate, travelerLastName} = data;
        // Change local moment lang, for api validation
        let enCheckInDate = moment(checkInDate);

        addReservationToProfile({
            data: {
                property: property,
                confirmation_number: confirmationNumber,
                check_in_date: enCheckInDate.locale('en').format('ll'),
                traveler_last_name: travelerLastName,
            }
        }).then((response) => {
            if (response.status === 201) {
                this.props.history.push({
                    pathname: ROUTE_RESERVATION_FOUND,
                    state: {data: response.data.data}
                })
            }

            if (response.status === 200) {
                store.dispatch(displayGlobalNotification(NOTIFICATION_ERROR, 'messages.reservation_not_found'));
            }
        });
    };

    renderForm = (props) => {
        let {errors, touched} = props;

        return (
            <Form autoComplete="off">
                <Grid container direction="column">
                    <Grid item xs={12} sm={12}>
                        <CustomSelect
                            name="property"
                            useNameAsValue={true}
                            error={errors.property && touched.property}
                            label={<FormattedMessage id="components.account.addReservationToProfile.AddReservationForm.select_property"/>}
                            items={this.props.reservationHotels}
                            onChange={e => props.setFieldValue('property', e.target.value)}
                        />
                        <ErrorMessage name="property" component="div" className="field-error"/>
                    </Grid>
                    <Grid item xs={12} sm={12} className="custom-field black">
                        <CustomTextField
                            type="text"
                            name="confirmationNumber"
                            error={errors.confirmationNumber && touched.confirmationNumber}
                            label={<FormattedMessage id="components.account.addReservationToProfile.AddReservationForm.confirmation_number"/>}
                            onChange={e => props.setFieldValue('confirmationNumber', e.target.value)}
                            handleBlur={props.handleBlur}
                        />
                        <ErrorMessage name="confirmationNumber" component="div" className="field-error"/>
                    </Grid>
                    <Grid item xs={12} sm={12} className="custom-field black">
                        <CustomDatePicker
                            fullWidth
                            id="checkInDate"
                            error={errors.checkInDate && touched.checkInDate}
                            class="check-in-date"
                            onChange={date => props.setFieldValue('checkInDate', date)}
                            label={<FormattedMessage id="components.account.addReservationToProfile.AddReservationForm.check_in_date"/>}
                        />
                        <ErrorMessage name="checkInDate" component="div" className="field-error"/>
                    </Grid>
                    <Grid item xs={12} sm={12} className="custom-field black">
                        <CustomTextField
                            type="text"
                            name="travelerLastName"
                            error={errors.travelerLastName && touched.travelerLastName}
                            label={<FormattedMessage id="components.account.addReservationToProfile.AddReservationForm.lead_travelers_last_name"/>}
                            onChange={e => props.setFieldValue('travelerLastName', e.target.value)}
                            handleBlur={props.handleBlur}
                        />
                        <ErrorMessage name="travelerLastName" component="div" className="field-error"/>
                    </Grid>
                    <Grid item xs={12} sm={12} className="submit-btn">
                        <button type="submit" className="submit-button">
                            <FormattedMessage id="globals.submit"/> <ChevronRightIcon/>
                        </button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        return (
            <div className="reservation-form">
                <Formik
                    initialValues={
                        {
                            property: '',
                            confirmationNumber: '',
                            checkInDate: '',
                            travelerLastName: ''
                        }
                    }
                    validationSchema={AddReservationValidationSchema}
                    onSubmit={this.submit}
                    enableReinitialize={true}
                    validateOnBlur={true}
                >
                    {this.renderForm}
                </Formik>
                <Messages/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reservationHotels: state.hotels.hotels,
})

const AddReservationToProfile = withRouter(connect(mapStateToProps, null)(AddReservationToProfileComponent));

export {AddReservationToProfile, AddReservationToProfileComponent};
