import React, {Component, Fragment} from 'react';
import {FormattedMessage} from 'react-intl';
import CustomTextField from "../../general/CustomTextField";
import Grid from '@material-ui/core/Grid';
import {ErrorMessage} from "formik";
import {CardNumber} from "./CardNumber";
import CustomSelect from "../../general/CustomSelect";
import {Toggle} from "../../general";
import moment from 'moment';

class CreditCardForm extends Component {
    renderField = (type, name, id, props, value) => {
        const {errors, touched} = props;

        return <Fragment>
            <FormattedMessage id={id}>
                {text =>
                    <CustomTextField
                        type={type}
                        name={name}
                        label={text}
                        error={errors[name] && touched[name]}
                        onChange={e =>{
                            props.setFieldValue(name, e.target.value)
                            if (this.props.isPaymentPage) {
                                this.props.setNewCardField(name, e.target.value);
                            }
                        }}
                        handleBlur={props.handleBlur}
                        value={value ?? ''}
                        class="card-bill"
                    />
                }
            </FormattedMessage>
            <ErrorMessage name={name} component="div" className="field-error"/>
        </Fragment>
    }

    render() {
        const {errors, touched} = this.props.formikProps;

        return <Fragment>
            <Grid container>
                <Grid item xs={12} className="custom-field black">
                    <CardNumber formikProps={this.props.formikProps} setNewCardField={this.props.setNewCardField} isPaymentPage={this.props.isPaymentPage}/>
                </Grid>
                <Grid container justify="space-between">
                    <Grid item xs={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <CustomSelect
                                    name="exp_month"
                                    label={<FormattedMessage
                                        id="globals.exp_month"/>}
                                    items={this.props.exp_months}
                                    error={errors.exp_month && touched.exp_month}
                                    textColor="black"
                                    class="card-bill"
                                    value={this.props.formikProps.values.exp_month ?? ''}
                                    onChange={e =>{
                                        this.props.formikProps.setFieldValue('exp_month', e.target.value);
                                        if (this.props.isPaymentPage) {
                                            this.props.setNewCardField('exp_month', e.target.value);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomSelect
                                    name="exp_year"
                                    label={<FormattedMessage
                                        id="globals.exp_year"/>}
                                    items={this.props.exp_years}
                                    error={errors.exp_year && touched.exp_year}
                                    textColor="black"
                                    class="card-bill"
                                    value={this.props.formikProps.values.exp_year ?? ''}
                                    onChange={e =>{
                                        this.props.formikProps.setFieldValue('exp_year', e.target.value);
                                        if (this.props.isPaymentPage) {
                                            this.props.setNewCardField('exp_year', e.target.value);
                                        }
                                    }}
                                    useNameAsValue={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className="custom-field black">
                        <FormattedMessage id="globals.cvv">
                            {text =>
                                <CustomTextField
                                    type="text"
                                    name="cvv"
                                    error={errors.cvv && touched.cvv}
                                    label={text}
                                    onChange={e => {
                                        this.props.formikProps.setFieldValue('cvv', e.target.value);
                                        if (this.props.isPaymentPage) {
                                            this.props.setNewCardField('cvv', e.target.value);
                                        }
                                    }}
                                    handleBlur={this.props.formikProps.handleBlur}
                                    value={ this.props.formikProps.values.cvv ?? '' }
                                    class="card-bill"
                                />
                            }
                        </FormattedMessage>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ErrorMessage name="exp_month" component="div" className="field-error"/>
                    <ErrorMessage name="exp_year" component="div" className="field-error"/>
                    <ErrorMessage name="cvv" component="div" className="field-error"/>
                </Grid>

                <Grid item xs={12} className="custom-field black">
                    {this.renderField('text', 'card_holder_name', 'globals.card_holder_name', this.props.formikProps, this.props.formikProps.values.card_holder_name)}
                </Grid>

                {/*THIS SECTION IS USED FROM 2 PARTS OF APPLICATION, FROM PAYMENT TYPE AND FROM EDIT ACCOUNT.*/}
                {/*WE MAKE FIELDS DISTINCT BY USING isUsedInModal props*/}

                {! this.props.isUsedInModal && <Grid item xs={12} className="toggle-container save-credit-card">
                    <Toggle
                        name="save_card"
                        onChange={e => {
                            this.props.formikProps.setFieldValue('save_card', e.target.checked);
                            if (this.props.isPaymentPage) {
                                this.props.setNewCardField('is_default', e.target.checked);
                            }
                        }}
                        value={this.props.formikProps.values.save_card ?? false}
                        text={<FormattedMessage id="globals.save_card"/>}
                    >
                    </Toggle>
                </Grid>
                }

                {this.props.isUsedInModal && <Grid item xs={12} className="toggle-container save-credit-card">
                    <Toggle
                        name="is_default"
                        onChange={e => this.props.formikProps.setFieldValue('is_default', e.target.checked)}
                        value={this.props.formikProps.values.is_default ?? false}
                        text={<FormattedMessage id="components.account.editProfile.editProfileForm.add_new_credit_card_modal.set_default"/>}
                    >
                    </Toggle>
                </Grid>
                }
            </Grid>
        </Fragment>
    }
}

function getExpirationYears() {
    let years=[];
    for(let i=1;i<=10;i++) {
        years.push({
            id:i,
            name: parseInt(moment().format('YYYY')) + (i - 1)
        });
    }

    return years;
}

CreditCardForm.defaultProps = {
    exp_months: [
        {
            id: 1,
            name: '01-JAN'
        },
        {
            id: 2,
            name: '02-FEB'
        },
        {
            id: 3,
            name: '03-MAR'
        },
        {
            id: 4,
            name: '04-APR'
        },
        {
            id: 5,
            name: '05-MAY'
        },
        {
            id: 6,
            name: '06-JUN'
        },
        {
            id: 7,
            name: '07-JUL'
        },
        {
            id: 8,
            name: '08-AUG'
        },
        {
            id: 9,
            name: '09-SEP'
        },
        {
            id: 10,
            name: '10-OCT'
        },
        {
            id: 11,
            name: '11-NOV'
        },
        {
            id: 12,
            name: '12-DEC'
        }
    ],
    exp_years: getExpirationYears()
}

export {CreditCardForm}
