import React, {Component} from 'react';
import Page from '../account/layout/Page';
import {DestinationsHighlightsHeader} from "../../components/account/destinationsHighlights/DestinationsHighlightsHeader";
import {DestinationHighlightsSection} from "../../components/account/destinationsHighlights/DestinationHighlightsSection";
import {getDestinationHighlightById} from "../../api/destinationHighlights/destination_highlight";
import {Messages} from "../../components/general/Messages";
import {Filters} from "../../components/layout/Navbars/header/Filters";
import { getDeviceName } from "../../helpers/checkDevice";

class DestinationsHighlights extends Component {
    constructor(props) {
        super(props);

        this.state = {
            destinationHighlight: {},
            device: getDeviceName(),

        }
    }

    setDevice = () => {
        this.setState({
            device: getDeviceName()
        });
    }

    componentDidMount() {
        this.getDestinationHighlight();
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDevice);
    }

    getDestinationHighlight = () => {
        const {id} = this.props.match.params;

        getDestinationHighlightById({destinationHighlightId: id}).then(data => {
            this.setState({
                destinationHighlight: data
            });
        });
    }

    render() {
        let {destinationHighlight, device} = this.state;

        return <Page class="account destination-highlights">
            <Messages/>
            <Filters />
            {this.state.destinationHighlight.id && <DestinationsHighlightsHeader destinationHighlight={destinationHighlight} device={device}/>}
            {this.state.destinationHighlight.id && <DestinationHighlightsSection destinationHighlight={destinationHighlight}/>}
        </Page>
    }
}

export {DestinationsHighlights};
