import React, {Component} from 'react';
import {connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import {Section} from '../../layout/Section';
import {FormattedMessage} from 'react-intl';
import {markAllMessagesRead} from "../../../api/messages/messages";
import {userDetails} from "../../../store/actions/user";

class MessagesCenterHeaderComponent extends Component {

    markAsRead = () => {
        if (this.countUnreadMessages() === 0) {
            return;
        }

        markAllMessagesRead().then(data => {
            this.props.setAllMessagesRead(data);
            this.props.userDetails(true);
        });
    }

    countUnreadMessages = () => {
        if (!this.props.messages.length) {
            return 0;
        }
        let newMessages = 0;

        this.props.messages.forEach(item => {
            if (item.unread_messages.length) {
                newMessages++;
            } else {
                if(!item.read_at) {
                    newMessages++;
                }
            }
        });

        return newMessages;
    }

    render() {
        return <Section color="blue">
            <div className="messages-center-container">
                <div className="messages-centre-header">
                    <Grid
                        container
                        alignItems="center"
                        justify="center"
                    >
                <span className="title">
                    <FormattedMessage id="components.account.messagesCenter.messagesCenterHeader.title"/>
                </span>
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                        className="messages-info-container"
                    >
                <span className="nr-messages">
                    {this.countUnreadMessages()} <FormattedMessage
                    id="components.account.messagesCenter.messagesCenterHeader.unread_messages"/>
                </span>
                        <span className="mark-as-read" onClick={() => this.markAsRead()}>
                    <FormattedMessage id="components.account.messagesCenter.messagesCenterHeader.mark_as_read"/>
                </span>
                    </Grid>
                </div>
            </div>
        </Section>
    }
}

const MessagesCenterHeader = connect(null, {userDetails})(MessagesCenterHeaderComponent);

export {MessagesCenterHeader, MessagesCenterHeaderComponent};
