import {
    SET_FAQS
} from "./types";

export const setFaqs = (
    payload
) => ( {
    type: SET_FAQS,
    payload: {
        faqs: payload || null
    }
} )
