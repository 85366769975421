import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Router from './routes/Router';
import {history, store} from './store/store'
import ConnectedIntlProvider from "./ConnectedIntlProvider";
import {shouldNotRenderPage, userDetails} from './store/actions/user';
import { isAuthenticated } from './api/helper';
import {GlobalNotification} from "./components/general/GlobalNotification";
import uuid from "uuid";
import { APP_VERSION, CYBERSOURCE_HOSTED_CHECK_OUT_ORG_ID } from "./config/config";

class AppComponent extends Component {

    constructor(props) {
        super(props);
        if (isAuthenticated()) {
            this.props.userDetails(true);
        }

        // Use this action when the page needs to update content from redux state, and wait for all data
        store.dispatch(shouldNotRenderPage());
    }

    render() {
        return (
            <ConnectedRouter history={history}>
                <ConnectedIntlProvider>
                    <GlobalNotification/>
                    <Router />
                </ConnectedIntlProvider>
            </ConnectedRouter>
        );
    }
}

const mapStateToProps = state => ({
    lang: state.locale.lang,
})

const mapActionsToProps = {
    userDetails,
}

const App = connect(mapStateToProps, mapActionsToProps)(AppComponent);

export { App, AppComponent };
