import React, {Component} from "react";
import globe from "../../resources/assets/globe.svg"
import { connect } from "react-redux";
import { userLocale } from "../../store/actions/locale";
import { ENGLISH_LOCALE, SPANISH_LOCALE } from "../../languages";
import { store } from "../../store/store";
import { hideNotification } from "../../store/actions/notification";

class SwitchLanguage extends Component {
    constructor(props) {
        super(props);

        const lang = localStorage.getItem('lang');
        
        this.state = {
            checked: lang === "es"
        };
    }

    componentDidUpdate(_, prevState) {
        const { checked } = this.props;
        if (typeof checked === "boolean" && checked !== prevState.checked) {
            this.setState({ checked });
        }
    }

    toggle = async () => {
        const { checked } = this.state;
        this.setState({ checked: !checked });
        let user_locale = checked ? ENGLISH_LOCALE : SPANISH_LOCALE;
        localStorage.setItem('lang', user_locale.lang);
        await this.props.userLocale(user_locale);
        store.dispatch(hideNotification());
    };

    render = () => {
        const cls = this.state.checked ? "on" : "off";

        return (
            <div className="switch btn " onClick={this.toggle}>
                <div className="switch-group">
                    <span className="switch-on btn">ES</span>
                    <span className="switch-off btn">US</span>
                    <img alt='language' src={globe} className={`switch-handle btn ${cls}`} />
                </div>
            </div>
        );
    };
}

const mapStateToProps = state => ({
    locale: state.locale
});

const SwitchLanguageComponent = connect(mapStateToProps, { userLocale })(SwitchLanguage);

export { SwitchLanguageComponent as SwitchLanguage };
