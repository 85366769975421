import {
    USER_LOCALE
} from '../actions/types';
import { ENGLISH_LOCALE, SPANISH_LOCALE } from "../../languages";

let user_lang = navigator.language.split(/[-_]/)[0];
const lang = localStorage.getItem('lang');
let LOCALE;

if(lang == null){
    LOCALE = user_lang === 'es' ? SPANISH_LOCALE : ENGLISH_LOCALE;
    localStorage.setItem('lang', user_lang);

}else{
    LOCALE = lang  === 'es' ? SPANISH_LOCALE : ENGLISH_LOCALE;
}

const initialState = {
    ...LOCALE
};

export default function(state = initialState, action) {
    switch (action.type) {
        case USER_LOCALE:
            return {
                ...state,
                lang: action.payload.lang,
                messages: action.payload.messages,
            };
        default:
            return state;
    }
};
