import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';

class VidaMemberId extends Component {
    render() {
        return <Grid container justify={this.props.align ? this.props.align: 'flex-end'}>
            <Grid item className={`vida-member-id-container ${this.props.class ? this.props.class : ''}`}>
                <div className="member">
                    <FormattedMessage id="components.account.general.vida_member_id.nr"/>
                </div>
                <div className="id">
                    {this.props.vidaMemberId}
                </div>
            </Grid>
        </Grid>
    }
}

export { VidaMemberId }
