import React, {useEffect, useState} from 'react';
import {DESTINATIONS_HIGHLIGHTS} from "../../../config/constants/destinationsHighlights";

const DestinationsHighlightsHeader = props => {
    const [destinationClass, setDestinationClass] = useState('');
    const {destinationHighlight , device} = props;

    useEffect(() => {
        switch (destinationHighlight.name) {
            case DESTINATIONS_HIGHLIGHTS.JUNGALA: {
                setDestinationClass('jungala');
                break;
            }
            case DESTINATIONS_HIGHLIGHTS.PALACIO: {
                setDestinationClass('palacio');
                break;
            }
            case DESTINATIONS_HIGHLIGHTS.BEACHLAND: {
                setDestinationClass('beachland');
                break;
            }
            case DESTINATIONS_HIGHLIGHTS.JOYA: {
                setDestinationClass('joya');
                break;
            }
            case DESTINATIONS_HIGHLIGHTS.RESERVE: {
                setDestinationClass('reserve');
                break;
            }
            default: {
                break;
            }
        }
    }, [destinationHighlight]);

    return (<div className={`destination-image ${destinationClass} ${device}`}></div>);
}

export { DestinationsHighlightsHeader }
