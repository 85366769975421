import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { Section } from '../Section';
import { Link } from "react-router-dom";
import { Link as LinkTo } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Routes } from '../../../config';
import FacebookIcon from '../../../resources/assets/Facebook.svg';
import TwitterIcon from '../../../resources/assets/Twitter.svg';
import InstagramIcon from '../../../resources/assets/Instagram.svg';

class Footer extends Component {
    render() {
        return <Fragment>
            <Section color="black">
                <Grid container className="footer" direction="column">
                    <Grid container className="footer-black-section" justify="space-between" direction="row">
                        <Grid item className="footer-about-links">
                            <div className="link">
                                <Link className="link-text" to={Routes.ROUTE_ABOUT_US}>
                                    <FormattedMessage id="components.layout.footer.about"/>
                                </Link>
                            </div>
                            <div className="link">
                                <Link className="link-text" to={Routes.ROUTE_CONTACT_US}>
                                    <FormattedMessage id="components.layout.footer.contact"/>
                                </Link>
                            </div>
                        </Grid>

                        <Grid item className="footer-social">
                            <LinkTo className="icon" href={'https://www.facebook.com/Vidanta'} alt='Facebook' target='_blank'>
                                <img className="social-icon" src={FacebookIcon} alt='Facebook'/>
                            </LinkTo>
                            <LinkTo className="icon" href={'https://twitter.com/vidanta'} alt='Twitter' target='_blank'>
                                <img className="social-icon" src={TwitterIcon} alt='Twitter'/>
                            </LinkTo>
                            <LinkTo className="icon" href={'https://www.instagram.com/vidanta'} alt='Instagram' target='_blank'>
                                <img className="social-icon" src={InstagramIcon} alt='Instagram'/>
                            </LinkTo>
                        </Grid>

                    </Grid>
                </Grid>
            </Section>

            <Section color="dark-grey">
                <Grid container className="footer-grey-section">
                    <div className="privacy-terms-container">
                        <div className="link-grey-section">
                            <Link className="link-grey-text" to={Routes.ROUTE_PRIVACY_POLICY} >
                                <FormattedMessage id="components.layout.footer.privacy"/>
                            </Link>
                        </div>
                        <div className="link-grey-section">
                            <Link className="link-grey-text" to={Routes.ROUTE_TERMS_AND_CONDITIONS} >
                                <FormattedMessage id="components.layout.footer.terms"/>
                            </Link>
                        </div>
                        <div className="link-grey-section">
                            <Link className="link-grey-text" to={Routes.ROUTE_HOME} >
                                V Passport
                            </Link>
                        </div>
                    </div>

                    <Grid item className="copyright">
                        Copyright © {new Date().getFullYear()} V Passport. <FormattedMessage id="components.layout.footer.copyright"/>
                    </Grid>
                </Grid>
            </Section>
        </Fragment>
    }
}

export { Footer }
