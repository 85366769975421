import { delay, put, takeLatest } from 'redux-saga/effects';
import * as Types from '../../actions/types';
import { Config } from '../../../config';
import { hideNotification, showNotification } from '../../actions/notification';

function* displayNotification(action) {
    yield put( hideNotification() );
    yield put( showNotification(
        action.payload.type,
        action.payload.message,
        action.payload.id
    ) );

    yield delay( Config.DELAY_NOTIFICATION );
    yield put( hideNotification() );
}

export default function* displayNotificationSaga() {
    yield takeLatest( Types.DISPLAY_NOTIFICATION, displayNotification );
}
