import React, { Component } from 'react';
import { connect } from "react-redux";
import Page from './layout/Page';
import { EntrySectionComponent } from "../../components/account/myVidaVacationsContracts/EntrySectionComponent";
import { Section } from "../../components/layout/Section";
import { ContractsListComponent } from "../../components/account/myVidaVacationsContracts/ContractsListComponent";
import { ContractsHeader } from "../../components/account/myVidaVacationsContracts/ContractsHeader";
import { Filters } from "../../components/layout/Navbars/header/Filters";

class MyVidaVacationsContractsComponent extends Component {
    render() {
        return <Page class="account my-vida-vacations-contracts">
            <Filters />
            <ContractsHeader />
            <Section color="white" className="wrapper">
                <EntrySectionComponent />
                {this.props.userProfile.contracts && <ContractsListComponent contracts={this.props.userProfile.contracts}/>}
            </Section>
        </Page>
    }
}

const mapStateToProps = (state) => ( {
    userProfile: state.user.profile
} )

const MyVidaVacationsContracts = connect( mapStateToProps, null )( MyVidaVacationsContractsComponent );

export { MyVidaVacationsContracts, MyVidaVacationsContractsComponent }
