import React  from "react";
import Grid from '@material-ui/core/Grid';
import logo from '../../resources/assets/logo.svg';
import { SwitchLanguage } from "../general/SwitchLanguage";
import { goHome } from "../../helpers/location";
import { FormattedMessage } from 'react-intl';

export const Header = ({ showSwitchLanguage, goHome, titleId, hideTitle }) => {
    const onClick = () => goHome();

    return (
        <Grid container direction="column" className='header'>
            <Grid item>
                <img className='logo' src={logo} alt='' onClick={onClick} />
            </Grid>
            {!hideTitle &&
                <Grid item className="title">
                    <FormattedMessage id={titleId}/>
                </Grid>
            }
            {showSwitchLanguage === true &&
                <Grid item>
                    <SwitchLanguage />
                </Grid>
            }
        </Grid>
    )
};

Header.defaultProps = {
    showSwitchLanguage: true,
    goHome,
    titleId: 'header.title'
};
