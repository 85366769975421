import React, { Component } from 'react';
import { Section } from '../../../Section';
import Grid from '@material-ui/core/Grid';
import LeftArrow from '../../../../../resources/assets/LeftArrow.svg';
import { checkDevice } from '../../../../../helpers/checkDevice';
import { FormattedMessage } from "react-intl";
import { history } from "../../../../../store/store";

class SecondNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            device: checkDevice(),
        }
    }

    setDevice = () => {
        this.setState({
            device: checkDevice()
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDevice);
    }

    render() {
        const { device } = this.state;

        return <Section
            color={ `blue sticky ${ this.props.class ? this.props.class : '' }` }
            className={ `second-nav-menu ${ this.props.border ? 'b-bottom' : '' }` }>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <span className="second-nav-link" onClick={ () => {
                    if (this.props.link && this.props.link.length) {
                        history.push(this.props.link);
                        return;
                    }

                    history.goBack();
                }
                }>
                    <img src={ LeftArrow } alt="icon"/>
                    { this.props.linkText }
                </span>
                { this.props.showSaveBtn &&
                <button className="second-nav-btn" onClick={ this.props.saveAction }>
                    { device.isDesktop &&
                    <span><FormattedMessage id="components.account.editProfile.submit_btn"/></span> }
                    { device.isMobile &&
                    <span><FormattedMessage id="components.account.editProfile.submit_btn.mobile"/></span> }
                    { device.isTablet &&
                    <span><FormattedMessage id="components.account.editProfile.submit_btn.mobile"/></span> }
                </button>
                }
            </Grid>
        </Section>
    }
}

export { SecondNav }
