import React from 'react';
import {FormattedMessage} from "react-intl";

export const RESERVATION_STATUSES = {
    1: <FormattedMessage id="globals.requested"/>,
    2: <FormattedMessage id="globals.confirmed"/>,
    3: <FormattedMessage id="globals.traveled"/>,
    4: <FormattedMessage id="globals.transferred"/>,
    5: <FormattedMessage id="globals.canceled"/>,
    6: <FormattedMessage id="globals.confirmed"/>,
    7: <FormattedMessage id="globals.complete"/>,
    8: <FormattedMessage id="globals.confirmed"/>,
    9: <FormattedMessage id="globals.confirmed"/>,
}

export const RESERVATION_STATUSES_NAME = {
    "REQUESTED": 1,
    "CONFIRMED": 2,
    "TRAVELED": 3,
    "TRANSFERRED": 4,
    "CANCELED": 5,
    "PENDING": 6,
    "COMPLETE": 7,
    "INHOUSE": 8,
    "NOSHOW": 9,
}

export const SCHEDULE_TRANSPORTATION_LINK = 'https://shuttleservices.vidanta.com/AutenticacionCliente';

export const RESERVATION_TYPES = {
    'UPCOMING': 'upcoming',
    'CANCELED': 'canceled',
    'PAST': 'past',
    'ALL': 'all'
}
