import React  from "react";
import Grid from '@material-ui/core/Grid';

export const Title = ({children, container, className}) => {
    return (
        <Grid container {...container}>
            <span className={className}>{children}</span>
        </Grid>
    )
};
