import React, {Component} from 'react';
import Page from '../../../pages/account/layout/Page';
import {Section} from "../../layout/Section";
import {TermsAndConditionsList} from "./TermsAndConditionsList";

class TermsAndConditionsAuthenticated extends Component {
    render() {
        return (
            <Page
                class="account termsAndConditions"
                titleId="pages.basic.privacy_policy.title"
            >
                <Section color="white">
                    <TermsAndConditionsList color="black"/>
                </Section>
            </Page>
        );
    }
}

export {TermsAndConditionsAuthenticated};
