import React, { Component } from 'react';
import Page from './layout/Page';
import { getUserContracts } from "../../api/userContracts/userContracts";
import { checkDevice } from "../../helpers/checkDevice";
import { CONTRACT_STATUSES } from "../../config/constants/contracts";
import { EntrySectionComponent } from "../../components/account/myVidaVacationsContracts/EntrySectionComponent";
import { Section } from "../../components/layout/Section";
import { ContractsPaymentsListComponent } from "../../components/account/myVidaVacationsContracts/ContractsPaymentsListComponent";
import { ContractsPaymentsHeader } from "../../components/account/myVidaVacationsContracts/ContractsPaymentsHeader";
import { Filters } from "../../components/layout/Navbars/header/Filters";
import  Container  from '@material-ui/core/Container';

class MyVidaVacationsPaymentsHistory extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            contracts: null,
            selectedContract: '',
            paymentTypes: [],
            device: checkDevice(),
        }
    }

    setDevice = () => {
        this.setState({
            device: checkDevice()
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.getUserContracts();
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.setDevice);
    }

    getUserContracts = () => {
        getUserContracts().then(data => {
            // Filter after active contracts
            data = Array.isArray(data) && data.length ? data.filter(item => item.contract_status !== CONTRACT_STATUSES.CANCELLED) : [];

            if (this._isMounted) {
                this.setState({
                    contracts: data
                });
            }
        });
    }

    render() {
        return(
        <Page class="account my-vida-vacations-paymemts-history">
            <Filters />
            <ContractsPaymentsHeader />
            <Section color="white" className="wrapper">
            <Container maxWidth="md">
                <EntrySectionComponent />
                {this.state.contracts && <ContractsPaymentsListComponent contracts={this.state.contracts}/>}
            </Container>
              
            </Section>
        </Page>
        )
    }
}

export { MyVidaVacationsPaymentsHistory }

