import React, {Component} from 'react';
import {Section} from "../../layout/Section";
import { Link } from "react-router-dom";
import {FormattedMessage} from 'react-intl';

class ContactHeader extends Component{
    render() {
        return (
            <Section color="white">
                <div className="contact-header">
               
                    <h1><FormattedMessage id="components.account.contact.contact_header_component.title"/></h1>
                    <p> <FormattedMessage id="components.account.contact.contact_header_component.sub_title"/> <Link to="/FAQs"><FormattedMessage id="components.account.contact.contact_header_component.sub_title_link"/></Link>.</p>
                </div>
            </Section>
        );
    }
}

export {ContactHeader};
