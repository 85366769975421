import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

class MessageModal extends Component {
    render() {
        let { open } = this.props;
        
        return <Modal
            className="message-modal-container"
            open={open}
            onClose={this.props.handleClose}>
            <div className="message-modal-content">
                <CloseIcon onClick={this.props.handleClose}/>
                <div className='message-modal-text'>
                    <div>{this.props.message}</div>
                </div>
            </div>
        </Modal>
    }
}

MessageModal.defaultProps = {
    open: false,
};

export { MessageModal }
