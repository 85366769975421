import React, {Component} from 'react';
import {Section} from "../../layout/Section";
import {AddReservationToProfile} from "./AddReservationForm/AddReservationToProfileForm";
import {FormattedMessage} from "react-intl";

class ReservationPage extends Component {

    render() {
        return (
            <Section color="white" className="reservation-page">
                <div className="reservation-page-text">
                    <h2>
                        <FormattedMessage id="components.account.reservationPage.title"/>
                    </h2>
                    <p>
                        <FormattedMessage id="components.account.reservationPage.sub_title1"/>
                        <span><FormattedMessage id="components.account.reservationPage.sub_title2"/></span>
                        <FormattedMessage id="components.account.reservationPage.sub_title3"/>
                    </p>
                </div>
                <AddReservationToProfile/>
            </Section>
        )
    }
}

export {ReservationPage}
