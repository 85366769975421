import React from "react";
import { Countdown } from '../../components/auth'
import { ROUTE_DASHBOARD } from "../../config/routes";
import Page from '../auth/Page';

export const VerifyEmailSuccess = ({ history }) => {

    const timerEnded = () => {
        history.push( ROUTE_DASHBOARD );
    };

    return (
        <Page class="verify-email-success-container">

            <Countdown
                title="globals.success"
                text="verify_email_success.text"
                seconds={5}
                onTimerEnd={timerEnded}
            />
        </Page>
    )
};
