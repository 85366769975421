import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userReducer from './user';
import localeReducer from './locale';
import NotificationReducer from './notification';
import loadingReducer from './loading';
import faqsReducer from './faqs';
import globalNotification from "./globalNotification";
import contactReasons from "./contactReasons";
import hotelsReducer from './reservationHotel';

export default (history) => combineReducers({
    router: connectRouter(history),
    user: userReducer,
    notification: NotificationReducer,
    locale: localeReducer,
    loading: loadingReducer,
    faqs: faqsReducer,
    globalNotification: globalNotification,
    contactReasons: contactReasons,
    hotels: hotelsReducer,
})
