import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { ErrorMessage } from "formik";
import CurrencyFormat from 'react-currency-format';

class PaymentMethodHeaderItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currencyValue: 0,
            totalValue: props.value,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.totalValue !== props.value && props.type === "input") {
            state.currencyValue = props.value;
            state.totalValue = props.value;
        }

        return state;
    }

    setPaymentAmount = event => {
        this.setState({
            currencyValue: event.target.value,
            changeField: true,
        });

        const { setPaymentAmount } = this.props;

        if (typeof setPaymentAmount !== "function") {
            return;
        }

        setPaymentAmount(event.target.value);

        this.setState({
            changeField: false,
        });
    }

    renderDate = () => {
        return <span
            className="value">{ this.props.value && moment(this.props.value).format('MMM. DD,YYYY') }
        </span>;
    }

    renderCurrency = () => {
        return <span className="value">
            { `$${ this.props.value } ` }
            <sup>
                { parseInt(this.props.currencyId) === 1 ? "MXN" : "USD" }
            </sup>
        </span>
    }

    renderTotal = () => {
        return <span className="value">
                    <span className="total">{ `$${ this.props.value } ` }</span>
                    <sup className="total-sup">{ parseInt(this.props.currencyId) === 1 ? "MXN" : "USD" }</sup>
                </span>
    }

    renderInput = () => {
        const { handleBlur, disabled } = this.props;

        if (typeof handleBlur !== "function") {
            return;
        }

        return <Grid container alignItems="center" justify="flex-end">
            <div className="payment-amount">
                <Grid item xs={ 3 } className="placeholder">
                    { parseInt(this.props.currencyId) === 1 ? "MXN" : "USD" }
                </Grid>
                <Grid item xs={ 9 } className="value">
                    <CurrencyFormat
                        thousandSeparator={ true }
                        decimalSeparator="."
                        name='order_amount'
                        autoComplete="off"
                        value={ this.state.currencyValue }
                        onBlur={ handleBlur }
                        onChange={ this.setPaymentAmount }
                        allowNegative={ false }
                        isAllowed={ () => disabled }
                    />
                    <ErrorMessage name='order_amount' component="div" className="field-error"/>
                </Grid>
            </div>
        </Grid>
    }

    renderValue = (type) => {
        switch (type) {
            case 'date': {
                return this.renderDate();
            }
            case 'total': {
                return this.renderTotal();
            }
            case 'input': {
                return this.renderInput();
            }
            case 'currency':
            default:
                return this.renderCurrency();
        }
    }

    render() {
        const { text, type } = this.props;

        return <Grid container justify="space-between" alignItems="center" className="payment-method-header-item">
            <Grid item xs={ 5 } sm={ 6 }>
                <span className="label">
                    <FormattedMessage id={ text }/>
                </span>
            </Grid>
            <Grid item xs={ 7 } sm={ 6 } className="values-container">
                { this.renderValue(type) }
            </Grid>
        </Grid>
    }
}

PaymentMethodHeaderItem.defaultProps = {
    disabled: true,
}

export { PaymentMethodHeaderItem }
