import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '675px',
        marginBottom: "15px",
        display: "inline-block"
    }
}));

export default function AccordionFAQs(props) {

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel'+props.id);
    const faqs = props.faqs;

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const renderFAQs = () => {
         return faqs.map((faq, key) =>
             <div className={ expanded === 'panel'+faq.id ? 'addBorder' : classes.root} key={key}>
                 <Accordion expanded={expanded === 'panel'+faq.id} onChange={handleChange('panel'+faq.id)}>
                     <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls={'panel'+faq.id+'bh-content'}
                         id={'panel'+faq.id+'bh-header'}
                     >
                         <div className={ expanded === 'panel'+faq.id ? 'questionExpanded' : 'summary'}>
                             <p>{faq.title}</p>
                         </div>
                     </AccordionSummary>
                     <AccordionDetails>
                         <div className='answer'>
                             <div className="content" dangerouslySetInnerHTML={{__html: faq.content}} />
                         </div>
                     </AccordionDetails>
                 </Accordion>
            </div>
        );
    }

    return (
        <div className="accordion-faqs">
            {renderFAQs()}
        </div>
    );
}
