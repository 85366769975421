import React, { Component } from 'react';
import Page from '../account/layout/Page';
import { ContactHeader } from '../../components/account/contact/ContactHeader';
import { ContactFormSection } from '../../components/account/contact/ContactFormSection';
import { ContactFooter } from '../../components/account/contact/ContactFooter';

class Contact extends Component {
    componentDidMount() {
        window.scroll(0,0);
    }

    render() {
        return (
                <Page class="account contact">
                    <ContactHeader />
                    <ContactFormSection />
                    <ContactFooter />
                </Page>
        )
    }
}

export { Contact };
