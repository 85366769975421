import React, {Component} from 'react';
import {Section} from '../../layout/Section';
import {MessageItem} from './MessageItem';

class MessagesList extends Component {

    renderMessages = () => {
        return this.props.messages.map((message, index) => {
            return <MessageItem key={index} message={message}/>
        });
    }

    render() {
        return <Section color="white">
            <div className="messages-center-container">
                <div className="messages-list">
                    {this.props.messages.length > 0 && this.renderMessages()}
                </div>
            </div>
        </Section>
    }
}

export {MessagesList}
