import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { store } from '../../../store/store'

class PrivacyPolicyList extends Component {

    renderPolicies = (policy) => {
        const { color } = this.props;

        return (
            <div className="policy-container" key={policy.title}>
                <div>
                    <h2 className={`policy-title ${color ? color : ''}`}>{policy.title}</h2>
                </div>
                <div>
                    <p className={`policy-text ${color ? color : ''}`}>{policy.text}</p>
                </div>
            </div>
        );
    }

    render() {
        const state = store.getState();
        const locale = state.locale.lang;

        if (locale === "en") return (
            <Grid>
                <div className="policy-container">
                    <div className="main">

                        <span className="titles">COMPREHENSIVE PRIVACY NOTICE.</span>
                        <br />
                        <br />
                        <span className="bold">
                            Desarrollo Marina Vallarta, S.A. de C.V.
                        </span>

                        <span className="text">
                            , with an address to hear and receive notifications located at Av. Paseo de la Marina Sur, #220, Marina Vallarta,
                            Puerto Vallarta, Jalisco Z.C. 48335, is a company that commercializes and carries out the contracting of
                            lodging rights and tourist services (Hereinafter referred to as
                            <span className="bold">"DMV"</span>
                            ). Our company is committed to protect your privacy under the
                            provisions of the Federal Law on Protection of Personal Data Held by Private Parties (Ley Federal de Protección de Datos Personales
                            en Posesión de los Partículares) [“LFPDPPP”], its regulations, the Guidelines of the Privacy Notice, and other legal provisions applicable
                            in the Mexican Republic (hereinafter and collectively, “Legislation”). In addition to the provisions of the Legislation, DMV has adopted
                            the best international practices in “Personal Data” management and administration, with the final purpose to clearly and specifically
                            inform which Personal Data we collect, how it is collected, As well as the treatment we give to them, always mediating our commitment
                            to protect your privacy under the highest standards of ethics, responsibility, and professionalism, we put this Privacy Notice at your
                            disposition.
                        </span>

                        <br />
                        <br />
                        <span className="titles">A. &nbsp;&nbsp;&nbsp;&nbsp;  PERSONAL DATA WE COLLECT.</span>
                        <br />
                        <div className="text list">
                            For the fulfillment of the legal relationship and the provision of the requested services, if you are our customer, DMV may collect
                            from you the following categories of Personal Data, either through physical or electronic formats:
                            <br />
                            <br />
                            Identification and contact data. <br />
                            Property Data (bank data for collection, tax data for billing). <br />
                            Biometric data. (Fingerprints, voiceprint signature, image data in photographs and / or videos). <br />
                            Geolocation data, when using the Vidanta App. <br />
                        </div>
                        <br />
                        <span className="text left">
                            Within the categories of Property Data, Biometric Data, since they are considered as Sensitive Personal Data that require special
                            protection, DMV will give special treatment to them and will request your express and written consent in accordance with the provisions
                            of the Legislation and the purposes established in this Notice.
                        </span>
                        <br />
                        <br />
                        <span className="titles">B. &nbsp;&nbsp;&nbsp;&nbsp;  THE PERSONAL DATA PROCESSING BY DMV HAS THE FOLLOWING PURPOSES.</span>
                        <br />
                        <div className="text list">
                            For identification, verification and / or contact, in any type of legal or business relationship with DMV. <br />
                            For the fulfillment of contractual obligations regarding the services that we must provide you. <br />
                            To inform you about the changes in the products and services that we commercialize. <br />
                            For your attention in case of security incidents during your stay in our facilities, for which we establish security and surveillance measures, including monitoring through video cameras, security control and physical and logical access surveillance.<br />
                            To carry out the collection and billing of the services you acquire with us, as well as to execute reimbursement processes if applicable. <br />
                            To authenticate your voice when contacting us through the customer care center. <br />
                        </div>
                        <br />
                        <span className="text left">
                            Additionally, we use your Personal Data for the following <span className="bold">“Secondary Purposes”</span>  that are not necessary for the fulfillment of the legal relationship that gives rise to the processing of Personal Data, but that allow and facilitate us to provide you with better care:
                        </span>
                        <br />
                        <br />
                        <div className="text list">
                            To develop studies and programs for marketing or advertising purposes. <br />
                            For record keeping for future service provision.<br />
                            To inform you of current or future products, services, advertising, promotions, and newsletters that may be of your interest. <br />
                            To carry out the promotion, prospecting, and profiling of potential clients for the acquisition of services we commercialize.<br />
                            To carry out quality surveys of our products and / or services. <br />
                            To send advertising and promotions while using the Vidanta App. <br />
                        </div>

                        <br />
                        <span className="text left">
                            In case you do not want your Personal Data to be used for any of the Secondary Purposes, we invite you to follow the procedure
                            established in point <span className="bold">“E”</span> of this Privacy Notice. The refusal to use your Personal Data for these Secondary Purposes may not be a
                            reason for us to deny the provision of the services and / or products that you acquire or contract with DMV.
                        </span>
                        <br />
                        <br />
                        <span className="titles">C. &nbsp;&nbsp;&nbsp;&nbsp;  CONSERVATION PERIOD OF YOUR PERSONAL DATA.</span>
                        <br />
                        <div className="text list">
                            DMV will keep your Personal Data for the duration of the legal relationship with you, or according to the established terms in
                            the Legislation, or until you request us to delete them, if the Legislation allows it.

                        </div>

                        <br />
                        <br />
                        <span className="titles">D. &nbsp;&nbsp;&nbsp;&nbsp;  MEANS TO LIMIT THE USE OR DISCLOSURE OF PERSONAL DATA.</span>
                        <br />
                        <div className="text list">
                            If you wish to express your refusal to use or disclose Personal Data, you may request your registration in our “Exclusion List”. To
                            request inclusion in our Exclusion List, you must follow the process established in point <span className="bold">“E.”</span> of this Privacy Notice.

                        </div>

                        <br />
                        <br />
                        <span className="titles">E. &nbsp;&nbsp;&nbsp;&nbsp;
                            EXERCISE OF THE ACCESS, RECTIFICATION, CANCELLATION, AND OPPOSITION RIGHTS (“ARCO Rights”); OR REQUEST
                            THE REVOCATION OF THE CONSENT TO THE TREATMENT OF YOUR PERSONAL DATA, DATA PORTABILITY, AND THE
                            RIGHT TO BE FORGOTTEN.</span>
                        <br />
                        <div className="text list">
                            In accordance with the Legislation, you may exercise your right of access to know the Personal Data we have about you in our
                            records, as well as to know what we use them for and the treatment we give them, you can rectify or request their correction in case of be inaccurate; You can request its cancellation, as well as express your opposition to the use and treatment; request the
                            right of portability; or revoke the consent for its treatment, as well as request the right to be forgotten.
                        </div>
                        <br />
                        <br />
                        <div className="text list">
                            For the purposes of exercising any of the rights indicated above, we ask you to download the format that we make available
                            to you through the following web link: “<a target="_blank" href="https://avisos.vidanta.com/formatosARCO/nuevovallarta/SDA-NV-Desarrollo-Marina-Vallarta.pdf">https://avisos.vidanta.com/formatosARCO/nuevovallarta/SDA-NV-Desarrollo-Marina-Vallarta.pdf</a>”. 
                            The request must be sent by email to the account: <a href="mailto:datospersonales@grupovidanta.com">datospersonales@grupovidanta.com</a> and must be accompanied
                            by the documentation indicated in said format. When DMV deems it convenient, it may agree with Personal Data Owner, the
                            alternative means for registration in our Exclusion List, always acting in accordance with the Legislation, so that our “Personal
                            Data Office” can authenticate your identity and be able to respond to your request.
                        </div>

                        <br />
                        <br />
                        <div className="text list">
                            Our “Personal Data Office” will notify you of the response within a maximum period of 20 (twenty) business days from the date
                            on which the notification of your request takes effect. If your request is appropriate in accordance with the guidelines established
                            in the Legislation and this Privacy Notice, it will become effective within 15 (fifteen) business days following the date on which
                            we notify you of the corresponding response.
                        </div>

                        <br />
                        <br />
                        <div className="text list">
                            It is important to mention that not in all cases we will be able to satisfy your request for ARCO rights, revocation of consent,
                            portability or right to be forgotten, since it is possible that due to some legal obligation we will need to continue keeping and /
                            or treating your Personal Data in accordance to this Privacy Notice. If this is the case, we will inform you by indicating the
                            corresponding obligations and we will proceed, where appropriate, blocking the corresponding Personal Data, the above during
                            the term that the Legislation requires for its conservation and until its elimination is definitive.
                        </div>

                        <br />
                        <br />
                        <div className="text list">
                            In case of doubts or if you wish to obtain more information regarding the procedure to (i) limit the use or disclosure of your
                            Personal Data, (ii) ARCO rights exercise (access, rectification, cancellation, and opposition), or (iii) exercise the right to revoke
                            consent, we offer you the following means of contact:

                        </div>

                        <br />
                        <br />
                        <div className="text list">
                            Email: <a href="mailto:datospersonales@grupovidanta.com">datospersonales@grupovidanta.com</a>, or contact the “Personal Data Office” at the telephone number 322 226 4000
                            Extension 64713. Business hours from Monday to Friday from 09:00 a.m. at 02:00 p.m. and 04:00 p.m. at 7:00 p.m. Mexico
                            City Central time.

                        </div>

                        <br />
                        <br />
                        <span className="titles">F. &nbsp;&nbsp;&nbsp;&nbsp;  DATA TRANSFER.</span>
                        <br />
                        <div className="text list">
                            DMV may transfer your Personal Data with affiliated or subsidiary companies, as well as with independent third parties with
                            whom it has a legal relationship, to comply with the services or products that you acquire from us and in accordance with the
                            following:

                        </div>

                        <br />
                        <br />

                        <table className="GeneratedTable">
                            <thead>
                                <tr>
                                    <th className="titles">PERSONAL DATA RECIPIENT</th>
                                    <th className="titles">PURPOSE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text">
                                        Affiliated companies, subsidiaries and/or
                                        specialized third parties.
                                    </td>
                                    <td className="text">
                                        Transportation service, if requested. <br />
                                        Evaluation of satisfaction and quality of service. <br />
                                        Sending promotions and advertising (email, SMS text message, certified
                                        mail, home parcel services).

                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        Comenity Capital Bank.

                                    </td>
                                    <td className="text">
                                        Subscription to credit card program.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        Barclays Bank PLC.

                                    </td>
                                    <td className="text">
                                        Subscription to credit card program.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        GCV Vacations, S.A. de C.V.

                                    </td>
                                    <td className="text">
                                        Financing Application.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        VCM Capital, S.A.P.I. de C.V.

                                    </td>
                                    <td className="text">
                                        Financing Application.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        Instituciones Bancarias Mexicanas

                                    </td>
                                    <td className="text">
                                        Payment processing and/or refunds if applicable.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        Operadora PayPal de México, S. de R.L. de C.V.

                                    </td>
                                    <td className="text">
                                        Payment processing and/or refunds if applicable.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        MapfreTech, S.A.

                                    </td>
                                    <td className="text">
                                        Application for travel insurance if requested.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        3RD Home LTD.

                                    </td>
                                    <td className="text">
                                        Subscription to an Exchange company.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        International Cruise & Excursions Gallery, INC (ICE).

                                    </td>
                                    <td className="text">
                                        Subscription to an Exchange company.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        Resort Condominiums International de México, S. de
                                        RL de C.V. (RCI).
                                    </td>
                                    <td className="text">
                                        Subscription to an Exchange company.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        The San Francisco Exchange Company (SFX).

                                    </td>
                                    <td className="text">
                                        Subscription to an Exchange company.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        Interval International, INC. / Intercambios
                                        Internacionales de Vacaciones, S.A. de C.V.
                                        (INTERVAL).
                                    </td>
                                    <td className="text">
                                        Subscription to an Exchange company.

                                    </td>
                                </tr>

                            </tbody>
                        </table>


                        <br />
                        <br />
                        <span className="titles">G. &nbsp;&nbsp;&nbsp;&nbsp; ELECTRONIC MEDIA, COOKIES OR WEB BEACONS.</span>
                        <br />
                        <div className="text list">
                            We inform you that on our website and in our Vidanta App we use cookies and other technologies through which it is possible
                            to collect Personal Data and monitor your browsing behavior based on IP and the places where it comes to fulfill the purposes
                            described in this Privacy Notice.

                        </div>

                        <br />
                        <br />
                        <div className="text list">
                            You can disable all cookies, avoid accepting new cookies, and have your browser notify you when you receive a new cookie through the “help” button found on the existing toolbar in most web browsers, or if you use the Vidanta App, you can change
                            geolocation permissions in your device settings.

                        </div>



                        <br />
                        <br />
                        <span className="titles">H. &nbsp;&nbsp;&nbsp;&nbsp; COMMUNICATION MEANS <span className="bold">FOR CHANGES AND / OR MODIFICATIONS TO THE PRIVACY NOTICE</span>.</span>
                        <br />
                        <div className="text list">
                            DMV reserves the right to update, change or modify this Privacy Notice at any time. In the event of any change, DMV will
                            communicate it through our website: <a target="_blank" href="https://avisos.vidanta.com/en/dmv.pdf">https://avisos.vidanta.com/en/dmv.pdf</a>.

                        </div>


                        <br />
                        <br />
                        <span className="titles">I. &nbsp;&nbsp;&nbsp;&nbsp; JURISDICTION.</span>
                        <br />
                        <div className="text list">
                            This Privacy Notice is governed by Mexican law and any controversy will be resolved before the competent Mexican authorities.
                            By accepting this Privacy Notice, you waive any other jurisdiction and legislation that may correspond to you by reason of your
                            address.

                        </div>


                        <br />
                        <br />
                        <span className="titles">J. &nbsp;&nbsp;&nbsp;&nbsp; AUTHORITY.</span>
                        <br />
                        <div className="text list">
                            If you consider that your right to Personal Data Protection has been injured by any conduct or omission on the part of DMV, or
                            you presume any violation of the provisions set forth in the Legislation and other applicable regulations, you may file your
                            disagreement with the National Institute of Transparency, Access to Information and Protection of Personal Data [“Instituto
                            Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales”] (“INAI”).

                        </div>

                        <br />
                        <br />
                        <span className="titles">K. &nbsp;&nbsp;&nbsp;&nbsp; VALIDITY.</span>
                        <br />
                        <div className="text list">
                            This is the most recent version of our Privacy Notice, which became effective as of April 20, 2017.<br />
                            Date of last modification, January 21, 2022.

                        </div>


                        <br />
                        <br />
                        <span className="text left italic">
                            This document comes from its Spanish language version, and it is translated into English language in good faith by DMV, however, it is
                            understood and accepted that, in case of doubt or controversy regarding its interpretation, the Spanish version will prevail in all cases.
                        </span>



                    </div>


                </div>
            </Grid>
        );

        return (

            <Grid>
                <div className="policy-container">
                    <div className="main">

                        <span className="titles">AVISO DE PRIVACIDAD INTEGRAL.</span>
                        <br />
                        <br />
                        <span className="bold">
                            Desarrollo Marina Vallarta, S.A. de C.V.
                        </span>

                        <span className="text">
                            , con domicilio para oír y recibir notificaciones el ubicado en Av. Paseo de la Marina Sur,
                            #220, Marina Vallarta, Puerto Vallarta, Jalisco, C.P. 48335, es una empresa que comercializa y lleva a cabo la contratación de
                            derechos de hospedaje y servicios turísticos (a quien en lo sucesivo se denominará como
                            <span className="bold">"DMV"</span>
                            ). Nuestra empresa está comprometida
                            a proteger su privacidad de conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los
                            Particulares <span className="bold">“LFPDPPP”</span>), su reglamento, los Lineamientos del Aviso de Privacidad y demás disposiciones legales aplicables en la
                            República Mexicana (en adelante y en conjunto, <span className="bold">“Legislación”</span>). Además de lo dispuesto en la Legislación, DMV ha adoptado las
                            mejores prácticas internacionales en el manejo y administración de <span className="bold">“Datos Personales”</span>, por lo que con el fin de informar de manera
                            clara y específica que Datos Personales recabamos, los medios por los que se recaban, así como el tratamiento que damos a los
                            mismos, siempre mediando nuestro compromiso de proteger su privacidad bajo los más altos estándares de ética, responsabilidad y
                            profesionalismo, ponemos a su disposición el presente Aviso de Privacidad.

                        </span>

                        <br />
                        <br />
                        <span className="titles">A. &nbsp;&nbsp;&nbsp;&nbsp;  DATOS PERSONALES QUE RECABAMOS</span>
                        <br />
                        <div className="text list">
                            Para el cumplimiento de la relación jurídica y la prestación de servicios que usted nos solicite, si usted es cliente nuestro cliente,
                            DMV podrá recabar de usted las siguientes categorías de Datos Personales, ya sea mediante formatos físicos o electrónicos:

                            <br />
                            <br />
                            Datos de identificación y contacto. <br />
                            Datos patrimoniales (datos bancarios para cobro, fiscales para facturación).
                            <br />
                            Datos biométricos. (huellas dactilares, huella de voz, datos de imagen en fotografías y/o videos).<br />
                            Datos de geolocalización, al hacer uso de Vidanta App.<br />
                        </div>
                        <br />
                        <span className="text left">
                            Dentro de las categorías de Datos Patrimoniales, Datos Biométricos y/o Datos de Salud, al ser considerados como Datos Personales
                            Sensibles que requieren especial protección, DMV dará especial tratamiento a los mismos y solicitará su consentimiento expreso y
                            por escrito de conformidad a lo establecido por la Legislación y las finalidades establecidas en el presente Aviso.
                        </span>
                        <br />
                        <br />
                        <span className="titles">B. &nbsp;&nbsp;&nbsp;&nbsp;  EL TRATAMIENTO DE DATOS PERSONALES POR PARTE DE DMV, TIENE LAS SIGUIENTES FINALIDADES.</span>
                        <br />
                        <div className="text list">
                            Para identificación, verificación y/o contacto, en cualquier tipo de relación jurídica o de negocios con DMV.<br />
                            Para el cumplimiento de obligaciones contractuales respecto a los servicios que debemos prestarle.
                            <br />
                            Para informarle sobre cambios en los productos y servicios que comercializamos. <br />
                            Para su atención en caso de incidentes de seguridad durante su permanencia en nuestras instalaciones, para lo cual establecemos
                            medidas de seguridad y vigilancia, entre ellas el monitoreo a través de videocámaras, control de seguridad y vigilancia de
                            acceso físico y lógico.<br />
                            Para realizar la cobranza y facturación de los servicios que adquiere con nosotros, así como ejecutar procesos de reembolso
                            en caso de aplicar<br />
                            Para autenticar si voz al momento de contactarnos a través del centro de atención a clientes.
                            <br />
                        </div>
                        <br />
                        <span className="text left">
                            De manera adicional, utilizamos sus Datos Personales para las siguientes <span className="bold">“Finalidades Secundarias”</span> que no son necesarias para
                            el cumplimiento de la relación jurídica que da origen al tratamiento de Datos Personales, pero que nos permiten y facilitan brindarle
                            una mejor atención:
                        </span>
                        <br />
                        <br />
                        <div className="text list">
                            Para elaborar estudios y programas con fines de mercadotecnia o publicitarios.<br />
                            Para mantenimiento de registros orientados a la prestación de servicios en el futuro.<br />
                            Para informarle de productos, servicios, publicidad, promociones y boletines informativos presentes o futuros que pudieran
                            resultar de su interés. <br />
                            Para realizar la promoción, prospección y perfilamiento de clientes potenciales para la adquisición de los servicios que
                            comercializamos. <br />
                            Para realizar encuestas de calidad de nuestros productos y/o servicios.<br />
                            Para envío de publicidad y promociones mientras hace uso de Vidanta App. <br />

                        </div>

                        <br />
                        <span className="text left">
                            En caso de que no desee que sus Datos Personales se utilicen para alguna(s) de las Finalidades Secundarias, lo invitamos a seguir
                            el procedimiento establecido en el punto <span className="bold">“E”</span> del presente Aviso de Privacidad. La negativa para el uso de sus Datos Personales
                            para estas Finalidades Secundarias no podrá ser un motivo para que le neguemos la prestación de los servicios y/o productos que
                            adquiere o contrata con DMV.

                        </span>
                        <br />
                        <br />
                        <span className="titles">C. &nbsp;&nbsp;&nbsp;&nbsp;  PLAZO DE CONSERVACIÓN DE SUS DATOS PERSONALES.</span>
                        <br />
                        <div className="text list">
                            DMV conservará sus Datos Personales durante el tiempo que dure la relación jurídica con usted, o conforme a los plazos
                            establecidos en la Legislación, o hasta que usted nos solicite que los eliminemos, siempre y cuando la Legislación lo permita.

                        </div>

                        <br />
                        <br />
                        <span className="titles">D. &nbsp;&nbsp;&nbsp;&nbsp;  MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES.</span>
                        <br />
                        <div className="text list">
                            Si desea manifestar su negativa al uso o divulgación de Datos Personales, usted podrá solicitar su inscripción en nuestro <span className="bold">“Listado
                                de Exclusión”</span> . Para solicitar su inclusión a nuestro Listado de Exclusión deberá seguir el proceso establecido en el punto <span className="bold">“E”</span>
                            del presente Aviso de Privacidad.

                        </div>

                        <br />
                        <br />
                        <span className="titles">E. &nbsp;&nbsp;&nbsp;&nbsp; EJERCICIO DE LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN, OPOSICIÓN (“Derechos ARCO”); O
                            SOLICITAR LA REVOCACIÓN DEL CONSENTIMIENTO AL TRATAMIENTO DE SUS DATOS PERSONALES, PORTABILIDAD DE
                            DATOS, Y DERECHO AL OLVIDO.</span>
                        <br />
                        <div className="text list">
                            De acuerdo con la Legislación, usted podrá ejercer su derecho de acceso para conocer los Datos Personales tenemos de usted
                            en nuestros registros, así como saber para que los utilizamos y el tratamiento que damos a los mismos, podrá rectificar o solicitar
                            su corrección en caso de ser inexactos; podrá solicitar su cancelación, así como manifestar su oposición al uso y tratamiento;
                            solicitar el derecho de portabilidad; o revocar el consentimiento para su tratamiento, así como solicitar el derecho al olvido.
                        </div>
                        <br />
                        <br />
                        <div className="text list">
                            Para efectos de ejercer cualquiera de los derechos señalados líneas arriba, le solicitamos descargar el formato que ponemos
                            a su disposición a través del siguiente enlace web: “<a target="_blank" href="https://avisos.vidanta.com/formatosARCO/nuevovallarta/SDA-NV-Desarrollo-Marina-Vallarta.pdf">https://avisos.vidanta.com/formatosARCO/nuevovallarta/SDA-NV-Desarrollo-Marina-Vallarta.pdf</a>”. 
                            La solicitud deberá ser enviada por correo electrónico a la cuenta: <a href="mailto:datospersonales@grupovidanta.com">datospersonales@grupovidanta.com</a> y deberá venir acompañada de la documentación que en dicho formato se indica. Cuando
                            DMV lo considere conveniente podrá acordar con el Titular de los Datos Personales, los medios alternativos para inscripción en
                            nuestro Listado de Exclusión, siempre actuando de conformidad con la Legislación, con el objeto de que nuestra “Oficina de
                            Datos Personales” pueda autenticar su identidad y estar en posibilidad de dar respuesta a su solicitud.

                        </div>

                        <br />
                        <br />
                        <div className="text list">
                            Nuestra "Oficina de Datos Personales" le comunicará la respuesta en un plazo máximo de 20 (veinte) días hábiles contados a
                            partir de la fecha en que surta efectos la notificación de su solicitud. Si su solicitud resulta procedente conforme a los lineamientos
                            establecidos en la Legislación y este Aviso de Privacidad, se hará efectiva dentro de los 15 (quince) días hábiles siguientes a
                            la fecha en que le notifiquemos la respuesta correspondiente.
                        </div>

                        <br />
                        <br />
                        <div className="text list">
                            Es importante mencionar que no en todos los casos podremos satisfacer su solicitud de derechos ARCO, revocación del
                            consentimiento, portabilidad o derecho al olvido, ya que es posible que por alguna obligación legal requiramos seguir
                            conservando y/o dando tratamiento a sus Datos Personales de conformidad al presente Aviso de Privacidad. Si éste fuera el
                            caso, lo haremos de su conocimiento indicando las obligaciones que correspondan y procederemos en su caso, al bloqueo de
                            los Datos Personales que así corresponda, lo anterior durante el término que la Legislación obligue para su conservación y hasta
                            que proceda su eliminación definitiva.
                        </div>

                        <br />
                        <br />
                        <div className="text list">
                            En caso de dudas o si desea obtener más información respecto al procedimiento para (i) limitar el uso o divulgación de sus
                            Datos Personales, (ii) ejercer los derechos ARCO (acceso, rectificación, cancelación y oposición), o (iii) ejercer el derecho de
                            revocación del consentimiento, ponemos a su disposición los siguientes medios de contacto:


                        </div>

                        <br />
                        <br />
                        <div className="text list">
                            Correo electrónico: <a href="mailto:datospersonales@grupovidanta.com">datospersonales@grupovidanta.com</a>, o bien comunicarse con la "Oficina de Datos Personales" al número
                            telefónico 322 226 4000 Extensión 64713. Horario de atención de lunes a viernes de 09:00 a.m. a 02:00 p.m. y 04:00 p.m. a
                            7:00 p.m. horario central de la Ciudad de México.

                        </div>

                        <br />
                        <br />
                        <span className="titles">F. &nbsp;&nbsp;&nbsp;&nbsp;  TRANSFERENCIA DE DATOS.</span>
                        <br />
                        <div className="text list">
                            DMV podrá transferir sus Datos Personales con compañías filiales o subsidiarias, así como con terceros independientes con
                            quienes tiene una relación jurídica, con el fin de dar cumplimiento a los servicios o productos que usted adquiere con nosotros y
                            de conformidad a lo siguiente:

                        </div>

                        <br />
                        <br />

                        <table className="GeneratedTable">
                            <thead>
                                <tr>
                                    <th className="titles">DESTINATARIO DE LOS DATOS PERSONALES </th>
                                    <th className="titles">FINALIDAD</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text">
                                        Compañías filiales, subsidiarias y/o terceros
                                        especializados.
                                    </td>
                                    <td className="text">
                                        Servicio de transportación, en caso de solicitarlo. <br />
                                        Evaluación de satisfacción y calidad en el servicio.
                                        <br />
                                        Envío de promociones y publicidad (correo electrónico,
                                        mensaje de texto SMS, correo certificado, servicios de
                                        paquetería a domicilio).

                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        Comenity Capital Bank.


                                    </td>
                                    <td className="text">
                                        Suscripción a programa de tarjeta de crédito.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        Barclays Bank PLC.

                                    </td>
                                    <td className="text">
                                        Suscripción a programa de tarjeta de crédito.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        GCV Vacations, S.A. de C.V.

                                    </td>
                                    <td className="text">
                                        Solicitud de financiamiento.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        VCM Capital, S.A.P.I. de C.V.

                                    </td>
                                    <td className="text">
                                        Solicitud de financiamiento.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        Instituciones Bancarias Mexicanas

                                    </td>
                                    <td className="text">
                                        Proceso de pagos y/o reembolsos en caso de aplicar.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        Operadora PayPal de México, S. de R.L. de C.V.

                                    </td>
                                    <td className="text">
                                        Proceso de pagos y/o reembolsos en caso de aplicar.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        MapfreTech, S.A.

                                    </td>
                                    <td className="text">
                                        Solicitud de seguro de viajero en caso de solicitarlo.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        3RD Home LTD.

                                    </td>
                                    <td className="text">
                                        Suscripción empresa de Sistema de Intercambio.

                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        International Cruise & Excursions Gallery, INC (ICE).

                                    </td>
                                    <td className="text">
                                        Suscripción empresa de Sistema de Intercambio.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        Resort Condominiums International de México, S. de
                                        RL de C.V. (RCI).
                                    </td>
                                    <td className="text">
                                        Suscripción empresa de Sistema de Intercambio.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        The San Francisco Exchange Company (SFX).

                                    </td>
                                    <td className="text">
                                        Suscripción empresa de Sistema de Intercambio.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text">
                                        Interval International, INC. / Intercambios
                                        Internacionales de Vacaciones, S.A. de C.V.
                                        (INTERVAL).
                                    </td>
                                    <td className="text">
                                        Suscripción empresa de Sistema de Intercambio.

                                    </td>
                                </tr>

                            </tbody>
                        </table>


                        <br />
                        <br />
                        <span className="titles">G. &nbsp;&nbsp;&nbsp;&nbsp; MEDIOS ELECTRÓNICOS, COOKIES O WEB BEACONS.</span>
                        <br />
                        <div className="text list">
                            Le informamos que en nuestra página web y en nuestra Vidanta App utilizamos cookies y otras tecnologías a través de las
                            cuales es posible recabar Datos Personales y monitorear su comportamiento de navegación basados en IP y los lugares de
                            donde proviene con el fin de cumplir con las finalidades descritas en este Aviso de Privacidad.
                        </div>

                        <br />
                        <br />
                        <div className="text list">
                            Usted podrá deshabilitar todas las cookies, evitar aceptar nuevas cookies, hacer que su navegador le notifique cuando recibe
                            una nueva cookie a través del botón de “ayuda” que se encuentra en la barra de herramientas existente en la mayoría de los
                            navegadores, o bien, en caso de utilizar Vidanta App, usted puede cambiar los permisos de geolocalización en los ajustes de
                            su dispositivo.

                        </div>



                        <br />
                        <br />
                        <span className="titles">H. &nbsp;&nbsp;&nbsp;&nbsp;MEDIOS DE COMUNICACIÓN PARA CAMBIOS Y/O MODIFICACIONES AL AVISO DE PRIVACIDAD.</span>
                        <br />
                        <div className="text list">
                            DMV se reserva el derecho de actualizar, cambiar o modificar este Aviso de Privacidad en cualquier momento. En caso de que
                            exista algún cambio, DMV lo comunicará a través de nuestro sitio web: <a target="_blank" href="https://avisos.vidanta.com/es/dmv.pdf">https://avisos.vidanta.com/es/dmv.pdf</a>.

                        </div>


                        <br />
                        <br />
                        <span className="titles">I. &nbsp;&nbsp;&nbsp;&nbsp; JURISDICCIÓN.</span>
                        <br />
                        <div className="text list">
                            Este Aviso de Privacidad se rige por las leyes mexicanas y cualquier controversia será resuelta frente a las autoridades
                            mexicanas competentes. Al aceptar este Aviso de Privacidad, usted renuncia a cualquier otro fuero y legislación que le pudiere
                            corresponder por razón de su domicilio.

                        </div>


                        <br />
                        <br />
                        <span className="titles">J. &nbsp;&nbsp;&nbsp;&nbsp; AUTORIDAD.</span>
                        <br />
                        <div className="text list">
                            Si usted considera que su derecho a la protección de Datos Personales ha sido lesionado por alguna conducta u omisión por
                            parte de DMV, o presume alguna violación a las disposiciones previstas en la Legislación y demás ordenamientos aplicables,
                            podrá interponer su inconformidad ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos
                            Personales (“INAI”).

                        </div>

                        <br />
                        <br />
                        <span className="titles">K. &nbsp;&nbsp;&nbsp;&nbsp; VIGENCIA.</span>
                        <br />
                        <div className="text list">
                            Esta es la versión más reciente de nuestro Aviso de Privacidad, el cual entró en vigor a partir del 20 de abril de 2017. <br />
                            Fecha de última modificación, 21 de enero de 2022.

                        </div>


                        <br />
                        <br />
                        <span className="text left italic">  
                            Este documento está escrito en su versión del idioma español y es traducido al inglés de buena fe por DMV, sin embargo, se entiende y
                            acepta que, en caso de duda o controversia en relación con su interpretación, la versión en español prevalecerá en todos los casos.
                        </span>



                    </div>


                </div>
            </Grid>
        );
    }
}

PrivacyPolicyList.defaultProps = {
    policies: [
        {
            title: <FormattedMessage id="components.auth.privacyPolicy.privacyPolicyList.title" />,
            text: <FormattedMessage id="components.auth.privacyPolicy.privacyPolicyList.text" />
        }
    ]
}

export { PrivacyPolicyList };
