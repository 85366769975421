import React, {Component} from 'react';
import Page from '../../../pages/auth/Page';
import {PrivacyPolicyList} from "./PrivacyPolicyList";

class PrivacyPolicyNotAuthenticated extends Component{
    render() {
        return (
            <Page
                class="auth privacyPolicy"
                showSwitchLanguage={true}
                titleId="pages.basic.privacy_policy.title"
            >
                <PrivacyPolicyList/>
            </Page>
        );
    }
}

export {PrivacyPolicyNotAuthenticated};
