import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

class LoaderComponent extends Component {
    render() {
        if (!this.props.loading) return null;

        return (
            <div className="loader">
                <CircularProgress />
            </div>
        );
    }
}
LoaderComponent.defaultProps = {
    loading: false
};

const mapStateToProps = state => ({
    loading: state.loading.pendingRequests
});

const Loader = connect(mapStateToProps)(LoaderComponent);

export { Loader, LoaderComponent };


