import {
    SET_HOTELS
} from "../actions/reservationHotels/types";

const initialState = {
    hotels: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_HOTELS:
            return {
                ...state,
                hotels: action.payload.hotels || []
            };
        default:
            return state;
    }
}
