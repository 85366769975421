import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { FormattedMessage } from "react-intl";

class AdditionalTraveler extends Component {
    render() {
        return <div className="additional-traveler-item">
            <Grid container direction="row" justify="space-between">
                <div>
                    <p className="additional-traveler-title"><FormattedMessage id="components.account.reservationSummary.additionalTraveler.additional_traveler"/> #{this.props.id + 1}</p>
                    <p className="additional-traveler-name">{this.props.traveler.first_name + ' ' + this.props.traveler.last_name}</p>
                </div>
                <div className="action-buttons-container">
                    <Grid container direction="row">
                        <Grid item>
                            <Grid container direction="column">
                                <button className="edit-traveler-button" type="button" onClick={this.props.onEdit}>
                                    <CreateOutlinedIcon />
                                    <FormattedMessage id="globals.edit"/>
                                </button>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction="column">
                                <button className="delete-traveler-button" onClick={this.props.onDelete}>
                                    <DeleteOutlineIcon className="delete-icon"/>
                                    <FormattedMessage id="globals.delete"/>
                                </button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </div>
    }
}

export { AdditionalTraveler }
