import { getAxios } from "../axios";
import { errorCatch, getTokenHeaders } from "../helper";
import { ENDPOINT_PAYMENT } from "../../config/endpoints";
import { Messages } from "../../config";

/**
 * Create a message
 * @param {object} payload
 * @param {object} payload.message
 * @param {object} payload.message.id
 * @param {string} payload.message.reason
 * @param {string} payload.message.subject
 * @param {string} payload.message.phone
 * @param {string} payload.message.message
 * @param {string} payload.message.need_to_contact_me
 * @param {string} payload.message.message_type
 * @param {boolean} payload.isContact
 * @returns {Promise<T>}
 */
export function payment(payload) {
    payload = cleanupPaymentData(payload);

    return getAxios().post(
        ENDPOINT_PAYMENT,
        payload,
        getTokenHeaders()
    ).then( response => {
        if (response && response.data) {
            return response;
        }

        return null
    } )
    .catch( e => {
        errorCatch(e, {
            400: 'concat',
            401: Messages.UNAUTHENTICATED_ERROR,
            403: Messages.UNAUTHORIZED_ERROR,
            404: Messages.NOT_FOUND_ERROR,
        });

        return e.response;
    });
}

function cleanupPaymentData(data) {
    delete data.card_name;
    delete data.card_holder_name;
    delete data.card_number;
    delete data.city;
    delete data.country;
    //delete data.cvv;
    delete data.exp_month;
    delete data.exp_year;
    delete data.first_name;
    delete data.last_name;
    delete data.postal_code;
    delete data.save_card;
    delete data.state;
    delete data.address;
    delete data.max_amount;

    return data;
}
