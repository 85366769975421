import React, {Component} from 'react';
import {PrivacyPolicyNotAuthenticated} from "../../components/auth/privacyPolicy/PrivacyPolicyNotAuthenticated";
import {isAuthenticated} from "../../api/helper";
import {PrivacyPolicyAuthenticated} from "../../components/auth/privacyPolicy/PrivacyPolicyAuthenticated";

class PrivacyPolicy extends Component {
    componentDidMount() {
        window.scroll(0,0);
    }

    render() {
        let component = isAuthenticated() ? <PrivacyPolicyAuthenticated/> : <PrivacyPolicyNotAuthenticated/>;
        return (
            {...component}
        )
    }
}

export {PrivacyPolicy};
