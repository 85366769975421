import React, { Component, Fragment } from 'react';
import { Formik, Form, ErrorMessage } from "formik";
import creditCardType from "credit-card-type";
import uuid from "uuid";
import { PaymentDetails } from "../PaymentDetails";
import {
    OtherFeesPaymentExistingCardSchema,
    OtherFeesPaymentNewCardSchema
} from "../../../../helpers/schemas";
import { getCreditCards } from "../../../../api/creditCards/creditCards";
import { Messages } from "../../../general/Messages";
import { OtherFeesPaymentMethodHeader } from "./OtherFeesPaymentMethodHeader";
import {signatureKript, getObjectByComma, getCustomTimeFormat, decrypt } from "../../../../helpers/general";
import { 
    CYBERSOURCE_HOSTED_CHECK_OUT_ORG_ID,
    CYBERSOURCE_HOSTED_CHECK_OUT_PAY
 } from "../../../../config/config";

class OtherFeesPaymentForm extends Component {
    constructor(props) {
        super(props);

        const affiliation = decrypt(props.contract.affiliation);

        const script = document.createElement("script");
        const finger_print =  uuid.v4();
        const merchan_id = affiliation.mertchant_id;
        const org_id = CYBERSOURCE_HOSTED_CHECK_OUT_ORG_ID;
        script.src =  `https://h.online-metrix.net/fp/tags.js?org_id=${org_id}&session_id=${merchan_id}${finger_print}`;
        script.async = false;
        script.id = "finger_print";
        document.head.appendChild(script);

        this.state = {
            type: props.type,
            contract: props.contract,
            amountDue: 0.00,
            lateFee: 0.00,
            totalAmount: 0.00,
            order_amount: 0.00,
            dueDate: '',
            paymentTypeIds: [],
            cards: [],
            selectedCard: {},
            cardDetails: {},
            fingerprint_id : finger_print,
            paymentType: props.type,
            monthlyType: 'monthly',
            currencyId: props.contract && props.contract.currency_id ? parseInt(props.contract.currency_id) : 1,
            access_key: affiliation.hco_access_key,
            profile_id: affiliation.hco_profile_id,
            secrete_key: affiliation.hco_secrete_key,
            locale: document.getElementById('root').className == 'lang-es' ? 'es-mx' : 'en-us',
            currency: parseInt(props.contract.currency_id) == 1 ? 'MXN' : 'USD',
            transaction_type: 'sale,create_payment_token',
        };

        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.getCreditCards();
    }

    getCreditCards = () => {
        let payload = {};
        payload.disablePagination = true;

        getCreditCards(payload)
            .then(
                data => {
                    data = Array.isArray(data) && data.length ?  data.filter(x => x.status === 1) : [];
                    // Add empty item that's is used in mapping for show Add New Card Item
                    data.push({
                        id: -1
                    });

                    this.setState({
                        cards: data
                    });
                }
            );
    }

    selectCard = (card) => {
        if (this.state.selectedCard.id === card.id) {
            return;
        }

        this.setState({
            selectedCard: { ...card },
        });

        this.getCreditCardType(card.card_number);
    }

    // Set on formik new card details of selected card
    getCreditCardType = (cardNumber) => {
        let cardTypes = creditCardType(cardNumber).filter(
            card => {
                return card;
            }
        );

        this.setState({
            cardDetails: cardTypes[0]
        });
    }

    setPaymentAmount = (order_amount, props) => {

        let order_amount_with_out_comma =  order_amount.replace(/,/g,'') 

        this.setState({
            order_amount: order_amount_with_out_comma
        }, () => {
            props.setFieldValue("order_amount", order_amount_with_out_comma);
        });
    }

    setStateOnFeeTypeChange = state => {
        this.setState(state);
    }

    setNewCardField = (field, value) => {
        let selectedCard = this.state.selectedCard;
        selectedCard[field] = value;

        this.setState({
            ...selectedCard
        });
    }

    render() {
        const { title, contract } = this.props;
        let { selectedCard, order_amount, paymentType,monthlyType, paymentTypeIds, totalAmount, access_key, secrete_key, profile_id, locale, currency, fingerprint_id } = this.state;
        let cardId = selectedCard.id && selectedCard.id !== -1 ? selectedCard.id : '';

        let validationSchema = OtherFeesPaymentExistingCardSchema;

        let cybersourceValues = {

            access_key: access_key,
            profile_id: profile_id,
            transaction_uuid: uuid.v4(),
            signed_date_time: getCustomTimeFormat(),
            locale: locale,
            transaction_type: !!(selectedCard.id && selectedCard.is_default ? selectedCard.is_default : false) ? "sale,create_payment_token" :  "sale",
            reference_number: new Date().getTime(),
            amount: order_amount,
            currency: currency,
            
            bill_to_forename: selectedCard.id === -1 ? selectedCard.bill_to_forename : cardId != "" ? selectedCard.first_name : '',
            bill_to_surname: selectedCard.id === -1 ? selectedCard.bill_to_surname : cardId != "" ? selectedCard.last_name : '',
            bill_to_address_line1:selectedCard.id  === -1 ? selectedCard.bill_to_address_line1 : cardId != "" ? selectedCard.address : '',

            bill_to_address_country: selectedCard.id === -1 ? selectedCard.bill_to_address_country : cardId != "" ? selectedCard.country : '',
            bill_to_address_state: selectedCard.id === -1 ? selectedCard.bill_to_address_state : cardId != "" ? selectedCard.state : '',

            bill_to_address_city: selectedCard.id === -1 ? selectedCard.bill_to_address_city : cardId != "" ? selectedCard.city : '',
            bill_to_address_postal_code: selectedCard.id === -1 ? selectedCard.bill_to_address_postal_code : cardId != "" ? selectedCard.post_code : '',
            
            merchant_defined_data99: contract.user_id,
            merchant_defined_data98: contract.contract_id,
            merchant_defined_data97: paymentType,
            merchant_defined_data96: monthlyType,
            merchant_defined_data95: !!paymentTypeIds.length,
            merchant_defined_data94: !!(selectedCard.id && selectedCard.is_default ? selectedCard.is_default : false),
            merchant_secure_data4: paymentTypeIds,

            unsigned_field_names: '',
            submit:  'Submit',
            device_fingerprint_id: fingerprint_id,

        }

        var signed_field = Object.keys(cybersourceValues).join(',');

        var signs = {
            ...cybersourceValues,
            signed_field_names: signed_field + ",signed_field_names",

        }

        var text = getObjectByComma(signs);
        var sign = signatureKript(text, secrete_key);

        let signature = {
            signature: sign,
        }

        let cardForm = {
            ...signs,
            ...signature,
            save_card: !!(selectedCard.id && selectedCard.is_default ? selectedCard.is_default : false),
        };

        let initialValues = {
            order_amount: order_amount ?? '',
            max_amount: totalAmount,
            contract_id: contract.contract_id,
            card_id: cardId,
            paymentTypeIds: paymentTypeIds,
            paymentType: paymentType,
            ...cardForm,

            multiplePayment: !!paymentTypeIds.length,
            monthlyType: this.state.monthlyType,

            country: selectedCard.id === -1 ? selectedCard.country : cardId != "" ? selectedCard.country : '',
            state: selectedCard.id === -1 ? selectedCard.state : cardId != "" ? selectedCard.state : '',
        }

        if ( !(cardId && cardId.length !== 0)) {
            validationSchema = OtherFeesPaymentNewCardSchema;
            delete initialValues.card_id;
        }

        return <Fragment>
            <Formik
                initialValues={ initialValues }
                validationSchema={ validationSchema }
                validateOnChange={ true }
                enableReinitialize={ true }
                innerRef={ ref => (this.formRef = ref) }>
                {
                    props => {
                        return (
                            <Form 
                            onSubmit={(e) => {}} 
                            className="payment-method-form" 
                            id="payment_confirmation"  
                            action={CYBERSOURCE_HOSTED_CHECK_OUT_PAY}
                            autoComplete="off"
                            method="post">

                                <OtherFeesPaymentMethodHeader
                                    title={ title }
                                    setPaymentAmount={ amount => this.setPaymentAmount(amount, props) }
                                    setStateOnFeeTypeChange={ this.setStateOnFeeTypeChange }
                                    handleBlur={ props.handleBlur }
                                    formikProps={ props }
                                    contract={ contract }
                                    { ...this.state }
                                />
                                <ErrorMessage name="card_id"/>
                                <ErrorMessage name="card"/>
                                <ErrorMessage name="contract_id"/>
                                {
                                    this.state.cards.length > 0 &&
                                    <PaymentDetails
                                        formikProps={ props }
                                        creditCards={ this.state.cards }
                                        selectCard={ this.selectCard }
                                        setNewCardField={ this.setNewCardField }
                                        isPaymentPage={ true }
                                    />
                                }
                            </Form>
                        )
                    } }
            </Formik>
        </Fragment>
    }
}

export { OtherFeesPaymentForm };

