import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Routes } from '../../../../config';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

class MyAccount extends Component {
    render() {
        return <Fragment>
            <div className="name">
                {`${this.props.userProfile.first_name ? this.props.userProfile.first_name : ""} ${this.props.userProfile.last_name ? this.props.userProfile.last_name : ""}`}
            </div>
            <div className="link">
                <Link to={Routes.ROUTE_MY_ACCOUNT}>
                    <FormattedMessage id="components.account.dashboard.profile_details.my_account"/>
                    <ChevronRightIcon fontSize="small"/>
                </Link>
            </div>
        </Fragment>
    }
}

export { MyAccount }
