import React from "react";
import Grid from '@material-ui/core/Grid';
import { Header, Footer } from '../../components/auth'
import { Loader } from '../../components/general';
import { FormattedMessage } from "react-intl";
import betaImgEs from '../../resources/assets/beta/V-Passport web add editables-01.png';
import betaImgEn from '../../resources/assets/beta/V-Passport web add editables-03.png';

class Page extends React.Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     close: false,
        // }
    }

    render() {
        return (
            <Grid container direction="column" alignItems="center" justify="space-between" className={`auth ${this.props.class}`}>
                <Grid item>
                    <Header showSwitchLanguage={this.props.showSwitchLanguage} titleId={this.props.titleId} hideTitle={this.props.hideTitle}/>
                </Grid>
                <Grid item className={`content ${this.props.middle ? this.props.middle : ''}`}>
                    {this.props.children}
                </Grid>
                {/* <Grid item>
                    <div className={"beta-container " + (!this.state.close ? 'show' : 'hidden')}>
                         <img src={this.props.locate == "es" ? betaImgEs : betaImgEn} alt='' style={{ width: '100%', height: 'auto', cursor: 'pointer' }} onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    close: true,
                                });
                            }}/>
                    </div>
                </Grid> */}
                <Grid item>
                    <Footer/>
                </Grid>
                <Loader/>
            </Grid>
        )
    }
};

export default Page;

