import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {FormattedMessage} from 'react-intl';
import {Routes} from '../../../config';
import {Link} from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import {VideoModal} from '../../modals/VideoModal';
import {checkDevice} from "../../../helpers/checkDevice";

class CardWithVideo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            device: checkDevice(),
        }
    }

    setDevice = () => {
        this.setState({
            device: checkDevice()
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDevice);
    }

    openModal = () => {
        this.setState({
            showModal: true
        });
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    renderDesktop = () => {
        let {title, subTitle, image, isVideo, language} = this.props;

        return <Grid container className="card-video-container">
            <Grid item xs={6}>
                <img src={image} alt="" className="card-desktop"/>
            </Grid>

            <Grid item xs={6} className="card-with-video-details-container card-desktop">
                <div className="card-with-video-title card-desktop">
                    {title}
                </div>
                <div className="card-with-video-subtitle card-desktop">
                    {subTitle}
                </div>
                <div>
                    {
                        isVideo ?
                            <div className="video-link">
                                <button className="play-video-btn" onClick={this.openModal}>
                                    <PlayCircleFilledIcon fontSize="small" className="card-video-icon"/>
                                    <FormattedMessage id="messages.watch_video"/>
                                </button>
                                <VideoModal
                                    open={this.state.showModal}
                                    handleClose={this.closeModal}
                                    language={language}
                                />
                            </div> :
                            <Link to={Routes.ROUTE_ABOUT_US}>
                                <FormattedMessage id="messages.learn_more"/>
                                <ChevronRightIcon fontSize="small"/>
                            </Link>
                    }
                </div>
            </Grid>
        </Grid>
    }

    renderMobile = () => {
        let {title, subTitle, image, isVideo, language} = this.props;

        return <Grid container direction="column" className="card-video-container">
            <Grid item>
                <div>
                    <img src={image} alt=""/>
                </div>
            </Grid>
            <Grid item className="card-with-video-details-container">
                <div className="card-with-video-title">
                    {title}
                </div>
                <div className="card-with-video-subtitle">
                    {subTitle}
                </div>
                <div>
                    {
                        isVideo ?
                            <div className="video-link">
                                <button className="play-video-btn" onClick={this.openModal}>
                                    <PlayCircleFilledIcon fontSize="small" className="card-video-icon"/>
                                    <FormattedMessage id="messages.watch_video"/>
                                </button>
                                <VideoModal
                                    open={this.state.showModal}
                                    handleClose={this.closeModal}
                                    language={language}
                                />
                            </div> :
                            <Link to={Routes.ROUTE_ABOUT_US}>
                                <FormattedMessage id="messages.learn_more"/>
                                <ChevronRightIcon fontSize="small"/>
                            </Link>
                    }
                </div>
            </Grid>
        </Grid>
    }

    render() {
        return this.state.device.isDesktop ? this.renderDesktop() : this.renderMobile()
    }
}

export {CardWithVideo}
