import React, {Component, Fragment} from 'react';
import {ErrorMessage,Formik, Form} from "formik";
import {FormattedMessage} from "react-intl";
import Grid from '@material-ui/core/Grid';
import CustomTextField from '../../general/CustomTextField';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Section } from '../../layout/Section'
import {AddNewCreditCardSchema} from "../../../helpers/schemas";
import {CardBillingInformation} from "../paymentMethod/CardBillingInformation";
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

import { 
    CYBERSOURCE_HOSTED_CHECK_OUT_ORG_ID,
    CYBERSOURCE_HOSTED_CHECK_OUT_CRATE_TOKEN,
    CYBERSOURCE_HOSTED_CHECK_OUT_UPDATE_TOKEN,
    APP_ACTIVE_PAYMENTS
 } from  "../../../config/config";

import uuid from "uuid";
import {signatureKript, getObjectByComma, getCustomTimeFormat, decrypt, getCountryByISOCode, getStateByISOCode} from "../../../helpers/general";


class EditCardForm extends Component {

    constructor(props) {
        super(props);

        const affiliation = decrypt(props.affiliation);

        const script = document.createElement("script");
        const finger_print =  uuid.v4();
        const merchan_id = affiliation.mertchant_id;
        const org_id = CYBERSOURCE_HOSTED_CHECK_OUT_ORG_ID;
        script.src =  `https://h.online-metrix.net/fp/tags.js?org_id=${org_id}&session_id=${merchan_id}${finger_print}`;
        script.async = false;
        script.id = "finger_print";
        document.head.appendChild(script);

        this.state = {
            type: props.type,
            contract: props.contract,
            amountDue: 0.00,
            lateFee: 0.00,
            totalAmount: 0.00,
            order_amount: 0.00,
            dueDate: '',
            paymentTypeIds: [],
            cards: [],
            selectedCard: {},
            cardDetails: {},

            paymentType: props.type,
            monthlyType: 'monthly',
            currencyId: 'currencyId',
            access_key: affiliation.hco_access_key,
            profile_id: affiliation.hco_profile_id,
            secrete_key: affiliation.hco_secrete_key,
            locale: document.getElementById('root').className == 'lang-es' ? 'es-mx' : 'en-us',
            currency: 'MXN',
            transaction_type: 'create_payment_token',
            openPayment: false,
            url: CYBERSOURCE_HOSTED_CHECK_OUT_CRATE_TOKEN,
            language: document.getElementById('root').className == 'lang-es' ? 'es' : 'en',
        };

        this.formRef = React.createRef();

    }

    openModalPayment = (props) => {

        props.setFieldTouched('bill_to_forename', true, true);
        props.setFieldTouched('bill_to_surname', true, true);
        props.setFieldTouched('bill_to_address_city', true, true);
        props.setFieldTouched('bill_to_address_line1', true, true);
        props.setFieldTouched('bill_to_address_postal_code', true, true);
        props.setFieldTouched('bill_to_address_country', true, true);
        props.setFieldTouched('country', true, true);
        props.setFieldTouched('bill_to_address_state', true, true);
        props.setFieldTouched('state', true, true);
        
        props.validateForm().then((s) => {

            if(
                s.bill_to_forename === undefined && 
                s.bill_to_surname === undefined && 
                s.bill_to_address_line1 === undefined && 
                s.bill_to_address_postal_code === undefined &&
                s.bill_to_address_country === undefined && 
                s.country === undefined && 
                s.bill_to_address_state === undefined &&
                s.state === undefined 
                ) {
                this.setState({
                    openPayment: true
                });
            }
        } );

    }

    handleClose = () => {
        
        this.setState({
            openPayment: false,
        });
    }


    setNewCardField = (field, value) => {
        let selectedCard = this.state.selectedCard;
        selectedCard[field] = value;

        this.setState({
            ...selectedCard
        });
    }

    render() {
        let { card, userId, onlydata } = this.props;
        
        let { selectedCard, transaction_type, order_amount, access_key, secrete_key, profile_id, locale, currency, openPayment, url, language} = this.state;

        let cybersourceValues = {

            access_key: access_key,
            profile_id: profile_id,
            transaction_uuid: uuid.v4(),
            signed_date_time: getCustomTimeFormat(),
            locale: locale,
            transaction_type: transaction_type,
            reference_number: new Date().getTime(),
            
            amount: order_amount,
            currency: currency,

            bill_to_forename: card ? card.first_name != selectedCard.bill_to_forename && selectedCard.bill_to_forename != undefined ? selectedCard.bill_to_forename : card.first_name : (selectedCard.bill_to_forename != '' && selectedCard.bill_to_forename != undefined) ? selectedCard.bill_to_forename : '',
            bill_to_surname: card ? card.last_name != selectedCard.bill_to_surname && selectedCard.bill_to_surname != undefined ? selectedCard.bill_to_surname : card.last_name : (selectedCard.bill_to_surname != '' && selectedCard.bill_to_surname != undefined) ? selectedCard.bill_to_surname : '',
            bill_to_address_line1: card ? card.address != selectedCard.bill_to_address_line1 && selectedCard.bill_to_address_line1 != undefined ? selectedCard.bill_to_address_line1 : card.address : (selectedCard.bill_to_address_line1 != '' && selectedCard.bill_to_address_line1 != undefined) ? selectedCard.bill_to_address_line1 : '',

            bill_to_address_country: card ? card.country !=selectedCard.bill_to_address_country && selectedCard.bill_to_address_country != undefined ? selectedCard.bill_to_address_country :  card.country : (selectedCard.bill_to_address_country != '' && selectedCard.bill_to_address_country != undefined) ? selectedCard.bill_to_address_country : '' ,
            bill_to_address_state: card ? card.state !=selectedCard.bill_to_address_state && selectedCard.bill_to_address_state != undefined ? selectedCard.bill_to_address_state :  card.state : (selectedCard.bill_to_address_state != '' && selectedCard.bill_to_address_state != undefined) ? selectedCard.bill_to_address_state : '' ,

            bill_to_address_city: card ? card.city != selectedCard.bill_to_address_city && selectedCard.bill_to_address_city != undefined ? selectedCard.bill_to_address_city : card.city : (selectedCard.bill_to_address_city != '' && selectedCard.bill_to_address_city != undefined) ? selectedCard.bill_to_address_city : '',
            bill_to_address_postal_code: card ? card.post_code != selectedCard.bill_to_address_postal_code && selectedCard.bill_to_address_postal_code != undefined ? selectedCard.bill_to_address_postal_code : card.post_code : (selectedCard.bill_to_address_postal_code != '' && selectedCard.bill_to_address_postal_code != undefined) ? selectedCard.bill_to_address_postal_code : '',
            
            merchant_defined_data99: userId,
            merchant_defined_data97: 'tokenization',
            merchant_defined_data94: !!((card && card.is_default) ? card.is_default : (selectedCard.is_default != undefined) ? selectedCard.is_default : false),

            unsigned_field_names: '',
            submit:  'Submit',

        }

        if(card){

            cybersourceValues.merchant_defined_data96 = 'update';
            cybersourceValues.payment_token = card.card_token;
            cybersourceValues.transaction_type = 'update_payment_token';
            url = CYBERSOURCE_HOSTED_CHECK_OUT_UPDATE_TOKEN
            
        }

        var signed_field = Object.keys(cybersourceValues).join(',');

        var signs = {
            ...cybersourceValues,
            signed_field_names: signed_field + ",signed_field_names",
        }

        var text = getObjectByComma(signs);
        var sign = signatureKript(text, secrete_key);

        let signature = {
            signature: sign,
        }

        let cardForm = {
            ...signs,
            ...signature,
            is_default: !!((card && card.is_default) ? card.is_default : (selectedCard.is_default != undefined) ? selectedCard.is_default : false),
        };

        let initialValues = {
            ...cardForm,

             country: card ? ((card.country != selectedCard.bill_to_address_country && selectedCard.bill_to_address_country != undefined) ? selectedCard.bill_to_address_country :  getCountryByISOCode(card.country, language)) : ((selectedCard.bill_to_address_country != '' && selectedCard.bill_to_address_country != undefined) ? selectedCard.country : ''),
             state: card ? ((card.state != selectedCard.bill_to_address_state && selectedCard.bill_to_address_state != undefined) ? selectedCard.bill_to_address_state :  getStateByISOCode(card.state, language, card.country)) : ((selectedCard.bill_to_address_state != '' && selectedCard.bill_to_address_state != undefined) ? selectedCard.state : ''),
 
        }
        
        return  <Section  color="white">

                    <Formik
                        initialValues={initialValues}
                        validationSchema={AddNewCreditCardSchema}
                        validateOnChange={true}
                        enableReinitialize={true}>
                        {
                            props => {
                                return (
                                    <Form 
                                        onSubmit={(e) => {}} 
                                        className="payment-method-form"
                                        id="payment_confirmation"  
                                        action={url}
                                        autoComplete="off"
                                        method="post">

                                        <CardBillingInformation 
                                            card={card} 
                                            formikProps={props} 
                                            isModal={true}
                                            setNewCardField={ this.setNewCardField }
                                            isPaymentPage={true}/>

                                       {APP_ACTIVE_PAYMENTS === "true" && !onlydata && <button type="submit" className="submit-save-card-btn" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.openModalPayment(props);
                                                            }}>
                                                                <FormattedMessage id="components.account.editProfile.editProfileForm.add_new_credit_card_modal.submit"/>
                                                                <ChevronRightIcon/>
                                        </button> }
                                    </Form>

                                    
                        )}}
                    </Formik>

                    <Modal
                        className="add-new-credit-card-modal"
                        open={openPayment}
                        onClose={() => this.handleClose()}>
                        <div className="add-new-credit-card-modal-container">
                            <Grid container alignItems="center" justify="space-between" className="modal-title">
                                <Grid item>
                                    <span>
                                        <FormattedMessage id="components.account.editProfile.editProfileForm.add_new_credit_card_modal.submit"/>
                                    </span>
                                </Grid>
                                <Grid item>
                                    <CloseIcon onClick={(e) => {e.preventDefault(); this.handleClose();}}/>
                                </Grid>
                            </Grid>
                            <div className="modal-body with-out-padding">
                                <div style = {{ marginBottom: '116px' }}>
                                    <FormattedMessage id="components.account.paymentMethod.card_billing_info.bill_body_message"/>
                                </div>

                                <div>

                                   <button type="submit" className="submit-save-card-btn" form="payment_confirmation">
                                        <FormattedMessage
                                            id="components.account.paymentMethod.card_billing_info.bill_btn_message"/>
                                        <span>
                                            <ChevronRightIcon fontSize="small"/>
                                        </span>
                                    </button>

                                </div>

                            </div>
                        </div>
                    </Modal>

                </Section>
    }
}

export {EditCardForm};
