import {call, takeLatest, put} from 'redux-saga/effects';
import * as Types from '../../actions/types';
import {errorCatch} from "../../../api/helper";
import {getContactReasonsApi} from "../../../api/contactReasons/contactReasons";
import {setContactReasons} from "../../actions/contactReasons";

function* getContactReasons(action) {
    try {
        let response;

        response = yield call(getContactReasonsApi, action.payload);

        if (response.status === 200) {
            yield put(setContactReasons({reasons: response.data.data}));
        }
    } catch (e) {

        yield errorCatch(e, {
            400: 'concat'
        });
    }
}

export default function* getContactReasonsSaga() {
    yield takeLatest(Types.REQUEST_GET_CONTACT_REASONS, getContactReasons);
}
