import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import myReservationIcon from '../../../resources/assets/account/myReservations/add-reservtion-icon.svg';
import { Section } from '../../layout/Section';
import { history } from '../../../store/store'
import { Routes } from '../../../config';

class AddReservation extends Component {
    render() {
        return <Section color="blue sticky-add-reservation">
            <Grid container className="add-reservation" onClick={() => history.push(Routes.ROUTE_ADD_RESERVATION_TO_PROFILE)}>
                <img src={myReservationIcon} alt=""/>
                <p className="text">
                    <FormattedMessage id="components.account.myReservations.add_reservations.title"/>
                </p>
            </Grid>
        </Section>
    }
}

export { AddReservation }
