import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';

class ReservationItemEmpty extends Component {

    render() {
        const { uncoming } = this.props;

        return <Grid item xs={12} sm={6} md={4} className="reservation-item">
                    <Grid item xs={12}  className={uncoming ? "emty-reservation-image-container" : "emty-reservation-image-container-past"}>
                        <img className="empty-reservation-image" src={ process.env.PUBLIC_URL + '/img/icons/empty.png'} alt=''/>
                    </Grid>
                </Grid>
    }
}

export {ReservationItemEmpty}
