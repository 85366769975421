import React, {Component} from 'react';
import Page from './layout/Page';
import {SecondNav} from "../../components/layout/Navbars/header/secondNav/SecondNav";
import {ReservationFoundHeader} from "../../components/account/reservationFound/ReservationFoundHeader";
import ReservationInfo from "../../components/account/reservationFound/ReservationInfo";
import {Section} from "../../components/layout/Section";
import {FormattedMessage} from "react-intl";

class ReservationFound extends Component {
    render() {
        if (!this.props.location.state) {
            return <Section color="black" className="account reservation-found-info">
                <div className="text-center">
                    <FormattedMessage id="messages.no_data_available"/>
                </div>
            </Section>
        }

        return (
            <Page class="account reservation-found">
                <SecondNav linkText={
                    <FormattedMessage id="pages.account.reservation_found.back_btn_text"/>
                }/>
                <ReservationFoundHeader/>
                <ReservationInfo/>
            </Page>
        )
    }
}

export {ReservationFound}
