import React, { Component } from 'react';
import Page from './layout/Page';
import {SecondNav} from "../../components/layout/Navbars/header/secondNav/SecondNav";
import {ReservationPage} from "../../components/account/addReservationToProfile/ReservationPage";

class AddReservationToProfile extends Component {

    render() {

        return (
            <Page class="account add-reservation-to-profile">
                <SecondNav linkText="Back to My Reservations" />
                <ReservationPage />
            </Page>
        )
    }
}

export {AddReservationToProfile}
