import React, { Component, Fragment } from 'react';
import { Section } from '../../../layout/Section';
import { FormattedMessage } from 'react-intl';
import { OtherFeesPaymentFormHeader } from "./OtherFeesPaymentFormHeader";
import { PaymentMethodHeaderItem } from "../PaymentMethodHeaderItem";

class OtherFeesPaymentMethodHeader extends Component {
    renderDownPayment = () => {
        const {
            dueDate,
            amountDue,
            lateFee,
            totalAmount,
            setPaymentAmount,
            handleBlur,
            currencyId,
        } = this.props;

        return (
            <Fragment>
                <PaymentMethodHeaderItem
                    text="components.account.paymentMethod.downPaymentItem.date"
                    value={ dueDate }
                    type="date"
                />
                <PaymentMethodHeaderItem
                    text="components.account.paymentMethod.downPaymentItem.amount"
                    value={ parseFloat(amountDue ?? '0') }
                    type="currency"
                    currencyId={ currencyId }
                />
                <PaymentMethodHeaderItem
                    text="components.account.paymentMethod.downPaymentItem.late_fee"
                    value={ parseFloat(lateFee ?? '0') }
                    type="currency"
                    currencyId={ currencyId }
                />
                <PaymentMethodHeaderItem
                    text="components.account.paymentMethod.downPaymentItem.total"
                    value={ parseFloat(totalAmount ?? '0') }
                    type="total"
                    currencyId={ currencyId }
                />
                <PaymentMethodHeaderItem
                    text="components.account.paymentMethod.downPaymentItem.payment_amount"
                    value={ totalAmount ?? 0 }
                    type="input"
                    setPaymentAmount={ setPaymentAmount }
                    handleBlur={ handleBlur }
                    currencyId={ currencyId }
                />
            </Fragment>
        )
    }

    render() {
        const { title, formikProps, contract, setStateOnFeeTypeChange } = this.props;

        return <Section color="dark-grey">
            <div className="payment-method-header">
                <h3 className="title">
                    <FormattedMessage id={ title }/>
                </h3>
                <h3 className="title mt-0">
                    <FormattedMessage id={ this.props.contract.contract_number }/>
                </h3>
                <OtherFeesPaymentFormHeader
                    formikProps={ formikProps }
                    contract={ contract }
                    setStateOnFeeTypeChange={ setStateOnFeeTypeChange }
                />
                { this.renderDownPayment() }
            </div>
        </Section>
    }
}

export { OtherFeesPaymentMethodHeader }
