import React, { Component } from 'react';
import { connect } from "react-redux";
import Page from './layout/Page';
import { Member } from "../../components/account/general/Member";
import { ProfileDetails } from "../../components/account/myAccount/ProfileDetails";
import { ProfileNotifications } from "../../components/account/myAccount/ProfileNotifications";
import { UpcomingReservations } from "../../components/account/myAccount/UpcomingReservations";
import { ContractCarousel } from '../../components/account/general/Carousel/ContractCarousel';
import { ContractDetails } from '../../components/account/myAccount/ContractDetails';
import { ContractsComponent } from "../../components/account/general/Contracts";
import { checkDevice } from "../../helpers/checkDevice";
import { Filters } from '../../components/layout/Navbars/header/Filters';

class MyAccountComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contractDetails: {},
            activeContract: this.props.userProfile.contracts[0],
            device: checkDevice(),
            contracts: []
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDevice);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.userProfile.contracts) {
            state.contracts = props.userProfile.contracts;
        }

        return state;
    }

    setDevice = () => {
        this.setState({
            device: checkDevice()
        });
    }

    setContractDetails = (contractDetails) => {
        this.setState({ contractDetails: contractDetails });
    }

    setActiveContract = (index) => {
        this.setState({ activeContract: this.state.contracts[index] });
    }

    changeSelectedItem = (direction) => {
        // This method is used only when not carousel component is loaded, and the length of contracts is 3
        let contracts = this.state.contracts;
        let aux = contracts[0];

        if (direction === 'back') {
            contracts[0] = contracts[2];
            contracts[2] = contracts[1];
            contracts[1] = aux;
        } else {
            contracts[0] = contracts[1];
            contracts[1] = contracts[2];
            contracts[2] = aux;
        }

        this.setState({
            contracts: contracts
        }, () => {
            this.setActiveContract(1);
        });
    }

    renderContracts() {
        if ( !this.state.contracts || !this.props.shouldNotRenderContent) {
            return null
        }

        if (this.state.device.isMobile) {
            return <ContractCarousel contracts={ this.state.contracts }
                                     onChange={ this.setContractDetails } device={ this.state.device }/>
        } else {

            if (this.state.contracts.length < 4) {
                return <ContractsComponent setActive={ this.setActiveContract }
                                           contracts={ this.state.contracts }
                                           device={ this.state.device }
                                           changeSelectedItem={ this.changeSelectedItem }
                />
            }

            return <ContractCarousel contracts={ this.state.contracts }
                                     onChange={ this.setContractDetails } device={ this.state.device }/>
        }
    }

    renderContractDetails() {
        if ( !this.state.contracts || !this.props.shouldNotRenderContent) {
            return null
        }

        if (this.state.contracts.length < 4 && !this.state.device.isMobile) {
            return <ContractDetails contract={ this.state.activeContract }/>
        }

        return <ContractDetails contract={ this.state.contractDetails }/>
    }

    render() {
        return (
            <Page class="account my-account">
                <Filters/>
                <Member userProfile={ this.props.userProfile } class="my-account"/>
                <ProfileDetails userProfile={ this.props.userProfile }/>
                { this.renderContracts() }
                { this.renderContractDetails() }
                <ProfileNotifications userProfile={ this.props.userProfile }/>
                <UpcomingReservations/>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    userProfile: state.user.profile,
    loading: state.loading.pendingRequests,
    shouldNotRenderContent: state.user.shouldNotRenderPageUntilAllData
})

const MyAccount = connect(mapStateToProps, null)(MyAccountComponent);

export { MyAccount, MyAccountComponent };
