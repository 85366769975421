import { getAxios } from './axios';
import { call, select } from 'redux-saga/effects';
import {
    ENDPOINT_LOGIN,
    ENDPOINT_REGISTER,
    ENDPOINT_RESET_PASSWORD,
    ENDPOINT_REGISTER_VERIFY_EMAIL,
    ENDPOINT_RESEND_EMAIL_VERIFICATION,
    ENDPOINT_USER,
    ENDPOINT_USER_LOGOUT,
    ENDPOINT_FAQS,
    ENDPOINT_FORGOT_PASSWORD,
    ENDPOINT_ADD_RESERVATION_TO_PROFILE,
    ENDPOINT_SEND_RESERVATION_CONFIRMATION,
    ENDPOINT_RESERVATION_REQUESTED
} from '../config/endpoints';

import { addParam, errorCatch, errorCatchGlobal, getTokenHeaders } from './helper';
import { Messages } from '../config';

/**
 * Login user
 * @param payload
 * @returns {Generator<*, *, Generator<*, *, *>>}
 */
export function* login(payload) {
    return yield call( getAxios().post, ENDPOINT_LOGIN, payload, getTokenHeaders() );
}

/**
 * Register user
 * @param {object} payload
 * @param {object} payload.data
 * @param {string} payload.data.first_name
 * @param {string} payload.data.last_name
 * @param {string} payload.data.email
 * @param {string} payload.data.password
 * @param {string} payload.data.passwordConfirmation
 * @param {string} payload.data.phone
 * @param {string} payload.data.country
 * @param {boolean} payload.data.optIn
 * @param {boolean} payload.data.captcha
 * @param {string} payload.data.vida_member_id
 * @param {string} payload.data.language_id
 * @returns {Promise<T>}
 */
export function registerUser(payload) {
    return getAxios().post(
        ENDPOINT_REGISTER,
        payload.data,
        getTokenHeaders())
        .then( response => {
            if (response && response.data) {
                return response;
            }

            return null
        } )
        .catch( e => {
            errorCatch( e, {
                400: 'concat',
            } );

            return e.response;
        } );
}

/**
 * Login user
 * @param {object} payload
 * @param {object} payload.data
 * @param {string} payload.data.email
 * @param {string} payload.data.password
 * @param {boolean} payload.data.captcha
 * @param {string} payload.data.language_id
 * @returns {Promise<T>}
 */
export function loginUser(payload) {
    return getAxios().post(
        ENDPOINT_LOGIN,
        payload.data,
        getTokenHeaders())
        .then( response => {
            if (response && response.data) {
                return response;
            }

            return null
        } )
        .catch( e => {
            errorCatch(e, {
                400: 'concat',
                403: Messages.ACCOUNT_NOT_VERIFIED,
                409: Messages.ACCOUNT_NOT_MEMBERID
            });

            return e.response;
        } );
}

/**
 * Get user profile
 * @param payload
 * @returns {Generator<*, *, Generator<*, *, *>>}
 */
export function* userDetails() {
    return yield call( getAxios().get, ENDPOINT_USER, getTokenHeaders() );
}

/**
 * Get faqs
 * @param payload
 * @returns {Generator<*, *, Generator<*, *, *>>}
 */
export function* faqs() {
    return yield call( getAxios().get, ENDPOINT_FAQS, getTokenHeaders() );
}

/**
 * Send forgot password email
 * @param {object} payload
 * @param {object} payload.data
 * @param {string} payload.data.email
 * @returns {Promise<T>}
 */
export function forgotPassword(payload) {
    return getAxios().post(
        ENDPOINT_FORGOT_PASSWORD,
        payload.data,
        getTokenHeaders()
    )
        .then( response => {
            return response
        } )
        .catch( e => {
            errorCatch( e, {
                400: 'concat',
                404: Messages.NOT_FOUND_ERROR,
            } );

            return e.response;
        } );
}

/**
 * Reset password
 * @param {object} payload
 * @param {number} payload.token
 * @param {object} payload.data
 * @param {number} payload.data.verification_code
 * @param {string} payload.data.password
 * @param {string} payload.data.password_confirmation
 * @returns {Promise<T>}
 */
export function resetPassword(payload) {

    return getAxios().put(
        addParam(
            ENDPOINT_RESET_PASSWORD,
            {
                '{token}': payload.token,
            }
        ),
        payload.data,
        getTokenHeaders()
    )
        .then( response => {
            return response;
        } )
        .catch( e => {
            errorCatch( e, {
                400: 'concat',
                404: Messages.NOT_FOUND_ERROR,
            } );

            return e.response;
        } );
}

/**
 * Activate account
 * @param {object} payload
 * @param {number} payload.id
 * @param {string} payload.token
 * @param {object} payload.data
 * @param {number} payload.data.expires
 * @param {string} payload.data.signature
 * @returns {Promise<T>}
 */
export function activateAccount(payload) {

    return getAxios().put(
        addParam(
            ENDPOINT_REGISTER_VERIFY_EMAIL,
            {
                '{id}': payload.id,
                '{hash}': payload.token,
                '{expires}': payload.data.expires,
                '{signature}': payload.data.signature
            }
        ),
        null,
        getTokenHeaders()
    )
        .then( response => {
            return response;
        } )
        .catch( e => {
            errorCatch( e, {
                400: Messages.VERIFICATION_CODE_INVALID,
            } );

            return e.response;
        } );
}

/**
 * Resend Activation Token
 * @param {object} payload
 * @param {object} payload.data
 * @param {number} payload.data.email
 * @returns {Promise<T>}
 */
export function resendActivationToken(payload) {

    return getAxios().post(
        ENDPOINT_RESEND_EMAIL_VERIFICATION,
        payload.data,
        getTokenHeaders()
    )
        .then( response => {
            if (response) {
                return response;
            }

            return null
        } )
        .catch( e => {
            errorCatch( e, {
                400: 'concat',
                401: 'Unauthorized',
                404: 'Not Found',
            } );

            return e.response;
        } );
}

/**
 *
 * @param {object} payload
 * @param {string} payload.current_password
 * @param {string} payload.new_password
 * @param {string} payload.new_password_confirmation
 * @returns {Promise<T>}
 */
export function* updateUserDetails(payload) {
    if (! payload.current_password) {
        delete payload.current_password;
        delete payload.new_password;
        delete payload.new_password_confirmation;
    }

    const prevState = yield select(state => state);

    // Not send mobile phone for validation on request if no mobile phone is set
    if (typeof payload.mobile_phone !== "undefined" && !prevState.user.profile.mobile_phone && !payload.mobile_phone) {
        payload.mobile_phone = null;
    }

    if (typeof payload.home_phone !== "undefined" && !prevState.user.profile.home_phone && !payload.home_phone) {
        payload.home_phone = null;
    }

    return yield call( getAxios().put, ENDPOINT_USER, payload, getTokenHeaders() );
}

/**
 *
 * @param {object} payload
 * @param {string} payload.current_password
 * @param {string} payload.new_password
 * @param {string} payload.new_password_confirmation
 * @returns {Promise<T>}
 */
export function updateProfile(payload) {
    if (! payload.current_password) {
        delete payload.current_password;
        delete payload.new_password;
        delete payload.new_password_confirmation;
    }

    // Not send mobile phone for validation on request if no mobile phone is set
    if (typeof payload.mobile_phone !== "undefined" && !payload.mobile_phone) {
        payload.mobile_phone = null;
    }

    if (typeof payload.home_phone !== "undefined" && !payload.home_phone) {
        payload.home_phone = null;
    }

    return getAxios().put(
        ENDPOINT_USER, 
        payload, getTokenHeaders()
        ).then( response => {
            if (response && response.data) {
                return response;
            }

            return null
        } )
        .catch( e => {
            errorCatch( e, {
                400: 'concat',
                401: 'Unauthorized',
                404: 'Not Found',
            } );

            return e.response;
        } );
}

export function* logoutUser() {
    return yield call( getAxios().post, ENDPOINT_USER_LOGOUT, null, getTokenHeaders() );
}

export function reservationRequested(payload) {
    return getAxios().post(
        ENDPOINT_RESERVATION_REQUESTED,
        payload.data,
        getTokenHeaders()
    )
        .then( response => {
            if (response && response.data) {
                return response;
            }

            return null
        } )
        .catch( e => {
            errorCatch( e, {
                400: 'concat',
                401: 'Unauthorized',
                404: 'Not Found',
            } );

            return e.response;
        } );
}

export function sendReservationConfirmation(payload) {
    return getAxios().post(
        ENDPOINT_SEND_RESERVATION_CONFIRMATION,
        payload.data,
        getTokenHeaders()
    )
        .then( response => {
            if (response && response.data) {
                return response;
            }

            return null
        } )
        .catch( e => {
            errorCatchGlobal( e, {
                400: 'concat',
                401: Messages.UNAUTHENTICATED_ERROR,
                403: Messages.UNAUTHORIZED_ERROR,
                404: Messages.NOT_FOUND_ERROR,
            }, payload.notificationId );

            return e.response;
        });
}

export function addReservationToProfile(payload) {
    return getAxios().post(
        ENDPOINT_ADD_RESERVATION_TO_PROFILE,
        payload.data,
        getTokenHeaders()
    )
        .then( response => {
            if (response && response.data) {
                return response;
            }

            return null
        } )
        .catch( e => {
            errorCatch( e, {
                400: 'concat',
                401: 'Unauthorized',
                404: 'Not Found',
            } );

            return e.response;
        } );
}

