import {useState, useEffect} from 'react';
import reservationfound from '../resources/assets/reservationfound.png';

import Joya from '../resources/assets/account/myReservations/joya.jpeg';
import Jungala from '../resources/assets/account/myReservations/jungala.jpeg';

//my reservations
import Acapulco from '../resources/assets/account/myReservations/Acapulco.jpg';
import LosCabos from '../resources/assets/account/myReservations/LosCabos.jpg';
import Mazatlan from '../resources/assets/account/myReservations/Mazatlan.jpg';
import NuevoVallarta from '../resources/assets/account/myReservations/NuevoVallarta.jpg';
import PuertoPenasco from '../resources/assets/account/myReservations/PuertoPenasco.jpg';
import PuertoVallarta from '../resources/assets/account/myReservations/PuertoVallarta.jpg';
import RivieraMaya from '../resources/assets/account/myReservations/RivieraMaya.jpg';

//my request reservation
import RAcapulco from '../resources/assets/account/requestReservation/acapulco.jpg';
import RLosCabos from '../resources/assets/account/requestReservation/loscabos.jpg';
import RMazatlan from '../resources/assets/account/requestReservation/mazatlan.jpg';
import RNuevoVallarta from '../resources/assets/account/requestReservation/nuevovallarta.jpg';
import RPuertoPenasco from '../resources/assets/account/requestReservation/puertopenasco.jpg';
import RPuertoVallarta from '../resources/assets/account/requestReservation/puertovallarta.jpg';
import RRivieraMaya from '../resources/assets/account/requestReservation/rivieramaya.jpg';

import crypto from "crypto";
import { Config } from "../config";

import { getTypeInfo } from "credit-card-type";
import countryList from 'react-select-country-list'

import { countriesData } from "./countries";
import { statesData } from "./states";

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export function cardNumberFormat(cardNumber, cardType) {
    let card = getTypeInfo(cardType);

    if (card) {
        let offsets = [].concat(0, card.gaps, cardNumber.length);
        let components = [];

        for (let i = 0; offsets[i] < cardNumber.length; i++) {
            let start = offsets[i];
            let end = Math.min(offsets[i + 1], cardNumber.length);
            components.push(cardNumber.substring(start, end));
        }

        return components.join(" ");
    }

    return cardNumber;
}

/**
 Format Card Number
 @param {string} value
 @param {boolean} hideNumber
 */
export function spacingCardNumber(value, hideNumber) {
    value = value.replace(/ /g, '');
    let i;

    let numberWithSpace = '';

    if (hideNumber) {
        for (i = 0; i < value.length - 4; i++) {
            numberWithSpace = numberWithSpace + '•';
            if ((i + 1) % 4 === 0 && i !== 0) {
                numberWithSpace += ' ';
            }
        }

        numberWithSpace += value.substr(value.length - 4);
    } else {
        for (i = 0; i < value.length; i++) {
            numberWithSpace = numberWithSpace + value[i];
            if ((i + 1) % 4 === 0 && i !== 0) {
                numberWithSpace += ' ';
            }
        }
    }

    return numberWithSpace;
}

export function getAllCountriesFormatted(language) {

     let countries = countriesData.filter(item => {
        return item.language === language
     });

    countries.forEach((country, index) => {
        country.name = country.label;
        country.id = index;
    });

    return countries;
}

export function getCountryByName(name, language) {
    if (! name) {
        return '';
    }

    let country = getAllCountriesFormatted(language).filter(item => {
       return item.name === name
    });

    return country[0] ?? '';
}

export function getAllStatesFormatted(language, place) {

    let countries = statesData.filter(item => {
       return item.language === language && item.country === place
    });
    
   countries.forEach((country, index) => {
       country.name = country.label;
       country.id = index;
   });

   return countries;
}

export function getStateByName(name, language, place) {
   if (! name) {
       return '';
   }

   let country = getAllStatesFormatted(language, place).filter(item => {
      return item.name === name
   });

   return country[0] ?? '';
}

export function getCountryByISOCode(code, language) {
    if (! code) {
        return '';
    }

    let country = getAllCountriesFormatted(language).filter(item => {
       return item.value === code
    });

    return country[0].name ?? '';
}

export function getStateByISOCode(code, language, country) {
    if (! code) {
        return '';
    }
 
    let countries = getAllStatesFormatted(language, country).filter(item => {
       return item.value === code
    });
 
    return countries[0]?.name ?? code;
 }

export function getDestinationImage(reservation) {
    if (!reservation.hotel) {
        return;
    }

    if (reservation.hotel.toLowerCase().search('jungala') > -1) {
        return Jungala;
    }

    if (reservation.hotel.toLowerCase().search('joya') > -1) {
        return Joya;
    }

    if (reservation.hotel.toLowerCase().search('acapulco') > -1) {
        return Acapulco;
    }

    if (reservation.hotel.toLowerCase().search('los cabos') > -1) {
        return LosCabos;
    }

    if (reservation.hotel.toLowerCase().search('mazatlán') > -1 || reservation.hotel.toLowerCase().search('mazatlan') > -1) {
        return Mazatlan;
    }

    if (reservation.hotel.toLowerCase().search('nuevo vallarta') > -1) {
        return NuevoVallarta;
    }

    if (reservation.hotel.toLowerCase().search('puerto peñasco') > -1 || reservation.hotel.toLowerCase().search('puerto penasco') > -1) {
        return PuertoPenasco;
    }

    if (reservation.hotel.toLowerCase().search('puerto vallarta') > -1) {
        return PuertoVallarta;
    }

    if (reservation.hotel.toLowerCase().search('riviera maya') > -1) {
        return RivieraMaya;
    }

    return reservationfound;
}

export function getDestinationImageRequest(reservation) {
    if (!reservation.hotel) {
        return;
    }

    if (reservation.hotel.toLowerCase().search('jungala') > -1) {
        return Jungala;
    }

    if (reservation.hotel.toLowerCase().search('joya') > -1) {
        return Joya;
    }

    if (reservation.hotel.toLowerCase().search('acapulco') > -1) {
        return RAcapulco;
    }

    if (reservation.hotel.toLowerCase().search('los cabos') > -1) {
        return RLosCabos;
    }

    if (reservation.hotel.toLowerCase().search('mazatlán') > -1 || reservation.hotel.toLowerCase().search('mazatlan') > -1) {
        return RMazatlan;
    }

    if (reservation.hotel.toLowerCase().search('nuevo vallarta') > -1) {
        return RNuevoVallarta;
    }

    if (reservation.hotel.toLowerCase().search('puerto peñasco') > -1 || reservation.hotel.toLowerCase().search('puerto penasco') > -1) {
        return RPuertoPenasco;
    }

    if (reservation.hotel.toLowerCase().search('puerto vallarta') > -1) {
        return RPuertoVallarta;
    }

    if (reservation.hotel.toLowerCase().search('riviera maya') > -1) {
        return RRivieraMaya;
    }

    return reservationfound;
}

export function formatGuestsArray(guests) {
    let formattedGuests = [];
    // Format guest guests array necessary for Select Component
    for (let i = 1; i <= guests; i++) {
        formattedGuests.push({
            id: i,
            name: i
        });
    }

    return formattedGuests;
}

export function signatureKript(query_string, secret) {
    return crypto
        .createHmac('sha256', secret)
        .update(query_string)
        .digest('base64');
}

export function getObjectByComma(object) {
    var text = "";
    Object.keys(object).forEach(key => {
      text += key + "=" + object[key] + ",";
    });
    return text.slice(0, -1);
}

export function getCustomTimeFormat() {
    var date = new Date();
    return date.getUTCFullYear() +
      '-' + pad(date.getUTCMonth() + 1) +
      '-' + pad(date.getUTCDate()) +
      'T' + pad(date.getUTCHours()) +
      ':' + pad(date.getUTCMinutes()) +
      ':' + pad(date.getUTCSeconds()) +
      'Z';
};

export function decrypt(data){

    let obj_json = JSON.parse(data);

    var encryptionMethod = 'AES-256-CBC';
    var secret = obj_json.key
    var iv = obj_json.iv

    var decryptor = crypto.createDecipheriv(encryptionMethod, secret, iv);
    var json = decryptor.update(obj_json.ciphertext, 'base64', 'utf8') + decryptor.final('utf8');

    return JSON.parse(json)
}

function pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
}
