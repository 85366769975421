export const statesData = [
    {
     id: 0,
     value: "AB",
     label: "Alberta",
     name: "Alberta",
     language: "es",
     country: "CA"
    },
    {
     id: 1,
     value: "BC",
     label: "British Columbia",
     name: "British Columbia",
     language: "es",
     country: "CA"
    },
    {
     id: 2,
     value: "PE",
     label: "Isla del Príncipe Eduardo",
     name: "Isla del Príncipe Eduardo",
     language: "es",
     country: "CA"
    },
    {
     id: 3,
     value: "MB",
     label: "Manitoba",
     name: "Manitoba",
     language: "es",
     country: "CA"
    },
    {
     id: 4,
     value: "NB",
     label: "New Brunswick",
     name: "New Brunswick",
     language: "es",
     country: "CA"
    },
    {
     id: 5,
     value: "NL",
     label: "Newfoundland y Labrador",
     name: "Newfoundland y Labrador",
     language: "es",
     country: "CA"
    },
    {
     id: 6,
     value: "NS",
     label: "Nova Scotia",
     name: "Nova Scotia",
     language: "es",
     country: "CA"
    },
    {
     id: 7,
     value: "NU",
     label: "Nunavut",
     name: "Nunavut",
     language: "es",
     country: "CA"
    },
    {
     id: 8,
     value: "ON",
     label: "Ontario",
     name: "Ontario",
     language: "es",
     country: "CA"
    },
    {
     id: 9,
     value: "QC",
     label: "Quebec",
     name: "Quebec",
     language: "es",
     country: "CA"
    },
    {
     id: 10,
     value: "SK",
     label: "Saskatchewan",
     name: "Saskatchewan",
     language: "es",
     country: "CA"
    },
    {
     id: 11,
     value: "YT",
     label: "Territorio del Yukón",
     name: "Territorio del Yukón",
     language: "es",
     country: "CA"
    },
    {
     id: 12,
     value: "NT",
     label: "Territorios del Noroeste",
     name: "Territorios del Noroeste",
     language: "es",
     country: "CA"
    },
    {
     id: 0,
     value: "AB",
     label: "Alberta",
     name: "Alberta",
     language: "en",
     country: "CA"
    },
    {
     id: 1,
     value: "BC",
     label: "British Columbia",
     name: "British Columbia",
     language: "en",
     country: "CA"
    },
    {
     id: 2,
     value: "MB",
     label: "Manitoba",
     name: "Manitoba",
     language: "en",
     country: "CA"
    },
    {
     id: 3,
     value: "NB",
     label: "New Brunswick",
     name: "New Brunswick",
     language: "en",
     country: "CA"
    },
    {
     id: 4,
     value: "NL",
     label: "Newfoundland and Labrador",
     name: "Newfoundland and Labrador",
     language: "en",
     country: "CA"
    },
    {
     id: 5,
     value: "NT",
     label: "Northwest Territories",
     name: "Northwest Territories",
     language: "en",
     country: "CA"
    },
    {
     id: 6,
     value: "NS",
     label: "Nova Scotia",
     name: "Nova Scotia",
     language: "en",
     country: "CA"
    },
    {
     id: 7,
     value: "NU",
     label: "Nunavut",
     name: "Nunavut",
     language: "en",
     country: "CA"
    },
    {
     id: 8,
     value: "ON",
     label: "Ontario",
     name: "Ontario",
     language: "en",
     country: "CA"
    },
    {
     id: 9,
     value: "PE",
     label: "Prince Edward Island",
     name: "Prince Edward Island",
     language: "en",
     country: "CA"
    },
    {
     id: 10,
     value: "QC",
     label: "Quebec",
     name: "Quebec",
     language: "en",
     country: "CA"
    },
    {
     id: 11,
     value: "SK",
     label: "Saskatchewan",
     name: "Saskatchewan",
     language: "en",
     country: "CA"
    },
    {
     id: 12,
     value: "YT",
     label: "Yukon Territory",
     name: "Yukon Territory",
     language: "en",
     country: "CA"
    },
    {
     id: 0,
     value: "AL",
     label: "Alabama",
     name: "Alabama",
     language: "es",
     country: "US"
    },
    {
     id: 1,
     value: "AK",
     label: "Alaska",
     name: "Alaska",
     language: "es",
     country: "US"
    },
    {
     id: 2,
     value: "AZ",
     label: "Arizona",
     name: "Arizona",
     language: "es",
     country: "US"
    },
    {
     id: 3,
     value: "AR",
     label: "Arkansas",
     name: "Arkansas",
     language: "es",
     country: "US"
    },
    {
     id: 4,
     value: "CA",
     label: "California",
     name: "California",
     language: "es",
     country: "US"
    },
    {
     id: 5,
     value: "CO",
     label: "Colorado",
     name: "Colorado",
     language: "es",
     country: "US"
    },
    {
     id: 6,
     value: "CT",
     label: "Connecticut",
     name: "Connecticut",
     language: "es",
     country: "US"
    },
    {
     id: 7,
     value: "DE",
     label: "Delaware",
     name: "Delaware",
     language: "es",
     country: "US"
    },
    {
     id: 8,
     value: "DC",
     label: "Distrito de Columbia",
     name: "Distrito de Columbia",
     language: "es",
     country: "US"
    },
    {
     id: 9,
     value: "FM",
     label: "Estados Federados de Micronesia",
     name: "Estados Federados de Micronesia",
     language: "es",
     country: "US"
    },
    {
     id: 10,
     value: "FL",
     label: "Florida",
     name: "Florida",
     language: "es",
     country: "US"
    },
    {
     id: 11,
     value: "AA",
     label: "Fuerzas Armadas (América)",
     name: "Fuerzas Armadas (América)",
     language: "es",
     country: "US"
    },
    {
     id: 12,
     value: "AE",
     label: "Fuerzas Armadas (Medio Oriente y África)",
     name: "Fuerzas Armadas (Medio Oriente y África)",
     language: "es",
     country: "US"
    },
    {
     id: 13,
     value: "AP",
     label: "Fuerzas Armadas (Pacífico)",
     name: "Fuerzas Armadas (Pacífico)",
     language: "es",
     country: "US"
    },
    {
     id: 14,
     value: "GA",
     label: "Georgia",
     name: "Georgia",
     language: "es",
     country: "US"
    },
    {
     id: 15,
     value: "GU",
     label: "Guam",
     name: "Guam",
     language: "es",
     country: "US"
    },
    {
     id: 16,
     value: "HI",
     label: "Hawai",
     name: "Hawai",
     language: "es",
     country: "US"
    },
    {
     id: 17,
     value: "ID",
     label: "Idaho",
     name: "Idaho",
     language: "es",
     country: "US"
    },
    {
     id: 18,
     value: "IL",
     label: "Illinois",
     name: "Illinois",
     language: "es",
     country: "US"
    },
    {
     id: 19,
     value: "IN",
     label: "Indiana",
     name: "Indiana",
     language: "es",
     country: "US"
    },
    {
     id: 20,
     value: "IA",
     label: "Iowa",
     name: "Iowa",
     language: "es",
     country: "US"
    },
    {
     id: 21,
     value: "MP",
     label: "Islas Mariana del Norte",
     name: "Islas Mariana del Norte",
     language: "es",
     country: "US"
    },
    {
     id: 22,
     value: "MH",
     label: "Islas Marshall",
     name: "Islas Marshall",
     language: "es",
     country: "US"
    },
    {
     id: 23,
     value: "VI",
     label: "Islas Vírgenes Estadounidenses",
     name: "Islas Vírgenes Estadounidenses",
     language: "es",
     country: "US"
    },
    {
     id: 24,
     value: "NJ",
     label: "Jersey",
     name: "Jersey",
     language: "es",
     country: "US"
    },
    {
     id: 25,
     value: "KS",
     label: "Kansas",
     name: "Kansas",
     language: "es",
     country: "US"
    },
    {
     id: 26,
     value: "KY",
     label: "Kentucky",
     name: "Kentucky",
     language: "es",
     country: "US"
    },
    {
     id: 27,
     value: "LA",
     label: "Louisiana",
     name: "Louisiana",
     language: "es",
     country: "US"
    },
    {
     id: 28,
     value: "ME",
     label: "Maine",
     name: "Maine",
     language: "es",
     country: "US"
    },
    {
     id: 29,
     value: "MD",
     label: "Maryland",
     name: "Maryland",
     language: "es",
     country: "US"
    },
    {
     id: 30,
     value: "MA",
     label: "Massachusetts",
     name: "Massachusetts",
     language: "es",
     country: "US"
    },
    {
     id: 31,
     value: "MI",
     label: "Michigan",
     name: "Michigan",
     language: "es",
     country: "US"
    },
    {
     id: 32,
     value: "MN",
     label: "Minnesota",
     name: "Minnesota",
     language: "es",
     country: "US"
    },
    {
     id: 33,
     value: "MS",
     label: "Mississippi",
     name: "Mississippi",
     language: "es",
     country: "US"
    },
    {
     id: 34,
     value: "MO",
     label: "Missouri",
     name: "Missouri",
     language: "es",
     country: "US"
    },
    {
     id: 35,
     value: "MT",
     label: "Montana",
     name: "Montana",
     language: "es",
     country: "US"
    },
    {
     id: 36,
     value: "NE",
     label: "Nebraska",
     name: "Nebraska",
     language: "es",
     country: "US"
    },
    {
     id: 37,
     value: "NV",
     label: "Nevada",
     name: "Nevada",
     language: "es",
     country: "US"
    },
    {
     id: 38,
     value: "NH",
     label: "New Hampshire",
     name: "New Hampshire",
     language: "es",
     country: "US"
    },
    {
     id: 39,
     value: "NY",
     label: "New York",
     name: "New York",
     language: "es",
     country: "US"
    },
    {
     id: 40,
     value: "NC",
     label: "North Carolina",
     name: "North Carolina",
     language: "es",
     country: "US"
    },
    {
     id: 41,
     value: "ND",
     label: "North Dakota",
     name: "North Dakota",
     language: "es",
     country: "US"
    },
    {
     id: 42,
     value: "NM",
     label: "Nuevo México",
     name: "Nuevo México",
     language: "es",
     country: "US"
    },
    {
     id: 43,
     value: "OH",
     label: "Ohio",
     name: "Ohio",
     language: "es",
     country: "US"
    },
    {
     id: 44,
     value: "OK",
     label: "Oklahoma",
     name: "Oklahoma",
     language: "es",
     country: "US"
    },
    {
     id: 45,
     value: "OR",
     label: "Oregon",
     name: "Oregon",
     language: "es",
     country: "US"
    },
    {
     id: 46,
     value: "PW",
     label: "Palau",
     name: "Palau",
     language: "es",
     country: "US"
    },
    {
     id: 47,
     value: "PA",
     label: "Pennsylvania",
     name: "Pennsylvania",
     language: "es",
     country: "US"
    },
    {
     id: 48,
     value: "PR",
     label: "Puerto Rico",
     name: "Puerto Rico",
     language: "es",
     country: "US"
    },
    {
     id: 49,
     value: "RI",
     label: "Rhode Island",
     name: "Rhode Island",
     language: "es",
     country: "US"
    },
    {
     id: 50,
     value: "AS",
     label: "Samoa Americana",
     name: "Samoa Americana",
     language: "es",
     country: "US"
    },
    {
     id: 51,
     value: "SC",
     label: "South Carolina",
     name: "South Carolina",
     language: "es",
     country: "US"
    },
    {
     id: 52,
     value: "SD",
     label: "South Dakota",
     name: "South Dakota",
     language: "es",
     country: "US"
    },
    {
     id: 53,
     value: "TN",
     label: "Tennessee",
     name: "Tennessee",
     language: "es",
     country: "US"
    },
    {
     id: 54,
     value: "TX",
     label: "Texas",
     name: "Texas",
     language: "es",
     country: "US"
    },
    {
     id: 55,
     value: "UT",
     label: "Utah",
     name: "Utah",
     language: "es",
     country: "US"
    },
    {
     id: 56,
     value: "VT",
     label: "Vermont",
     name: "Vermont",
     language: "es",
     country: "US"
    },
    {
     id: 57,
     value: "VA",
     label: "Virginia",
     name: "Virginia",
     language: "es",
     country: "US"
    },
    {
     id: 58,
     value: "WA",
     label: "Washington",
     name: "Washington",
     language: "es",
     country: "US"
    },
    {
     id: 59,
     value: "WV",
     label: "West Virginia",
     name: "West Virginia",
     language: "es",
     country: "US"
    },
    {
     id: 60,
     value: "WI",
     label: "Wisconsin",
     name: "Wisconsin",
     language: "es",
     country: "US"
    },
    {
     id: 61,
     value: "WY",
     label: "Wyoming",
     name: "Wyoming",
     language: "es",
     country: "US"
    },
    {
     id: 0,
     value: "AL",
     label: "Alabama",
     name: "Alabama",
     language: "en",
     country: "US"
    },
    {
     id: 1,
     value: "AK",
     label: "Alaska",
     name: "Alaska",
     language: "en",
     country: "US"
    },
    {
     id: 2,
     value: "AS",
     label: "American Samoa",
     name: "American Samoa",
     language: "en",
     country: "US"
    },
    {
     id: 3,
     value: "AZ",
     label: "Arizona",
     name: "Arizona",
     language: "en",
     country: "US"
    },
    {
     id: 4,
     value: "AR",
     label: "Arkansas",
     name: "Arkansas",
     language: "en",
     country: "US"
    },
    {
     id: 5,
     value: "AA",
     label: "Armed Forces (AA)",
     name: "Armed Forces (AA)",
     language: "en",
     country: "US"
    },
    {
     id: 6,
     value: "AE",
     label: "Armed Forces (AE)",
     name: "Armed Forces (AE)",
     language: "en",
     country: "US"
    },
    {
     id: 7,
     value: "AP",
     label: "Armed Forces (AP)",
     name: "Armed Forces (AP)",
     language: "en",
     country: "US"
    },
    {
     id: 8,
     value: "CA",
     label: "California",
     name: "California",
     language: "en",
     country: "US"
    },
    {
     id: 9,
     value: "CO",
     label: "Colorado",
     name: "Colorado",
     language: "en",
     country: "US"
    },
    {
     id: 10,
     value: "CT",
     label: "Connecticut",
     name: "Connecticut",
     language: "en",
     country: "US"
    },
    {
     id: 11,
     value: "DE",
     label: "Delaware",
     name: "Delaware",
     language: "en",
     country: "US"
    },
    {
     id: 12,
     value: "DC",
     label: "District of Columbia",
     name: "District of Columbia",
     language: "en",
     country: "US"
    },
    {
     id: 13,
     value: "FL",
     label: "Florida",
     name: "Florida",
     language: "en",
     country: "US"
    },
    {
     id: 14,
     value: "GA",
     label: "Georgia",
     name: "Georgia",
     language: "en",
     country: "US"
    },
    {
     id: 15,
     value: "GU",
     label: "Guam",
     name: "Guam",
     language: "en",
     country: "US"
    },
    {
     id: 16,
     value: "HI",
     label: "Hawaii",
     name: "Hawaii",
     language: "en",
     country: "US"
    },
    {
     id: 17,
     value: "ID",
     label: "Idaho",
     name: "Idaho",
     language: "en",
     country: "US"
    },
    {
     id: 18,
     value: "IL",
     label: "Illinois",
     name: "Illinois",
     language: "en",
     country: "US"
    },
    {
     id: 19,
     value: "IN",
     label: "Indiana",
     name: "Indiana",
     language: "en",
     country: "US"
    },
    {
     id: 20,
     value: "IA",
     label: "Iowa",
     name: "Iowa",
     language: "en",
     country: "US"
    },
    {
     id: 21,
     value: "KS",
     label: "Kansas",
     name: "Kansas",
     language: "en",
     country: "US"
    },
    {
     id: 22,
     value: "KY",
     label: "Kentucky",
     name: "Kentucky",
     language: "en",
     country: "US"
    },
    {
     id: 23,
     value: "LA",
     label: "Louisiana",
     name: "Louisiana",
     language: "en",
     country: "US"
    },
    {
     id: 24,
     value: "ME",
     label: "Maine",
     name: "Maine",
     language: "en",
     country: "US"
    },
    {
     id: 25,
     value: "MH",
     label: "Marshall Islands",
     name: "Marshall Islands",
     language: "en",
     country: "US"
    },
    {
     id: 26,
     value: "MD",
     label: "Maryland",
     name: "Maryland",
     language: "en",
     country: "US"
    },
    {
     id: 27,
     value: "MA",
     label: "Massachusetts",
     name: "Massachusetts",
     language: "en",
     country: "US"
    },
    {
     id: 28,
     value: "MI",
     label: "Michigan",
     name: "Michigan",
     language: "en",
     country: "US"
    },
    {
     id: 29,
     value: "FM",
     label: "Micronesia, Federated States Of",
     name: "Micronesia, Federated States Of",
     language: "en",
     country: "US"
    },
    {
     id: 30,
     value: "MN",
     label: "Minnesota",
     name: "Minnesota",
     language: "en",
     country: "US"
    },
    {
     id: 31,
     value: "MS",
     label: "Mississippi",
     name: "Mississippi",
     language: "en",
     country: "US"
    },
    {
     id: 32,
     value: "MO",
     label: "Missouri",
     name: "Missouri",
     language: "en",
     country: "US"
    },
    {
     id: 33,
     value: "MT",
     label: "Montana",
     name: "Montana",
     language: "en",
     country: "US"
    },
    {
     id: 34,
     value: "NE",
     label: "Nebraska",
     name: "Nebraska",
     language: "en",
     country: "US"
    },
    {
     id: 35,
     value: "NV",
     label: "Nevada",
     name: "Nevada",
     language: "en",
     country: "US"
    },
    {
     id: 36,
     value: "NH",
     label: "New Hampshire",
     name: "New Hampshire",
     language: "en",
     country: "US"
    },
    {
     id: 37,
     value: "NJ",
     label: "New Jersey",
     name: "New Jersey",
     language: "en",
     country: "US"
    },
    {
     id: 38,
     value: "NM",
     label: "New Mexico",
     name: "New Mexico",
     language: "en",
     country: "US"
    },
    {
     id: 39,
     value: "NY",
     label: "New York",
     name: "New York",
     language: "en",
     country: "US"
    },
    {
     id: 40,
     value: "NC",
     label: "North Carolina",
     name: "North Carolina",
     language: "en",
     country: "US"
    },
    {
     id: 41,
     value: "ND",
     label: "North Dakota",
     name: "North Dakota",
     language: "en",
     country: "US"
    },
    {
     id: 42,
     value: "MP",
     label: "Northern Mariana Islands",
     name: "Northern Mariana Islands",
     language: "en",
     country: "US"
    },
    {
     id: 43,
     value: "OH",
     label: "Ohio",
     name: "Ohio",
     language: "en",
     country: "US"
    },
    {
     id: 44,
     value: "OK",
     label: "Oklahoma",
     name: "Oklahoma",
     language: "en",
     country: "US"
    },
    {
     id: 45,
     value: "OR",
     label: "Oregon",
     name: "Oregon",
     language: "en",
     country: "US"
    },
    {
     id: 46,
     value: "PW",
     label: "Palau",
     name: "Palau",
     language: "en",
     country: "US"
    },
    {
     id: 47,
     value: "PA",
     label: "Pennsylvania",
     name: "Pennsylvania",
     language: "en",
     country: "US"
    },
    {
     id: 48,
     value: "PR",
     label: "Puerto Rico",
     name: "Puerto Rico",
     language: "en",
     country: "US"
    },
    {
     id: 49,
     value: "RI",
     label: "Rhode Island",
     name: "Rhode Island",
     language: "en",
     country: "US"
    },
    {
     id: 50,
     value: "SC",
     label: "South Carolina",
     name: "South Carolina",
     language: "en",
     country: "US"
    },
    {
     id: 51,
     value: "SD",
     label: "South Dakota",
     name: "South Dakota",
     language: "en",
     country: "US"
    },
    {
     id: 52,
     value: "TN",
     label: "Tennessee",
     name: "Tennessee",
     language: "en",
     country: "US"
    },
    {
     id: 53,
     value: "TX",
     label: "Texas",
     name: "Texas",
     language: "en",
     country: "US"
    },
    {
     id: 54,
     value: "UT",
     label: "Utah",
     name: "Utah",
     language: "en",
     country: "US"
    },
    {
     id: 55,
     value: "VT",
     label: "Vermont",
     name: "Vermont",
     language: "en",
     country: "US"
    },
    {
     id: 56,
     value: "VI",
     label: "Virgin Islands, U.S.",
     name: "Virgin Islands, U.S.",
     language: "en",
     country: "US"
    },
    {
     id: 57,
     value: "VA",
     label: "Virginia",
     name: "Virginia",
     language: "en",
     country: "US"
    },
    {
     id: 58,
     value: "WA",
     label: "Washington",
     name: "Washington",
     language: "en",
     country: "US"
    },
    {
     id: 59,
     value: "WV",
     label: "West Virginia",
     name: "West Virginia",
     language: "en",
     country: "US"
    },
    {
     id: 60,
     value: "WI",
     label: "Wisconsin",
     name: "Wisconsin",
     language: "en",
     country: "US"
    },
    {
     id: 61,
     value: "WY",
     label: "Wyoming",
     name: "Wyoming",
     language: "en",
     country: "US"
    }
   ];