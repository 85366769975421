import React, {Component} from 'react';
import {Section} from '../../layout/Section';
import Grid from '@material-ui/core/Grid';
import {FormattedMessage} from 'react-intl';
import {Filters} from "../../layout/Navbars/header/Filters";

class WelcomeComponent extends Component {
    render() {
        return <Section color='dashboard-image'>
            <Grid container direction="column" alignItems="center" className="welcome-component">
                <div className="title">
                    <FormattedMessage id="components.account.dashboard.welcome.title"/>
                </div>
                <div className="sub-title">
                    <FormattedMessage id="components.account.dashboard.welcome.sub_title"/>
                </div>
                <div className="welcome-filters">
                    <span>
                        <FormattedMessage id="components.account.dashboard.welcome.filters_title"/>
                    </span>
                    {/* <Filters isDashboardPage={true} /> */}
                </div>
            </Grid>
        </Section>
    }
}

export {WelcomeComponent}
