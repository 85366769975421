import React from 'react';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { FormattedMessage } from 'react-intl';

class SubmitButton extends React.Component {
    render() {
        return <button type="submit" className="submit">
            <FormattedMessage id={this.props.id}/>
            <ChevronRightIcon/>
        </button>
    }
}

export { SubmitButton }
