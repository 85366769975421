import React, {Component} from "react"
import Slider from "react-slick"
import visit_website_img from '../../../resources/assets/account/exchanges/visit_website_img.svg';
import {SingleExchangeCard} from "./SingleExchangeCard";
import {FormattedMessage} from "react-intl";
import {getExchanges} from "../../../api/exchanges/exchanges";
import {Section} from "../../layout/Section";
import {checkDevice} from "../../../helpers/checkDevice";
import {history, store} from "../../../store/store";
import { displayNotification } from "../../../store/actions/notification";
import { displayGlobalNotification } from "../../../store/actions/globalNotification";

import {NOTIFICATION_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_WARNING, DISPLAY_GLOBAL_NOTIFICATION} from "../../../store/actions/types";
import {Routes} from "../../../config";

class ExchangesCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: [],
            isCarousel: false,
            singleCompany: {},
            device: checkDevice(),
        }
    }

    setDevice = () => {
        this.setState({
            device: checkDevice()
        }, () => {
            this.checkCarouselOrSingleCard()
        });
    }

    componentDidMount() {
        this.getExchanges();
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDevice);
    }

    getExchanges = () => {
        getExchanges().then(response => {

            if(response.length <= 0) {
                store.dispatch(displayGlobalNotification(NOTIFICATION_WARNING, 'messages.exchange_partners'));
                history.push(Routes.ROUTE_HOME);
            }

            this.setState({companies: response}, () => {
                this.checkCarouselOrSingleCard()
            });
        })
    }

    renderCard = (card) => {
        return (
            <div className='card' key={card.title} onClick={(e) => {
                e.preventDefault();
                window.open(card.link)
            }}>
                <div className='card-container'>
                <img className={`logo ${card.className}`} src={card.img} alt="card-bg"/>
                    <div className='card-content'>
                        <div className="card-button">
                            <a className={'link-btn'} href={card.link}>
                                <img className="visit-website-img" src={visit_website_img} alt="visit-website"/>
                                <p className={'visit-website'}>
                                    <FormattedMessage id="components.account.exchanges.visit_website"/>
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    checkCarouselOrSingleCard() {
        let match = 0;

        if (!this.state.companies || !this.state.companies.length) {
            return;
        }

        for (let i = 0; i < this.props.exchanges.length; ++i) {
            for (let j = 0; j < this.state.companies.length; ++j) {
                if (this.props.exchanges[i]['title'] === this.state.companies[j]['MembershipType']) {
                    match = match + 1;
                }
            }
        }

        switch (match) {
            case 1: {
                this.setState({isCarousel: false});

                break;
            }
            case 2: {
                if (this.state.device.isDesktop) {
                    this.setState({isCarousel: false});
                } else {
                    this.setState({isCarousel: true});
                }

                break;
            }
            case 3: {
                if (this.state.device.isDesktop) {
                    // For 3 items and width < 1000 make carousel
                    if (window.innerWidth < 1000) {
                        this.setState({isCarousel: true});
                    } else {
                        this.setState({isCarousel: false});
                    }
                } else {
                    this.setState({isCarousel: true});
                }

                break;
            }
            default : {
                this.setState({isCarousel: true});
            }
        }
    }

    carousel() {
        let {companies} = this.state;
        let foundCompanies = [];

        for (let i = 0; i < this.props.exchanges.length; ++i) {
            for (let j = 0; j < companies.length; ++j) {
                if (this.props.exchanges[i]['title'] === companies[j]['MembershipType']) {
                    foundCompanies.push(this.props.exchanges[i]);
                }
            }
        }

        return foundCompanies;
    }

    render() {
        const settings = {
            dots: true,
            centerPadding: 0,
            infinite: false,
            arrows: true,
            speed: 500,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        className: "center",
                        centerMode: true,
                        variableWidth: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        className: "center",
                        centerMode: true,
                        variableWidth: true,
                        centerPadding: 60,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        className: "center",
                        centerMode: true,
                        variableWidth: true,
                        centerPadding: 60,
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 2,
                        centerPadding: 50,
                    }
                },
            ]
        };

        return (
            <Section color="white">
                {!this.state.isCarousel && <SingleExchangeCard companies={this.carousel()}/>}
                {this.state.isCarousel && <div className="exchanges-carousel">
                    <Slider {...settings}>
                        {this.carousel().map(card => this.renderCard(card))}
                    </Slider>
                </div>}
            </Section>
        )
    }
}

ExchangesCarousel.defaultProps = {
    exchanges: [
        {
            title: "RCI",
            img: "/img/exchanges/RCI.jpg",
            link: "https://www.rci.com/",
            subtitle: "",
            className: 'rci'
        },
        {
            title: "Interval",
            img: "/img/exchanges/Interval.jpg",
            link: "https://www.intervalworld.com/",
            subtitle: "",
            className: 'interval'
        },
        {
            title: "DAE",
            img: "/img/offers/partners/dae.svg",
            link: "https://7across.com/",
            subtitle: "",
            className: 'dae'
        },
        {
            title: "ICE Gallery",
            img: "/img/offers/partners/ice-logo.png",
            link: "https://www.icerewardslifestyle.com/",
            subtitle: "",
            className: 'ice-gallery'
        },
        {
            title: "SFX",
            img: "/img/exchanges/SFX.jpg",
            link: "https://www.sfxresorts.com/",
            subtitle: "",
            className: "sfx"
        },
        {
            title: "ThirdHome International",
            img: "/img/exchanges/Thirdhome.jpg",
            link: "https://www.thirdhome.com/",
            subtitle: "",
            className: 'third-home'
        },
    ],
};

export { ExchangesCarousel };
