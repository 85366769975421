import React, {Component} from 'react';
import {Section} from '../../Section';
import {DesktopFilters} from './filters/DesktopFilters';
import {MobileFilters} from './filters/MobileFilters';
import {checkDevice} from '../../../../helpers/checkDevice';

class Filters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            device: checkDevice(),
            className: 'removeColor'

        }
    }

    setDevice = () => {
        this.setState({
            device: checkDevice()
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.setDevice);
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDevice);
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll=()=>{
        if ((this.state.device.isDesktop && window.pageYOffset > 240) || (this.state.device.isTablet && window.pageYOffset > 240) || (this.state.device.isMobile  && window.pageYOffset > 203)) {
     
              this.setState({ className: "addColor" });   
            
        }else{
            
              this.setState({ className: "removeColor" });
           
        }
       
      }

    render() {
        const {device} = this.state;
        const {isMainPage, isDashboardPage, isDesktopAndAddMargin, isDesktopDarkFilter} = this.props;

        return <Section className={isMainPage ? this.state.className : ''}
            color={`${isDashboardPage ? 'transparent-filters' : 'filters'} ${isDesktopAndAddMargin ? 'margin-top-100' : ''} b-bottom-filters`}>
            {
                device.isMobile ? <MobileFilters isMobile={true} isDashboardPage={isDashboardPage} isDesktopDarkFilter={isDesktopDarkFilter}/> :
                <DesktopFilters isTablet={device.isTablet} device={device} isDashboardPage={isDashboardPage}/>
            }
        </Section>
    }
}


export {Filters}
