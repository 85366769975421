import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';

class CustomCheckBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: false
        }
    }

    handleChange = (e, id) => {
        this.props.onChange(id);
        this.setState({
            checked: e.target.checked
        });
    }

    render() {
        const {id, name, value } = this.props;

        return <FormGroup row>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={this.state.checked}
                        onChange={(e) => this.handleChange(e,id)}
                        name={name.toString()}
                        color="secondary"
                        id={id.toString()}
                        checkedIcon={<CheckIcon className="payment-checked-icon" />}
                    />
                }
                className={`${this.props.class} ${this.state.checked ? 'checked' : ''}`}
                label={value}
            />
        </FormGroup>
    }
}

export {CustomCheckBox};
