import React, {Component} from 'react';
import { FormattedMessage } from "react-intl";
import Page from '../../pages/auth/Page';

class NotFoundNotAuthenticated extends Component{
    render() {
        return (
            <Page
                class="auth not-found"
                showSwitchLanguage={false}
                hideTitle={true}
            >
                <h1 className="not-authenticated">
                    <FormattedMessage id="components.general.not_found_text"/>
                </h1>
            </Page>
        );
    }
}

export {NotFoundNotAuthenticated};
