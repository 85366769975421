import React, {Component} from 'react';
import {connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import {FormattedMessage} from 'react-intl';
import {Routes} from '../../../../config';
import {userLogout} from '../../../../store/actions/user';
import {history} from "../../../../store/store";
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import HeaderAccordion from "../header/HeaderAcoordion";
import {requestUpdateProfile} from '../../../../store/actions/user';
import {updateProfile} from "../../../../api/user";

class MenuContentComponent extends Component {
    navigateToPage = page => {
        history.push(page);
    }

    submit = (language, {...props}) => {
        
        const {userProfile} = this.props;

        var data = {
            email: userProfile.email || '',
            home_phone: userProfile.home_phone || '',
            mobile_phone: userProfile.mobile_phone || '',
            address_line_1: userProfile.address_line_1 || '',
            address_line_2: userProfile.address_line_2 || '',
            language_id: language,
            all_communications: userProfile.all_communications || false,
            monthly_communications: userProfile.monthly_communications || false
        }

        updateProfile(data).then((response) => {
            this.props.requestUpdateProfile(data, response);
        })
    };

    render() {
        let {userProfile} = this.props;
        return <Grid container className="menu-content-container" direction="column">
            <Grid item className="first-menu-item">

                <Grid item xs={7}>
                    <div className="user-details">{`${userProfile.first_name ? userProfile.first_name : ""} ${userProfile.last_name ? userProfile.last_name : ""}`}</div>
                    <div className="logout-btn" onClick={() => this.props.userLogout()}>
                        <FormattedMessage id="components.layout.header.menu.logout"/>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <HeaderAccordion props={this.props} submit = {this.submit}/>
                </Grid>
                <Grid item xs={1}>
                </Grid>
                
            </Grid>
            <Grid container className="menu-item" alignItems="center"
                  onClick={() => this.navigateToPage(Routes.ROUTE_HOME)}>
                <FormattedMessage id="components.layout.header.menu.home"/>
            </Grid>
            <Grid container className="menu-item" alignItems="center"
                  onClick={() => this.navigateToPage(Routes.ROUTE_MY_ACCOUNT)}>
                <FormattedMessage id="components.layout.header.menu.my_account"/>
            </Grid>
            <Grid container className="menu-item" alignItems="center"
                  onClick={() => {localStorage.removeItem('reservation'); this.navigateToPage(Routes.ROUTE_REQUEST_RESERVATION);}}>
                <FormattedMessage id="components.layout.header.menu.request_reservation"/>
            </Grid>
            <Grid container className="menu-item" alignItems="center"
                  onClick={() => this.navigateToPage(Routes.ROUTE_MY_RESERVATIONS)}>
                <FormattedMessage id="components.layout.header.menu.my_reservations"/>
            </Grid>
            <Grid container className="menu-item" alignItems="center"
                  onClick={() => this.navigateToPage(Routes.ROUTE_PRE_REGISTRATION)}>
                <FormattedMessage id="components.layout.header.menu.pre_registration"/>
            </Grid>
            
            <Grid container className="menu-item" alignItems="center"
                onClick={() => this.navigateToPage(Routes.ROUTE_MY_VIDA_VACATIONS_CONTRACTS)}>
                <FormattedMessage id="components.layout.header.menu.my_vida_vacations_contracts"/>
            </Grid>

            {/* <Grid container className="menu-item" alignItems="center" justify="space-between"
                onClick={() => this.navigateToPage(Routes.ROUTE_MY_VIDA_VACATIONS_PAYMENTS)}>
                <FormattedMessage id="components.layout.header.menu.my_vida_vacations_payments"/>
                <Badge badgeContent={this.props.payments} className="vidanta-badge"/>
            </Grid>
            <Grid container className="sub-menu-item" alignItems="center"
                onClick={() => this.navigateToPage(Routes.ROUTE_MY_VIDA_VACATIONS_PAYMENTS_HISTORY)}>
                <FormattedMessage id="components.layout.header.menu.my_vida_vacations_payments_history"/>
            </Grid> */}

            <Accordion onClick={(event) => event.stopPropagation()}  onFocus={(event) => event.stopPropagation()} style={{ boxShadow: "none", background: "#000" }}>
                <AccordionSummary  className="menu-item-accordion-summary" expandIcon={<ExpandMore style={{ color: 'white' }} />}> <FormattedMessage id="components.layout.header.menu.my_vida_vacations"/></AccordionSummary>
                    <AccordionDetails className="menu-item-accordion-details">
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            item xs={12}
                            spacing={0}>

                                <Grid container className="menu-item" alignItems="center" justify="space-between"
                                    onClick={(e) => this.navigateToPage(Routes.ROUTE_MY_VIDA_VACATIONS_PAYMENTS)}>
                                    <FormattedMessage id="components.layout.header.menu.my_vida_vacations_payments"/>
                                    <Badge badgeContent={this.props.payments} className="vidanta-badge"/>
                                </Grid>
                                <Grid container className="sub-menu-item" alignItems="center"
                                    onClick={() => this.navigateToPage(Routes.ROUTE_MY_VIDA_VACATIONS_PAYMENTS_HISTORY)}>
                                    <FormattedMessage id="components.layout.header.menu.my_vida_vacations_payments_history"/>
                                </Grid>

                        </Grid>
                    </AccordionDetails>
            </Accordion>

            <Grid container className="menu-item" alignItems="center"
                  onClick={() => this.navigateToPage(Routes.ROUTE_CLUB)}>
                <FormattedMessage id="components.layout.header.menu.club_benefits"/>
            </Grid>
            <Grid container className="menu-item" alignItems="center"
                  onClick={() => this.navigateToPage(Routes.ROUTE_EXCHANGES)}>
                <FormattedMessage id="components.layout.header.menu.exchanges"/>
            </Grid>
            <Grid container className="menu-item" alignItems="center" justify="space-between"
                  onClick={() => this.navigateToPage(Routes.ROUTE_MESSAGES_CENTER)}>
                <FormattedMessage id="components.layout.header.menu.my_messages"/>
                <Badge badgeContent={this.props.unreadMessages} className="vidanta-badge"/>
            </Grid>
            <Grid container className="last-menu-container" direction="column" justify="space-around">
                <Grid item className="last-menu-item" onClick={() => this.navigateToPage(Routes.ROUTE_ABOUT_US)}>
                    <FormattedMessage id="components.layout.header.menu.about"/>
                </Grid>
                <Grid item className="last-menu-item" onClick={() => this.navigateToPage(Routes.ROUTE_CONTACT_US)}>
                    <FormattedMessage id="components.layout.header.menu.contact"/>
                </Grid>
                <Grid item className="last-menu-item" onClick={() => this.navigateToPage(Routes.ROUTE_FAQS)}>
                    <FormattedMessage id="components.layout.header.menu.faq"/>
                </Grid>
            </Grid>
        </Grid>
    }
}

const mapStateToProps = (state) => ({
    userProfile: state.user.profile
})

const MenuContent = connect(mapStateToProps, {
    userLogout,
    requestUpdateProfile
})(MenuContentComponent);

export {MenuContent, MenuContentComponent};

