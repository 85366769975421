import React, { Component, Fragment } from 'react';
import { FormattedMessage } from "react-intl";

class FormTitle extends Component {
    render() {
        return <Fragment>
            <h2 className="form-title">
                <FormattedMessage id={"components.account.reservationSummary.oneMoreStep.additional_traveler"}/> #{this.props.number}
            </h2>
            <hr className="form-title-underline"/>
        </Fragment>
    }
}

export { FormTitle }
