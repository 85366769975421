import {
    SET_FAQS
} from "../actions/faqs/types";

const initialState = {
    faqs: []
};

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_FAQS:
            return {
                ...state,
                faqs: action.payload
            };
        default:
            return state;
    }
}
