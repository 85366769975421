import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import AboutVPassportVideoEn from '../../resources/assets/video/about-vpassport-en.mp4';
import AboutVPassportVideoEs from '../../resources/assets/video/about-vpassport-es.mp4';

class VideoModal extends Component {
    render() {
        let { open, language } = this.props;
        
        return <Modal
            className="video-modal-container"
            open={open}
            onClose={this.props.handleClose}>
            <div className="video-modal-content">
                <CloseIcon onClick={this.props.handleClose}/>
                <iframe width="100%" height="100%" frameBorder="0" title="vidanta-video"
                        src={(language == "en" ? AboutVPassportVideoEn : AboutVPassportVideoEs) }>
                </iframe>
            </div>
        </Modal>
    }
}

VideoModal.defaultProps = {
    open: false,
};

export { VideoModal }
