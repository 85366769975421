import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid } from '@material-ui/core';
import { StyledRadio } from '../../general/CustomRadio';
import IconRightDetails from "../../../resources/assets/account/payments/IconRightDetails.svg";
import CustomizedTooltips from '../../general/CustomizedTooltips';
import { Link } from "react-router-dom";
import { ROUTE_PAYMENT_METHOD } from '../../../config/routes';
import { PAYMENT_TYPES } from "../../../config/constants/payment_types";

class PaymentTypeItem extends Component {
    formatLink = (type) => {
        let link = ROUTE_PAYMENT_METHOD;
        link = link.replace(':type', type);

        return {
            pathname: link,
            state: {
                contract: this.props.contract
            }
        };
    }

    render() {
        const { payment, contract } = this.props;
        let balance = 0.00;
        let messageBalance = "components.account.myVidaVacationsPayments.PaymentType.total_balance";

        if (payment.param !== PAYMENT_TYPES.MONTHLY && contract.payment_types[payment.param] && contract.payment_types[payment.param].length) {
            balance = 0.00;
            contract.payment_types[payment.param].forEach(item => {
                let amount = 0.00;

                switch (payment.param) {
                    case PAYMENT_TYPES.DOWN:
                        amount += parseFloat(item.amount_due ?? 0) + parseFloat(item.closing_cost ?? 0);
                        balance += parseFloat(amount ?? 0) + parseFloat(item.late_fee ?? 0);
                        break;
                    case PAYMENT_TYPES.OTHER_FEES:
                    case PAYMENT_TYPES.CASH_OUT:
                        amount += parseFloat(item.amount_due ?? 0);
                        balance += parseFloat(amount ?? 0) + parseFloat(item.late_fee ?? 0);
                        break;
                    default:
                        amount += 0.00;
                        balance += parseFloat(amount ?? 0) + parseFloat(item.late_fee ?? 0);
                }
            });

            balance = parseFloat(balance.toFixed(2));
        } else if (payment.param === PAYMENT_TYPES.MONTHLY) {
            balance = 0.00;
            messageBalance = "components.account.myVidaVacationsPayments.PaymentType.amount";

            if (contract.payment_types[payment.param].length) {
                balance += parseFloat(contract.loan_payment_amount ?? 0);
            }
        }

        return (
            <div className="payment-type-options">
                <Grid item xs={ 8 }>
                    <FormControlLabel
                        value={ payment.type }
                        control={ <StyledRadio/> }
                        label={ payment.label }
                    />
                    <CustomizedTooltips
                        title={ payment.tooltip_title }
                        content={ payment.tooltip_description }
                    />
                </Grid>
                <Grid item xs={ 4 } className="payment-type-balance">
                    <div className="payment-type-box">
                        <h4><FormattedMessage
                            id={ messageBalance }/>:</h4>
                        <p>${ balance } </p>
                        { balance > 0 &&
                        <Link to={ this.formatLink(payment.param) }>
                            <FormattedMessage id="components.account.myVidaVacationsPayments.PaymentType.details"/>
                            <img src={ IconRightDetails } alt="details"/>
                        </Link> }
                    </div>
                </Grid>
            </div>
        )
    }
};

export
{
    PaymentTypeItem
}

