import React from "react";
import Grid from '@material-ui/core/Grid';
import { connect } from "react-redux";
import { ErrorMessage, Form, Formik } from "formik";
import Page from "./Page";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Title, Text } from "../../components";
import { ResendEmailVerificationSchema } from "../../helpers/schemas";
import { FormattedMessage } from "react-intl";
import CustomTextField from '../../components/general/CustomTextField';
import { Messages } from '../../components/general/Messages';
import { resendActivationToken } from '../../api/user';
import { history } from '../../store/store';
import { Routes } from '../../config';
import { displayNotification } from '../../store/actions/notification';
import { NOTIFICATION_SUCCESS } from '../../store/actions/types';
import { NEW_VERIFICATION_CODE } from '../../config/messages';

class ResendEmailVerificationComponent extends React.Component {

    submit = (data) => {
        const { email } = data;

        let _self = this;
        resendActivationToken( {
            data: {
                email: email
            }
        } ).then( (response) => {
            if (response.status === 204) {
                history.push( Routes.ROUTE_LOGIN );
                _self.props.displayNotification(NOTIFICATION_SUCCESS, NEW_VERIFICATION_CODE)
            }
        } );
    };

    renderForm = (props) => {
        const { touched, errors, isValid } = props;

        return (
            <Form className="forgot-form" autoComplete="off">
                <Grid container direction="column">
                    <Grid item className="custom-field">
                        <FormattedMessage id="globals.email_address">
                            {email_address =>
                                <CustomTextField
                                    type="email"
                                    name="email"
                                    label={email_address}
                                    error={errors.email && touched.email}
                                    onChange={e => props.setFieldValue( 'email', e.target.value )}
                                    handleBlur={props.handleBlur}
                                />
                            }
                        </FormattedMessage>
                        <ErrorMessage name="email" component="div" className="field-error"/>
                    </Grid>

                    <Grid container>
                        <button
                            name="submit"
                            type="submit"
                            disabled={! isValid}>
                            <FormattedMessage id="globals.submit"/>
                            <span><ChevronRightIcon fontSize="small"/></span>
                        </button>
                    </Grid>
                </Grid>
            </Form>
        );
    };

    render() {
        return (
            <Page class="register-resend-email-container">
                <Grid
                    container
                    direction="column"
                    className="auth-smaller-container"
                >
                    <Title className="center auth-title b-top"><FormattedMessage id="resend_email.title"/></Title>
                    <Text className='center auth-subtitle'><FormattedMessage id="resend_email.text"/></Text>
                    <Formik
                        initialValues={{ email: '' }}
                        validationSchema={ResendEmailVerificationSchema}
                        onSubmit={this.submit}
                        enableReinitialize={true}
                    >
                        {this.renderForm}
                    </Formik>
                    <Messages/>
                </Grid>
            </Page>
        )
    }
};


const ResendEmailVerification = connect( null, { displayNotification } )( ResendEmailVerificationComponent );

export { ResendEmailVerification, ResendEmailVerificationComponent };
