import React, { Component } from 'react';
import {Section} from "../../layout/Section";
import {FormattedMessage} from "react-intl";

class EntrySectionComponent extends Component {

    render() {
        return (
            <Section color="white" className="entry-section">
                <div className="entry-section-content">
                   <p> <FormattedMessage id="components.account.myVidaVacationsContracts.select" /></p>
                </div>
            </Section>
        )
    }
}
export { EntrySectionComponent }
