import React, {Component} from 'react';
import Page from '../account/layout/Page';
import {PreRegistrationSection} from "../../components/account/preRegistration/PreRegistrationSection";
import {Filters} from "../../components/layout/Navbars/header/Filters";

class PreRegistration extends Component {
    componentDidMount() {
        window.scroll(0,0);
    }

    render() {
        return <Page class="account preRegistration">
            <Filters/>
            <PreRegistrationSection/>
        </Page>
    }
}

export {PreRegistration};
