import React, {Component} from 'react';
import Modal from '@material-ui/core/Modal';
import {FormattedMessage} from "react-intl";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {history} from "../../../store/store";
import {ROUTE_MY_RESERVATIONS} from "../../../config/routes";

class ThankYouModal extends Component{
    render() {
        return <Modal open={this.props.open} className="thank-you-modal">
            <div className="thank-you-modal-container">
                <h1 className="thank-you-modal-title">
                    <FormattedMessage id="globals.thank_you" />
                </h1>
                <p className="thank-you-modal-text">
                    <FormattedMessage id="components.account.reservationSummary.oneMoreStep.modal_text_one" />
                </p>
                <button className="thank-you-modal-button" onClick={() => history.push(ROUTE_MY_RESERVATIONS)}>
                    <FormattedMessage id="components.account.reservationSummary.oneMoreStep.see_my_reservations" />
                    <ChevronRightIcon />
                </button>
            </div>
        </Modal>
    }
}
ThankYouModal.defaultProps = {
    open: false,
};

export {ThankYouModal};
