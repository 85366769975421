export const DISPLAY_NOTIFICATION = 'DISPLAY_NOTIFICATION';
export const DISPLAY_GLOBAL_NOTIFICATION = 'DISPLAY_GLOBAL_NOTIFICATION';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const SHOW_GLOBAL_NOTIFICATION = 'SHOW_GLOBAL_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const HIDE_GLOBAL_NOTIFICATION = 'HIDE_GLOBAL_NOTIFICATION';
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_WARNING = 'NOTIFICATION_WARNING';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';


export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const USER_DETAILS = 'USER_DETAILS';
export const REQUEST_UPDATE_PROFILE = 'REQUEST_UPDATE_PROFILE';

export const TOKEN_DETAILS = 'TOKEN_DETAILS';
export const USER_INFO = 'USER_INFO';
export const SHOULD_RENDER_PAGE = 'SHOULD_RENDER_PAGE';
export const SHOULD_NOT_RENDER_PAGE = 'SHOULD_NOT_RENDER_PAGE';


export const INCREMENT_PENDING_REQUESTS = 'INCREMENT_PENDING_REQUESTS';
export const DECREMENT_PENDING_REQUESTS = 'DECREMENT_PENDING_REQUESTS';


export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOCALE = 'USER_LOCALE';

export const REQUEST_GET_CONTACT_REASONS = 'REQUEST_GET_CONTACT_REASONS';
export const SET_CONTACT_REASONS = 'SET_CONTACT_REASONS';
