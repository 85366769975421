import React, {Component} from 'react';
import { FormattedMessage } from "react-intl";
import Page from '../../../pages/account/layout/Page';
import {Section} from "../../layout/Section";

class NotFoundAuthenticated extends Component {
    render() {
        return (
            <Page
                class="account not-found"
            >
                <Section color="white">
                    <div className="not-found-container">
                        <h1 className="authenticated">
                            <FormattedMessage id="components.general.not_found_text"/>
                        </h1>
                    </div>
                </Section>
            </Page>
        );
    }
}

export {NotFoundAuthenticated};
