import React, {Component} from 'react';
import {Section} from '../../layout/Section';
import {MobileProfileDetails} from './profileDetails/MobileProfileDetails';
import {checkDevice} from '../../../helpers/checkDevice';

class ProfileDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            device: checkDevice(),
        }
    }

    setDevice = () => {
        this.setState({
            device: checkDevice()
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDevice);
    }

    render() {
        const {device} = this.state;

        return <Section color='transparent' className="profile-details-z-index"
                        containerNoPadding={device.isMobile}>
            <MobileProfileDetails userProfile={this.props.userProfile}/>
        </Section>
    }
}

export {ProfileDetails}
