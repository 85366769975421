import React, { Component } from "react"
import Slider from "react-slick"
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { FormattedMessage } from "react-intl";
import { Section } from '../../layout/Section';
import { history } from "../../../store/store";
import { ROUTE_DESTINATION_HIGHLIGHTS } from "../../../config/routes";
import { getDestinationHighlights } from "../../../api/destinationHighlights/destination_highlight";
import { checkDevice } from "../../../helpers/checkDevice";
import { DESTINATIONS_HIGHLIGHTS } from "../../../config/constants/destinationsHighlights";

class DestinationHighlights extends Component {
    constructor(props) {
        super(props);

        this.state = {
            destinations: [],
            device: checkDevice(),
        }
    }

    setDevice = () => {
        this.setState({
            device: checkDevice()
        });
    }

    componentDidMount() {
        this.getDestinations();
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDevice);
    }

    getDestinations = () => {
        getDestinationHighlights()
            .then(data => {
                this.setState({
                    destinations: data || []
                });
            }).catch(() => {
            this.setState({
                destinations: []
            });
        });
    }

    getUrl = (destination) => {
        let url = ROUTE_DESTINATION_HIGHLIGHTS;
        return url.replace(':id', destination.id)
    }


    renderDestination = (destination) => {
        if ( !destination) {
            return;
        }

        return (
            <div className='card' key={ destination.id } onClick={ () => {
                if (destination.description.trim() != "") {
                    history.push(this.getUrl(destination))
                }
            } }>
                <div className='card-container'>
                    <div className="card-img-container">
                        <img className={ 'bg' } src={ destination.image_location } alt=""/>
                    </div>
                    <div className={ 'caption' }>
                        <p className={ 'subtitle' }>{ destination.location }</p>
                        <p className={ 'title' }>{ destination.name }</p>
                        { destination.description.trim() != "" ? <button className={ 'learn-more' }>
                            <FormattedMessage id="messages.learn_more"/>
                            <ChevronRightIcon fontSize="small"/>
                        </button> : ""}
                    </div>
                </div>
            </div>
        );
    };

    checkIfMustCenterCarousel = () => {
        if ( !this.state.destinations.length) {
            return;
        }

        let carousel = document.querySelector('.slick-track');

        if (carousel) {
            if (this.state.destinations.length <= 3) {
                carousel.classList.add('center-carousel');
            } else {
                carousel.classList.remove('center-carousel');
            }
        }
    }

    getDefaultSlidesToShow = () => {
        if ( !this.state.destinations) {
            return;
        }

        switch (this.state.destinations.length) {
            case 0: {
                return 0;
            }
            case 1: {
                return 1;
            }
            case 2: {
                return 2;
            }
            case 3: {
                if (this.state.device.isDesktop) {
                    return 3;
                } else {
                    return 2;
                }
            }
            default : {
                if (this.state.device.isDesktop) {
                    return 4;
                } else {
                    return 2;
                }
            }
        }
    }

    render() {
        const settings = {
            dots: false,
            centerMode: false,
            centerPadding: 0,
            infinite: true,
            speed: 500,
            slidesToShow: this.getDefaultSlidesToShow(),
            slidesToScroll: 1,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: this.getDefaultSlidesToShow(),
                        arrows: false,
                        centerMode: true,
                        centerPadding: 25,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: this.getDefaultSlidesToShow(),
                        arrows: true,
                        centerMode: true,
                        centerPadding: 40,
                    }
                },
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: this.getDefaultSlidesToShow(),
                        arrows: true,
                        centerMode: true,
                        centerPadding: 60,
                    }
                }
            ]
        };

        this.checkIfMustCenterCarousel();

        return (
            <Section color="grey">
                <div className="destination-highlights">
                    <h2><FormattedMessage id="components.account.dashboard.destinations.title"/></h2>
                    <Slider { ...settings }>
                        {
                            this.state.destinations.map(destination => this.renderDestination(destination))
                        }
                    </Slider>
                </div>
            </Section>
        )
    }
}

export { DestinationHighlights }
