import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import moment from "moment";

export const emailRules = Yup.string()
    .email(<FormattedMessage id="validation.email_valid"/>)
    .required(<FormattedMessage id="validation.email_required"/>);

export const emailRulesNoChild = Yup.string()
    .email(<FormattedMessage id="validation.email_valid"/>)
        .when('age', {
            is: "adult",
            then: Yup.string().required(<FormattedMessage id="validation.email_required"/>)
  })

export const passwordRules = Yup.string()
    .required(<FormattedMessage id="validation.password_required"/>);

export const recaptchaRules = Yup.string()
    .required(<FormattedMessage id="validation.recaptcha_required"/>);

export const passwordRegisterRules = passwordRules
    .matches(
        /^.{8,}$/,
        {
            message: <FormattedMessage id="validation.password_characters"/>,
            excludeEmptyString: true
        }
    )
    .matches(
        /^(?=.*[A-Z]).*$/,
        {
            message: <FormattedMessage id="validation.password_uppercase"/>,
            excludeEmptyString: true
        }
    )
    .matches(
        /^(?=.*[a-z]).*$/,
        {
            message: <FormattedMessage id="validation.password_lowercase"/>,
            excludeEmptyString: true
        }
    )
    .matches(
        /^(?=.*\d).*$/,
        {
            message: <FormattedMessage id="validation.password_number"/>,
            excludeEmptyString: true
        }
    )
    .matches(
        /^(?=.*[@$!%*#?&()[\]{}_\-+=."';:<>]).*$/,
        {
            message: <FormattedMessage id="validation.password_special_char"/>,
            excludeEmptyString: true
        }
    );

export const passwordConfirmationRules = Yup.string()
    .oneOf([Yup.ref('password'), null], <FormattedMessage id="validation.password_mismatch"/>)
    .required(<FormattedMessage id="validation.confirmation_password_required"/>);

export const currentPasswordRules = Yup.string()
    .required(<FormattedMessage id="validation.current_password_required"/>);

export const newPasswordRules = Yup.string()
    .required(<FormattedMessage id="validation.new_password_required"/>)
    .matches(
        /^.{8,}$/,
        {
            message: <FormattedMessage id="validation.password_characters"/>,
            excludeEmptyString: true
        }
    )
    .matches(
        /^(?=.*[A-Z]).*$/,
        {
            message: <FormattedMessage id="validation.password_uppercase"/>,
            excludeEmptyString: true
        }
    )
    .matches(
        /^(?=.*[a-z]).*$/,
        {
            message: <FormattedMessage id="validation.password_lowercase"/>,
            excludeEmptyString: true
        }
    )
    .matches(
        /^(?=.*\d).*$/,
        {
            message: <FormattedMessage id="validation.password_number"/>,
            excludeEmptyString: true
        }
    )
    .matches(
        /^(?=.*[@$!%*#?&()[\]{}_\-+=."';:<>]).*$/,
        {
            message: <FormattedMessage id="validation.password_special_char"/>,
            excludeEmptyString: true
        }
    );

export const newPasswordConfirmation = Yup.string()
    .oneOf([Yup.ref('new_password'), null], <FormattedMessage id="validation.password_mismatch"/>)
    .required(<FormattedMessage id="validation.confirm_new_password_required"/>);

const nameRules = /^[A-Za-zÀ-ÖØ-öø-ÿĂă_]+( [A-Za-zÀ-ÖØ-öø-ÿĂă_]+)*$/;

const surNameRules = /^[A-Za-zÀ-ÖØ-öø-ÿĂă_-]+( [A-Za-zÀ-ÖØ-öø-ÿĂă_-]+)*$/;

export const firstNameRules = Yup.string()
    .required(<FormattedMessage id="validation.first_name"/>)
    .matches(
        /^.{0,128}$/,
        {
            message: <FormattedMessage id="validation.valid_max"/>,
            excludeEmptyString: true
        }
    );

export const lastNameRules = Yup.string()
    .required(<FormattedMessage id="validation.last_name"/>)
    .matches(
        /^.{0,128}$/,
        {
            message: <FormattedMessage id="validation.valid_max"/>,
            excludeEmptyString: true
        }
    );

export const verificationCodeRules = Yup.number()
    .typeError(<FormattedMessage id="validation.verification_code_must_be_number"/>)
    .required(<FormattedMessage id="validation.verification_code_required"/>);

export const countryRules = Yup.string()
    .required(<FormattedMessage id="validation.country_required"/>);

export const phoneRules = Yup.string()
    .required(<FormattedMessage id="validation.phone_required"/>);

/**
 * START Related field validation any one between home_phone mobile_phone
 */
let phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const homePhoneRules = Yup.string()
    .matches(phoneRegExp, <FormattedMessage id="validation.phone_invalid"/>);

export const mobilePhoneRules = phoneRules
    .matches(phoneRegExp, <FormattedMessage id="validation.phone_invalid"/>);

/**
 * END Related field validation any one between home_phone mobile_phone
 */

export const messageRules = Yup.string()
    .required(<FormattedMessage id="validation.message_required"/>);

export const termsAndConditionsRules = Yup.boolean()
    .test('terms_and_conditions', <FormattedMessage id="register.you_have_to_agree"/>,
        (value) => {
            return !!(typeof value !== 'undefined' && value);
        }
    );

export const vidaMemberIdRules = Yup.number()
    .typeError(<FormattedMessage id="validation.vida_member_id_numeric"/>)
    .required(<FormattedMessage id="validation.vida_member_id_required"/>);

export const selectDestinationRules = Yup.string()
    .required(<FormattedMessage id="validation.destination_required"/>);

export const selectReasonRules = Yup.string()
    .required(<FormattedMessage id="validation.reason_required"/>);

export const selectGuestsRules = Yup.string()
    .test('guests', <FormattedMessage id="validation.guests_required"/>,
        (value) => {
            return value >= 0
        })
    .required(<FormattedMessage id="validation.guests_required"/>);

export const selectGuestsShortRules = Yup.string()
    .test('guests', <FormattedMessage id="validation.guests_short_required"/>,
        (value) => {
            return value > 0
        })
    .required(<FormattedMessage id="validation.guests_short_required"/>);

export const selectArrivingDateRules = Yup.string()
    .required(<FormattedMessage id="validation.date_required"/>)
    .test('arrivingDate', <FormattedMessage id="validation.date_grater_today"/>, value => {
        if (moment(new Date(value)).unix() <= moment().unix()) {
            return false;
        } else {
            return true;
        }
    });

let arrivingDate;
export const selectDepartingDateRules = Yup.string()
    .required(<FormattedMessage id="validation.date_required"/>)
    .test('departingDate', <FormattedMessage id="validation.date_grater_today"/>, value => {
        if (moment(new Date(value)).unix() <= moment().unix()) {
            return false;
        } else {
            if (value) {
                return true;
            }
        }
    })
    .when('arrivingDate', {
        is: value => {
            if (value) {
                arrivingDate = value;
                return true;
            }

            return false;
        },
        then: Yup.string()
            .test('departingDate', <FormattedMessage id="validation.departing_date_greater"/>, (val) => {
                if (moment(new Date(val)).unix() < moment(new Date(arrivingDate)).unix()) {
                    return false;
                }

                return true
            })
    });

export const addressRules = Yup.string()
    .matches(
        /^.{8,}$/,
        {
            message: <FormattedMessage id="validation.address_format_short"/>,
            excludeEmptyString: true
        }
    )
    .matches(
        /^(?=.*[A-Za-zÀ-ÖØ-öø-ÿ]).*$/,
        {
            message: <FormattedMessage id="validation.address_format_alphanumeric"/>,
            excludeEmptyString: true
        }
    )
    .matches(
        /^(?=.*\d).*$/,
        {
            message: <FormattedMessage id="validation.address_format_alphanumeric"/>,
            excludeEmptyString: true
        }
    )
    .required(<FormattedMessage id="validation.address_required"/>);

export const addressRulesRequired = Yup.string()
    .required(<FormattedMessage id="validation.address_required"/>);

export const selectUnitTypeRules = Yup.string()
    .required(<FormattedMessage id="validation.unit_type_required"/>);

export const selectPropertyRules = Yup.string()
    .required(<FormattedMessage id="validation.select_property_required"/>);

export const confirmationNumberRules = Yup.string()
    .required(<FormattedMessage id="validation.confirmation_number_required"/>);

export const checkInDateRules = Yup.string()
    .required(<FormattedMessage id="validation.check_in_date_required"/>);

export const travelerLastNameRules = Yup.string()
    .required(<FormattedMessage id="validation.traveler_last_name_rules_required"/>);

export const selectExpirationMonthRules = Yup.string()
    .min(1, <FormattedMessage id="validation.exp_month_greater"/>)
    .max(12, <FormattedMessage id="validation.exp_month_less"/>)
    .required(<FormattedMessage id="validation.exp_month"/>);

export const selectExpirationYearRules = Yup.number()
    .min(parseInt(moment().format('YYYY')), <FormattedMessage id="validation.exp_year_greater"/>)
    .max(parseInt(moment().format('YYYY')) + 10, <FormattedMessage id="validation.exp_year_less"/>)
    .required(<FormattedMessage id="validation.exp_year"/>);

let maxAmount = 0;

export const downPaymentAmountRules = Yup.string()
    .required(<FormattedMessage id="validation.payment_amount"/>)
    .test('order_amount', <FormattedMessage id="validation.payment_amount_positive"/>, value => {
        if (value <= 0) {
            return false
        }

        return true;
    })
    .when('max_amount', {
        is: value => {
            maxAmount = value || 0;
            return true;
        },
        then: Yup.string()
            .test('order_amount', <FormattedMessage id="validation.payment_amount_bigger"/>, value => {
                if (value) {
                    value = value.replace(/,/g, '');
                }

                if (parseFloat(value) > parseFloat(maxAmount)) {
                    return false;
                }

                return true;
            })
    });

let totalAmount = 0;

export const paymentAmountRules = Yup.string()
    .required(<FormattedMessage id="validation.payment_amount"/>)
    .test('order_amount', <FormattedMessage id="validation.payment_amount_positive"/>, value => {
        if (value < 0) {
            return false
        }

        return true;
    })
    .when('paymentTypeDetails', {
        is: value => {
            totalAmount = 0;

            if (typeof value === 'string') {
                const paymentTypeDetails = ['full', 'monthly', 'multiple'];

                if (paymentTypeDetails.indexOf(value) !== false) {
                    return false;
                }
            }

            if (value && value.balance) {
                totalAmount = parseFloat(value.balance ?? 0) +
                    parseFloat(value.payment_types.cash_out.amount_due ?? 0) + parseFloat(value.payment_types.cash_out.late_fee ?? 0);
            } else {
                if (value && typeof value.forEach === 'function') {
                    value.forEach(item => {
                        if (item) {
                            totalAmount += (parseFloat(item.amount_due ? item.amount_due : 0) +
                                parseFloat(item.late_fee ? item.late_fee : 0));
                        }
                    });
                }
            }

            return true;
        },
        then: Yup.string()
            .test('order_amount', <FormattedMessage id="validation.payment_amount_bigger"/>, value => {
                if (value) {
                    value = value.replace(/,/g, '');
                }

                if (parseFloat(value) > parseFloat(totalAmount)) {
                    return false;
                }

                return true;
            })
    })

export const cardNameRules = Yup.string()
    .required(<FormattedMessage id="validation.card_name"/>);

export const stateRules = Yup.string()
    .required(<FormattedMessage id="validation.state"/>);

export const cityRules = Yup.string()
    .required(<FormattedMessage id="validation.city"/>);

export const postalCodeRules = Yup.string()
    .required(<FormattedMessage id="validation.postal_code"/>);

let cardDetails = null;
export const cvvRules = Yup.mixed()
    .required(<FormattedMessage id="validation.cvv"/>)
    .test('cvv', <FormattedMessage id="validation.only_numbers"/>, value => {
        let reg = /^\d+$/;
        return reg.test(value)
    })
    .when('cardDetails', {
        is: value => {
            if (value && value.type) {
                cardDetails = value;
            }

            return true;
        },
        then: Yup.string()
            .test('cvv', <FormattedMessage id="validation.card_number"/>, () => {
                return !!cardDetails
            })
            .test('cvv', <FormattedMessage id="validation.cvv_max_length"/>, value => {
                if ( !cardDetails || !value) {
                    return false;
                }
                return cardDetails.code.size === value.length;
            })
    });

export const cardNumberRules = Yup.mixed()
    .required(<FormattedMessage id="validation.card_number"/>)
    .test('card_number', <FormattedMessage id="validation.only_numbers"/>, value => {
        if ( !value) {
            return;
        }

        let reg = /^\d+$/;
        // Card prettier number format add white spaces, when we test card number for only digits we must ignore white spaces
        let valueWithoutWhiteSpaces = value.replace(/ /g, '');
        return reg.test(valueWithoutWhiteSpaces)
    })
    .when('cardDetails', {
        is: value => {
            if (value && value.type) {
                cardDetails = value;
            }

            return true;
        },
        then: Yup.string()
            .test('card_number', <FormattedMessage id="validation.invalid_card_number_length"/>, value => {
                if ( !value || !cardDetails) {
                    return;
                }

                let isCardNumberLengthValid = false;
                let valueWithoutWhiteSpaces = value.replace(/ /g, '');

                cardDetails.lengths.forEach(item => {
                    if (item === valueWithoutWhiteSpaces.length) {
                        isCardNumberLengthValid = true;
                    }
                });

                return isCardNumberLengthValid;
            })
    });

export const paymentTypeAllRules = Yup.string()
    .required(<FormattedMessage id="validation.select_payment_type"/>);

export const cardIdRules = Yup.string().required().nullable();

export const paymentCurrencyRules = Yup.string().required();

export const paymentTypeIdsMonthly = Yup.mixed()
    .when('multiplePayment', {
        is: value => value,
        then: Yup.array()
            .test('months', <FormattedMessage id="validation.select_payment_months"/>, value => {
                if ( !value.length) {
                    return false;
                }

                return true;
            })
    });
