import { ENDPOINT_ORDER } from '../../config/endpoints';
import {
    addParam,
    download,
    errorCatch,
    getTokenHeaders
} from '../helper';
import { Messages } from '../../config';
import moment from "moment";

/**
 * Start download a file from API
 * @param {object} payload
 * @param {string} payload.orderId
 * @returns {Promise<T>}
 */
export function downloadOrder(payload) {
    let date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    return download(
        addParam(
            ENDPOINT_ORDER,
            {
                '{order}': payload.orderId,
            }
        ),
        getTokenHeaders(),
        "invoice_" + date + ".pdf"
    )
        .then( data => {
            return data.data
        } )
        .catch(
            e => {
                errorCatch(e, {
                    401: Messages.UNAUTHENTICATED_ERROR,
                    403: Messages.UNAUTHORIZED_ERROR,
                    404: Messages.NOT_FOUND_ERROR,
                });

                return e.response;
            }
        );
}
