import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Loader } from "../../../components/general";
import { Header } from '../../../components/layout/Navbars/header/Header';
import { Footer } from '../../../components/layout/footer/Footer';

class Page extends Component {

    render() {
        return (
            <div className={this.props.class ? this.props.class : ''}>
                <Header />
                <Grid container>
                    { this.props.children }
                </Grid>
                <Footer />
                <Loader />
            </div>
        );
    }
}

export default Page;
