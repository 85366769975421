import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {connect} from "react-redux";
import {FormattedMessage} from 'react-intl';
import CustomSelect from '../../../../general/CustomSelect';
import {CustomDatePicker} from '../../../../general/CustomDatePicker';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {history} from "../../../../../store/store";
import {Form, Formik} from "formik";
import {SearchDestinationSchema} from '../../../../../helpers/schemas';

class DesktopFiltersComponent extends Component {

    submit = (data) => {
        localStorage.removeItem('reservation');

        history.push({
            pathname: '/request-reservation',
            state: {data: data}
        });
    };

    renderForm = (props) => {
        let {errors, touched} = props;

        let destinationColGap = 4;

        if (this.props.device.isDesktop && !this.props.isDashboardPage) {
            destinationColGap = 3;
        }

        return (
            <Form className={`${this.props.isDashboardPage ? 'remove-padding-for-transparent-filters' :''}`} autoComplete="off">
                <Grid container className="filters-container" alignItems="center">
                    {!this.props.isDashboardPage &&
                    <Grid item xs={1} className="filter-item-container start-vacation-text">
                    <span>
                        <FormattedMessage id="components.layout.header.filters.start_planning"/>
                    </span>
                    </Grid>}
                    {this.props.hotels &&
                    <Grid item xs={destinationColGap} className="filter-item-container filter-select-destination">
                        <CustomSelect
                            name="destination"
                            label={<FormattedMessage id="components.layout.header.filters.destination"/>}
                            class="header-filter"
                            items={this.props.hotels}
                            textColor="black"
                            onChange={e => props.setFieldValue('destination', e.target.value)}
                            handleBlur={props.handleBlur}
                            error={errors.destination && touched.destination}
                        />
                    </Grid>}
                    {this.props.userProfile.guests && <Grid item xs={2} className="filter-item-container">
                        <CustomSelect
                            name="guests"
                            class="header-filter"
                            items={this.props.userProfile.guests ? this.props.userProfile.guests : []}
                            label={<FormattedMessage id="components.layout.header.filters.guests"/>}
                            textColor="black"
                            onChange={e => props.setFieldValue('guests', e.target.value)}
                        />
                    </Grid>}
                    <Grid item xs={2} className="filter-item-container">
                        <CustomDatePicker
                            id="departingDate"
                            isTablet={this.props.isTablet}
                            class="header-filter"
                            label={<FormattedMessage id="components.layout.header.filters.arriving"/>}
                        onChange={date => props.setFieldValue('arrivingDate', date)}
                        error={errors.arrivingDate && touched.arrivingDate}
                        />
                    </Grid>
                    <Grid item xs={2} className="filter-item-container">
                        <CustomDatePicker
                            id="arrivingDate"
                            class="header-filter"
                            isTablet={this.props.isTablet}
                            label={<FormattedMessage id="components.layout.header.filters.departing"/>}
                        onChange={date => props.setFieldValue('departingDate', date)}
                        error={errors.departingDate && touched.departingDate}
                        />
                    </Grid>
                    <Grid item xs={2} className="filter-item-container">
                        <button className="reserve-btn" type="submit">
                            <FormattedMessage id="components.layout.header.filters.request_btn"/>
                            <ChevronRightIcon/>
                        </button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        return <Formik
            initialValues={{destination: '', guests: '', arrivingDate: '', departingDate: ''}}
            validationSchema={SearchDestinationSchema}
            onSubmit={this.submit}
            enableReinitialize={true}
            validateOnBlur={true}
        >
            {this.renderForm}
        </Formik>
    }
}

const mapStateToProps = (state) => ({
    userProfile: state.user.profile,
    hotels: state.hotels.hotels,
})

const DesktopFilters = connect(mapStateToProps, null)(DesktopFiltersComponent);

export {DesktopFilters, DesktopFiltersComponent}
