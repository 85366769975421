// API Endpoints

export const API_URL = process.env.REACT_APP_API_URL;

// Account
export const ENDPOINT_LOGIN = API_URL + '/v1/login';
export const ENDPOINT_REGISTER = API_URL + '/v1/users';
export const ENDPOINT_FORGOT_PASSWORD = API_URL + '/v1/users/passwords';
export const ENDPOINT_RESET_PASSWORD = API_URL + '/v1/users/passwords/{token}';
export const ENDPOINT_REGISTER_VERIFY_EMAIL = API_URL + '/v1/account/verify/{id}/{hash}?expires={expires}&signature={signature}';
export const ENDPOINT_RESEND_EMAIL_VERIFICATION = API_URL + '/v1/account/verify/resend-validation';
export const ENDPOINT_USER = API_URL + '/v1/profile';
export const ENDPOINT_USER_LOGOUT = API_URL + '/v1/logout';

// Credit Cards
export const ENDPOINT_CREDIT_CARDS = API_URL + '/v1/credit_cards/{credit_card}';

// User Contracts
export const ENDPOINT_USER_CONTRACTS = API_URL + '/v1/user_contracts/{user_contract}';

// FAQs
export const ENDPOINT_FAQS = API_URL + '/v1/faqs';

//Messages
export const ENDPOINT_MESSAGES = API_URL + '/v1/messages/{message}';
export const ENDPOINT_MARK_ALL_MESSAGES_READ = API_URL + '/v1/messages/mark-all-messages-read';
export const ENDPOINT_READ_CONVERSATIONS = API_URL + '/v1/messages/{message}/mark-as-read';
export const ENDPOINT_CONTACT_REASONS = API_URL + '/v1/contact_reasons';

//Destination Highlights
export const ENDPOINT_DESTINATION_HIGHLIGHTS = API_URL + '/v1/destination_highlights/{destination_highlight}';

//Reservation
export const ENDPOINT_RESERVATION_HOTELS = API_URL + '/v1/reservation_hotels';
export const ENDPOINT_RESERVATION_REQUESTED = API_URL + '/v1/reservation_requested';
export const ENDPOINT_ADD_RESERVATION_TO_PROFILE = API_URL + '/v1/add_reservation_to_profile';
export const ENDPOINT_SEND_RESERVATION_CONFIRMATION = API_URL + '/v1/send_reservation_confirmation';
export const ENDPOINT_USER_RESERVATIONS = API_URL + '/v1/user_reservations/{type}';

// Payment
export const ENDPOINT_PAYMENT = API_URL + '/v1/payment';

// Order
export const ENDPOINT_ORDER = API_URL + '/v1/orders/{order}/download';

//Exchanges
export const ENDPOINT_EXCHANGES = API_URL + '/v1/exchanges';
