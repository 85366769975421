import React, { Component } from 'react';
import { Section } from "../../layout/Section";
import {FormattedMessage} from "react-intl";

class ReservationFoundHeader extends Component {

    render() {
        return (
            <Section color="white" className="reservation-found-header">
                <div className="reservation-found-header-container">
                    <h1><FormattedMessage id="components.account.reservationFound.reservationFoundHeader.title"/></h1>
                    <p><FormattedMessage id="components.account.reservationFound.reservationFoundHeader.sub_title"/></p>
                </div>
            </Section>
        )
    }
}
export {ReservationFoundHeader}
