import {
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION
} from '../actions/types';

const initialState = {
    message: null,
    type: null,
    notificationId: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return {
                ...state,
                message: action.payload.message || null,
                type: action.payload.type || null,
                notificationId: action.payload.notificationId || null
            };
        case HIDE_NOTIFICATION:
            return {
                ...state,
                type: null,
                message: null,
                notificationId: null
            };
        default:
            return state;
    }
}
