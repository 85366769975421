import React, {Component} from 'react';
import {Section} from "../../layout/Section";
import Grid from '@material-ui/core/Grid';
import AccordionFAQs from "./AccordionFAQs";

class FaqComponent extends Component{
    render() {

        return (
            <Section color="grey">
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                >
                    <AccordionFAQs faqs={this.props.faqs} language={this.props.language}/>
                </Grid>
            </Section>
        );
    }
}

export {FaqComponent}
