import React, { Component } from 'react';
import {Section} from "../../layout/Section";
import { FormattedMessage } from "react-intl";
import Grid from '@material-ui/core/Grid';

class AboutListSection extends Component {
    render() {
        return (
            <Section className="about-section-background">
                <div className="about-list-section">
                    <h2><FormattedMessage id="components.account.about.about_list_section.title"/></h2>
                    <Grid container direction="row" className="about-list-container">
                        <Grid item xs={6}>
                            <ul className="ul-left">
                                <li><span className="dot" /><FormattedMessage id="components.account.about.about_list_section.list_item_1"/></li>
                                <li><span className="dot" /><FormattedMessage id="components.account.about.about_list_section.list_item_2"/></li>
                                <li><span className="dot" /><FormattedMessage id="components.account.about.about_list_section.list_item_3"/></li>
                                <li><span className="dot" /><FormattedMessage id="components.account.about.about_list_section.list_item_4"/></li>
                            </ul>
                        </Grid>
                        <Grid item xs={6}>
                            <ul className="ul-right">
                                <li><span className="dot" /><FormattedMessage id="components.account.about.about_list_section.list_item_5"/></li>
                                <li><span className="dot" /><FormattedMessage id="components.account.about.about_list_section.list_item_6"/></li>
                                <li><span className="dot" /><FormattedMessage id="components.account.about.about_list_section.list_item_7"/></li>
                                <li><span className="dot" /><FormattedMessage id="components.account.about.about_list_section.list_item_8"/></li>
                            </ul>
                        </Grid>
                    </Grid>
                </div>
            </Section>
        )
    }
}

export {AboutListSection}
