// General Routes
export const ROUTE_HOME = '/';
export const ROUTE_DASHBOARD = '/dashboard';

// Auth
export const ROUTE_REGISTER = '/register';
export const ROUTE_REGISTER_SUCCESS = '/register/thank-you';
export const ROUTE_RESET_PASSWORD = '/reset-password/:token?';
export const ROUTE_RESET_PASSWORD_SUCCESS = '/reset-password/success';
export const ROUTE_LOGIN = '/login';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_FORGOT_PASSWORD_SUCCESS = '/forgot-password/sent';
export const ROUTE_FORGOT_PASSWORD_SUCCESS_TOKEN = '/forgot-password/sent/:token?';
export const ROUTE_VERIFY_EMAIL = '/account/verify/:id/:token';
export const ROUTE_VERIFY_EMAIL_SUCCESS = '/account/verify/success';
export const ROUTE_RESEND_EMAIL_VERIFICATION = '/account/verify/resend-email';

//Basic Pages
export const ROUTE_TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const ROUTE_PRIVACY_POLICY = '/privacy-policy';
export const ROUTE_ABOUT_US = '/about';
export const ROUTE_CONTACT_US = '/contact';

// Account
export const ROUTE_MY_ACCOUNT = '/my-account';
export const ROUTE_MESSAGES_CENTER = '/messages-center';
export const ROUTE_MY_RESERVATIONS = '/my-reservations';
export const ROUTE_EDIT_PROFILE = '/edit-profile';
export const ROUTE_MY_VIDA_VACATIONS_PAYMENTS  = '/my-vida-vacations-payments'
export const ROUTE_MY_VIDA_VACATIONS_PAYMENTS_HISTORY  = '/my-vida-vacations-payments_history'
export const ROUTE_READ_MESSAGE = '/messages/:id';
export const ROUTE_REQUEST_RESERVATION = '/request-reservation';
export const ROUTE_PAYMENT_METHOD = '/payment-method/:type';
export const ROUTE_FAQS = '/FAQs';
export const ROUTE_MY_VIDA_VACATIONS_CONTRACTS = '/my-vida-vacations-contracts'
export const ROUTE_ADD_RESERVATION_TO_PROFILE ='/add-reservation-to-profile';
export const ROUTE_EXCHANGES = '/exchanges';
export const ROUTE_CLUB = '/club-freedom-benefits';
export const ROUTE_PRE_REGISTRATION = '/pre-registration';
export const ROUTE_SUCCESS_PAYMENT = '/success-payment/:order';
export const ROUTE_DESTINATION_HIGHLIGHTS = '/destination-highlights/:id';
export const ROUTE_RESERVATION_SUMMARY = '/reservation-summary';
export const ROUTE_RESERVATION_FOUND = '/reservation-found';
export const ROUTE_FAIL_PAYMENT= '/fail-payment/:message';
export const ROUTE_CANCELLED_PAYMENT = '/cancelled-payment/:message';
export const ROUTE_SUCCESS_TOKENIZATION ='/success-tokenization';
