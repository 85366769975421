import React, { Component, Fragment } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {FormattedMessage} from 'react-intl';
import { downloadOrder } from "../../../api/order/order";
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { PAYMENT_TYPES } from "../../../config/constants/payment_types";

class ListContractPaymentItem extends Component {

    render() { 
        return (<ContractDetails contracts={this.props.contracts} />)
    }
}

export { ListContractPaymentItem }

function downloadInvoice(orderId) {
   downloadOrder({orderId: orderId});
}

function PaymentName(paymentType){

    let label = "components.account.myVidaVacationsPayments.paymentTypes.monthly_payment";

    switch (paymentType.type) {
        case PAYMENT_TYPES.DOWN:
            label = "components.account.myVidaVacationsPayments.paymentTypes.down";
            break;
        case PAYMENT_TYPES.OTHER_FEES:
            label = "components.account.myVidaVacationsPayments.paymentTypes.other_fees";
            break;
        case PAYMENT_TYPES.CASH_OUT:
            label = "components.account.myVidaVacationsPayments.paymentTypes.cash_out";
            break;
        default:
            label = "components.account.myVidaVacationsPayments.paymentTypes.monthly_payment";
    }

    return(<FormattedMessage id={label}/>)
}

function mapFilter(contracts){

    //filter payments two years
    var days = 730;

    const filteredContracts = contracts.map((contract, key) => {

        const filteredPayments = contract.payments.filter(payment => {

            const validDate = new Date(payment.created_at) >= new Date().setDate(new Date().getDate() - days);
                                        
            if (validDate && (payment.status == 2 || payment.status == 3)) {
              return true
            }

            return false
          });
          
          contract.payments = filteredPayments;

          return contract;
    })
    
    return filteredContracts;
}

function ContractDetails(props) {

    return (
        <div className="list-contract">
            {mapFilter(props.contracts).map((contract, key) =>

                <Accordion key={key} className={key % 2 === 0 ? 'even' : ' odd' } style={{ boxShadow: "none" }}>
                    
                    <AccordionSummary  style={{ height: '65px'  }} expandIcon={<ExpandMore />}> 
                        <div className="accordion-summary">
                            <div className="accordion-summary-item">{contract.resort}</div>
                            <div className="accordion-summary-item">{contract.contract_number}</div>
                        </div>   
                    </AccordionSummary>

                        {contract.payments.length > 0 ?
                            
                            <AccordionDetails>

                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    item xs={12}
                                    spacing={0}
                                    className="details-container-header">

                                    <Grid item xs={12} sm={3}>
                                        <Box>
                                            <p className="label" aria-label={key}>
                                                <FormattedMessage id="components.account.myVidaVacationsContracts.contract_history.type_detail"/>
                                            </p>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Box>
                                            <p className="label">
                                                <FormattedMessage id="components.account.myVidaVacationsContracts.contract_history.date_detail"/>
                                            </p>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Box>
                                            <p className="label">
                                                <FormattedMessage id="components.account.myVidaVacationsContracts.contract_history.payment_detail"/>
                                            </p>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Box>
                                            <p className="label">
                                                <FormattedMessage id="components.account.myVidaVacationsContracts.contract_history.account_detail"/>
                                            </p>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Box>
                                            <p className="label">
                                                <FormattedMessage id="components.account.myVidaVacationsContracts.contract_history.more_detail"/>
                                            </p>
                                        </Box>
                                    </Grid>

                                </Grid>
                            
                                {contract.payments
                                    .sort((a, b) => a.id > b.id ? -1 : 1)
                                    .map((payment, key) => 
                                    
                                    <Grid
                                    key={key}
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    item xs={12}
                                    spacing={0}
                                    className={key % 2 === 0 ? 'details-container-item odd' : 'details-container-item even' }>

                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                            item xs={12}
                                            spacing={0}
                                            className='desktop'>

                                            <Grid item xs={12} sm={3}>
                                                <Box>
                                                    <p className="label-value">
                                                        <PaymentName type = {payment.payment_type}/>
                                                    </p>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <Box>
                                                    <p className="label-value">
                                                        {payment.created_at.split(" ")[0]}
                                                    </p>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Box>
                                                    <p className="label-value">
                                                        <span className="value">
                                                            <span className="total">{ `$${ payment.amount } ` }</span>
                                                            <sup className="total-sup">{ payment.currency }</sup>
                                                        </span>
                                                    </p>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <Box>
                                                    <p className="label-value">
                                                        **** {payment.last_four}
                                                    </p>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <Box>
                                                    <DownloadIcon fontSize="small" className="download-icon" onClick={() => downloadInvoice(payment.uuid)}/>
                                                </Box>
                                            </Grid>

                                        </Grid>

                                        <Grid
                                            container
                                            direction="column"
                                            justify="space-between"
                                            alignItems="center"
                                            item xs={6}
                                            spacing={0}
                                            className='device'>

                                        <Grid item lg={12}>
                                                <Box>
                                                    <div className="label">
                                                        <FormattedMessage id="components.account.myVidaVacationsContracts.contract_history.type_detail"/>: 
                                                    </div>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Box>
                                                    <div className="label">
                                                        <FormattedMessage id="components.account.myVidaVacationsContracts.contract_history.date_detail"/>:
                                                    </div>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Box>
                                                    <div className="label">
                                                        <FormattedMessage id="components.account.myVidaVacationsContracts.contract_history.payment_detail"/>:
                                                    </div>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Box>
                                                    <div className="label">
                                                        <FormattedMessage id="components.account.myVidaVacationsContracts.contract_history.account_detail"/>:
                                                    </div>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Box>
                                                    <div className="label">
                                                        <FormattedMessage id="components.account.myVidaVacationsContracts.contract_history.more_detail"/>:
                                                    </div>
                                                </Box>
                                            </Grid>

                                        </Grid>

                                        <Grid
                                            container
                                            direction="column"
                                            justify="space-between"
                                            alignItems="center"
                                            item xs={6}
                                            spacing={0}
                                            className='device'>

                                            <Grid item lg={12}>
                                                <Box>
                                                    <div className="label-value">
                                                        <PaymentName type = {payment.payment_type}/>
                                                    </div>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Box>
                                                    <div className="label-value">
                                                        {payment.created_at.split(" ")[0]}
                                                    </div>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Box>
                                                    <div className="label-value">
                                                        <span className="value">
                                                            <span className="total">{ `$${ payment.amount } ` }</span>
                                                            <sup className="total-sup">{ payment.currency }</sup>
                                                        </span>
                                                    </div>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Box>
                                                    <div className="label-value">
                                                        **** {payment.last_four}
                                                    </div>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Box>
                                                        <DownloadIcon fontSize="small" className="download-icon" onClick={() => downloadInvoice(payment.uuid)}/>
                                                </Box>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                )}

                            </AccordionDetails>
                            :
                            <AccordionDetails>
                               <div className="empty-text"><FormattedMessage id="components.account.myVidaVacationsContracts.contract_history.non_payments"/></div>
                            </AccordionDetails>
                            
                         }   

                </Accordion>

            )}
        </div>
    );
  }

