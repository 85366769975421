import React, { Component } from 'react';
import {humanCalculateDiffDate} from "../../../config/date";

class ShortMessage extends Component {

    wordCount = (str) => {
        let msg = str.split(' ').slice(0,10).join(' ');

        return msg;
    }

    render() {

        return (
            <div className="accordion-item short-message">
                <div className="accordion-item-header">
                    <div className="message-subject">
                        <h3>{this.props.from}</h3>
                    </div>
                    <div className="message-header-date">
                        <p>{humanCalculateDiffDate(this.props.date)}</p>
                    </div>
                </div>
                <div className="message-body">
                    <p>{this.wordCount(this.props.message)} ...</p>
                </div>
            </div>
        )
    }
}

export {ShortMessage}
