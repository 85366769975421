import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Section } from '../../../layout/Section';
import { MonthlyPaymentHeader } from "./MonthlyPaymentHeader";
import { PaymentMethodHeaderItem } from "../PaymentMethodHeaderItem";

class MonthlyPaymentMethodHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            monthlyType: "",
            noOfMonths: 0,
        }
    }

    renderDownPayment = () => {
        const {
            dueDate,
            amountDue,
            lateFee,
            totalAmount,
            setPaymentAmount,
            handleBlur,
            currencyId,
        } = this.props;
        const { monthlyType, noOfMonths } = this.state;

        return (
            <Fragment>
                <PaymentMethodHeaderItem
                    text="components.account.paymentMethod.downPaymentItem.date"
                    value={ dueDate }
                    type="date"
                />
                <PaymentMethodHeaderItem
                    text="components.account.paymentMethod.downPaymentItem.amount"
                    value={ parseFloat(amountDue ?? '0') }
                    type="currency"
                    currencyId={ currencyId }
                />
                <PaymentMethodHeaderItem
                    text="components.account.paymentMethod.downPaymentItem.late_fee"
                    value={ parseFloat(lateFee ?? '0') }
                    type="currency"
                    currencyId={ currencyId }
                />
                <PaymentMethodHeaderItem
                    text="components.account.paymentMethod.downPaymentItem.total"
                    value={ parseFloat(totalAmount ?? '0') }
                    type="total"
                    currencyId={ currencyId }
                />
                <PaymentMethodHeaderItem
                    text="components.account.paymentMethod.downPaymentItem.payment_amount"
                    value={ totalAmount ?? 0 }
                    type="input"
                    setPaymentAmount={ setPaymentAmount }
                    handleBlur={ handleBlur }
                    currencyId={ currencyId }
                    disabled={ monthlyType !== "monthly" && monthlyType !== "multiple" && noOfMonths === 1 }
                />
            </Fragment>
        )
    }

    render() {
        const { title, formikProps, contract, setStateOnMonthlyTypeChange } = this.props;

        return <Section color="dark-grey">
            <div className="payment-method-header">
                <h3 className="title">
                    <FormattedMessage id={ title }/>
                </h3>
                <h3 className="title mt-0">
                    <FormattedMessage id={ this.props.contract.contract_number }/>
                </h3>
                <MonthlyPaymentHeader
                    formikProps={ formikProps }
                    contract={ contract }
                    setStateOnMonthlyTypeChange={ state => {
                        this.setState({
                            monthlyType: state.monthlyType,
                            noOfMonths: state.noOfMonths,
                        });

                        setStateOnMonthlyTypeChange(state);
                    } }
                />
                { this.renderDownPayment() }
            </div>
        </Section>
    }
}

export { MonthlyPaymentMethodHeader }
