import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

class TermsAndConditionsList extends Component {

    renderTitle = (item) => {
        const {color} = this.props;

        return (
            <div className="terms-container" key={item.title}>
                <div>
                    <h2 className={`term-title ${color ? color : ''}`}>{item.title}</h2>
                </div>
                <div>
                    <h2 className={`term-title ${color ? color : ''} no-padding`}>{item.sub_title}</h2>
                </div>
            </div>
        );
    }

    render() {
        const {color} = this.props;

        return (
            <Grid>
                <div className="terms-and-conditions-list">
                    {this.props.terms.map(policy => this.renderTitle(policy))}
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <div>
                            <p className={`cat-title ${color ? color : ''}`}>
                                <FormattedMessage
                                    id="components.auth.termsAndConditions.termsAndConditionsList.what_is_title"/>
                            </p>
                            <p className={`term-text ${color ? color : ''}`}>
                                <FormattedMessage
                                    id="components.auth.termsAndConditions.termsAndConditionsList.what_is_text1"/>
                            </p>
                        </div>
                        <ul className={color}>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.option1"/></li>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.option2"/></li>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.option3"/></li>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.option4"/></li>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.option5"/></li>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.option6"/></li>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.option7"/></li>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.option8"/></li>
                        </ul>
                        <div>
                            <p className={`term-text ${color ? color : ''}`}>
                                <FormattedMessage
                                    id="components.auth.termsAndConditions.termsAndConditionsList.what_is_text2"/>
                            </p>
                            <p className={`term-text ${color ? color : ''}`}>
                                <FormattedMessage
                                    id="components.auth.termsAndConditions.termsAndConditionsList.what_is_text3"/>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <p className={`cat-title ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat1"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat1_text"/>
                        </p>
                    </div>
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <p className={`cat-title ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat2"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat2_text"/>
                        </p>

                        <ul className={color}>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat2_option1"/></li>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat2_option2"/></li>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat2_option3"/></li>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat2_option4"/></li>
                            <li><FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat2_option5"/></li>
                        </ul>

                        <p className={`term-text ${color ? color : ''}`}><FormattedMessage
                            id="components.auth.termsAndConditions.termsAndConditionsList.cat2_text2"/></p>
                    </div>
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <p className={`cat-title ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat3"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat3_text"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat3_text1"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat3_text2"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat3_text3"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat3_text4"/>
                        </p>
                    </div>
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <p className={`cat-title ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat4"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat4_text"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat4_text1"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat4_text2"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat4_text3"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat4_text4"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat4_text5"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat4_text6"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat4_text7"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat4_text8"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat4_text9"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedHTMLMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat4_text10"/>
                        </p>
                    </div>
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <p className={`cat-title ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat5"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat5_text"/>
                        </p>
                    </div>
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <p className={`cat-title ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat6"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat6_text"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat6_text1"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat6_text2"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat6_text3"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat6_text4"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat6_text5"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat6_text6"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat6_text7"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat6_text8"/>
                        </p>
                    </div>
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <p className={`cat-title ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat7"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat7_text"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat7_text1"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat7_text2"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat7_text3"/>
                        </p>
                    </div>
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <p className={`cat-title ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat8"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <b><FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat8_text"/></b>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat8_text1"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <b><FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat8_text2"/></b>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat8_text3"/>
                        </p>
                    </div>
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <p className={`cat-title ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat9"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat9_text"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat9_text1"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat9_text2"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat9_text3"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat9_text4"/>
                        </p>
                    </div>
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <p className={`cat-title ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat10"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat10_text"/>
                        </p>
                    </div>
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <p className={`cat-title ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat11"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat11_text"/>
                        </p>
                    </div>
                </div>

                <div className="terms-and-conditions-list">
                    <div className="terms-container">
                        <p className={`cat-title ${color ? color : ''}`}>
                            <FormattedMessage
                                id="components.auth.termsAndConditions.termsAndConditionsList.cat12"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat12_text"/>
                        </p>
                        <p className={`term-text ${color ? color : ''}`}>
                            <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.cat12_text1"/>
                        </p>
                    </div>
                </div>
            </Grid>
        );
    }
}

TermsAndConditionsList.defaultProps = {
    terms: [
        {
            title: <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.title"/>,
            sub_title: <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.sub_title"/>,
            text: <FormattedMessage id="components.auth.termsAndConditions.termsAndConditionsList.text1"/>
        }
    ]
}

export {TermsAndConditionsList};
