import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {Section} from "../../layout/Section";
import {ResortLogo} from "../myAccount/ResortLogo";

class Member extends Component {
    render() {
        const {userProfile} = this.props;
        return (
            <Section color="blue" className="account-profile-member">
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className="account-profile-member-container"
                >
                    <Grid item xs={12} className="text-center">
                        <div className="account-user-name">
                            <p className="user-name">{`${userProfile.first_name ? userProfile.first_name : "" } ${userProfile.last_name ? userProfile.last_name : ""}`}</p>
                        </div>
                        <hr className="hr-line"/>
                        {this.props.userProfile.resort_name &&
                        <ResortLogo contracts={this.props.userProfile.contracts}/>}
                    </Grid>
                </Grid>
            </Section>
        );
    }
}

export {Member}
