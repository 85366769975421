import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';

class ContractEmty extends Component {

    renderButtonEmpty() {
        const className = "contract-button";

        return (
            <button className={className}></button>
        )
    }

    render() {
        return (
            <Grid item className={`general-contract-item emty`}>
                { this.renderButtonEmpty() }
            </Grid>
        );
    }
}

export {ContractEmty}
