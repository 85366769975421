import React from "react";
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';

export const Footer = () => {
    return (
        <Grid container className='footer' direction="column" alignItems="center">
            <div>Copyright © {new Date().getFullYear()} V Passport.</div>
            <div> <FormattedMessage id="components.layout.footer.copyright"/></div>
        </Grid>
    )
}
