import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';

class ReservationActionItem extends Component {

    render() {
        const { img, text, action } = this.props;

        return <Fragment>
            <Grid item xs={12} className="new-reservation-action-item" onClick={action}>
                <div className="action-item-container">
                    <img className="action-icon" src={img} alt=""/>
                    <div className="action-text">
                        <FormattedMessage id={text}/>
                    </div>
                </div>
            </Grid>
        </Fragment>
    }
}

export { ReservationActionItem }
