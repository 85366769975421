import React, { Component } from 'react';
import {isAuthenticated} from "../../api/helper";
import {NotFoundAuthenticated} from "../../components/account/general/NotFoundAuthenticated";
import {NotFoundNotAuthenticated} from "../../components/auth/NotFoundNotAuthenticated";

class NotFoundPage extends Component {
    componentDidMount() {
        window.scroll(0,0);
    }

    render() {
        let component = isAuthenticated() ? <NotFoundAuthenticated/> : <NotFoundNotAuthenticated/>;
        return (
            {...component}
        )
    }
}

export { NotFoundPage };
