import React, { Component } from 'react';
import {isAuthenticated} from "../../api/helper";
import {TermsAndConditionsAuthenticated} from "../../components/auth/termsAndConditions/TermsAndConditionsAuthenticated";
import {TermsAndConditionsNotAuthenticated} from "../../components/auth/termsAndConditions/TermsAndConditionsNotAuthenticated";

class TermsAndConditions extends Component {
    componentDidMount() {
        window.scroll(0,0);
    }

    render() {
        let component = isAuthenticated() ? <TermsAndConditionsAuthenticated/> : <TermsAndConditionsNotAuthenticated/>;
        return (
            {...component}
        )
    }
}

export { TermsAndConditions };
