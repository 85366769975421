import React, {Component} from 'react';
import {Section} from "../../layout/Section";
import { FormattedMessage } from 'react-intl';

class ExchangesHeaderComponent extends Component{
    render() {
        return (
            <Section color="white">
                <div className="header-exchanges">
                    <h1>
                        <FormattedMessage id="components.account.exchanges.exchanges_title" />
                    </h1>
                    <p>
                        <FormattedMessage id="components.account.exchanges.exchanges_subtitle" />
                    </p>
                </div>
            </Section>
        );
    }
}

export {ExchangesHeaderComponent}
