import React from "react";
import { FormattedMessage } from "react-intl";

export const GENERIC_ERROR_MESSAGE = <FormattedMessage id="messages.generic_error_message"/>;
export const WRONG_LOGIN_CREDENTIALS = <FormattedMessage id="messages.wrong_login_credentials"/>;
export const USER_ALREADY_EXISTS = <FormattedMessage id="messages.user_already_exists"/>;
export const TOKEN_INVALID = <FormattedMessage id="messages.token_invalid"/>;
export const VERIFICATION_CODE_INVALID = <FormattedMessage id="messages.verification_code_invalid"/>;
export const EMAIL_DOES_NOT_EXIST = <FormattedMessage id="messages.email_not_exist"/>;
export const EMAIL_ALREADY_VERIFIED = <FormattedMessage id="messages.email_already_verified"/>;
export const EMAIL_INVALID_OR_DOES_NOT_EXIST = <FormattedMessage id="messages.email_invalid"/>;
export const ACCOUNT_NOT_VERIFIED = <FormattedMessage id="messages.account_not_verified"/>;
export const ACCOUNT_NOT_MEMBERID = <FormattedMessage id="messages.account_not_member_id"/>;
export const ACTIVATION_LINK_EXPIRED = <FormattedMessage id="messages.activation_link"/>;
export const CAPSLOCK_ACTIVE = <FormattedMessage id="messages.capslock_active"/>;
export const USER_LOGOUT_SUCCESSFULL = <FormattedMessage id="messages.user_logout"/>;
export const USER_EXISTS_IN_TE2= <FormattedMessage id="messages.user_already_exists_in_te2"/>;
export const ACCOUNT_DISABLED= <FormattedMessage id="messages.account_disabled"/>;
export const PROFILE_UPDATED = <FormattedMessage id="messages.profile_updated"/>;
export const PROFILE_UPDATED_EMAIL_SENT = <FormattedMessage id="messages.profile_updated_email_sent"/>;
export const UNAUTHENTICATED_ERROR = <FormattedMessage id="messages.unauthenticated" />;
export const UNAUTHORIZED_ERROR = <FormattedMessage id="messages.unauthorized" />;
export const NOT_FOUND_ERROR = <FormattedMessage id="messages.not_found" />;
export const NEW_VERIFICATION_CODE = <FormattedMessage id="messages.new_verification_code" />;
export const CREDIT_CARD_CREATED = <FormattedMessage id="messages.credit_card_created"/>;
export const CREDIT_CARD_UPDATED = <FormattedMessage id="messages.credit_card_updated"/>;
export const CONTACT_MESSAGE_WAS_SENT = <FormattedMessage id="messages.contact_message_was_sent"/>;
export const NO_PROPERTY_WAS_CHANGED =<FormattedMessage id="messages.no_property_was_changed"/>;
