import React, {Component} from 'react';
import Page from '../../../pages/auth/Page';
import {TermsAndConditionsList} from "./TermsAndConditionsList";

class TermsAndConditionsNotAuthenticated extends Component{
    render() {
        return (
            <Page
                class="auth termsAndConditions"
                showSwitchLanguage={true}
                titleId="pages.basic.terms_and_conditions.title"
            >
                <TermsAndConditionsList />
            </Page>
        );
    }
}

export {TermsAndConditionsNotAuthenticated};
