import React, { Component } from 'react';
import Page from './layout/Page';
import { ExchangesHeaderComponent} from "../../components/account/exchanges/ExchangesHeaderComponent";
import {ExchangesCarousel} from "../../components/account/exchanges/ExchangesCarousel";

class Exchanges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exchanges: []
        }
    }

    render() {
        return <Page class="account exchanges">
            <ExchangesHeaderComponent />
            <ExchangesCarousel />
        </Page>
    }
}

export { Exchanges };



