import React, {Component} from 'react';
import {Section} from "../../layout/Section";
import { FormattedMessage } from 'react-intl';

class FAQsHeaderComponent extends Component{
    render() {
        return (
            <Section color="white">
                <div className="header-faqs">
                    <h2><FormattedMessage id="components.account.faq.faqs_header_component.title"/></h2>
                    <p><FormattedMessage id="components.account.faq.faqs_header_component.sub_title"/></p>
                </div>
            </Section>
        );
    }
}

export {FAQsHeaderComponent}
