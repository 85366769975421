import React, {Component} from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';

class SavedCard extends Component {

    renderSmallCardNumber = (number) => {
        let last4Digists = number.slice(-4);
        return last4Digists
    }

    render() {
        const {card, selectedCard} = this.props;
        let expDate = moment().set({'year': card.exp_year, 'month': card.exp_month - 1});

        return <Grid item>
            {card && typeof(card.id) === 'string' &&
            <div className={`saved-card-container ${card.id === selectedCard.id ? 'selected' : ''}`}
                 onClick={() => this.props.selectCard(card)}>
                <div className="saved-card-header">
                    <div className="img">
                        <img src={`/img/payments/${card.card_type.toLowerCase()}.svg`} alt=''/>
                    </div>
                    <span className="expiration-date">
                        {expDate.format('MM/YYYY')}
                </span>
                </div>

                <span
                    className="number">&bull;&bull;&bull;{this.renderSmallCardNumber(card.card_number)}</span>

                <span className="owner-name">{card.card_holder_name}</span>
            </div>
            }
            {/*ADD AN EMPY ITEM IN ARRAY FOR CREATE ADD NEW CARD ELEMENT*/}
            {card.id < 0 &&
            <div id="add-new-card-item" className={`saved-card-container last-item ${card.id === selectedCard.id ? 'selected' : ''}`}
                 onClick={() => this.props.selectCard(card)}>
                <div className="add-new-card">
                    <AddCircleIcon className="add-circle"/>
                    <span>Add new</span>
                </div>
                {/*Display arrow only if Add new card item is selected*/}
                {selectedCard.id === -1 && <div className="border-arrow"></div>}
            </div>
            }
        </Grid>
    }
}

export {SavedCard}
