import React, {Component, Fragment} from 'react';
import {ErrorMessage, Form} from "formik";
import {FormattedMessage} from "react-intl";
import Grid from '@material-ui/core/Grid';
import CustomTextField from '../../general/CustomTextField';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {SelectLanguage} from './SelectLanguage';
import {Messages} from '../../general/Messages';
import {FormTitle} from './FormTitle';
import {Toggle} from '../../general';
import {PaymentType} from "./PaymentType";
import {ChangePasswordModal} from "./ChangePasswordModal";
import {VidaMemberId} from "../general/VidaMemberId";
import { Section } from '../../layout/Section'

class EditProfileForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openChangePasswordModal: false,
            language: document.getElementById('root').className == 'lang-es' ? 'es' : 'en',
        }
    }

    openChangePasswordModal = () => {
        let header = document.querySelector('.sticky');
        header.style.zIndex = '1298';

        this.setState({
            openChangePasswordModal: true
        });
    }

    handleClose = () => {
        let header = document.querySelector('.sticky');
        header.style.zIndex = '1301';
        this.setState({
            openChangePasswordModal: false,
        });
    }

    renderField = (type, name, id, props, value) => {
        const {errors, touched} = props;

        return <Fragment>
            <FormattedMessage id={id}>
                {text =>
                    <CustomTextField
                        type={type}
                        name={name}
                        label={text}
                        error={errors[name] && touched[name]}
                        onChange={e => props.setFieldValue(name, e.target.value)}
                        handleBlur={props.handleBlur}
                        value={value}
                    />
                }
            </FormattedMessage>
            <ErrorMessage name={name} component="div" className="field-error"/>
        </Fragment>
    }

    renderProfileInformationForm = (props) => {
        const {errors, touched} = props;

        // check if we have errors in the form to scroll the page up
        // if (Object.keys(errors).length && Object.keys(touched).length) {
        //     let profileEditForm = document.getElementById('profile-edit-form');
        //     if (profileEditForm) {
        //         profileEditForm.scrollIntoView(true);
        //     }
        // }

        return <Fragment>
            
            <FormTitle name="components.account.editProfile.editProfileForm.profile_information"/>
            <Grid container direction="row" spacing={4}>
                <Grid item sm={6} xs={12} className="custom-field black">
                    {this.renderField('email', 'email', 'globals.email_address', props, props.values.email)}
                </Grid>
                <Grid item sm={6} xs={12} className="change-field-btn-container"
                      onClick={() => this.openChangePasswordModal()}>
                    <span className="field-label">
                        <FormattedMessage id="globals.password"/>
                        <div className="field-btn">
                            ••••••••••••••••
                        </div>
                    </span>
                </Grid>
                <Grid item sm={6} xs={12} className="custom-field black">
                    {this.renderField('text', 'home_phone', 'register.home_phone', props, props.values.home_phone)}
                </Grid>
                <Grid item sm={6} xs={12} className="custom-field black">
                    {this.renderField('text', 'mobile_phone', 'register.mobile_phone', props, props.values.mobile_phone)}
                </Grid>
                <Grid item sm={6} xs={12} className="custom-field black">
                    {this.renderField('text', 'address_line_1', 'globals.address_line_1', props, props.values.address_line_1)}
                </Grid>
                <Grid item sm={6} xs={12} className="custom-field black">
                    {this.renderField('text', 'address_line_2', 'globals.address_line_2', props, props.values.address_line_2)}
                </Grid>
                <Grid item sm={6} xs={12} className="custom-field black">
                    {this.renderField('text', 'country', 'globals.country', props, props.values.country)}
                </Grid>
                <Grid item sm={6} xs={12} className="custom-field black">
                    {this.renderField('text', 'state', 'globals.state', props, props.values.state)}
                </Grid>
                <Grid item sm={6} xs={12} className="custom-field black">
                    {this.renderField('text', 'city', 'globals.city', props, props.values.city)}
                </Grid>
                <Grid item sm={6} xs={12} className="custom-field black">
                    {this.renderField('text', 'postal_code', 'globals.postal_code', props, props.values.postal_code)}
                </Grid>
            </Grid>

        </Fragment>
    }

    renderPaymentMethod = (userProfile) => {
        return <Fragment>
            <Grid item xs={12} sm={6} md={4} className="member-no">
                <VidaMemberId vidaMemberId={userProfile.vida_member_id} align="center" class="member-no-edit"/>
            </Grid>
            <FormTitle name="components.account.editProfile.editProfileForm.payment_method"/>
            <PaymentType userId={userProfile.user_id} profile={userProfile}/>
        </Fragment>
    }

    renderLanguagePreferences = (props) => {
        return <Fragment>
            <FormTitle name="components.account.editProfile.editProfileForm.language_preference"/>
            <SelectLanguage
                value={props.values.language_id}
                onChange={e => props.setFieldValue('language_id', e.target.value)}
            />
        </Fragment>
    }

    renderCommunicationsPreferences = (props) => {
        return <Fragment>
            <FormTitle name="components.account.editProfile.editProfileForm.communication_preferences"/>
            <Grid container direction="row" alignItems="flex-start" className="toggle-container edit-profile">
                <Grid item xs={12} sm={6}>
                    <Toggle
                        name="all_communications"
                        onChange={e => props.setFieldValue('all_communications', e.target.checked)}
                        value={props.values.all_communications}
                        text={<FormattedMessage id="components.account.editProfile.editProfileForm.allCommunications"/>}
                    >
                    </Toggle>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Toggle
                        name="monthly_communications"
                        onChange={e => props.setFieldValue('monthly_communications', e.target.checked)}
                        value={props.values.monthly_communications}
                        text={<FormattedMessage
                            id="components.account.editProfile.editProfileForm.monthlyCommunications"/>}
                    >
                    </Toggle>
                </Grid>
            </Grid>
        </Fragment>
    }

    render() {
        const {formikProps, userProfile} = this.props;

        return <Section color="white">
                        
                {this.renderPaymentMethod(userProfile)}

                <Form autoComplete="off">
                    {this.renderProfileInformationForm(formikProps)}
                    {this.renderLanguagePreferences(formikProps)}
                    {this.renderCommunicationsPreferences(formikProps)}

                    <Grid container direction="row">
                        <button
                            className="submit-btn"
                            name="edit_profile"
                            type="submit">
                            <FormattedMessage id="components.account.editProfile.submit_btn"/>
                            <span><ChevronRightIcon fontSize="small"/></span>
                        </button>
                    </Grid>
                    <Messages/>
                    <ChangePasswordModal
                        open={this.state.openChangePasswordModal}
                        handleClose={() => this.handleClose()}
                    />
                </Form>
            </Section>
    }
}

export {EditProfileForm};
