import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { CustomCheckBox } from "../../../general/CustomCheckBox";
import { PAYMENT_TYPES } from "../../../../config/constants/payment_types";

class ChooseMonths extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedMonths: [],
            selectedItem: props.selectedItem
        }
    }

    selectMonths = (month) => {
        let { selectedMonths } = this.state;
        let { setMonths } = this.props;

        let foundIndex = -1;

        let exists = selectedMonths.find((item, index) => {
            if (item.id === month.id) {
                foundIndex = index;
                return true;
            }

            return false;
        });

        if (exists) {
            selectedMonths.splice(foundIndex, 1);
        } else {
            selectedMonths.push(month);

        }

        setMonths(selectedMonths);
    }

    renderSelectMonthlyPayment = () => {
        const { contract } = this.props;

        return contract.payment_types[PAYMENT_TYPES.MONTHLY].map((item, index) => {
            return <Grid item xs={ 6 } md={ 4 } key={ index }>
                <CustomCheckBox
                    class="payment-field"
                    name={ item.id } id={ item.id }
                    value={ moment(item.due_date).format('MMMM YYYY') }
                    onChange={
                        () => {
                            this.selectMonths(item);
                        }
                    }
                />
            </Grid>
        })
    }

    render() {
        const { selectedItem } = this.props;

        return <div className={ `choose-months ${ selectedItem === 'multiple' ? 'expanded' : '' }` }>
            <span className="choose-months-title">
                <FormattedMessage id="components.account.paymentMethod.choose_months.title"/>
            </span>
            <Grid container className="custom-payment-field">
                { this.renderSelectMonthlyPayment() }
            </Grid>
        </div>
    }
}

export { ChooseMonths }
