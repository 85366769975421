import React, { Component } from 'react';
import { Section } from '../../layout/Section';
import Grid from '@material-ui/core/Grid';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { FormattedMessage } from 'react-intl';
import {history} from "../../../store/store";
import { Routes } from '../../../config';
import { getExchanges } from "../../../api/exchanges/exchanges";
import { COMPANIES } from "../../../config/constants/companies";

class StayWithUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companies: [],
        };
    }
    componentDidMount() {
        this.getExchanges();
    }

    getExchanges = () => {
        getExchanges().then(response => {
            let companies = [];

            for (let index in response) {
                companies.push(response[index].MembershipType);
            }

            this.setState({ companies: companies });
        })
    }

    renderRCI = () => {
        if (! this.state.companies || ! this.state.companies.includes(COMPANIES.RCI)) {
            return null;
        }

        return <Grid item xs={6} sm={3} className="img-container">
            <div className="img-company-background">
                <img src={ process.env.PUBLIC_URL + '/img/exchanges/RCI.jpg'} alt="" />
            </div>
        </Grid>;
    }

    renderInterval = () => {
        if (! this.state.companies || ! this.state.companies.includes(COMPANIES.INTERVAL)) {
            return null;
        }

        return <Grid item xs={6} sm={3} className="img-container">
            <div className="img-company-background">
                <img src={ process.env.PUBLIC_URL + '/img/exchanges/Interval.jpg'} alt="" />
            </div>
        </Grid>;
    }

    renderDAE = () => {
        if (! this.state.companies || ! this.state.companies.includes(COMPANIES.DAE)) {
            return null;
        }

        return <Grid item xs={6} sm={3} className="img-container">
            <div className="img-company-background">
                <img src={ process.env.PUBLIC_URL + '/img/offers/partners/dae.svg'} alt="" />
            </div>
        </Grid>;
    }

    renderICE = () => {
        if (! this.state.companies || ! this.state.companies.includes(COMPANIES.ICE_GALLERY)) {
            return null;
        }

        return <Grid item xs={6} sm={3} className="img-container">
            <div className="img-company-background">
                <img src={ process.env.PUBLIC_URL + '/img/offers/partners/ice-logo.png'} alt="" />
            </div>
        </Grid>;
    }

    renderSFX = () => {
        if (! this.state.companies || ! this.state.companies.includes(COMPANIES.SFX)) {
            return null;
        }

        return <Grid item xs={6} sm={3} className="img-container">
            <div className="img-company-background">
                <img src={ process.env.PUBLIC_URL + '/img/exchanges/SFX.jpg'} alt="" />
            </div>
        </Grid>;
    }

    renderThirdHomeInternational = () => {
        if (! this.state.companies || ! this.state.companies.includes(COMPANIES.THIRD_HOME_INTERNATIONAL)) {
            return null;
        }

        return <Grid item xs={6} sm={3} className="img-container">
            <div className="img-company-background">
                <img src={ process.env.PUBLIC_URL + '/img/exchanges/Thirdhome.jpg'} alt="" />
            </div>
        </Grid>;
    }

    renderClubFreedom = () => {
        if (! this.state.companies || ! this.state.companies.includes(COMPANIES.CLUB_FREEDOM)) {
            return null;
        }

        return <Grid item xs={6} sm={3} className="img-container">
            <div className="img-company-background">
                <img src={ process.env.PUBLIC_URL + '/img/exchanges/Club-freedom.jpg'} alt="" />
            </div>
        </Grid>;
    }

    renderRegistryCollection = () => {
        if (! this.state.companies || ! this.state.companies.includes(COMPANIES.REGISTRY_COLLECTION)) {
            return null;
        }

        return <Grid item xs={6} sm={3} className="img-container">
            <div className="img-company-background">
                <img src={ process.env.PUBLIC_URL + '/img/exchanges/The-Registry-Collection.jpg'} alt="" />
            </div>
        </Grid>;
    }

    renderVidaLifestyle = () => {
        if (! this.state.companies || ! this.state.companies.includes(COMPANIES.VIDA_LIFESTYLE)) {
            return null;
        }

        return <Grid item xs={6} sm={3} className="img-container">
            <div className="img-company-background">
                <img src={ process.env.PUBLIC_URL + '/img/exchanges/Vida-Lifestyle.jpg'} alt="" />
            </div>
        </Grid>;
    }

    render() {

        if (this.state.companies.length <= 0) {
            return null;
        }

        return (
        
        <Section color='white'>
            <Grid container direction="column" alignItems="center" className="stay-with-us">
                <div className="stay-with-us-title">
                    <FormattedMessage id="components.account.dashboard.stay_with_us.title"/>
                </div>

                <Grid container className="partners">
                    { this.renderRCI() }
                    { this.renderInterval() }
                    { this.renderDAE() }
                    { this.renderICE() }
                    { this.renderSFX() }
                    { this.renderThirdHomeInternational() }
                    { this.renderClubFreedom() }
                    { this.renderRegistryCollection() }
                    { this.renderVidaLifestyle() }
                </Grid>

                <Grid container alignItems="center" direction="column">
                    <Grid item xs={12} sm={9} md={6}>
                        <div className="stay-with-us-subtitle">
                            <FormattedMessage id="components.account.dashboard.stay_with_us.sub_title"/>
                        </div>
                    </Grid>
                    <Grid item>
                        <button className="stay-with-us-btn" onClick={() => history.push(Routes.ROUTE_EXCHANGES)}>
                            <FormattedMessage id="messages.learn_more"/>
                            <ChevronRightIcon/>
                        </button>
                    </Grid>
                </Grid>
            </Grid>
        </Section>
        
        )
    }
}

export { StayWithUs }
