import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAlertType } from '../../helpers/alerts';
import SimpleAlertComponent from './SimpleAlert';

class MessagesComponent extends Component {
    render() {
        const { showFlag, notificationId, id, ownProps, customMessage } = this.props;

        let { message, type, color } = this.props;
        let msgs = message || customMessage;

        if (!(showFlag || (msgs && notificationId === id))) return null;

        if (ownProps && ownProps.showFlag) {
            msgs = customMessage || '';
        }

        if (ownProps && ownProps.type) {
            type = ownProps.type;
        }

        if (!color) {
            color = getAlertType(type)
        }

        return ( <SimpleAlertComponent color={ color } className={'text-center'} id="errors-container">
                {
                    msgs && Array.isArray(msgs) && msgs.length &&
                    (
                        msgs.map((msg, key) => (
                            <div key={ key }>{ msg }</div>
                        ))
                    )
                }
                {
                    msgs && !Array.isArray(msgs) && <div>{ msgs }</div>
                }
                { this.props.children }
            </SimpleAlertComponent>
        );
    }
}

MessagesComponent.defaultProps = {
    showFlag: false,
    notificationId: null,
    id: null,
    customMessage: '',
};

const mapStateToProps = (state, ownProps) => ({
    message: state.notification.message,
    type: state.notification.type,
    id: state.notification.notificationId,
    ownProps: ownProps,
});

const Messages = connect(mapStateToProps)(MessagesComponent);

export { Messages, MessagesComponent };
