import React, {Component} from 'react';
import {Section} from "../../layout/Section";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {FormattedMessage} from "react-intl";
import { PRECHECKIN_URL } from  "../../../config/config";

class PreRegistrationSection extends Component {
    render() {

        return (
            <Section color="white">
                <div className="pre-registration-section">
                    <h1><FormattedMessage id="components.account.pre_registration.pre_registration_section.title"/></h1>
                    <p><FormattedMessage id="components.account.pre_registration.pre_registration_section.sub_title"/>
                    </p>
                    <button className="pre-registration-button">
                        <a href={ PRECHECKIN_URL } target="_blank" rel="noopener noreferrer">
                            <FormattedMessage id="components.account.pre_registration.pre_registration_section.btn"/>
                            <ChevronRightIcon/>
                        </a>
                    </button>
                </div>
            </Section>
        )
    }
}

export {PreRegistrationSection}
