import React from "react";
import { Countdown } from '../../components/auth'
import { ROUTE_DASHBOARD } from "../../config/routes";
import Page from '../auth/Page';

export const ResetPasswordSuccess = ({ history }) => {
    const timerEnded = () => {
        history.push( ROUTE_DASHBOARD );
    };

    return (
        <Page class="reset-password-success-wrapper" middle="middle">
            <Countdown
                title="globals.success"
                text="reset_password_success.text"
                seconds={5}
                onTimerEnd={timerEnded}
            />
        </Page>
    )
};
