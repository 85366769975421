import React, {Component} from 'react';
import {connect} from "react-redux";
import {Section} from '../../layout/Section';
import Grid from '@material-ui/core/Grid';
import {FormattedMessage} from 'react-intl';
import CustomSelect from '../../general/CustomSelect';
import {ErrorMessage, Form, Formik} from "formik";
import CustomTextField from '../../general/CustomTextField';
import {Toggle} from '../../general';
import {ContactSchema} from '../../../helpers/schemas';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {createMessage} from "../../../api/messages/messages";
import {Messages} from "../../general/Messages";
import {NOTIFICATION_CONTACT_MESSAGE_SENT} from "../../../config/notifications";
import {MESSAGE_STATUSES} from "../../../config/constants/messages";

class ContactFormComponent extends Component {

    submit = (data, {resetForm}) => {
        const {reason, phone, message, needToContactMe, email} = data;

        let payload = {
            message: {
                reason: reason,
                from: email,
                from_name: this.props.userProfile.first_name + ' ' + this.props.userProfile.last_name,
                phone: phone,
                message: message,
                message_type: MESSAGE_STATUSES.SUPPORT,
                need_to_contact_me: needToContactMe ? true : false,
            },
            isContact: true
        }

        createMessage(payload).then(() => {
            resetForm();
        });
    };

    renderForm = (props) => {
        let {errors, touched} = props;

        return (
            <Form autoComplete="off">
                <Grid container direction="column" justify="center" alignItems="center">
                    <Messages notificationId={NOTIFICATION_CONTACT_MESSAGE_SENT}/>
                    <Grid item xs={12}>
                        <CustomSelect
                            name="reason"
                            label={<FormattedMessage
                                id="components.account.contact.reason"/>}
                            items={this.props.contactReasons}
                            textColor="black"
                            class="contact-form-select"
                            onChange={e => props.setFieldValue('reason', e.target.value)}
                            useNameAsValue={true}
                            value={props.values.reason}
                            error={errors.reason && touched.reason}
                        />
                        <ErrorMessage name="reason" component="div" className="field-error left"/>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="custom-field">
                            <FormattedMessage
                                id="components.account.contact.phone">
                                {phone =>
                                    <CustomTextField
                                        id="phone"
                                        type="text"
                                        name="phone"
                                        class="contact-form-phone"
                                        label={phone}
                                        onChange={e => props.setFieldValue('phone', e.target.value)}
                                        handleBlur={props.handleBlur}
                                        value={props.values.phone}
                                        error={errors.phone && touched.phone}
                                    />
                                }
                            </FormattedMessage>
                            <ErrorMessage name="phone" component="div" className="field-error left"/>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="custom-field">
                            <FormattedMessage
                                id="components.account.contact.email">
                                {email =>
                                    <CustomTextField
                                        type="text"
                                        name="email"
                                        class="contact-form-email"
                                        label={email}
                                        onChange={e => props.setFieldValue('email', e.target.value)}
                                        handleBlur={props.handleBlur}
                                        value={props.values.email}
                                        error={errors.email && touched.email}
                                    />
                                }
                            </FormattedMessage>
                            <ErrorMessage name="email" component="div" className="field-error left"/>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="custom-field">
                            <FormattedMessage
                                id="components.account.contact.message">
                                {message =>
                                    <CustomTextField
                                        type="text"
                                        name="message"
                                        class="contact-form-message"
                                        label={message}
                                        onChange={e => props.setFieldValue('message', e.target.value)}
                                        handleBlur={props.handleBlur}
                                        value={props.values.message}
                                        error={errors.message && touched.message}
                                    />
                                }
                            </FormattedMessage>
                            <ErrorMessage name="message" component="div" className="field-error left"/>
                        </div>
                    </Grid>

                    <Grid container className="toggle-container request-reservation contact-form" justify="center"
                          alignItems="flex-start">
                        <Grid item xs={12}>
                            <Toggle
                                name="needToContactMe"
                                onChange={e => props.setFieldValue('needToContactMe', e.target.checked)}
                                value={props.values.needToContactMe}
                                text={<FormattedMessage
                                    id="components.account.contact.needToContactMe"/>}
                            >
                            </Toggle>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <button type="submit" className="contact-submit">
                            <FormattedMessage id="globals.send"/>
                            <ChevronRightIcon/>
                        </button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    getPhoneNumber = () => {
        let phoneNumber = this.props.userProfile.mobile_phone;

        if (!phoneNumber) {
            phoneNumber = this.props.userProfile.home_phone;
        }

        if (!phoneNumber) {
            phoneNumber = '';
        }

        return phoneNumber;
    }

    render() {

        return <Section color="white">
            <div className="contact-form">
                <Formik
                    initialValues={
                        {
                            reason: '',
                            subject: '',
                            phone: this.getPhoneNumber(),
                            email: this.props.userProfile ? this.props.userProfile.email : '',
                            message: '',
                            needToContactMe: false,
                        }
                    }
                    validationSchema={ContactSchema}
                    onSubmit={this.submit}
                    enableReinitialize={true}
                    validateOnBlur={true}
                >
                    {this.renderForm}
                </Formik>
            </div>
        </Section>
    }
}

ContactFormComponent.defaultProps = {
    contactReasons: [],
}

const mapStateToProps = (state) => ({
    userProfile: state.user.profile,
    contactReasons: state.contactReasons.reasons
})

const ContactForm = connect(mapStateToProps, null)(ContactFormComponent);

export {ContactForm, ContactFormComponent};


