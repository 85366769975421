import React, {Component, Fragment} from 'react';
import {FormattedMessage} from 'react-intl';
import Grid from '@material-ui/core/Grid';
import CustomTextField from "../../general/CustomTextField";
import {ErrorMessage} from "formik";
import { checkDevice } from "../../../helpers/checkDevice";
import CustomAutoComplete from "../../general/CustomAutoComplete";
import { getAllCountriesFormatted, getCountryByName, getAllStatesFormatted, getStateByName } from "../../../helpers/general";
import {Toggle} from "../../general";
import TextField from '@material-ui/core/TextField';

class BillingForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            device: checkDevice(),
            country: '',
            language: document.getElementById('root').className == 'lang-es' ? 'es' : 'en',
        }
    }

    setDevice = () => {
        this.setState({
            device: checkDevice()
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDevice);
    }


    renderField = (type, name, id, props, value) => {
        const {errors, touched} = props;

        return <Fragment>
            <FormattedMessage id={id}>
                {text =>
                    <CustomTextField
                        type={type}
                        name={name}
                        label={text}
                        error={errors[name] && touched[name]}
                        onChange={e =>{
                            props.setFieldValue(name, e.target.value);
                            if (this.props.isPaymentPage) {
                                this.props.setNewCardField(name, e.target.value);
                            }
                        }}
                        handleBlur={props.handleBlur}
                        value={value ?? ''}
                        class="card-bill"
                    />
                    
                }
            </FormattedMessage>
            <ErrorMessage name={name} component="div" className="field-error"/>
        </Fragment>
    }

    renderFieldState = (type, name, id, props, value) => {
        const {errors, touched} = props;

        return <Fragment>
            
            <FormattedMessage id={id}>
                {text =>
                   <TextField
                   className="card-bill"
                   type={type || 'text'}
                   fullWidth
                   margin='normal'
                   autoComplete='off'
                   name={name}
                   label={text}
                   error={errors[name] && touched[name]}
                   onChange={(e) => {
                    this.props.setNewCardField('state', e.target.value);
                    this.props.setNewCardField('bill_to_address_state', e.target.value);
                   }}
                   placeholder={props.placeholder}
                   onBlur={e => props.handleBlur(e)}
                   value={value ?? ''}
                   disabled={props.disabled ? props.disabled : false}
               />
                    
                }
            </FormattedMessage>
           
            <ErrorMessage name={name} component="div" className="field-error"/>
        </Fragment>
    }

    render() {
        let {device, language} = this.state;

        return <Grid container>
            
            {/* THIS SECTION IS TO COMPLEAT HOSTED-CHECK-OUT FIELD POST */}
            {/* THIS IS HIDDEN TO DON'T SEE IT IN THE MAIN PAGE */}
            <div style = {{visibility: "hidden", display: "none"}}>
                {this.renderField('hidden', 'access_key', 'globals.empty', this.props.formikProps, this.props.formikProps.values.access_key)}
                {this.renderField('hidden', 'profile_id', 'globals.empty', this.props.formikProps, this.props.formikProps.values.profile_id)}
                {this.renderField('hidden', 'transaction_uuid', 'globals.empty', this.props.formikProps, this.props.formikProps.values.transaction_uuid)}
                {this.renderField('hidden', 'signed_date_time', 'globals.empty', this.props.formikProps, this.props.formikProps.values.signed_date_time)}
                {this.renderField('hidden', 'locale', 'globals.empty', this.props.formikProps, this.props.formikProps.values.locale)}
                {this.renderField('hidden', 'transaction_type', 'globals.empty', this.props.formikProps, this.props.formikProps.values.transaction_type)}
                {this.renderField('hidden', 'reference_number', 'globals.empty', this.props.formikProps, this.props.formikProps.values.reference_number)}
                {this.renderField('hidden', 'amount', 'globals.empty', this.props.formikProps, this.props.formikProps.values.amount)}
                {this.renderField('hidden', 'currency', 'globals.empty', this.props.formikProps, this.props.formikProps.values.currency)}
                {this.renderField('hidden', 'unsigned_field_names', 'globals.empty', this.props.formikProps, this.props.formikProps.values.unsigned_field_names)}
                {this.renderField('hidden', 'submit', 'globals.empty', this.props.formikProps, this.props.formikProps.values.submit)}
                {this.renderField('hidden', 'signed_field_names', 'globals.empty', this.props.formikProps, this.props.formikProps.values.signed_field_names)}
                {this.renderField('hidden', 'signature', 'globals.empty', this.props.formikProps, this.props.formikProps.values.signature)}
                {this.renderField('hidden', 'device_fingerprint_id', 'globals.empty', this.props.formikProps, this.props.formikProps.values.device_fingerprint_id)}
                {this.renderField('hidden', 'save_card', 'globals.empty', this.props.formikProps, this.props.formikProps.values.save_card)}
                {this.renderField('hidden', 'merchant_defined_data99', 'globals.empty', this.props.formikProps, this.props.formikProps.values.merchant_defined_data99)}
                {!this.props.isUsedInModal && this.renderField('hidden', 'merchant_defined_data98', 'globals.empty', this.props.formikProps, this.props.formikProps.values.merchant_defined_data98)}
                {this.renderField('hidden', 'merchant_defined_data97', 'globals.empty', this.props.formikProps, this.props.formikProps.values.merchant_defined_data97)}
                {this.renderField('hidden', 'merchant_defined_data96', 'globals.empty', this.props.formikProps, this.props.formikProps.values.merchant_defined_data96)}
                {!this.props.isUsedInModal && this.renderField('hidden', 'merchant_defined_data95', 'globals.empty', this.props.formikProps, this.props.formikProps.values.merchant_defined_data95)}
                {this.renderField('hidden', 'merchant_defined_data94', 'globals.empty', this.props.formikProps, this.props.formikProps.values.merchant_defined_data94)}
                {!this.props.isUsedInModal && this.renderField('hidden', 'merchant_secure_data4', 'globals.empty', this.props.formikProps, this.props.formikProps.values.merchant_secure_data4)}
                {(this.props.formikProps.values.merchant_defined_data96 == 'update' && this.props.formikProps.values.merchant_defined_data96 != undefined) && this.renderField('hidden', 'payment_token', 'globals.empty', this.props.formikProps, this.props.formikProps.values.payment_token)}
            </div>
                             
            <Grid container spacing={device.isMobile ? 0 : 2}>
                <Grid item xs={12} sm={6} className="custom-field black">
                    {this.renderField('text', 'bill_to_forename', 'globals.first_name', this.props.formikProps, this.props.formikProps.values.bill_to_forename)}
                </Grid>

                <Grid item xs={12} sm={6} className="custom-field black">
                    {this.renderField('text', 'bill_to_surname', 'globals.last_name', this.props.formikProps, this.props.formikProps.values.bill_to_surname)}
                </Grid>
            </Grid>

            <Grid item xs={12} className="custom-field black">
                {this.renderField('text', 'bill_to_address_line1', 'globals.address', this.props.formikProps, this.props.formikProps.values.bill_to_address_line1)}
            </Grid>

            {/*THIS SECTION IS COMMENTED BEACAUSE HOSTED-CHECK-OUT DON'T ACCEPT COUNTRY AND STATES COMPLEAT NAME.*/}

            <Grid container spacing={device.isMobile ? 0 : 2}>
                <Grid item xs={12} sm={6} className="custom-field black">
                    <CustomAutoComplete
                        name="country"
                        items={getAllCountriesFormatted(language)}
                        label={<FormattedMessage
                        id="globals.country"/>}
                        textColor="black"
                        error={this.props.formikProps.errors.country && this.props.formikProps.touched.country}
                        handleBlur={this.props.formikProps.handleBlur}
                        class="card-bill"
                        onChange={newValue => {
                            //this.props.formikProps.setFieldValue('country', newValue && newValue.value ? newValue.value : '');
                            if (this.props.isPaymentPage) {
                                this.props.setNewCardField('country', newValue && newValue.name ? newValue.name : '');
                                this.props.setNewCardField('bill_to_address_country', newValue && newValue.value ? newValue.value : '');
                            }
                        }}
                        value={ getCountryByName(this.props.formikProps.values.country, language) }
                    />
                    <ErrorMessage name="country" component="div" className="field-error"/>
                </Grid>
              
                <Grid item xs={12} sm={6} className="custom-field black" style = {{visibility: "hidden", display: "none"}}>
                    {this.renderField('hidden', 'bill_to_address_country', 'globals.country', this.props.formikProps, this.props.formikProps.values.bill_to_address_country)}
                </Grid>

                {(this.props.formikProps.values.bill_to_address_country == "US" || this.props.formikProps.values.bill_to_address_country == "CA") && 
                <Grid item xs={12} sm={6}  className="custom-field black">
                   <CustomAutoComplete
                        name="state"
                        items={getAllStatesFormatted(language, this.props.formikProps.values.bill_to_address_country)}
                        label={<FormattedMessage
                        id="globals.state"/>}
                        textColor="black"
                        error={this.props.formikProps.errors.state && this.props.formikProps.touched.state}
                        handleBlur={this.props.formikProps.handleBlur}
                        class="card-bill"
                        onChange={newValue => {
                            if (this.props.isPaymentPage) {
                                this.props.setNewCardField('state', newValue && newValue.name ? newValue.name : '');
                                this.props.setNewCardField('bill_to_address_state', newValue && newValue.value ? newValue.value : '');
                            }
                        }}
                        value={ getStateByName(this.props.formikProps.values.state, language, this.props.formikProps.values.bill_to_address_country) }
                    />
                    <ErrorMessage name="state" component="div" className="field-error"/>
                </Grid>}

                {(this.props.formikProps.values.bill_to_address_country != "US" && this.props.formikProps.values.bill_to_address_country != "CA") && 
                <Grid item xs={12} sm={6}  className="custom-field black">
                    {this.renderFieldState('text', 'state', 'globals.state', this.props.formikProps, this.props.formikProps.values.state)}
                </Grid>}

                <Grid item xs={12} sm={6} className="custom-field black" style = {{visibility: "hidden", display: "none"}}>
                    {this.renderField('hidden', 'bill_to_address_state', 'globals.state', this.props.formikProps, this.props.formikProps.values.bill_to_address_state)}
                </Grid>
            </Grid>
            
            <Grid container spacing={device.isMobile ? 0 : 2}>
                <Grid item xs={12} sm={6} className="custom-field black">
                    {this.renderField('text', 'bill_to_address_city', 'globals.city', this.props.formikProps, this.props.formikProps.values.bill_to_address_city)}
                </Grid>

                <Grid item xs={12} sm={6} className="custom-field black">
                    {this.renderField('text', 'bill_to_address_postal_code', 'globals.postal_code', this.props.formikProps, this.props.formikProps.values.bill_to_address_postal_code)}
                </Grid>
            </Grid>

            {/*THIS SECTION IS USED FROM 2 PARTS OF APPLICATION, FROM PAYMENT TYPE AND FROM EDIT ACCOUNT.*/}
            {/*WE MAKE FIELDS DISTINCT BY USING isUsedInModal props*/}

            {! this.props.isUsedInModal && <Grid item xs={12} className="toggle-container save-credit-card">
                <Toggle
                    name="save_card"
                    onChange={e => {
                        this.props.formikProps.setFieldValue('save_card', e.target.checked);
                        if (this.props.isPaymentPage) {
                            this.props.setNewCardField('is_default', e.target.checked);
                        }
                    }}
                    value={this.props.formikProps.values.save_card ?? false}
                    text={<FormattedMessage id="globals.save_card"/>}
                >
                </Toggle>
            </Grid>
            }

            {/* {this.props.isUsedInModal && <Grid item xs={12} className="toggle-container save-credit-card">
                <Toggle
                    name="is_default"
                    onChange={e => {
                        this.props.formikProps.setFieldValue('is_default', e.target.checked)
                        if (this.props.isPaymentPage) {
                            this.props.setNewCardField('is_default', e.target.checked);
                        }
                    }}
                    value={this.props.formikProps.values.is_default ?? false}
                    text={<FormattedMessage id="components.account.editProfile.editProfileForm.add_new_credit_card_modal.set_default"/>}
                >
                </Toggle>
            </Grid>
            } */}

        </Grid>
    }
}

export {BillingForm}
