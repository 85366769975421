import React, { Component } from 'react';
import { Section } from "../../layout/Section";
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import VidaVacationsLogo from "../../../resources/assets/account/payments/vida-vacations.svg";

class FailPaymentContent extends Component {

    render() {
        let message = this.props.message;
        return (
            <Section color="white" className="success-payment-content">
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={4} sm="auto" className="logo-img">
                            <img src={VidaVacationsLogo} alt="vida-payments-logo"/>
                        </Grid>
                        <Grid item xs={8} sm="auto" className="title-right">
                            <h1 className="my-vida-vacations-payments-title">
                                <FormattedMessage
                                    id="components.account.myVidaVacationsPayments.PaymentType.my_vida_vacations_payments"/>
                            </h1>
                        </Grid>
                    </Grid>

                    <div className="thank-container" style={{padding: '70px 0'}} >
                        <span className="thank-text">
                            {message}
                        </span>
                    </div>

                    <img src={ process.env.PUBLIC_URL + '/img/payments/fail.png'} alt="" style={{width:'auto'}} />
                </Grid>

            </Section>
        )
    }
}

export { FailPaymentContent }

