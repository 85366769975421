import React, {Component} from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

class CustomTextarea extends Component {
    render() {
        return ( <TextareaAutosize
            id={this.props.name}
            className={this.props.class}
            margin="normal"
            name={this.props.name}
            autoComplete="off"
            onChange={(e) => this.props.onChange( e )}
            placeholder={this.props.placeholder}
            onBlur={e => this.props.handleBlur(e)}
            value={this.props.value}
        />
        )
    }
}

export { CustomTextarea};
