import { getAxios } from '../axios';
import {
    ENDPOINT_MARK_ALL_MESSAGES_READ,
    ENDPOINT_MESSAGES,
    ENDPOINT_READ_CONVERSATIONS
} from '../../config/endpoints';
import { store } from '../../store/store';
import {
    addParam,
    errorCatch,
    getTokenHeaders,
} from '../helper';
import { Messages } from '../../config';
import { displayNotification } from "../../store/actions/notification";
import { NOTIFICATION_SUCCESS } from "../../store/actions/types";
import {
    NOTIFICATION_CONTACT_MESSAGE_SENT,
    NOTIFICATION_GET_MESSAGE_BY_ID,
    NOTIFICATION_GET_MESSAGES
} from "../../config/notifications";

/**
 * Create a message
 * @param {object} payload
 * @param {object} payload.message
 * @param {object} payload.message.id
 * @param {string} payload.message.reason
 * @param {string} payload.message.subject
 * @param {string} payload.message.phone
 * @param {string} payload.message.message
 * @param {string} payload.message.need_to_contact_me
 * @param {string} payload.message.message_type
 * @param {boolean} payload.isContact
 * @returns {Promise<T>}
 */
export function createMessage(payload) {
    return getAxios().post(
        addParam(
            ENDPOINT_MESSAGES,
            {
                '{message}': ''
            }
        ),
        payload.message,
        getTokenHeaders()
    )
        .then(
            response => {
                if (response && response.data) {
                    if (payload.isContact) {
                        store.dispatch(displayNotification(NOTIFICATION_SUCCESS, Messages.CONTACT_MESSAGE_WAS_SENT, NOTIFICATION_CONTACT_MESSAGE_SENT));
                    }

                    return response.data.data;
                }

                return null;
            }
        )
        .catch(
            e => {
                errorCatch(e, {
                    400: 'concat',
                    401: Messages.UNAUTHENTICATED_ERROR,
                    403: Messages.UNAUTHORIZED_ERROR,
                    404: Messages.NOT_FOUND_ERROR,
                }, NOTIFICATION_CONTACT_MESSAGE_SENT);

                return e.response;
            });
}

/**
 * Replay a message
 * @param {object} payload
 * @param {object} payload.message
 * @param {object} payload.message.id
 * @param {string} payload.message.message
 * @param {string} payload.message.message_type
 * @returns {Promise<T>}
 */
export function reply(payload) {
    return getAxios().post(
        addParam(
            ENDPOINT_MESSAGES,
            {
                '{message}': payload.message.id
            }
        ),
        payload.message,
        getTokenHeaders()
    )
        .then(
            response => {
                if (response && response.data) {
                    if (payload.isContact) {
                        store.dispatch(displayNotification(NOTIFICATION_SUCCESS, Messages.CONTACT_MESSAGE_WAS_SENT, NOTIFICATION_CONTACT_MESSAGE_SENT));
                    }

                    return response.data.data;
                }

                return null;
            }
        )
        .catch(
            e => {
                errorCatch(e, {
                    400: 'concat',
                    401: Messages.UNAUTHENTICATED_ERROR,
                    403: Messages.UNAUTHORIZED_ERROR,
                    404: Messages.NOT_FOUND_ERROR,
                }, NOTIFICATION_CONTACT_MESSAGE_SENT);

                return e.response;
            });
}

/**
 * Get messages list
 * @returns {Promise<T>}
 */
export function getMessages() {
    return getAxios(false).get(
        addParam(
            ENDPOINT_MESSAGES,
            {
                '{message}': ''
            }),
        getTokenHeaders()
    )
        .then(
            data => {
                return data && data.data ? data.data.data : []
            }
        )
        .catch(
            e => {
                errorCatch(
                    e,
                    {
                        400: 'concat',
                        401: Messages.UNAUTHENTICATED_ERROR,
                        403: Messages.UNAUTHORIZED_ERROR,
                        404: Messages.NOT_FOUND_ERROR,
                    },
                    NOTIFICATION_GET_MESSAGES
                );

                return [];
            }
        );
}

/**
 * Read a message and conversations
 * @param {object} payload
 * @param {object} payload.message
 * @param {object} payload.message.id
 * @returns {Promise<T>}
 */
export function readMessages(payload) {
    return getAxios().patch(
        addParam(
            ENDPOINT_READ_CONVERSATIONS,
            {
                '{message}': payload.message.id
            }
        ),
        null,
        getTokenHeaders()
    )
        .then(
            response => {
                if (response && response.data) {
                    return response.data.data;
                }

                return null;
            }
        )
        .catch(
            e => {
                errorCatch(e, {
                    400: 'concat',
                    401: Messages.UNAUTHENTICATED_ERROR,
                    403: Messages.UNAUTHORIZED_ERROR,
                    404: Messages.NOT_FOUND_ERROR,
                });

                return e.response;
            });
}

/**
 * Mark all messages read
 * @returns {Promise<T>}
 */
export function markAllMessagesRead() {
    return getAxios().patch(
        ENDPOINT_MARK_ALL_MESSAGES_READ,
        null,
        getTokenHeaders()
    )
        .then(
            response => {
                if (response && response.data) {
                    return response.data.data;
                }

                return null;
            }
        )
        .catch(
            e => {
                errorCatch(e, {
                    400: 'concat',
                    401: Messages.UNAUTHENTICATED_ERROR,
                    403: Messages.UNAUTHORIZED_ERROR,
                    404: Messages.NOT_FOUND_ERROR,
                }, NOTIFICATION_CONTACT_MESSAGE_SENT);

                return e.response;
            });
}

/**
 * Get message data by id
 * @param {object} payload
 * @param {string} payload.messageId
 * @returns {Promise<T>}
 */
export function getMessageById(payload) {
    return getAxios().get(
        addParam(
            ENDPOINT_MESSAGES,
            {
                '{message}': payload.messageId
            }),
        getTokenHeaders()
    )
        .then(
            data => {
                return data && data.data ? data.data.data : null
            }
        )
        .catch(
            e => {
                errorCatch(
                    e,
                    {
                        400: 'concat',
                        401: Messages.UNAUTHENTICATED_ERROR,
                        403: Messages.UNAUTHORIZED_ERROR,
                        404: Messages.NOT_FOUND_ERROR,
                    },
                    NOTIFICATION_GET_MESSAGE_BY_ID
                );

                return e.response;
            }
        );
}
