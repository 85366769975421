import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import { FormattedMessage } from 'react-intl';

class ReplyNotification extends Component {

    render() {
        return (
            <Container maxWidth="md" className="message-area-reply-notification">
                <button onClick={this.props.onClick}>
                    <FormattedMessage id="components.account.readMessages.reply.reply_notification.text"/>
                </button>
            </Container>
        )
    }
}

export {ReplyNotification}
