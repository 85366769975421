import React, {Component} from 'react';
import {Section} from "../../layout/Section";

class ClubFreedomBenefitsHeader extends Component{
    render() {
        return (
            <Section color="white" className="club-header-image"></Section>
        );
    }
}

export { ClubFreedomBenefitsHeader }
