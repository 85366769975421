import React, {Component} from 'react';
import Page from './layout/Page';
import {SuccessPaymentContent} from "../../components/account/myVidaVacationsPayments/SuccessPaymentContent";
import {SecondNav} from "../../components/layout/Navbars/header/secondNav/SecondNav";
import {Routes} from "../../config";
import {FormattedMessage} from 'react-intl';

class SuccessPayment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            "orderId": props.match.params.order,
        };
    }

    render() {
        return <Page class="account my-vida-vacations-payments">
            <SecondNav
                linkText={
                    <FormattedMessage id="pages.account.successPayment.back_text"/>
                }
                link={Routes.ROUTE_MY_VIDA_VACATIONS_PAYMENTS}
                showSaveBtn={false}
                border={false}
            />
            <SuccessPaymentContent orderId={ this.state.orderId } />
        </Page>
    }
}

export {SuccessPayment}
