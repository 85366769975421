import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import { Text, Title } from '../general';
import { FormattedMessage } from "react-intl";

class Countdown extends Component {
    constructor(props) {
        super( props );

        this.state = {
            seconds: this.props.seconds,
        };

        this.timer = null;
        this.startTimer()
    }

    startTimer = () => {
        this.timer = setInterval( () => {
            if (this.state.seconds > 0) {
                this.setState( { seconds: this.state.seconds - 1 } );
                return
            }

            clearInterval( this.timer );
            this.props.onTimerEnd()
        }, 1000 );
    };

    render() {
        return (
            <Grid container direction="column" className="message">
                <Title className="center auth-title">
                    <FormattedMessage id={this.props.title} />
                </Title>
                <Text className='center auth-subtitle'>
                    <FormattedMessage
                        id={this.props.text}
                        values={{
                            seconds: <span className="seconds">{this.state.seconds}</span>
                        }}
                    />
                    <span className="seconds">
                    {this.state.seconds === 1 ? <FormattedMessage id="globals.second"/> :
                        <FormattedMessage id="globals.seconds"/>}
                    </span>
                </Text>
            </Grid>
        )
    }
}

export { Countdown };
