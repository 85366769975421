import React, {Component} from 'react';
import {Section} from "../../layout/Section";
import Grid from '@material-ui/core/Grid';
import {Contract} from "./Contract";
import {ContractEmty} from "./ContractEmty";
import {FormattedMessage} from 'react-intl';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

class ContractsComponent extends Component {
    constructor(props) {
        super(props);

        let activeBtn = 0;

        // Set always the middle element active when contracts length is 3
        if (props.contracts.length === 3) {
            activeBtn = 1;
        }

        this.state = {
            activeButton: activeBtn
        };
    }

    componentDidMount() {
        this.props.setActive(this.state.activeButton);
    }

    changeHandler = (index) => {
        if (this.props.contracts.length === 3) {
            return;
        }

        this.setState({
            activeButton: index,
        });

        this.props.setActive(index);
    };

    navigateContracts = (type) => {
        if (this.props.contracts.length === 3) {
            this.props.changeSelectedItem(type);
        } else {
            let activeButton = this.state.activeButton;
            if (type === 'back') {
                if (activeButton === 0) {
                    activeButton = this.props.contracts.length - 1;
                } else {
                    activeButton--;
                }
            } else {
                if (activeButton === this.props.contracts.length - 1) {
                    activeButton = 0;
                } else {
                    activeButton++;
                }
            }

            this.changeHandler(activeButton);
        }
    }

    renderArrowButtonsWithoutCarousel = (type) => {
        if (this.props.contracts.length === 2 || this.props.contracts.length === 3) {
            if (type === 'back') {
                return <div className="icon-arrow-container left-btn-arrow"
                            onClick={() => this.navigateContracts('back')}>
                    <ChevronLeftIcon/>
                </div>
            } else {
                return <div className="icon-arrow-container right-btn-arrow" onClick={() => this.navigateContracts()}>
                    <ChevronRightIcon/>
                </div>
            }
        } else {
            return null;
        }
    }

    setSliderArrowPosition = () => {
        let currentElement = document.querySelector('.contract-button-active');
        let slickPrev = document.querySelector('.left-btn-arrow');
        let slickNext = document.querySelector('.right-btn-arrow');
        let container = document.querySelector('.contracts-container');

        setTimeout(() => {

            if (currentElement && slickPrev && slickNext && container) {

                let leftPosition = currentElement.getBoundingClientRect().left - container.getBoundingClientRect().left - 12;
                let rightPosition = container.getBoundingClientRect().right - currentElement.getBoundingClientRect().right - 12;
                slickPrev.style.left = `${leftPosition}px`;
                slickNext.style.right = `${rightPosition}px`;

                if (this.props.device.isMobile) {
                    slickNext.style.top = '42px';
                    slickPrev.style.top = '42px';
                }

                if (this.props.device.isTablet) {
                    slickNext.style.top = '35px';
                    slickPrev.style.top = '35px';
                }

                if (this.props.device.isDesktop) {
                    slickNext.style.top = '37px';
                    slickPrev.style.top = '37px';
                }
            }
        }, 200);
    }

    render() {
        if (this.props.contracts.length === 3) {
            this.setSliderArrowPosition();
        }

        return (
            <Section color="bg-dark-grey" className="contract-carousel">
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className="contracts-container"
                >
                    {this.renderArrowButtonsWithoutCarousel('back')}
                    {this.props.contracts.length == 1 && this.props.contracts.map((contract, index) => 
                        
                        <div  style={{width: '100%', display: 'flex'}}>
                            { this.props.device.isDesktop && <ContractEmty/> }

                            <Contract contractLength={this.props.contracts.length} contract={contract} key={index}
                                    index={index}
                                    isActive={this.state.activeButton === index}
                                    onClick={() => this.changeHandler(index)}/>
                            
                            { this.props.device.isDesktop && <ContractEmty/> }

                        </div>
                    )
                    }
                    {this.props.contracts.length > 1 && this.props.contracts.map((contract, index) => 
    
                            <Contract contractLength={this.props.contracts.length} contract={contract} key={index}
                                    index={index}
                                    isActive={this.state.activeButton === index}
                                    onClick={() => this.changeHandler(index)}/>
                            
                    )
                    }
                    {this.renderArrowButtonsWithoutCarousel()}

                    {
                        !this.props.contracts.length &&
                        <div className="no-contracts-available">
                            <span><FormattedMessage id="components.account.myAccount.contract.no_items"/></span>
                        </div>
                    }
                </Grid>
            </Section>
        );
    }
}

export {ContractsComponent}
