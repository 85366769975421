import React, {Component} from 'react';
import {Section} from "../../layout/Section";

class AboutHeader extends Component{
    render() {
        return (
            <Section color="white" className="header-image"></Section>
        );
    }
}

export {AboutHeader }
