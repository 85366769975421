
export const APP_VERSION = process.env.REACT_APP_VERSION

export const GOOGLE_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

export const APP_ACTIVE_PAYMENTS = process.env.REACT_APP_ACTIVE_PAYMENTS

export const DELAY_NOTIFICATION = 10000;

export const CYBERSOURCE_HOSTED_CHECK_OUT_PAY = process.env.REACT_APP_CYBERSOURCE_HOSTED_CHECK_OUT + "/pay";
export const CYBERSOURCE_HOSTED_CHECK_OUT_CRATE_TOKEN = process.env.REACT_APP_CYBERSOURCE_HOSTED_CHECK_OUT + "/token/create";
export const CYBERSOURCE_HOSTED_CHECK_OUT_UPDATE_TOKEN = process.env.REACT_APP_CYBERSOURCE_HOSTED_CHECK_OUT + "/token/update";
export const CYBERSOURCE_HOSTED_CHECK_OUT_ORG_ID = process.env.REACT_APP_CYBERSOURCE_HOSTED_CHECK_OUT_ORG_ID

export const PRECHECKIN_URL = process.env.REACT_APP_PRECHECKIN_URL

