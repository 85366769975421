import React, {Component} from 'react';
import Page from './layout/Page';
import {Filters} from '../../components/layout/Navbars/header/Filters';
import {AddReservation} from '../../components/account/myReservations/AddReservation';
import {UpcomingReservations} from '../../components/account/myReservations/UpcomingReservations';
import {PastReservations} from '../../components/account/myReservations/PastReservations';
import {CanceledReservations} from '../../components/account/myReservations/CanceledReservations';
import {getReservations} from "../../api/reservations/reservations";
import {RESERVATION_TYPES} from "../../config/constants/reservations";
import {RESERVATION_STATUSES_NAME} from '../../config/constants/reservations';
import {history, store} from "../../store/store";
import { displayGlobalNotification } from "../../store/actions/globalNotification";

import {NOTIFICATION_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_WARNING, DISPLAY_GLOBAL_NOTIFICATION} from "../../store/actions/types";
import {Routes} from "../../config";

class MyReservations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pastReservations: [],
            canceledReservations: [],
            upcomingReservations: [],
        }
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getData = () => {

        getReservations({type: RESERVATION_TYPES.ALL}).then(data => {

            if(Array.isArray(data) && data.length <= 0) {
                store.dispatch(displayGlobalNotification(NOTIFICATION_WARNING, 'messages.empty_reservations'));
                history.push(Routes.ROUTE_HOME);
            }

            var upcomingReservations = Array.isArray(data) && data.length ? data.filter(item => new Date(item.arriving_date) >= new Date() && item.reservation_status !== RESERVATION_STATUSES_NAME.CANCELED && item.reservation_status !== RESERVATION_STATUSES_NAME.NOSHOW) : [];
            var pastReservations = Array.isArray(data) && data.length ? data.filter(item => new Date(item.arriving_date) < new Date() && item.reservation_status !== RESERVATION_STATUSES_NAME.CANCELED && item.reservation_status !== RESERVATION_STATUSES_NAME.NOSHOW) : [];
            var canceledReservations = Array.isArray(data) && data.length ? data.filter(item => item.reservation_status == RESERVATION_STATUSES_NAME.CANCELED) : [];

            this.setState({
                    pastReservations: pastReservations,
                    upcomingReservations: upcomingReservations,
                    canceledReservations: canceledReservations
                });

            });
    }

    render() {
        const { upcomingReservations, pastReservations, canceledReservations } = this.state;

        return <Page class="account my-reservations">
            <Filters/>
            {/* {<AddReservation/>} */}
            <UpcomingReservations reservations={upcomingReservations}/>
            <PastReservations pastReservations={pastReservations} cancelledReservations={canceledReservations} />
            <CanceledReservations pastReservations={pastReservations} cancelledReservations={canceledReservations} />
        </Page>
    }
}

export {MyReservations};
