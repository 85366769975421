import {
    TOKEN_DETAILS,
    USER_INFO,
    USER_LOGOUT,
    REQUEST_UPDATE_PROFILE,
    REQUEST_LOGIN,
    USER_DETAILS, SHOULD_RENDER_PAGE, SHOULD_NOT_RENDER_PAGE
} from './types';

export const requestLogin = (response) => ({
    type: REQUEST_LOGIN,
    payload: response
});


export const userDetails = (stopRedirect) => ({
    type: USER_DETAILS,
    payload: {
        stopRedirect: stopRedirect || false
    }
});

/**
 * method for update profile
 * @param {object} payload
 * @param response
 * @param {string} payload.address_line_1,
 * @param {string} payload.address_line_2,
 * @param {string} payload.email,
 * @param {string} payload.home_phone,
 * @param {number} payload.language_id,
 * @param {string} payload.mobile_phone,
 * @param {boolean} payload.all_communications,
 * @param {string} payload.current_password,
 * @param {string} payload.new_password,
 * @param {string} payload.new_password_confirmation,
 * @param {boolean} payload.monthly_communications,
 */
export const requestUpdateProfile = (payload, response) => ( {
    type: REQUEST_UPDATE_PROFILE,
    payload: {
        address_line_1: payload.address_line_1 || null,
        address_line_2: payload.address_line_2 || null,
        email: payload.email || null,
        home_phone: payload.home_phone || null,
        language_id: payload.language_id || null,
        mobile_phone: payload.mobile_phone || null,
        current_password: payload.current_password || null,
        new_password: payload.new_password || null,
        new_password_confirmation: payload.confirm_new_password || null,
        all_communications: payload.all_communications,
        monthly_communications: payload.monthly_communications,
        isModal: payload.isModal,
    },
    response: {...response}
} );

/**
 * method for update profile password
 * @param {object} payload
 * @param response
 * @param {string} payload.current_password,
 * @param {string} payload.new_password,
 * @param {string} payload.new_password_confirmation,
 */
export const requestUpdateProfilePassword = (payload, response) => ( {
    type: REQUEST_UPDATE_PROFILE,
    payload: {
        current_password: payload.current_password || null,
        new_password: payload.new_password || null,
        new_password_confirmation: payload.new_password_confirmation || null,
        isModal: payload.isModal,
    },
    response: {...response}
} );

/**
 * method for update profile from Redux
 * @param {object} payload
 * @param {*|null} payload.vida_member_id,
 * @param {*|null} payload.first_name,
 * @param {*|null} payload.last_name,
 * @param {string|null} payload.home_phone,
 * @param {string} payload.email,
 * @param {string|null} payload.address_line_1,
 * @param {string|null} payload.address_line_2,
 * @param {string} payload.home_phone,
 * @param {number} payload.language_id,
 * @param {string} payload.mobile_phone,
 * @param {boolean} payload.all_communications,
 * @param {string} payload.current_password,
 * @param {string} payload.new_password,
 * @param {string} payload.new_password_confirmation,
 * @param {boolean} payload.monthly_communications,
 * @param {boolean} payload.unread_messages,
 * @param {object} payload.contracts,
 * @param {string} payload.resort_name,
 * @param {object} payload.faqs,
 * @param {object} payload.guests,
 * @param {object} payload.country,
 */
export const userInfo = (
    payload
) => ( {
    type: USER_INFO,
    payload: {
        user_id: (payload && payload.user_id) || null,
        vida_member_id: (payload && payload.vida_member_id) || null,
        first_name: (payload && payload.first_name) || null,
        last_name: (payload && payload.last_name) || null,
        email: (payload && payload.email) || null,
        address_line_1: (payload && payload.address_line_1) || null,
        address_line_2: (payload && payload.address_line_2) || null,
        monthly_communications: payload && payload.monthly_communications,
        mobile_phone: (payload && payload.mobile_phone) || null,
        home_phone: (payload && payload.home_phone) || null,
        all_communications: payload && payload.all_communications,
        language_id: (payload && payload.language_id) || 'en',
        contracts: (payload && payload.contracts) || null,
        resort_name: (payload && payload.resort_name) || null,
        faqs: (payload && payload.faqs) || null,
        unread_messages: (payload && payload.unread_messages) || null,
        guests: (payload && payload.guests) || null,
        country: (payload && payload.country) || null,
        payments: (payload && payload.payments) || null,
        affiliation: (payload && payload.affiliation) || null,
        states: (payload && payload.state) || null,
        city: (payload && payload.city) || null,
        postal_code: (payload && payload.postal_code) || null,
    },
} );

export const tokenDetails = (access_token, expires_at, refresh_token) => ( {
    type: TOKEN_DETAILS,
    payload: {
        access_token: access_token || null,
        expires_at: expires_at || null,
        refresh_token: refresh_token || null,
    },
} );

export const userLogout = () => ( {
    type: USER_LOGOUT,
} );

export const shouldRenderPage = () => ({
    type: SHOULD_RENDER_PAGE,
    payload: {}
});

export const shouldNotRenderPage = () => ({
    type: SHOULD_NOT_RENDER_PAGE,
    payload: {}
});
