import React, {Component} from 'react';
import GrandLuxxe from '../../../resources/assets/resortLogos/GrandLuxxe.png';
import MayanPalace from '../../../resources/assets/resortLogos/MayanPalace.png';
import TheBliss from '../../../resources/assets/resortLogos/SeaGarden.png';
import TheGrandBliss from '../../../resources/assets/resortLogos/TheGrandBliss.png';
import TheGrandMayan from '../../../resources/assets/resortLogos/TheGrandMayan.png';
import TheEstates from '../../../resources/assets/resortLogos/TheEstates.png';
import {LOGO_PRIORITIES} from "../../../config/constants/logoPriorities";

class ResortLogo extends Component {
    getLogo() {
        const {contracts} = this.props;
        if (!contracts || !contracts.length) {
            return;
        }

        let image = '';
        LOGO_PRIORITIES.forEach(logo => {
            contracts.forEach(contract => {
                if (image.length) {
                    return;
                }

                let contactReasonNoSpace = contract.resort.replace(/\s/g, "");

                if (contactReasonNoSpace.toLowerCase().search(logo.short_name) > -1) {
                    image = logo.short_name;
                    return;
                }
            });
        });

        switch (image) {
            case LOGO_PRIORITIES[0].short_name:
                return TheEstates;
            case LOGO_PRIORITIES[1].short_name:
                return GrandLuxxe;
            case LOGO_PRIORITIES[2].short_name:
                return TheGrandBliss;
            case LOGO_PRIORITIES[3].short_name:
                return TheGrandMayan;
            case LOGO_PRIORITIES[4].short_name:
                return TheBliss;
            case LOGO_PRIORITIES[5].short_name:
                return MayanPalace;
            default:
                return GrandLuxxe;
        }
    }

    render() {
        return (
            <div>
                <img className="resort-logo" src={this.getLogo()} alt=""/>
            </div>
        );
    }
}

export {ResortLogo}
