import React, {Component} from 'react';
import {Section} from "../../layout/Section";
import call_icon from '../../../resources/assets/call_icon.svg';
import Grid from '@material-ui/core/Grid';
import {FormattedMessage} from 'react-intl';

class ContactFooter extends Component{
    render() {
        return (
            <Section color="grey">
                <div className="contact-footer">
                    <h1><FormattedMessage id="components.account.contact.contact_footer_component.title"/></h1>
                    <p className="text"><FormattedMessage id="components.account.contact.contact_footer_component.sub_title"/></p>
                    <a href="tel:1-800-292-9446">
                        <button className="contact-footer-button" type="button">
                            <img src={call_icon} className="contact-call-icon" alt="contact-call-icon"/>
                            1-800-292-9446
                        </button>
                    </a>
                    <div className="contact-customer-care">
                        <p className="customer-care-text"><FormattedMessage id="components.account.contact.contact_footer_component.costumer_care"/></p>
                        <p className="customer-care-text">-----</p>
                    </div>

                    <div className="contact-program">
                        <Grid container direction="row" alignItems="center" justify="center">
                            <Grid className="contact-grid-item-left" item xs={6}>
                                <p className="contact-program-hours"><FormattedMessage id="components.account.contact.contact_footer_component.costumer_hours_week"/></p>
                                <p className="contact-program-days"><FormattedMessage id="components.account.contact.contact_footer_component.costumer_days_week"/></p>
                            </Grid>
                            <Grid className="contact-grid-item-right" item xs={6}>
                                <p className="contact-program-hours"><FormattedMessage id="components.account.contact.contact_footer_component.costumer_hours_weekend"/></p>
                                <p className="contact-program-days"><FormattedMessage id="components.account.contact.contact_footer_component.costumer_days_weekend"/></p>
                            </Grid>
                        </Grid>
                    </div>

                    <p className="closed-sunday"><FormattedMessage id="components.account.contact.contact_footer_component.costumer_close_days"/></p>
                </div>
            </Section>
        );
    }
}

export {ContactFooter};
