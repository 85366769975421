import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

class Section extends Component {
    render() {
        const { color, className, id } = this.props;
        return <Grid container className={`section bg-${color} ${className}`} justify="center" id={id}>
                    <Grid item xs={12} className={`container ${this.props.containerNoPadding ? 'container-no-padding' : ''}`}>
                        {this.props.children}
                    </Grid>
                </Grid>
    }
}

Section.defaultProps = {
    color: 'black',
    className: '',
    id: '',
}

export { Section }
