import React, { Component } from 'react';
import Page from '../account/layout/Page';
import { ClubFreedomBenefitsHeader } from '../../components/account/clubFreedomBenefits/ClubFreedomBenefitsHeader';
import { ClubFreedomBenefitsSection } from '../../components/account/clubFreedomBenefits/ClubFreedomBenefitsSection';
import { Filters } from "../../components/layout/Navbars/header/Filters";

class ClubFreedomBenefits extends Component {
    render() {
        return <Page class="account clubFreedomBenefits">
            <Filters/>
            <ClubFreedomBenefitsHeader />
            <ClubFreedomBenefitsSection />
        </Page>
    }
}

export { ClubFreedomBenefits };
