import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import { CustomTextarea } from "../../../general/CustomTextarea";
import Grid from '@material-ui/core/Grid';
import { ErrorMessage, Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import {SendMessageSchema} from "../../../../helpers/schemas";
import Send from "../../../../resources/assets/account/messagesCenter/send.svg";
import {reply} from "../../../../api/messages/messages";
import {MESSAGE_STATUSES} from "../../../../config/constants/messages";

class ReplyForm extends Component {

    submit = (data) => {
        const { message } = data;
        const {selectedMessage} = this.props;
        let payload = {
            message: {
                id: selectedMessage.id,
                message: message,
                from: selectedMessage.from,
                from_name: selectedMessage.from_name,
                message_type: selectedMessage.message_type || MESSAGE_STATUSES.SUPPORT
            }
        }

        reply(payload).then(data => {
            if (data && data.status) {
                return;
            }

            this.props.getMessageById();
            this.props.showMessageNotification();
        });
    };

    renderForm = (props) => {
        const { touched, errors, isValid } = props;

        return (
            <Form autoComplete="off">
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10} className="custom-field black">
                        <FormattedMessage id="components.account.readMessages.reply.reply_form.message_placeholder">
                            {message =>
                                <CustomTextarea
                                    name="message"
                                    placeholder={message}
                                    error={errors.message && touched.message}
                                    onChange={e => props.setFieldValue( 'message', e.target.value )}
                                    handleBlur={props.handleBlur}
                                />
                            }
                        </FormattedMessage>
                        <ErrorMessage data-testid="errors-message" name="message" component="div" className="field-error"/>
                    </Grid>
                    <Grid item xs={2} className="send-message-btn-center">
                        <button
                            className="send-message-btn"
                            name="message"
                            type="submit"
                            disabled={! isValid}>
                            <img src={Send} alt="send" />
                        </button>
                    </Grid>
                </Grid>
            </Form>
        );
    };

    render() {
        return (
            <Container maxWidth="md" className="message-area-reply-form">
                <Formik
                    initialValues={{ message: '' }}
                    validationSchema={SendMessageSchema}
                    onSubmit={this.submit}
                    enableReinitialize={true}
                    validateOnBlur={true}
                >
                    {this.renderForm}
                </Formik>
            </Container>
        )
    }
}

export {ReplyForm}
