import {call, put, takeLatest, select} from 'redux-saga/effects';
import {push} from 'connected-react-router'
import {NOTIFICATION_SUCCESS, REQUEST_LOGIN, USER_DETAILS, USER_LOGOUT} from "../../actions/types";
import {logoutUser, faqs} from '../../../api/user';
import {displayNotification} from '../../actions/notification';
import {shouldRenderPage, tokenDetails, userDetails, userInfo} from '../../actions/user';
import {setFaqs} from "../../actions/faqs/faqs";
import {Messages, Routes} from "../../../config";
import {errorCatch} from '../../../api/helper';
import * as User from '../../../api/user';
import {displayGlobalNotification} from "../../actions/globalNotification";
import moment from 'moment';
import {userLocale} from "../../actions/locale";
import {ENGLISH_LOCALE, SPANISH_LOCALE} from "../../../languages";
import {requestGetContactReasons} from "../../actions/contactReasons";
import {getHotels} from "../../../api/hotels/hotels";
import {setHotels} from "../../actions/reservationHotels/hotels";
import {CONTRACT_STATUSES} from "../../../config/constants/contracts";
import {formatGuestsArray} from "../../../helpers/general";

/**
 * Login Saga
 * @param {object} action
 * @returns {Generator<*, void, *>}
 */
function* loginSaga(action) {
    let response;

    //try {
        //response = yield call(User.login, action.payload);
        response = action.payload;

        if (response.data.data.access_token) {
            localStorage.setItem('alerted', true);
            const {access_token, expires_at, refresh_token} = response.data.data;
            yield put(tokenDetails(access_token, expires_at, refresh_token));

            yield put(userDetails());
        }
    // } catch (e) {
    //     yield errorCatch(e, {
    //         400: 'concat',
    //         403: Messages.ACCOUNT_NOT_VERIFIED
    //     });
    // }
}

/**
 *
 * @param {object} action
 * @returns {Generator<*, void, *>}
 */
function* userDetailsSaga(action) {
    let response;

    try {
        response = yield call(User.userDetails);

        if (response.data.data.email) {
            // Filter after active contracts
            if (Array.isArray(response.data.data.contracts) && response.data.data.contracts.length) {
                response.data.data.contracts = response.data.data.contracts.filter(item => item.contract_status === CONTRACT_STATUSES.ACTIVE || item.contract_status === CONTRACT_STATUSES.PENDING || item.contract_status === CONTRACT_STATUSES.BLOCKED);
            }

            response.data.data.guests = formatGuestsArray(response.data.data.guests);

            yield put(userInfo(response.data.data));

            const now = moment();
            const prevState = yield select(state => state);
            const notificationSent = prevState.globalNotification.sent;

            // Check if language was changed
            if (prevState.locale.lang !== response.data.data.language_id) {
                yield put(userLocale(response.data.data.language_id === 'en' ? ENGLISH_LOCALE : SPANISH_LOCALE));
            }

            if (now.diff(moment(response.data.data.updated_at), 'minutes') < 5 &&
                (
                    !notificationSent ||
                    moment(response.data.data.updated_at).isSameOrAfter(moment(notificationSent))
                )
            ) {
                //yield put(displayGlobalNotification(NOTIFICATION_SUCCESS, 'messages.profile_updated', now));
            }

            let faqsResponse = yield call(faqs);
            yield put(setFaqs(faqsResponse.data.data));

            // Get contact reasons on login
            yield put(requestGetContactReasons());

            let hotelsResponse = yield call(getHotels);
            yield put(setHotels(hotelsResponse.data.data));

            yield put(shouldRenderPage());

            if (!action.payload.stopRedirect) {
                // Redirect user
                yield put(push(Routes.ROUTE_DASHBOARD));
            }
        }
    } catch (e) {
        yield errorCatch(e, {
            400: Messages.WRONG_LOGIN_CREDENTIALS,
            401: Messages.UNAUTHENTICATED_ERROR
        });
    }
}

function* logoutUserSaga() {

    try {
        yield call(logoutUser);
    } catch (e) {
        // TODO: we should test to see if we need this catch
    } finally {
        // Clean user Info in State
        yield put(tokenDetails());
        yield put(userInfo());

        // Redirect user
        yield put(push(Routes.ROUTE_HOME));

        // Notify user
        yield put(displayNotification(NOTIFICATION_SUCCESS, Messages.USER_LOGOUT_SUCCESSFULL));
    }
}

export default function* loginUserSaga() {
    yield takeLatest(REQUEST_LOGIN, loginSaga);
    yield takeLatest(USER_DETAILS, userDetailsSaga);
    yield takeLatest(USER_LOGOUT, logoutUserSaga);
}
