import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import VidaVacationsLogo from '../../../resources/assets/account/payments/vida-vacations-white.svg';
import {FormattedMessage} from "react-intl";

class ContractsHeader extends Component {

    render() {
        return (
            <div color="white" className="contracts-header">
                <Grid container className="contracts-header-container" direction="column">
                    <Grid container className="logo-and-title-container">
                        <Grid item className="logo-container">
                            <img className="vida-payments-logo" src={VidaVacationsLogo} alt="vida-payments-logo" />
                        </Grid>
                        <Grid item className="title-container">
                            <h1 className="title-text">
                                <FormattedMessage id="components.account.myVidaVacationsContracts.title" />
                            </h1>
                            <hr className="border" />
                        </Grid>
                    </Grid>

                    <Grid item className="center-text">
                        <p className="welcome-text">
                            <FormattedMessage id="components.account.myVidaVacationsContracts.subtitle" />
                        </p>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export {ContractsHeader}
