import React, { Component, Fragment } from 'react';
import { Section } from '../../layout/Section';
import Grid from '@material-ui/core/Grid';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { SavedCard } from "./SavedCard";
import { SubmitButton } from "../../general/SubmitButton";
import { CardBillingInformation } from "./CardBillingInformation";

import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

import { Spinner } from 'react-bootstrap';

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TextField from '@material-ui/core/TextField';

import { payment } from "../../../api/payment/payment";
import { addParam } from "../../../api/helper";
import { ROUTE_SUCCESS_PAYMENT } from "../../../config/routes";

import { Config } from "../../../config";
import moment from 'moment';

import {history, store} from "../../../store/store";
import { displayGlobalNotification } from "../../../store/actions/globalNotification";

import {NOTIFICATION_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_WARNING, DISPLAY_GLOBAL_NOTIFICATION} from "../../../store/actions/types";


class PaymentDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creditCards: this.props.creditCards,
            selectedCard: this.setDefaultCard(),
            openCvv: false,
            openPayment: false,
            cvv:'',
            initCvv : false
        }
    }

    setDefaultCard = () => {
        let defaultCard;

        if (this.props.creditCards && this.props.creditCards.length > 0) {
            defaultCard = this.props.creditCards.filter(item => item.is_default);

            if ( !defaultCard.length) {
                defaultCard = {
                    id: -1,
                }
            } else {
                defaultCard = defaultCard[0];
            }
        }

        if (typeof this.props.selectCard === "function") {
            // Set default card values on formik(parent)
            this.props.selectCard(defaultCard);
        }

        return defaultCard;
    }

    selectCard = (card) => {
        let creditCards = this.state.creditCards;
        let selectedCard = null;

        creditCards.forEach(item => {
            if (card.id === item.id) {
                selectedCard = item;
            }
        });

        if (typeof this.props.selectCard === "function") {
            // Select card on parent to update formik values
            this.props.selectCard(card, this.props.formikProps);
        }

        this.setState({
            creditCards: creditCards,
            selectedCard: selectedCard
        });
    }

    renderSavedCards = () => {
        return this.props.creditCards.map((card, index) => {
            return <SavedCard key={ index } card={ card } selectCard={ this.selectCard }
                              selectedCard={ this.state.selectedCard }/>
        });
    }

    openModalCvv = () => {
        this.setState({
            openCvv: true,
        });
    }

    openModalPayment = (props) => {

        props.setFieldTouched('bill_to_forename', true, true);
        props.setFieldTouched('bill_to_surname', true, true);
        props.setFieldTouched('bill_to_address_city', true, true);
        props.setFieldTouched('bill_to_address_line1', true, true);
        props.setFieldTouched('bill_to_address_postal_code', true, true);
        props.setFieldTouched('bill_to_address_country', true, true);
        props.setFieldTouched('country', true, true);
        props.setFieldTouched('bill_to_address_state', true, true);
        props.setFieldTouched('state', true, true);
        
        props.validateForm().then((s) => {

            if(
                s.bill_to_forename === undefined && 
                s.bill_to_surname === undefined && 
                s.bill_to_address_line1 === undefined && 
                s.bill_to_address_postal_code === undefined &&
                s.bill_to_address_country === undefined && 
                s.country === undefined && 
                s.bill_to_address_state === undefined &&
                s.state === undefined 
                ) {
                this.setState({
                    openPayment: true
                });
            }
        } );

    }

    handleClose = () => {
        
        this.setState({
            openCvv: false,
            openPayment:false,
            initCvv: false,
            cvv:''
        });
    }

    submit = (props) => {

        if(this.state.cvv.length > 2){
            let post = {
                order_amount : props.formikProps.initialValues.order_amount,
                contract_id :  props.formikProps.initialValues.contract_id,
                card_id : props.formikProps.initialValues.card_id,
                cvv : this.state.cvv,
                paymentTypeIds : props.formikProps.initialValues.paymentTypeIds,
                paymentType : props.formikProps.initialValues.paymentType,
                device_fingerprint_id : props.formikProps.initialValues.device_fingerprint_id,

            }
        
            if(props.formikProps.initialValues.paymentType === "monthly"){
                post.multiplePayment = props.formikProps.initialValues.multiplePayment;
                post.monthlyType = props.formikProps.initialValues.monthlyType;
            };

            payment(post).then(
                response => {
                    if (response && response.status === 201 && response.data && response.data.data && response.data.data.id) {
                        history.push(
                            addParam(ROUTE_SUCCESS_PAYMENT,
                                ':order',
                                response.data.data.id
                            )
                        );
                    }else if(response){
                        store.dispatch(displayGlobalNotification(NOTIFICATION_ERROR, response.data.message));
                    }
            });

        }else{
            this.setState({
                initCvv: true
            });
        }       

    }

    render() {
        
        let { openCvv, openPayment, initCvv, cvv, selectedCard } = this.state;
        let expDate = moment().set({'year': selectedCard.exp_year, 'month': selectedCard.exp_month - 1});

        return <Section color="white">
            <div className="payment-details-container">
                <h3 className="title">
                    <FormattedMessage id="components.account.paymentMethod.paymentDetails.title"/>
                </h3>

                <Grid container>
                    { this.props.creditCards.length > 0 && this.renderSavedCards() }
                </Grid>

                {
                    !this.state.selectedCard || (this.state.selectedCard && this.state.selectedCard.id < 0) ?
                        <CardBillingInformation
                            formikProps={ this.props.formikProps }
                            setNewCardField={this.props.setNewCardField}
                            isPaymentPage={this.props.isPaymentPage}/> : null
                }

                {/* <Grid container alignItems="center" justify="center">
                    <div className="replace-card">
                        <FormattedHTMLMessage id="components.account.paymentMethod.paymentDetails.change_card_text"/>
                    </div>
                </Grid> */}
                {/* <SubmitButton id="components.account.paymentMethod.submit_btn"/> */}

                <br></br>
                <br></br>
                { 
                    !this.state.selectedCard || (this.state.selectedCard && this.state.selectedCard.id < 0) ?

                    Config.APP_ACTIVE_PAYMENTS === "true" && <button type="submit" className="pay submit" onClick={(e) => {
                        e.preventDefault();
                        this.openModalPayment( this.props.formikProps);
                    }}>
                        <FormattedMessage id="components.account.paymentMethod.submit_btn"/>
                        <ChevronRightIcon/>
                    </button> 

                    :  

                    Config.APP_ACTIVE_PAYMENTS === "true" &&  <button type="submit" className="pay submit"  onClick={(e) => {
                        e.preventDefault();
                        this.openModalCvv();
                    }}  style = {{
                        font: 'normal normal 600 20px/36px Source Sans Pro',
                        height: '56px',
                        width: '291px',
                        textTransform:'none',
                        marginBottom: '167px',
                        letterSpacing: '2px',
                    }}>
                        <FormattedMessage id="components.account.paymentMethod.submit_btn"/>
                        <ChevronRightIcon/>
                    </button> 
                   
                }
               
            </div>

            <Modal
                className="add-new-credit-card-modal"
                open={openCvv}
                onClose={() => this.handleClose()}>
                <div className="add-new-credit-card-modal-container">
                    <Grid container alignItems="center" justify="space-between" className="modal-title">
                        <Grid item>
                            <span>
                                <FormattedMessage id="components.account.paymentMethod.cvv_add_message"/>
                            </span>
                        </Grid>
                        <Grid item>
                            <CloseIcon onClick={(e) => {e.preventDefault(); this.handleClose();}}/>
                        </Grid>
                    </Grid>
                    <div className="modal-body with-out-padding">
                   
                        <div style = {{
                            marginBottom: '20px',
                            width: '291px',
                        }}>    
                            <div className="payment-details-container-modal">
                                <Grid item>
                                        <div className={`saved-card-container-modal selected`}>
                                            <div className="saved-card-header-modal">
                                                <div className="img-modal">
                                                    <img src={`/img/payments/${selectedCard.card_type}.svg`} alt=''/>
                                                </div>
                                                <span className="expiration-date-modal">{expDate.format('MM/YYYY')}</span>
                                            </div>
                                            <span className="number-modal">&bull;&bull;&bull;{selectedCard.card_number}</span>
                                            <span className="owner-name-modal">{selectedCard.card_holder_name}</span>
                                        </div>
                                </Grid>
                            </div>
                            { this.state.selectedCard.card_type === "amex" ? <TextField 
                                id="outlined-basic" 
                                variant="outlined"
                                type={'text'}
                                autoComplete='off'
                                fullWidth
                                margin='normal'
                                name={'cvv'}
                                label={'cvv/cvc'}
                                placeholder={'cvv/cvc'}
                                error={(initCvv && cvv === "") || (initCvv && cvv.length < 3)}
                                helperText={(initCvv && cvv === "") || (initCvv && cvv.length < 3) ? <FormattedMessage id="components.account.paymentMethod.cvv_error_message"/> : ''}
                                onChange={(e) => {

                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value === '' || re.test(e.target.value) && e.target.value.length <= 4) {
                                        this.setState({cvv: e.target.value})
                                    }
                                }}
                                onBlur={e =>  this.setState({cvv: e.target.value, initCvv: true})}
                                value={this.state.cvv}
                            /> : 
                            <TextField 
                                id="outlined-basic" 
                                variant="outlined"
                                type={'text'}
                                autoComplete='off'
                                fullWidth
                                margin='normal'
                                name={'cvv'}
                                label={'cvv/cvc'}
                                placeholder={'cvv/cvc'}
                                error={(initCvv && cvv === "") || (initCvv && cvv.length < 2)}
                                helperText={(initCvv && cvv === "") || (initCvv && cvv.length < 2) ? <FormattedMessage id="components.account.paymentMethod.cvv_error_message"/> : ''}
                                onChange={(e) => {

                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value === '' || re.test(e.target.value) && e.target.value.length <= 3) {
                                        this.setState({cvv: e.target.value})
                                    }
                                }}
                                onBlur={e =>  this.setState({cvv: e.target.value, initCvv: true})}
                                value={this.state.cvv}
                            />
                            }
                        </div>
                    
                        <button type="submit" className="submit" onClick={(e) => {
                            e.preventDefault();
                            this.submit(this.props);
                        }} style = {{
                            font: 'normal normal 600 20px/36px Source Sans Pro',
                            height: '56px',
                            width: '291px',
                            textTransform:'none',
                            letterSpacing: '2px',
                        }}>
                            <FormattedMessage id="components.account.paymentMethod.submit_btn_pay"/>
                            <ChevronRightIcon/>
                        </button> 
                    </div>
                </div>
            </Modal>

            <Modal
                className="add-new-credit-card-modal"
                open={openPayment}
                onClose={() => this.handleClose()}>
                <div className="add-new-credit-card-modal-container">
                    <Grid container alignItems="center" justify="space-between" className="modal-title">
                        <Grid item>
                            <span>
                                <FormattedMessage id="components.account.paymentMethod.card_billing_info.bill_title_message"/>
                            </span>
                        </Grid>
                        <Grid item>
                            <CloseIcon onClick={(e) => {e.preventDefault(); this.handleClose();}}/>
                        </Grid>
                    </Grid>
                    <div className="modal-body with-out-padding">
                        <div style = {{ marginBottom: '116px' }}>
                            <FormattedMessage id="components.account.paymentMethod.card_billing_info.bill_body_message"/>
                        </div>

                        <div>

                            <button type="submit" className="submit" form="payment_confirmation" style = {{
                                font: 'normal normal 600 20px/36px Source Sans Pro',
                                height: '56px',
                                width: '291px',
                                textTransform:'none',
                                letterSpacing: '2px',
                            }}>
                                <FormattedMessage id="components.account.paymentMethod.card_billing_info.bill_btn_message"/>
                                <ChevronRightIcon/>
                            </button> 

                        </div>

                        
                    </div>
                </div>
            </Modal>

        </Section>
    }
}

export { PaymentDetails }
