import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {connect} from "react-redux";
import {FormattedMessage} from 'react-intl';
import CustomSelect from '../../../../general/CustomSelect';
import {CustomDatePicker} from '../../../../general/CustomDatePicker';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {Form, Formik} from "formik";
import {history} from "../../../../../store/store";
import {SearchDestinationSchema} from '../../../../../helpers/schemas';

class MobileFiltersComponent extends Component {
    submit = (data) => {
        localStorage.removeItem('reservation');
        history.push({
            pathname: '/request-reservation',
            state: {data: data}
        });
    };

    renderForm = (props) => {
        let {errors, touched} = props;

        return (
            <Form className={`${this.props.isDashboardPage ? 'remove-padding-for-transparent-filters' : ''}`} autoComplete="off">
                <Grid container className="filters-container">
                    <Grid container>
                        {this.props.hotels &&
                        <Grid item xs={8} className="filter-item-container filter-select-destination">
                            <CustomSelect
                                name="destination"
                                label={<FormattedMessage id="components.layout.header.filters.destination"/>}
                                class="header-filter"
                                items={this.props.hotels}
                                textColor="black"
                                onChange={e => props.setFieldValue('destination', e.target.value)}
                                error={errors.destination && touched.destination}
                            />
                        </Grid>}
                        {this.props.userProfile.guests && <Grid item xs={4} className="filter-item-container">
                            <CustomSelect
                                name="guests"
                                class="header-filter"
                                items={this.props.userProfile.guests}
                                label={<FormattedMessage id="components.layout.header.filters.guests"/>}
                                textColor="black"
                                onChange={e => props.setFieldValue('guests', e.target.value)}
                            />
                        </Grid>}
                    </Grid>
                    <Grid container>
                        <Grid item xs={4} className="filter-item-container">
                            <CustomDatePicker
                                isMobile={this.props.isMobile}
                                id="arrivingDate"
                                error={errors.arrivingDate && touched.arrivingDate}
                                class="header-filter"
                                label={<FormattedMessage id="components.layout.header.filters.arriving"/>}
                            onChange={date => props.setFieldValue('arrivingDate', date)}
                            />
                        </Grid>
                        <Grid item xs={4} className="filter-item-container">
                            <CustomDatePicker
                                isMobile={this.props.isMobile}
                                id="departingDate"
                                error={errors.departingDate && touched.departingDate}
                                class="header-filter"
                                label={<FormattedMessage id="components.layout.header.filters.departing"/>}
                            onChange={date => props.setFieldValue('departingDate', date)}
                            />
                        </Grid>
                        <Grid item xs={4} className="filter-item-container">
                            <button className="reserve-btn" type="submit">
                                <FormattedMessage id="components.layout.header.filters.request_btn"/>
                                <ChevronRightIcon/>
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        return <Formik
            initialValues={{destination: '', guests: '', arrivingDate: '', departingDate: ''}}
            validationSchema={SearchDestinationSchema}
            onSubmit={this.submit}
            enableReinitialize={true}
            validateOnBlur={true}
        >
            {this.renderForm}
        </Formik>
    }
}

const mapStateToProps = (state) => ({
    userProfile: state.user.profile,
    hotels: state.hotels.hotels,
})

const MobileFilters = connect(mapStateToProps, null)(MobileFiltersComponent);

export {MobileFilters, MobileFiltersComponent}
