import React, { Component } from 'react';
import { connect } from "react-redux";
import Page from './layout/Page';
import { Filters } from '../../components/layout/Navbars/header/Filters';
import { WelcomeComponent } from '../../components/account/dashboard/WelcomeComponent';
import { ProfileDetails } from '../../components/account/dashboard/ProfileDetails';
import { MoreAbout } from '../../components/account/dashboard/MoreAbout';
import { DestinationHighlights } from '../../components/account/dashboard/DestinationHighlights';
import { StayWithUs } from '../../components/account/dashboard/StayWithUs';

class DashboardComponent extends Component {
    
    render() {

        return <Page class="account dashboard">
            <WelcomeComponent/>
            <Filters isMainPage={true} isDesktopAndAddMargin={true} isDesktopDarkFilter={true} />
            <ProfileDetails userProfile={this.props.userProfile}/>
            <MoreAbout language={this.props.userProfile.language_id}/>
            <DestinationHighlights/>
            <StayWithUs/>
        </Page>
    }
}

const mapStateToProps = (state) => ( {
    userProfile: state.user.profile
} )

const Dashboard = connect( mapStateToProps, null )( DashboardComponent );

export { Dashboard, DashboardComponent };

