import React, {Component} from 'react';
import {Section} from "../../layout/Section";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {ErrorMessage, Form, Formik} from "formik";
import Grid from '@material-ui/core/Grid';
import CustomTextField from "../../general/CustomTextField";
import {SelectMember} from "./SelectMember";
import {reservationRequested} from "../../../api/user";
import {ReservationSummarySchema} from "../../../helpers/schemas";
import {AdditionalTraveler} from "./AdditionalTraveler";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {PrimaryTraveller} from "./PrimaryTraveller";
import {TravelerForm} from "./TravelerForm";
import {Transportation} from "./Transportation";
import {withRouter} from "react-router";
import {getAllCountriesFormatted, getCountryByName} from "../../../helpers/general";
import CustomAutoComplete from "../../general/CustomAutoComplete";
import {Messages} from "../../general/Messages";
import {ThankYouModal} from "./ThankYouModal";
import * as Yup from 'yup';

class OneMoreStepComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMember: this.props.userProfile.vida_member_id !== null,
            travelers: [],
            openThankYouModal: false,
            language: this.props.userProfile.language_id,
        }
    }

    componentDidMount() {
        if (this.props.location.state.data.guests) {
            let newArray = [];
            for (let i = 1; i <= this.props.location.state.data.guests - 1; i++) {
                newArray = [...newArray, {
                    age: 'adult',
                    first_name: '',
                    last_name: '',
                    email: '',
                    isNew: true,
                    show: true
                }];
            }
            this.setState({
                travelers: newArray
            })
        }

    }

    openThankYouModal = () => {
        let header = document.querySelector('.sticky');
        header.style.zIndex = '1298';

        this.setState({
            openThankYouModal: true
        })
    }

    onCancelTraveler = (traveler, index) => {
        if (!traveler.show || traveler.isNew) {
            return
        }

        const {travelers} = this.state;

        if (!traveler.isNew) {
            traveler.show = false;
            travelers[index] = traveler;
            this.setState({travelers});
            return;
        }
    }

    onUpdateTraveler = (index, name, value) => {
        const {travelers} = this.state;

        travelers[index][name] = value;

        this.setState({travelers});
    }

    onSubmitAdditionalTraveler = (index) => {
        const {travelers} = this.state;

        travelers[index].isNew = false;
        travelers[index].show = false;

        this.setState({travelers});
    }

    addNewTraveler = () => {
        if (this.state.travelers.length <= 7) {
            this.setState({
                travelers: [...this.state.travelers, {
                    age: 'adult',
                    first_name: '',
                    last_name: '',
                    email: '',
                    isNew: true,
                    show: true
                }]
            })
        }
    }

    deleteTraveler(index) {
        let {travelers} = this.state;
        travelers[index] = {
            age: 'adult',
            first_name: '',
            last_name: '',
            email: '',
            isNew: true,
            show: true
        }

        this.setState(travelers);
    }

    editTraveler(index) {
        const {travelers} = this.state;
        travelers[index].show = true;
        this.setState({travelers});

        setTimeout(() => {
            let selectedTraveler = document.getElementById(`additional-traveller-${index + 1}`);
            selectedTraveler && selectedTraveler.scrollIntoView();
        }, 100);
    }

    validateAllTravelers = (isMember, data) => {

        let travelerError = false;
        let schemaAdult = Yup.object().shape({
            first_name: Yup.string().required(),
            last_name: Yup.string().required(),
            email: Yup.string().email().required()
        });
        let schemaChild = Yup.object().shape({
            first_name: Yup.string().required(),
            last_name: Yup.string().required(),
        });

        let promises = [];

        this.state.travelers.forEach((traveler, index) => {

            let travelerFormSubmitBtn = document.getElementById(`traveller-form-submit-${index}`);
            if (travelerFormSubmitBtn) {
                travelerFormSubmitBtn.click();
            }


            if(traveler.age === "adult"){
                promises.push(schemaAdult.isValid(traveler).then(valid => {
                    if (!valid) {
                        travelerError = true;
                    }
                }));
            }else{
                promises.push(schemaChild.isValid(traveler).then(valid => {
                    if (!valid) {
                        travelerError = true;
                    }
                }));
            }

        });

        Promise.all(promises).then(() => {
            if (!travelerError) {
                if (isMember) {
                    this.requestReservationIsMember();
                } else {
                    this.requestReservationIsNonMember(data)
                }
            }
        });
    }

    submitForm = (data) => {
        if (!this.props.location.state) {
            return;
        }

        this.validateAllTravelers(false, data);
    }

    requestReservationIsNonMember = (data) => {
        data = {...data, reservation: this.props.location.state.data};
        reservationRequested({
            data: {
                is_member: this.state.isMember,
                country: data.country,
                email: data.email,
                first_name: data.firstName,
                last_name: data.lastName,
                phone: data.phone,
                add_transportation: data.reservation.addTransportation,
                flexible_dates: data.reservation.flexibleDates,
                flexible_destinations: data.reservation.flexibleDestination,
                destination: data.reservation.hotel,
                arriving_date: data.reservation.arrivingDate,
                departing_date: data.reservation.departingDate,
                guests: data.reservation.guests,
                promo_code: data.reservation.promoCode,
                unit_type: data.reservation.unitTypeName,
                travelers: this.state.travelers || null,
            }
        }).then((response) => {
            if (response.status === 201) {
                localStorage.removeItem('reservation'); 
                this.openThankYouModal();
            }
        });
    }

    submitIsMember() {
        if (!this.props.location.state) {
            return;
        }

        this.validateAllTravelers(true);
    }

    requestReservationIsMember = () => {
        const data = this.props.location.state.data;
        reservationRequested({
            data: {
                is_member: this.state.isMember,
                country: this.props.userProfile.country,
                email: this.props.userProfile.email,
                first_name: this.props.userProfile.first_name,
                last_name: this.props.userProfile.last_name,
                phone: this.props.userProfile.mobile_phone ? this.props.userProfile.mobile_phone : this.props.userProfile.home_phone,
                add_transportation: data.addTransportation,
                flexible_dates: data.flexibleDates,
                flexible_destinations: data.flexibleDestination,
                destination: data.hotel,
                arriving_date: data.arrivingDate,
                departing_date: data.departingDate,
                guests: data.guests,
                promo_code: data.promoCode,
                unit_type: data.unitTypeName,
                travelers: this.state.travelers || null,
            }
        }).then((response) => {
            if (response.status === 201) {
                localStorage.removeItem('reservation'); 
                this.openThankYouModal();
            }
        });
    }

    renderAdditionalTravelers(additionalTravelers) {
        return additionalTravelers.map((traveler, index) => {
                if (traveler.isNew) {
                    return '';
                }

                return <AdditionalTraveler
                    key={index}
                    traveler={traveler}
                    id={index}
                    onDelete={() => this.deleteTraveler(index)}
                    onEdit={() => this.editTraveler(index)}/>
            }
        );
    }

    renderForm = (props) => {
        let {errors, touched} = props;
        let {language} = this.state;

        return (
            <Form id="form" autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <div className="custom-field">
                            <FormattedMessage
                                id="globals.first_name">
                                {firstName =>
                                    <CustomTextField
                                        type="text"
                                        name="firstName"
                                        error={errors.firstName && touched.firstName}
                                        class="reservation-summary-form"
                                        label={firstName}
                                        onChange={e => props.setFieldValue('firstName', e.target.value)}
                                        handleBlur={props.handleBlur}
                                        value={props.values.firstName}
                                    />
                                }
                            </FormattedMessage>
                            <ErrorMessage name="firstName" component="div" className="field-error"/>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <div className="custom-field">
                            <FormattedMessage
                                id="globals.last_name">
                                {lastName =>
                                    <CustomTextField
                                        type="text"
                                        name="lastName"
                                        error={errors.lastName && touched.lastName}
                                        class="reservation-summary-form"
                                        label={lastName}
                                        onChange={e => props.setFieldValue('lastName', e.target.value)}
                                        handleBlur={props.handleBlur}
                                        value={props.values.lastName}
                                    />
                                }
                            </FormattedMessage>
                            <ErrorMessage name="lastName" component="div" className="field-error"/>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <div className="custom-field">
                            <FormattedMessage
                                id="globals.email_address">
                                {email =>
                                    <CustomTextField
                                        type="text"
                                        name="email"
                                        error={errors.email && touched.email}
                                        class="reservation-summary-form"
                                        label={email}
                                        onChange={e => props.setFieldValue('email', e.target.value)}
                                        handleBlur={props.handleBlur}
                                        value={props.values.email}
                                    />
                                }
                            </FormattedMessage>
                            <ErrorMessage name="email" component="div" className="field-error"/>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <div className="custom-field">
                            <FormattedMessage
                                id="globals.mobile_phone">
                                {phone =>
                                    <CustomTextField
                                        type="text"
                                        name="phone"
                                        error={errors.phone && touched.phone}
                                        class="reservation-summary-form"
                                        label={phone}
                                        onChange={e => props.setFieldValue('phone', e.target.value)}
                                        handleBlur={props.handleBlur}
                                        value={props.values.phone}
                                    />
                                }
                            </FormattedMessage>
                            <ErrorMessage name="phone" component="div" className="field-error"/>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} className="custom-field">
                        <CustomAutoComplete
                            name="country"
                            items={getAllCountriesFormatted(language)}
                            label={<FormattedMessage id="globals.country"/>}
                            textColor="black"
                            error={errors.country && touched.country}
                            handleBlur={props.handleBlur}
                            class="reservation-summary-form"
                            onChange={newValue => props.setFieldValue('country', newValue ? newValue.name : '')}
                            value={getCountryByName(props.values.country, language)}
                        />
                        <ErrorMessage name="country" component="div" className="field-error"/>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        return (
            <Section color="white">

                {this.props.location.state && this.props.location.state.data.addTransportation && <Transportation/>}
                
                <div className="one-more-step">
                    <hr className="one-more-line"/>
                    <h1><FormattedMessage id="components.account.reservationSummary.oneMoreStep.one_more_step"/></h1>
                </div>
                <div className="one-more-step-form">
                    <PrimaryTraveller name="components.account.reservationSummary.oneMoreStep.primary_traveller"/>

                    <SelectMember
                        value={this.state.isMember}
                        onChange={() => this.setState({isMember: !this.state.isMember})}
                    />

                    <Formik
                        initialValues={
                            {
                                firstName: '',
                                lastName: '',
                                email: '',
                                phone: '',
                                country: '',
                            }
                        }
                        validationSchema={ReservationSummarySchema}
                        validateOnBlur={true}
                        onSubmit={this.submitForm}
                    >
                        {!this.state.isMember && this.renderForm}
                    </Formik>

                    <Grid container alignItems="center" className="travelers-container">
                        <Grid item xs={9} md={10} className="custom-field">
                            <FormattedMessage
                                id="components.account.reservationSummary.oneMoreStep.additional_travelers">
                                {travelers =>
                                    <CustomTextField
                                        type="text"
                                        name="travelers"
                                        class="reservation-summary-form"
                                        label={travelers}
                                        value={this.state.travelers.length}
                                        disabled={true}
                                    />
                                }
                            </FormattedMessage>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" className="addition-traveler-item-container">
                        {this.state.travelers.length > 0 && this.renderAdditionalTravelers(this.state.travelers)}
                    </Grid>

                    {
                        this.state.travelers.map((traveler, index) => (
                            <TravelerForm
                                key={index}
                                number={index}
                                traveler={traveler}
                                onUpdate={this.onUpdateTraveler}
                                onSubmit={this.onSubmitAdditionalTraveler}
                                onCancel={this.onCancelTraveler}
                            />
                        ))
                    }

                </div>
                {!this.state.isMember &&
                <div className="submit-container">
                    <button form="form" className="submit" type="submit">
                        <FormattedMessage id="components.account.reservationSummary.oneMoreStep.confirm_request"/>
                        <ChevronRightIcon/>
                    </button>
                </div>
                }
                {this.state.isMember &&
                <div className="submit-container">
                    <button className="submit" onClick={() => this.submitIsMember()} >
                        <FormattedMessage id="components.account.reservationSummary.oneMoreStep.confirm_request"/>
                        <ChevronRightIcon/>
                    </button>
                </div>

                }
                <Messages/>
                <ThankYouModal open={this.state.openThankYouModal}/>
            </Section>
        );
    }
}

const mapStateToProps = (state) => ({
    userProfile: state.user.profile,
})

const OneMoreStep = withRouter(connect(mapStateToProps, null)(OneMoreStepComponent));

export {OneMoreStep, OneMoreStepComponent};
