import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import  Arrow from 'resources/assets/account/messagesCenter/arrow.svg';
import { FormattedMessage } from 'react-intl';

class ReplyButton extends Component {
    render() {
        return (
            <Container maxWidth="md" className="message-area-reply-button">
                <button onClick={this.props.onClick}>
                    <img src={Arrow} alt="reply" />
                    <FormattedMessage id="components.account.readMessages.reply.reply_button.label"/>
                </button>
            </Container>
        )
    }
}

export {ReplyButton}
