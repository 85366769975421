import React, {Component} from 'react';
import moment from "moment";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CalendarIcon from '../../resources/assets/icons/calendar.svg';
import CalendarErrorIcon from '../../resources/assets/icons/calendar-error.svg';

class CustomDatePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDate: null,
            isOpen: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value) {
            if (nextProps.value !== prevState.selectedDate) {
                return {
                    ...prevState,
                    selectedDate: nextProps.value,
                }
            }
        }

        return prevState;
    }

    changeDate = (date) => {
        this.setState({
            selectedDate: moment(date),
            isOpen: false
        });
    }


    render() {

        const icon = this.props.error ? CalendarErrorIcon : CalendarIcon;

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    className={`date-picker-container ${this.props.class}`}
                    disableToolbar
                    disablePast
                    error={typeof(this.props.error) !== 'object' ? this.props.error : true}
                    variant="inline"
                    autoOk={true}
                    inputVariant={this.props.variant ? this.props.variant : 'filled'}
                    format={this.props.isMobile || this.props.isTablet ? 'dd MMM' : 'dd MMM yyyy'}
                    margin="normal"
                    
                    autoComplete="off"
                    id={this.props.id}
                    label={this.props.label}
                    value={this.state.selectedDate}
                    onChange={date => {
                        this.props.onChange(date);
                        this.changeDate(date)
                    }
                    }
                    open={this.state.isOpen}
                    KeyboardButtonProps={{
                        onFocus: e => {
                            this.setState({
                                isOpen: true
                            });
                        },
                        // 'aria-label': 'change date',
                    }}
                    PopoverProps={{
                        disableRestoreFocus: true,
                        onClose: () => {
                            this.setState({
                                isOpen: false
                            });
                        }
                    }}
                    InputProps={{
                        onFocus: () => {
                            this.setState({
                                isOpen: true
                            });
                        }
                    }}
                    keyboardIcon={ <img src={icon} alt='Calendar' className={`${this.props.error ? 'icon-error' : ''}`}/> }
                />
            </MuiPickersUtilsProvider>
        );
    }
}

export
{
    CustomDatePicker
}
