import { getAxios } from '../axios';
import { ENDPOINT_DESTINATION_HIGHLIGHTS } from '../../config/endpoints';
import {
    addParam,
    errorCatch,
    getTokenHeaders,
} from '../helper';
import { Messages } from '../../config';

/**
 * Get destination highlight list
 * @returns {Promise<T>}
 */
export function getDestinationHighlights() {
    return getAxios(false).get(
        addParam(
            ENDPOINT_DESTINATION_HIGHLIGHTS,
            {
                '{destination_highlight}': ''
            }),
        getTokenHeaders()
    )
        .then(
            data => {
                if (data && data.status !== 200) {
                    return [];
                }

                return data && data.data && data.data.data ? data.data.data : []
            }
        )
        .catch(
            e => {
                errorCatch(
                    e,
                    {
                        400: 'concat',
                        401: Messages.UNAUTHENTICATED_ERROR,
                        403: Messages.UNAUTHORIZED_ERROR,
                        404: Messages.NOT_FOUND_ERROR,
                    }
                );

                return [];
            }
        );
}

/**
 * Get destination highlight data by id
 * @param {object} payload
 * @param {string} payload.destinationHighlightId
 * @returns {Promise<T>}
 */
export function getDestinationHighlightById(payload) {
    return getAxios().get(
        addParam(
            ENDPOINT_DESTINATION_HIGHLIGHTS,
            {
                '{destination_highlight}': payload.destinationHighlightId
            }),
        getTokenHeaders()
    )
        .then(
            data => {
                return data && data.data ? data.data.data : null
            }
        )
        .catch(
            e => {
                errorCatch(
                    e,
                    {
                        400: 'concat',
                        401: Messages.UNAUTHENTICATED_ERROR,
                        403: Messages.UNAUTHORIZED_ERROR,
                        404: Messages.NOT_FOUND_ERROR,
                    }
                );

                return e.response;
            }
        );
}
