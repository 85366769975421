import React, {Component} from 'react';
import Page from './layout/Page';
import {SecondNav} from '../../components/layout/Navbars/header/secondNav/SecondNav';
import {ReadMessageComponent} from "../../components/account/readMessages/ReadMessageComponent";
import {FormattedMessage} from 'react-intl';
import {NOTIFICATION_GET_MESSAGE_BY_ID} from "../../config/notifications";
import {Messages} from "../../components/general/Messages";

class ReadMessage extends Component {

    render() {
        const {id} = this.props.match.params;

        return <Page class="account read-message">
            <SecondNav linkText={
                <FormattedMessage id="pages.account.readMessages.back_to_message_center"/>
            }/>
            <div className="d-flex justify-center mx-auto w-100">
                <Messages notificationId={NOTIFICATION_GET_MESSAGE_BY_ID}/>
            </div>
            <ReadMessageComponent id={id}/>
        </Page>
    }
}

export {ReadMessage}
