import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {Section} from "../../layout/Section";
import Messages from "../../../resources/assets/account/myAccount/messages.svg";
import Payments from "../../../resources/assets/account/myAccount/payments.svg";
import Badge from '@material-ui/core/Badge';
import {FormattedMessage} from 'react-intl';
import {Link} from "react-router-dom";
import {Routes} from '../../../config';

class ProfileNotifications extends Component {
    render() {
        return (
            <Section color="gray" className="profile-notifications">
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >

                    <Grid item xs={6} className="profile-notifier remove-xs-space">
                        <h1 className="profile-notifications-badge">
                            <Badge badgeContent={this.props.userProfile.unread_messages}>
                                <Link to={Routes.ROUTE_MESSAGES_CENTER}>
                                    <img className="profile-notifications-image" src={Messages} alt="messages"/>
                                </Link>
                            </Badge>
                        </h1>
                        <h1 className="profile-notifications-label">
                            <FormattedMessage id="components.account.myAccount.profile_notifications.messages"/>
                        </h1>
                    </Grid>

                    <Grid item xs={6} className="profile-notifier remove-xs-space">
                        <h1 className="profile-notifications-badge">
                            <Badge badgeContent={this.props.userProfile.payments}>
                                <Link to={Routes.ROUTE_MY_VIDA_VACATIONS_PAYMENTS}>
                                    <img className="profile-notifications-image" src={Payments} alt="payments"/>
                                </Link>
                            </Badge>
                        </h1>
                        <h1 className="profile-notifications-label">
                            <FormattedMessage id="components.account.myAccount.profile_notifications.payments"/>
                        </h1>
                    </Grid>
                </Grid>
            </Section>
        );
    }
}

export {ProfileNotifications}
