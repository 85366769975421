import {getAxios} from '../axios';
import {ENDPOINT_CREDIT_CARDS} from '../../config/endpoints';
import {store} from '../../store/store';
import {
    addParam,
    errorCatch,
    getTokenHeaders,
} from '../helper';
import {Messages} from '../../config';
import {displayNotification} from "../../store/actions/notification";
import {NOTIFICATION_SUCCESS} from "../../store/actions/types";
import {NOTIFICATION_CREDIT_CARD_MODAL} from "../../config/notifications";

/**
 * Get contacts list
 * @param {object} payload
 * @param {boolean} payload.disablePagination
 * @param {object} payload.pagination
 * @param {number} payload.pagination.page
 * @param {number} payload.pagination.per_page
 * @param {string} payload.pagination.sort_by
 * @param {string} payload.pagination.sort
 * @param {string} payload.pagination.q
 * @returns {Promise<T>}
 */
export function getCreditCards(payload) {
    if (payload && payload.disablePagination === true) {
        if (payload.pagination)
            payload.pagination.per_page = 1000;
        else {
            payload.pagination = {
                ...payload.pagination,
                per_page: 1000,
            };
        }
    }

    return getAxios(false).get(
        addParam(
            ENDPOINT_CREDIT_CARDS,
            {
                '{credit_card}': ''
            },
            payload.pagination || null),
        getTokenHeaders(payload.pagination || null)
    )
        .then(
            data => {
                return data && data.data ? data.data.data : null
            }
        )
        .catch(
            e => {
                errorCatch(
                    e,
                    {
                        401: Messages.UNAUTHENTICATED_ERROR,
                        403: Messages.UNAUTHORIZED_ERROR,
                        404: Messages.NOT_FOUND_ERROR,
                    }
                );

                return e.response;
            }
        );
}


/**
 * Create a credit card
 * @param {object} payload
 * @param {object} payload.creditCard
 * @param {string} payload.creditCard.card_holder_name
 * @param {string} payload.creditCard.card_number
 * @param {string} payload.creditCard.exp_year
 * @param {string} payload.creditCard.exp_month
 * @param {boolean} payload.creditCard.is_default
 * @param {string} payload.creditCard.card_type
 * @returns {Promise<T>}
 */
export function createCreditCard(payload) {
    return getAxios().post(
        addParam(
            ENDPOINT_CREDIT_CARDS,
            {
                '{credit_card}': ''
            }
        ),
        payload.creditCard,
        getTokenHeaders()
    )
        .then(
            response => {
                if (response && response.data) {
                    store.dispatch(displayNotification(NOTIFICATION_SUCCESS, Messages.CREDIT_CARD_CREATED, NOTIFICATION_CREDIT_CARD_MODAL));
                    return response.data.data;
                }

                return null;
            }
        )
        .catch(
            e => {
                errorCatch(e, {
                    400: 'concat',
                    401: Messages.UNAUTHENTICATED_ERROR,
                    403: Messages.UNAUTHORIZED_ERROR,
                    404: Messages.NOT_FOUND_ERROR,
                }, NOTIFICATION_CREDIT_CARD_MODAL);

                return e.response;
            });
}

/**
 * Edit a credit card
 * @param {object} payload
 * @param {object} payload.creditCard
 * @param {object} payload.creditCard.id
 * @param {string} payload.creditCard.card_holder_name
 * @param {string} payload.creditCard.card_number
 * @param {string} payload.creditCard.exp_year
 * @param {string} payload.creditCard.exp_month
 * @param {boolean} payload.creditCard.is_default
 * @param {string} payload.creditCard.card_type
 * @returns {Promise<T>}
 */
export function updateCreditCard(payload) {
    return getAxios().put(
        addParam(
            ENDPOINT_CREDIT_CARDS,
            {
                '{credit_card}': payload.creditCard.id
            }
        ),
        payload.creditCard,
        getTokenHeaders()
    )
        .then(
            response => {
                if (response && response.data) {
                    store.dispatch(displayNotification(NOTIFICATION_SUCCESS, Messages.CREDIT_CARD_UPDATED, NOTIFICATION_CREDIT_CARD_MODAL));
                    return response.data.data;
                }

                return null;
            }
        )
        .catch(
            e => {
                errorCatch(e, {
                    400: 'concat',
                    401: Messages.UNAUTHENTICATED_ERROR,
                    403: Messages.UNAUTHORIZED_ERROR,
                    404: Messages.NOT_FOUND_ERROR,
                }, NOTIFICATION_CREDIT_CARD_MODAL);

                return e.response;
            });
}
