import { Responsive } from '../config/constants';

export const checkDevice = () => {
    const { innerWidth: width } = window;

    return {
        isMobile: width <= Responsive.MOBILE,
        isTablet: width > Responsive.MOBILE && width <= Responsive.TABLET,
        isDesktop: width > Responsive.TABLET
    }
};


export const getDeviceName = () => {
    const { innerWidth: width } = window;

        if(width <= Responsive.MOBILE) 
            return "mobile";
        if( width > Responsive.MOBILE && width <= Responsive.TABLET) 
            return "tablet";
        if( width > Responsive.TABLET) 
            return "desktop";
    
};

