import { history } from "../store/store";
import {ROUTE_HOME, ROUTE_FORGOT_PASSWORD, ROUTE_MY_ACCOUNT} from "../config/routes";

export const goHome = () => {
    if (history.location.pathname === ROUTE_HOME) return false;

    history.push(ROUTE_HOME);
};

export const goToForgotPassword = () => {
    if (history.location.pathname === ROUTE_FORGOT_PASSWORD) return false;

    history.push(ROUTE_FORGOT_PASSWORD);
};

export const goBack = () => {
    history.goBack();
};

export const goToMyAccount = () => {
    if (history.location.pathname === ROUTE_MY_ACCOUNT) return false;

    history.push(ROUTE_MY_ACCOUNT);
};
