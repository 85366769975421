import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {Section} from "../../layout/Section";
import Pencil from "../../../resources/assets/Pencil.svg";
import Box from '@material-ui/core/Box';
import {FormattedMessage} from 'react-intl';
import {Routes} from '../../../config';
import {history} from '../../../store/store';
import {VidaMemberId} from "../general/VidaMemberId";

class ProfileDetails extends Component {
    render() {
        const {userProfile} = this.props;

        return (
            <Section color="grey12" className="profile-details">
                <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} sm={6} md={4} className="member-no">
                        <VidaMemberId vidaMemberId={userProfile.vida_member_id} align="center"
                                      class="member-no"/>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        item xs={12}
                        spacing={4}
                        className="details-container"
                    >
                        <Grid item xs={12} sm={6} lg={3} className="section-profile">
                            <Box className="box-container-tablet">
                                <p className="label-info">
                                    <img src={`${process.env.PUBLIC_URL}/img/icons/email.png`} style={{width: '18px', height: 'auto', margin: '0 5px 0 0' }}></img>
                                    <FormattedMessage id="components.account.myAccount.profile_details.email"/>
                                </p>
                                <p className="info">{userProfile.email}</p>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} className="section-profile">
                            <Box className="section-profile-info box-container-tablet">
                                <p className="label-info">
                                    <img src={`${process.env.PUBLIC_URL}/img/icons/home-phone.png`} style={{width: '18px', height: 'auto', margin: '0 5px 0 0' }}></img>
                                    <FormattedMessage id="components.account.myAccount.profile_details.home_phone"/>
                                </p>
                                <p className="info">{userProfile.home_phone}</p>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} className="section-profile">
                            <Box className="section-profile-mobile-phone box-container-tablet">
                                <p className="label-info">
                                    <img src={`${process.env.PUBLIC_URL}/img/icons/mobile-phone.png`} style={{width: '11px', height: 'auto', margin: '0 5px 0 0' }}></img>
                                    <FormattedMessage id="components.account.myAccount.profile_details.mobile_phone"/>
                                </p>
                                { userProfile.mobile_phone ?  <p className="info">{userProfile.mobile_phone}</p>: <p className="info">-</p> }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} className="section-profile">
                            <Box className="section-profile-info box-container-tablet">
                                <p className="label-info">
                                    <img src={`${process.env.PUBLIC_URL}/img/icons/address.png`} style={{width: '18px', height: 'auto', margin: '0 5px 0 0'}}></img>
                                    <FormattedMessage id="components.account.myAccount.profile_details.address"/>
                                </p>
                                <p className="info">{`${userProfile.address_line_1 ? userProfile.address_line_1 : ''} ${userProfile.address_line_2 ? userProfile.address_line_2 : ''} `}</p>
                            </Box>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} md={4} className="edit-profile-button">
                        <Button className="edit-button" onClick={() => history.push(Routes.ROUTE_EDIT_PROFILE)}>
                            <img src={Pencil} alt="edit-profile" className="edit-profile-icon"/>
                            <FormattedMessage
                                id="components.account.myAccount.profile_details.edit_profile_button_text"/>
                        </Button>
                    </Grid>
                </Grid>
            </Section>
        );
    }
}

export {ProfileDetails}
