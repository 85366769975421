export const PAYMENT_TYPES = {
    'MONTHLY': 'monthly',
    'OTHER_FEES': 'other_fees',
    'DOWN': 'down',
    'CASH_OUT': 'cash_out',
}

export const FINANCE_TYPES = {
    'MONTHLY': 'Monthly Payments',
    'OTHER_FEES': 'Other fees',
    'DOWN': 'Down Payment',
    'CASH_OUT': 'Cash Out',
}
