import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {FormattedMessage} from 'react-intl';
import {Section} from '../../layout/Section';
import {CardWithVideo} from '../general/CardWithVideo';
import whatIsVPassPort from "./../../../resources/assets/account/dashboard/what_is_v_passport.jpg";
import howItWorks from "./../../../resources/assets/account/dashboard/how_it_works.jpg";

class MoreAbout extends Component {
    render() {
        var { language } = this.props;

        return <Section color="white">
            <Grid container className="more-about">
                <div style={{width: "100%"}}>
                    <div className="title">
                        <FormattedMessage id="components.account.dashboard.more_about.title"/>
                    </div>
                    <hr className="more-about-title-line"/>
                    <div className="sub-title">
                        <FormattedMessage id="components.account.dashboard.more_about.sub_title"/>
                    </div>
                </div>
                <Grid container spacing={2} style={{width: "100%"}}>
                    <Grid item xs={6}>
                        <CardWithVideo
                            title={<FormattedMessage id="components.account.general.card_with_video.what_is"/>}
                            subTitle={<FormattedMessage
                                id="components.account.general.card_with_video.what_is_subtitle"/>}
                            isVideo={false}
                            image={whatIsVPassPort}
                            language={language}
                        >
                        </CardWithVideo>
                    </Grid>

                    <Grid item xs={6}>
                        <CardWithVideo
                            title={<FormattedMessage id="components.account.general.card_with_video.how_it_works"/>}
                            subTitle={<FormattedMessage
                                id="components.account.general.card_with_video.how_it_works_subtitle"/>}
                            isVideo={true}
                            image={howItWorks}
                            language={language}
                        >
                        </CardWithVideo>
                    </Grid>
                </Grid>
            </Grid>
        </Section>
    }
}

export {MoreAbout}
