import {
    REQUEST_GET_CONTACT_REASONS, SET_CONTACT_REASONS
} from './types';

export const requestGetContactReasons = () => ({
    type: REQUEST_GET_CONTACT_REASONS,
});

export const setContactReasons = (payload) => ({
    type: SET_CONTACT_REASONS,
    payload: {
        reasons: payload.reasons
    }
});
