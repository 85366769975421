import { delay, put, takeLatest } from 'redux-saga/effects';
import * as Types from '../../actions/types';
import { Config } from '../../../config';
import {hideGlobalNotification, showGlobalNotification} from "../../actions/globalNotification";

function* displayGlobalNotification(action) {
    yield put( hideGlobalNotification() );
    yield put( showGlobalNotification(
        action.payload.type,
        action.payload.message,
        action.payload.sent,
    ) );

    yield delay( Config.DELAY_NOTIFICATION );
    yield put( hideGlobalNotification() );
}

export default function* displayGlobalNotificationSaga() {
    yield takeLatest( Types.DISPLAY_GLOBAL_NOTIFICATION, displayGlobalNotification );
}
