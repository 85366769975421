import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';

class ContractItem extends Component {

    render() {
        const {contract} = this.props;
        return (
            <div className="contracts-slider remove-xs-space" key={contract.id}>
                <p className="contract-carousel-text contract-number">
                    <FormattedMessage id="components.account.general.carousel.contract"/> {contract.contract_number}
                </p>
                <p className="contract-carousel-text">{contract.resort.substring(0, 20)}</p>
            </div>
        );
    }
}

export {ContractItem}
