import * as types from './types';

export const displayNotification = (type, message, id) => ({
    type: types.DISPLAY_NOTIFICATION,
    payload: {
        type,
        message,
        id
    }
});

export const showNotification = (type, message, notificationId) => ({
    type: types.SHOW_NOTIFICATION,
    payload: {
        type,
        message,
        notificationId
    }
});

export const hideNotification = () => ({
    type: types.HIDE_NOTIFICATION,
});
