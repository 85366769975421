import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

class CustomTextFieldWithIcon extends React.Component {
    render() {

        return <FormControl className={this.props.class}>
            <InputLabel className={this.props.error ? 'Mui-error' : ''}>{this.props.label}</InputLabel>
            <Input
                id={this.props.name}
                type={this.props.type || 'text'}
                autoComplete="off"
                name={this.props.name}
                error={this.props.error}
                value={this.props.value}
                onBlur={e => this.props.handleBlur(e)}
                onChange={(e) => this.props.onChange(e)}
                disabled={this.props.disabled ? this.props.disabled : false}
                endAdornment={
                    <InputAdornment position="end">
                        <img className={this.props.img ? this.props.imgClass : ''} src={this.props.img} alt=''/>
                    </InputAdornment>
                }
            />
        </FormControl>
    }
}

export default CustomTextFieldWithIcon;
