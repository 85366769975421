import {
    SHOW_GLOBAL_NOTIFICATION,
    HIDE_GLOBAL_NOTIFICATION
} from '../actions/types';

const initialState = {
    message: null,
    type: null,
    sent: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_GLOBAL_NOTIFICATION:
            return {
                ...state,
                message: action.payload.message || null,
                type: action.payload.type || null,
                sent: action.payload.sent || null,
            };
        case HIDE_GLOBAL_NOTIFICATION:
            return {
                ...state,
                type: null,
                message: null,
            };
        default:
            return state;
    }
}
