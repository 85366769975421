import {
    INCREMENT_PENDING_REQUESTS,
    DECREMENT_PENDING_REQUESTS
} from "../actions/types";

const initialState = {
    pendingRequests: 0
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INCREMENT_PENDING_REQUESTS:
            return {
                ...state,
                pendingRequests: state.pendingRequests ? state.pendingRequests + 1 : 1,
            };
        case DECREMENT_PENDING_REQUESTS:
            return {
                ...state,
                pendingRequests: state.pendingRequests && state.pendingRequests > 0 ? state.pendingRequests - 1 : 0,
            };
        default:
            return state
    }
}
