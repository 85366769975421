import React, { Component } from 'react';
import Page from './layout/Page';
import YourReservation from "../../components/account/reservationSummary/YourReservation";
import {OneMoreStep} from "../../components/account/reservationSummary/OneMoreStep";
import { FormattedMessage } from 'react-intl';
import { SecondNav } from "../../components/layout/Navbars/header/secondNav/SecondNav";
import {Section} from "../../components/layout/Section";

class ReservationSummary extends Component {
    render() {
        if (!this.props.location.state) {
            return <Section color="black" className="account reservation-summary">
                <div className="text-center">
                    <FormattedMessage id="messages.no_data_available"/>
                </div>
            </Section>
        }

        return <Page class="account reservation-summary">
            <SecondNav
                linkText={
                    <FormattedMessage id="pages.account.reservation_summary.back_link_text"/>
                }
                border={true}
            />
            <YourReservation />
            <OneMoreStep />
        </Page>
    }
}

export {ReservationSummary};
