import React, {Component, Fragment} from 'react';
import CustomTextFieldWithIcon from "../../general/CustomTextFieldWithIcon";
import {FormattedMessage} from 'react-intl';
import {ErrorMessage} from "formik";
import creditCardType, {
    types as CardType,
} from "credit-card-type";
import {CARDS_LOGO} from "../../../config/constants/cards";
import {cardNumberFormat, spacingCardNumber} from "../../../helpers/general";

class CardNumber extends Component {
    constructor(props) {
        super(props);

        this.state = {
            card_logo: '/img/payments/empty-card.svg',
            cardNumber: ''
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.formikProps.values.card_number !== state.cardNumber) {
            let e = {
                target: {
                    value: props.formikProps.values.card_number
                }
            }

            let cardTypes = creditCardType(e.target.value).filter(function (card) {
                return card;
            });

            let cardType = '';
            if (cardTypes.length) {
                props.formikProps.setFieldValue('cardDetails', cardTypes[0]);
                cardType = cardTypes[0].type.toUpperCase();
            }

            state.card_logo=CARDS_LOGO[cardType] ? CARDS_LOGO[cardType] : '/img/payments/empty-card.svg';
            state.cardNumber = props.formikProps.values.card_number;

            // Reset always card number without space, to work correct the format of the card number
            e.target.value = e.target.value ? e.target.value.replace(/ /g, '') : '';

            let cardNumber = cardNumberFormat(e.target.value, CardType[cardType]);
            props.formikProps.setFieldValue('card_number', cardNumber);
        }

        return state;
    }

    getCreditCardType = (e, props, isEdit) => {
        if (!isEdit) {
            let cardTypes = creditCardType(e.target.value).filter(function (card) {
                return card;
            });

            let cardType = '';
            if (cardTypes.length) {
                props.setFieldValue('cardDetails', cardTypes[0]);
                cardType = cardTypes[0].type.toUpperCase();
            }

            this.setState({
                card_logo: CARDS_LOGO[cardType] ? CARDS_LOGO[cardType] : '/img/payments/empty-card.svg'
            });

            // Reset always card number without space, to work correct the format of the card number
            e.target.value = e.target.value.replace(/ /g, '');

            let cardNumber = cardNumberFormat(e.target.value, CardType[cardType]);
            props.setFieldValue('card_number', cardNumber);
        } else {
            // Format card with hidden characters on edit mode
            let number = spacingCardNumber(e.target.value);
            props.setFieldValue('card_number', number);
        }
    }

    render() {
        const {errors, touched} = this.props.formikProps;

        return <Fragment>
            <FormattedMessage id='globals.number'>
                {text =>
                    <CustomTextFieldWithIcon
                        type="text"
                        name="card_number"
                        label={text}
                        error={errors.card_number && touched.card_number}
                        onChange={e =>{
                            this.getCreditCardType(e, this.props.formikProps);
                            if (this.props.isPaymentPage) {
                                this.props.setNewCardField('card_number', e.target.value);
                            }
                        }}
                        handleBlur={this.props.formikProps.handleBlur}
                        value={this.props.formikProps.values.card_number ?? ''}
                        class="card-bill"
                        img={this.state.card_logo}
                        imgClass="card-logo-img"
                    />
                }
            </FormattedMessage>
            <ErrorMessage name={'card_number'} component="div" className="field-error"/>
        </Fragment>
    }
}

export {CardNumber}
