import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { Grid } from '@material-ui/core';
import { Section } from "../../layout/Section";
import { PaymentTypeItem } from './PaymentTypeItem';
import { Form, Formik } from "formik";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { PaymentTypeSchema } from "../../../helpers/schemas";
import { ErrorMessage } from "formik";
import { history } from '../../../store/store';
import { ROUTE_PAYMENT_METHOD } from "../../../config/routes";
import { PAYMENT_TYPES, FINANCE_TYPES } from "../../../config/constants/payment_types";

class PaymentType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPaymentOption: this.props.contract ? this.props.contract.finance : '',
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.selectedPaymentOption && props.contract) {
            if (props.contract.contract_id !== state.selectedPaymentOption.contract_id) {
                state.selectedPaymentOption = props.contract.finance;
            }
        }

        return state;
    }

    // This submit only navigate to a new page with payment type param
    submit = (data) => {
        const { paymentType } = data;

        // Get value from props
        let param = '';
        this.props.paymentTypes.forEach(item => {
            if (item.type === paymentType) {
                param = item.param;
            }
        });

        // If  payment type is monthly for selected contract, change link to prevent crash of the app
        if ( !param) {
            param = this.props.paymentTypes[0].type;
        }

        let link = ROUTE_PAYMENT_METHOD;
        link = link.replace(':type', param);

        history.push({
            pathname: link,
            state: {
                contract: this.props.contract
            }
        });
    };

    onChange = (event, props) => {
        this.setState({
            selectedPaymentOption: event.target.value
        });

        props.setFieldValue('paymentType', event.target.value)
    }

    renderPaymentTypes = () => {
        return this.props.paymentTypes.map((payment, index, type) => {
            return <PaymentTypeItem payment={ payment } contract={ this.props.contract } key={ index }/>
        });
    }

    renderForm = (props) => {
        return <Form autoComplete="off">
            <FormControl component="fieldset" className="options-container">
                <RadioGroup
                    aria-label="payment-type"
                    name="payment-type"
                    value={ props.values.paymentType }
                    onChange={ (e) => this.onChange(e, props) }
                >
                    { this.renderPaymentTypes() }
                </RadioGroup>
            </FormControl>

            <ErrorMessage name="paymentType" component="div" className="field-error"/>

            { this.props.contract.balance > 0 &&
            <Grid item xs={ 12 }>
                <button className="vidanta-submit-button" type="submit">
                    <FormattedMessage id="components.account.myVidaVacationsPayments.PaymentType.make_payment"/>
                    <ChevronRightIcon/>
                </button>
            </Grid>
            }
        </Form>
    }

    render() {
        return (
            <Section color="white" className="payments-type">
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className="payments-container"
                >
                    <Grid item xs={ 12 } sm={ 6 } className="payments-title-container">
                        <div className="payment-type-text">
                            <h3><FormattedMessage
                                id="components.account.myVidaVacationsPayments.PaymentType.payment_type"/></h3>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className="payments-boxes"
                >
                    <Formik
                        initialValues={ {
                            paymentType: this.state.selectedPaymentOption,
                        } }
                        validationSchema={ PaymentTypeSchema }
                        onSubmit={ this.submit }
                        validateOnChange={ true }
                        enableReinitialize={ true }
                    >
                        { this.renderForm }
                    </Formik>
                </Grid>
            </Section>
        )
    }
}

PaymentType.defaultProps = {
    paymentTypes: [
        {
            id: 1,
            type: FINANCE_TYPES.DOWN,
            label: <FormattedMessage id="components.account.myVidaVacationsPayments.paymentTypes.down"/>,
            tooltip_title: <FormattedMessage
                id="components.account.myVidaVacationsPayments.paymentTypes.down_tooltip_title"/>,
            tooltip_description: <FormattedMessage
                id="components.account.myVidaVacationsPayments.paymentTypes.down_tooltip_description"/>,
            value: '1',
            param: PAYMENT_TYPES.DOWN
        },
        {
            id: 2,
            type: FINANCE_TYPES.MONTHLY,
            label: <FormattedMessage id="components.account.myVidaVacationsPayments.paymentTypes.monthly_payment"/>,
            tooltip_title: <FormattedMessage
                id="components.account.myVidaVacationsPayments.paymentTypes.monthly_payment_tooltip_title"/>,
            tooltip_description: <FormattedMessage
                id="components.account.myVidaVacationsPayments.paymentTypes.monthly_payment_tooltip_description"/>,
            value: '2',
            param: PAYMENT_TYPES.MONTHLY
        },
        {
            id: 3,
            type: FINANCE_TYPES.CASH_OUT,
            label: <FormattedMessage id="components.account.myVidaVacationsPayments.paymentTypes.cash_out"/>,
            tooltip_title: <FormattedMessage
                id="components.account.myVidaVacationsPayments.paymentTypes.cash_out_tooltip_title"/>,
            tooltip_description: <FormattedMessage
                id="components.account.myVidaVacationsPayments.paymentTypes.cash_out_tooltip_description"/>,
            value: '3',
            param: PAYMENT_TYPES.CASH_OUT
        },
        {
            id: 4,
            type: FINANCE_TYPES.OTHER_FEES,
            label: <FormattedMessage id="components.account.myVidaVacationsPayments.paymentTypes.other_fees"/>,
            tooltip_title: <FormattedMessage
                id="components.account.myVidaVacationsPayments.paymentTypes.other_fees_tooltip_title"/>,
            tooltip_description: <FormattedMessage
                id="components.account.myVidaVacationsPayments.paymentTypes.other_fees_tooltip_description"/>,
            value: '4',
            param: PAYMENT_TYPES.OTHER_FEES
        }
    ]
}

export { PaymentType }

