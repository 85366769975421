import { getAxios } from '../axios';
import { call } from 'redux-saga/effects';
import {
    ENDPOINT_RESERVATION_HOTELS,
} from '../../config/endpoints';

import { getTokenHeaders } from '../helper';

/**
 * Get Reservation hotels
 * @param payload
 * @returns {Generator<*, *, Generator<*, *, *>>}
 */
export function* getHotels() {
    return yield call(
        getAxios().get, ENDPOINT_RESERVATION_HOTELS, getTokenHeaders() );
}
