import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {ResortLogo} from "../../myAccount/ResortLogo";
import {VidaMemberId} from '../../general/VidaMemberId';
import {MyAccount} from './MyAccount';

class MobileProfileDetails extends Component {
    render() {
        return <Grid container className="profile-details" alignItems="center">
            <Grid item xs={6}>
                <MyAccount userProfile={this.props.userProfile}/>
            </Grid>
            <Grid item xs={6} className="vida-profile-details-id">
                <div>
                    <VidaMemberId class="vida-dashboard" vidaMemberId={this.props.userProfile.vida_member_id}/>
                </div>
                <div className="logo">
                    <ResortLogo contracts={this.props.userProfile.contracts}/>
                </div>
            </Grid>
        </Grid>
    }
}

export {MobileProfileDetails}
