import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { OtherFeesPaymentFormHeaderItem } from "./OtherFeesPaymentFormHeaderItem";
import { FEE_TYPES } from "../../../../config/constants/payments";
import { PAYMENT_TYPES } from "../../../../config/constants/payment_types";

class OtherFeesPaymentFormHeader extends Component {
    constructor(props) {
        super(props);

        let paymentTypes = {};

        if (props.contract &&
            props.contract.payment_types &&
            props.contract.payment_types[PAYMENT_TYPES.OTHER_FEES] &&
            props.contract.payment_types[PAYMENT_TYPES.OTHER_FEES].length
        ) {
            props.contract.payment_types[PAYMENT_TYPES.OTHER_FEES].forEach(item => {
                if ( !paymentTypes[item.fee_type]) {
                    paymentTypes[item.fee_type] = [];
                }

                paymentTypes[item.fee_type].push(item);
            });
        }

        let selectedItem = Object.keys(paymentTypes) && Object.keys(paymentTypes)[0] &&
        paymentTypes[Object.keys(paymentTypes)[0]] ?
            paymentTypes[Object.keys(paymentTypes)[0]] : null;

        const initialStateValues = this.onFeeTypeChange(selectedItem);

        this.state = {
            paymentTypes: paymentTypes,
            selectedItem: selectedItem,
            ...initialStateValues,
        };

        props.setStateOnFeeTypeChange(initialStateValues);
    }

    onFeeTypeChange = (props) => {
        let amountDue = 0.00;
        let totalAmountDue = 0.00;
        let lateFee = 0.00;
        let dueDate = '';
        let paymentTypeIds = [];

        if (props && props.length) {
            props.forEach(
                paymentType => {
                    paymentTypeIds.push(paymentType.id);
                    amountDue += parseFloat(paymentType.amount_due ?? 0);
                    lateFee += parseFloat(paymentType.late_fee ?? 0);

                    if ( !dueDate.length) {
                        dueDate = paymentType.due_date;
                    } else if (moment(dueDate).format('x') > moment(paymentType.due_date).format('x')) {
                        dueDate = paymentType.due_date;
                    }
                });

            totalAmountDue = amountDue + lateFee;
        }

        return {
            amountDue: parseFloat(amountDue.toFixed(2)),
            lateFee: parseFloat(lateFee.toFixed(2)),
            totalAmount: parseFloat(totalAmountDue.toFixed(2)),
            order_amount: parseFloat(totalAmountDue.toFixed(2)),
            dueDate: dueDate,
            paymentTypeIds: paymentTypeIds,
        }
    }

    chooseOtherFeesPaymentType = (type) => {
        if (typeof this.state.paymentTypes[type] !== "object") {
            return;
        }

        const { setStateOnFeeTypeChange } = this.props;

        let selectedItemId = this.state.paymentTypes[type] && this.state.paymentTypes[type].length ?
            this.state.paymentTypes[type][0].id : null;

        this.setState({
            selectedItem: selectedItemId ? this.state.paymentTypes[type] : this.state.selectedItem,
        }, () => {
            setStateOnFeeTypeChange(this.onFeeTypeChange(this.state.paymentTypes[type]));
        });
    }

    displayWarning = paymentType => {
        let displayWarning = false;
        const currentDate = moment();

        const { selectedItem } = this.state;

        if (selectedItem && Array.isArray(selectedItem) && selectedItem.length) {
            this.state.selectedItem.forEach(item => {
                if (item.fee_type === paymentType) {
                    if (currentDate.valueOf() > moment(item.due_date, 'YYYY-MM-DD').valueOf()) {
                        displayWarning = true;
                    }
                }
            });
        }

        return displayWarning;
    }

    render() {
        return <div className="other-fees-payment-form-header">
            <span className="select-one">
                <FormattedMessage id="components.account.paymentMethod.other_fees_payment_form_header.title"/>
            </span>
            <Grid container justify="center" alignItems="center">
                <OtherFeesPaymentFormHeaderItem
                    text="components.account.paymentMethod.other_fees_payment_form_header.usage_fee"
                    type={ FEE_TYPES.USAGE_FEE }
                    chooseOtherFeesPaymentType={ this.chooseOtherFeesPaymentType }
                    warning={ this.displayWarning(FEE_TYPES.USAGE_FEE) }
                    selectedItem={ this.state.selectedItem }
                />
                <OtherFeesPaymentFormHeaderItem
                    text="components.account.paymentMethod.other_fees_payment_form_header.renovation_fee"
                    type={ FEE_TYPES.RENOVATION_FEE }
                    chooseOtherFeesPaymentType={ this.chooseOtherFeesPaymentType }
                    warning={ this.displayWarning(FEE_TYPES.RENOVATION_FEE) }
                    selectedItem={ this.state.selectedItem }
                />
                {/* <OtherFeesPaymentFormHeaderItem
                    text="components.account.paymentMethod.other_fees_payment_form_header.extension_fee"
                    type={ FEE_TYPES.EXTENSION_FEE }
                    chooseOtherFeesPaymentType={ this.chooseOtherFeesPaymentType }
                    warning={ this.displayWarning(FEE_TYPES.EXTENSION_FEE) }
                    selectedItem={ this.state.selectedItem }
                /> */}
                <OtherFeesPaymentFormHeaderItem
                    text="components.account.paymentMethod.other_fees_payment_form_header.renewal_fee"
                    type={ FEE_TYPES.RENEWAL_FEE }
                    chooseOtherFeesPaymentType={ this.chooseOtherFeesPaymentType }
                    warning={ this.displayWarning(FEE_TYPES.RENEWAL_FEE) }
                    selectedItem={ this.state.selectedItem }
                />
            </Grid>
        </div>
    }
}

export { OtherFeesPaymentFormHeader }
