import {
    SHOULD_NOT_RENDER_PAGE,
    SHOULD_RENDER_PAGE,
    TOKEN_DETAILS,
    USER_INFO,
} from '../actions/types';

const initialState = {
    token: {},
    profile: {
        resort_name: '',
        contracts: [],
        guests: [],
    },
    shouldNotRenderPageUntilAllData: false
};

export default function(state = initialState, action) {

    switch(action.type) {
        case TOKEN_DETAILS:
            const { access_token, expires_at, refresh_token } = action.payload;
            return {
                ...state,
                token: {
                    access_token,
                    expires_at,
                    refresh_token
                }
            };
        case USER_INFO:
            const {
                user_id,
                vida_member_id,
                first_name,
                last_name,
                email,
                address_line_1,
                address_line_2,
                mobile_phone,
                home_phone,
                all_communications,
                monthly_communications,
                language_id,
                resort_name,
                contracts,
                faqs,
                unread_messages,
                guests,
                country,
                payments,
                affiliation,
                states,
                city,
                postal_code,
               } = action.payload;
            return {
                ...state,
                profile: {
                    user_id,
                    vida_member_id,
                    first_name,
                    last_name,
                    email,
                    address_line_1,
                    address_line_2,
                    mobile_phone,
                    home_phone,
                    all_communications,
                    monthly_communications,
                    language_id,
                    resort_name,
                    contracts,
                    faqs,
                    unread_messages,
                    guests,
                    country,
                    payments,
                    affiliation,
                    states,
                    city,
                    postal_code,              
                }
            };
        case SHOULD_RENDER_PAGE: {
            return {
                ...state,
                shouldNotRenderPageUntilAllData: true
            }
        }

        case SHOULD_NOT_RENDER_PAGE: {
            return {
                ...state,
                shouldNotRenderPageUntilAllData: false
            }
        }

        default:
            return state;
    }
}
