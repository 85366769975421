import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';
import { MenuContent } from './MenuContent';
import { checkDevice } from '../../../../helpers/checkDevice';

class MenuComponent extends Component {

    constructor(props) {
        super( props );

        this.state = {
            isOpen: false,
            anchorEl: null,
            device: checkDevice(),
        }
    }

    setDevice = () => {
        this.setState( {
            device: checkDevice()
        } );
    }

    componentDidMount() {
        window.addEventListener( 'resize', this.setDevice );
    }

    componentWillUnmount() {
        window.removeEventListener( 'resize', this.setDevice );
    }

    toggleMenu = (event, isOpen) => {
        if (! isOpen) {
            this.setState( {
                anchorEl: event.currentTarget,
                isOpen: ! isOpen
            } );
        } else {
            this.setState( {
                anchorEl: null,
                isOpen: ! isOpen
            } );
        }
    }

    render() {
        let { anchorEl, isOpen, device } = this.state;
        return <Grid container className={`header-menu ${this.state.isOpen ? 'closed' : ''}`} onClick={(e) => this.toggleMenu( e, isOpen )}>
            {! this.state.isOpen && <div onClick={this.openMenu}>
                <MenuIcon className="icon"/>
                <span className="menu-text">
                <FormattedMessage id="components.layout.header.menu"/>
                </span>
            </div>
            }

            {this.state.isOpen && <div className="close-menu-button" onClick={(e) => this.toggleMenu( e, isOpen )}>
                <CloseIcon className="icon"/>
                {(device.isTablet || device.isDesktop)  && <span className="menu-text"><FormattedMessage id="components.layout.header.close"/></span>}
            </div>
            }

            <Menu
                id="header-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean( anchorEl )}
                onClick={(e) => this.toggleMenu( e, isOpen )}
            >
                <MenuContent unreadMessages={this.props.unreadMessages} payments={this.props.payments}/>
            </Menu>
        </Grid>
    }
}

export { MenuComponent }
