import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { Section } from '../../layout/Section';
import { ReservationItem } from './ReservationItem';
import { ReservationItemEmpty } from './ReservationItemEmpty';
import {checkDevice} from '../../../helpers/checkDevice';

class UpcomingReservations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            device: checkDevice(),
        }
    }

    setDevice = () => {
        this.setState({
            device: checkDevice()
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDevice);
    }


    renderReservations = (reservations) => {
        if (! reservations.length) {
            return;
        }

        return reservations.map( (reservation, index) => {
            return <ReservationItem key={index} reservation={reservation} index={index}/>
        } );
    }

    renderEmptyReservations = (reservations) => {
        const { innerWidth: width } = window;

        if (! reservations.length) {
            return;
        }
        
        var empties = [];
        var limitLines = 0;

        if (width > 500 && width < 959) {
            limitLines = 2;
        }
        
        if(width > 959){
            limitLines = 3;
        }

        var len =  reservations.length / limitLines;
        var lenText = len.toString();
        var lines = !(lenText?.indexOf(".") == -1) ?  Math.floor(len) + 1 : Math.floor(len);

        var items = ((lines * limitLines) - reservations.length);

        for(let x = 0; x <= items -1 ; x++){
            empties.push(0);
        }

        return empties.map( (empties, index) => {
            return  <ReservationItemEmpty key={index} uncoming= { true }/> 
        } );

    }

    render() {
        const { reservations } = this.props;
        const { device } = this.state;

        return <Section color="white">
                    {reservations.length > 0 && <div style={{width: "100%"}}>
                        <Section color="white" className ="upcoming-reservations">
                            <p className="upcoming-reservations-title">
                                <FormattedMessage id="components.account.myReservations.upcoming_reservations.title"/>
                            </p>
                            <Grid container direction="row" spacing={1} className="upcoming-reservations-container">
                                { this.renderReservations( reservations ) }
                                { this.renderEmptyReservations( reservations )}
                            </Grid>
                        </Section>
                    </div>}
                </Section>
       
    }
}

export { UpcomingReservations }
