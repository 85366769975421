import { put, takeLatest, select } from "redux-saga/effects";
import { NOTIFICATION_SUCCESS, REQUEST_UPDATE_PROFILE } from "../../actions/types";
import { userDetails, userInfo } from "../../actions/user";
import { displayNotification } from "../../actions/notification";
import { Messages } from "../../../config";
import { errorCatch } from '../../../api/helper';
import { NOTIFICATION_UPDATE_USER_MODAL } from "../../../config/notifications";
import { userLocale } from "../../actions/locale";
import { ENGLISH_LOCALE, SPANISH_LOCALE } from "../../../languages";
import {formatGuestsArray} from "../../../helpers/general";

// isModal is used to display notification with notificationId on modals
function* updateProfileSaga(action) {
    try {
        const response = action.response;

        const prevState = yield select(state => state);
        let data = response.data.data;
        let prevUserProfile = prevState.user.profile;

        if (response.status === 200) {
            let wasChangedOnlyLanguage = false;

            if (data.language_id !== prevState.user.profile.language_id) {
                wasChangedOnlyLanguage = true;
            }

            if (prevUserProfile.address_line_1 !== action.payload.address_line_1) {
                wasChangedOnlyLanguage = false;
            }

            if (prevUserProfile.address_line_2 !== action.payload.address_line_2) {
                wasChangedOnlyLanguage = false;
            }

            if (prevUserProfile.home_phone !== action.payload.home_phone) {
                wasChangedOnlyLanguage = false;
            }

            if (prevUserProfile.mobile_phone !== action.payload.mobile_phone) {
                wasChangedOnlyLanguage = false;
            }

            if (prevUserProfile.monthly_communications !== action.payload.monthly_communications) {
                wasChangedOnlyLanguage = false;
            }

            if (prevUserProfile.email !== action.payload.email) {
                wasChangedOnlyLanguage = false;
            }

            if (prevUserProfile.all_communications !== action.payload.all_communications) {
                wasChangedOnlyLanguage = false;
            }

            // Check if language was changed
            if (prevState.locale.lang !== data.language_id) {
                yield put(userLocale(data.language_id === 'en' ? ENGLISH_LOCALE : SPANISH_LOCALE));
            }

            if (wasChangedOnlyLanguage) {
                yield put(userDetails(true));
            } else {
                yield put(displayNotification(NOTIFICATION_SUCCESS, action.payload.isModal ? Messages.PROFILE_UPDATED : Messages.PROFILE_UPDATED_EMAIL_SENT, action.payload.isModal ? NOTIFICATION_UPDATE_USER_MODAL : null));
            }

            response.data.data.guests = formatGuestsArray(response.data.data.guests);

            yield put(userInfo(response.data.data));
        }
    } catch (e) {

        yield errorCatch(e, {
            400: 'concat'
        }, action.payload.isModal ? NOTIFICATION_UPDATE_USER_MODAL : null)
    }
}

export default function* actionsSaga() {
    yield takeLatest(REQUEST_UPDATE_PROFILE, updateProfileSaga);
}
