import React, { Component } from 'react';
import {Section} from "../../layout/Section";
import { ListContractItem } from "./ListContractItem";

class ContractsListComponent extends Component {

    render() {
        return (
            <Section color="white" className="contracts-list">
                <ul className="list-contract">
                    {this.props.contracts.filter(contract => contract.contract_status !== 'Cancelled').map((contract, key) =>
                        <ListContractItem contract = {contract} index={key} key={key} />
                    )}
                </ul>
            </Section>
        )
    }
}

export { ContractsListComponent }
