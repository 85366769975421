import { getAxios } from '../axios';
import { call } from 'redux-saga/effects';
import { getTokenHeaders } from '../helper';
import {ENDPOINT_CONTACT_REASONS} from "../../config/endpoints";


/**
 * Get contact reasons
 * @param payload
 * @returns {Generator<*, *, Generator<*, *, *>>}
 */
export function* getContactReasonsApi() {
    return yield call( getAxios().get, ENDPOINT_CONTACT_REASONS, getTokenHeaders() );
}

