import moment from 'moment';

export function humanCalculateDiffDate(date) {
    let now = moment( new Date() );
    let diff = moment.duration( -now.diff( moment( date ) ) );

    if (diff._data.months < 0) {
        return moment(date).format( 'DD MMM YYYY' )
    } else {
        return diff.humanize( true );
    }
}


export function showCalendarTime(date) {
    let now = moment( new Date() );
    let diff = moment.duration( -now.diff( moment( date ) ) );

    if (diff._data.months < 0) {
        return moment(date).format( 'DD MMM YYYY' )
    } else {
        return diff.humanize( true );
    }
}
