import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import moment from 'moment';
import 'moment/locale/es';

function mapStateToProps(state) {
    const { lang, messages } = state.locale;
    registerLocale(lang, lang === 'es' ? es : en);
    // Set moment language
    moment.locale(lang);
    return { locale: lang, key: lang, messages };
}

export default connect(mapStateToProps)(IntlProvider);
