import { all } from 'redux-saga/effects';
import loginUserSaga from "./auth/login.saga";
import userSaga from "./user/actions.saga";
import notificationSaga  from './notification/index.saga';
import globalNotificationSaga  from './globalNotification/index.saga';
import contactReasonSaga  from './contactReasons/index.saga';

export default function* rootSaga() {
    yield all([
        loginUserSaga(),
        userSaga(),
        notificationSaga(),
        globalNotificationSaga(),
        contactReasonSaga()
    ]);
}
