import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowFrom from '../../../resources/assets/account/messagesCenter/arrow.svg'
import { humanCalculateDiffDate } from '../../../config/date';
import { history } from '../../../store/store';
import { Routes } from '../../../config';
import { FormattedMessage } from "react-intl";

class MessageItem extends Component {
    navigateToMessage = (message) => {
        let url = Routes.ROUTE_READ_MESSAGE;
        url = url.replace(':id', message.id);

        history.push( url );
    }

    countUnreadMessages = (message) => {
        let unreadMessages = 0;

        if (message.unread_messages.length) {
            unreadMessages = message.unread_messages.length
        } else {
            if (!message.read_at) {
                unreadMessages = 1;
            }
        }
        return unreadMessages;
    }

    wordCount = (str) => {
        let msg = str.split(' ').slice(0,10).join(' ');

        return msg;
    }

    render() {
        const { message } = this.props;

        return <Fragment>
            <div className="messages-item" onClick={() => this.navigateToMessage( message )}>
                <Grid container alignItems="center" justify="space-between">
                    <Grid item xs={9} sm={10} className="message-left-section">
                        <div className="message-icon-container">
                            {this.countUnreadMessages(message) && <div className="title-badge"></div>}
                        </div>
                        <div className="message-title-container">
                            <span className="title">{message.reason}</span>
                        </div>
                    </Grid>
                    <Grid item xs={3} sm={2} className="messages-right-section">
                        <span className="time">{humanCalculateDiffDate( message.date )}</span>
                    </Grid>
                </Grid>

                <Grid container alignItems="center">
                    <Grid item className="message-left-section">
                        <div className="message-icon-container">
                            {message.replied && <img src={ArrowFrom} alt=''/>}
                        </div>
                        <div className="message-from">
                            <span className="label"><FormattedMessage id="globals.from"/>:</span>
                            <span className="text">{message.from_name}</span>
                        </div>
                    </Grid>
                </Grid>

                <Grid container alignItems="center" justify="space-between">
                    <Grid item xs={10} sm={11}>
                        <div className="subject">
                            <span>{this.wordCount(message.message)}</span>
                        </div>
                    </Grid>
                    <Grid item xs={2} sm={1} className="messages-right-section">
                        <div className="nr-notification">{message.nr_of_messages + 1}</div>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    }
}

export { MessageItem }
