import React, {Component} from 'react';
import {connect} from "react-redux";
import Page from './layout/Page';
import {SecondNav} from '../../components/layout/Navbars/header/secondNav/SecondNav';
import {FormattedMessage} from 'react-intl';
import {Routes} from '../../config';
import {Member} from '../../components/account/general/Member';
import {EditProfileForm} from '../../components/account/editProfile/EditProfileForm';
import {EditProfileSchema} from '../../helpers/schemas';
import {Formik} from "formik";
import {Section} from '../../components/layout/Section';
import {Grid} from '@material-ui/core';
import {requestUpdateProfile} from '../../store/actions/user';
import {displayNotification} from "../../store/actions/notification";
import {NOTIFICATION_ERROR} from "../../store/actions/types";
import {NO_PROPERTY_WAS_CHANGED} from "../../config/messages";
import {updateProfile} from "../../api/user";
import {userDetails} from "../../store/actions/user";
import {Filters} from '../../components/layout/Navbars/header/Filters';

class EditProfileComponent extends Component {
    constructor(props) {
        super(props);
        
        this.formRef = React.createRef();
    }

    submit = (data, {...props}) => {
        if (JSON.stringify(data) === JSON.stringify(this.getEditProfileInitialValues())) {
            this.props.displayNotification(NOTIFICATION_ERROR, NO_PROPERTY_WAS_CHANGED);
            return;
        }

        updateProfile(data).then((response) => {
            this.props.requestUpdateProfile(data, response);
        })
    };

    renderForm = (props) => {
        return <EditProfileForm
            userProfile={this.props.userProfile}
            formikProps={props}
        />
    }

    getEditProfileInitialValues = () => {
        const { userProfile } = this.props;


        return {
            email: userProfile.email || '',
            home_phone: userProfile.home_phone || '',
            mobile_phone: userProfile.mobile_phone || '',
            address_line_1: userProfile.address_line_1 || '',
            address_line_2: userProfile.address_line_2 || '',
            language_id: userProfile.language_id || '',
            all_communications: userProfile.all_communications || false,
            monthly_communications: userProfile.monthly_communications || false,

            country: userProfile.country || '',
            state: userProfile.states || '',

            city: userProfile.city || '',
            postal_code: userProfile.postal_code || '',

        }
    }

    render() {
        const {userProfile} = this.props;

        return <Page class="account edit-profile">
            <Filters />
            <SecondNav
                linkText={
                    <FormattedMessage id="pages.account.edit_profile.back_link_text"/>
                }
                link={Routes.ROUTE_MY_ACCOUNT}
                showSaveBtn={true}
                border={true}
                class="edit-profile"
                saveAction={() => this.formRef.submitForm()}
            />
            <Member userProfile={userProfile} class="my-account"/>

            <Section color="grey" className="edit-profile-form" id="profile-edit-form">
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={12} sm={9} md={6}>
                        <div className="title">
                            <FormattedMessage id="components.account.editProfile.title"/>
                        </div>
                        <div className="sub-title">
                            <FormattedMessage id="components.account.editProfile.sub_title"/>
                        </div>
                        <Formik
                            initialValues={this.getEditProfileInitialValues()}
                            validationSchema={EditProfileSchema}
                            onSubmit={this.submit}
                            validateOnChange={true}
                            enableReinitialize={true}
                            innerRef={ref => (this.formRef = ref)}
                        >
                            {this.renderForm}
                        </Formik>
                    </Grid>
                </Grid>
            </Section>
        </Page>
    }
}

const mapStateToProps = (state) => ({
    userProfile: state.user.profile
})

const EditProfile = connect(mapStateToProps, {
    userDetails,
    displayNotification,
    requestUpdateProfile
})(EditProfileComponent);

export {EditProfile, EditProfileComponent};
