import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from '@material-ui/core/Grid';

export default function HeaderAcoordion(props) {

  const [expand, setExpand] = React.useState(false);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  return (
    <Accordion onClick={(event) => event.stopPropagation()}  onFocus={(event) => event.stopPropagation()} className="header-accordion" expanded={expand} style={{ boxShadow: "none", background: "#000000" }}>
        <AccordionSummary className="header-menu-item-accordion-summary" expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}  IconButtonProps={{
            onClick: toggleAcordion
          }} style={{ color: "#FFFFFF" }}> { props.props.userProfile.language_id.toUpperCase() } </AccordionSummary>
            <AccordionDetails className="header-menu-item-accordion-details">
                <Grid
                    container
                    direction="row"
                    justifycontent="space-between"
                    alignItems="center"
                    item xs={12}
                    spacing={0}>
                        {props.props.userProfile.language_id == 'es' ? 
                        <Grid container alignItems="center" style={{ color: "#FFFFFF", cursor: "pointer" }}
                            onClick={(e) => { setExpand((prev) => !prev); props.submit("en"); }}>
                            EN
                        </Grid>
                        :
                        <Grid container alignItems="center" style={{ color: "#FFFFFF", cursor: "pointer" }}
                            onClick={(e) => { setExpand((prev) => !prev); props.submit("es"); }}>
                            ES
                        </Grid>}
                </Grid>
            </AccordionDetails>
    </Accordion>
  );
}