import React, {Component} from 'react';
import Page from './layout/Page';
import {MessagesCenterHeader} from '../../components/account/messagesCenter/MessagesCenterHeader';
import {MessagesList} from '../../components/account/messagesCenter/MessagesList';
import {getMessages} from "../../api/messages/messages";
import {Messages} from "../../components/general/Messages";
import {NOTIFICATION_GET_MESSAGES} from "../../config/notifications";

class MessagesCenter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messages: []
        }
    }

    componentDidMount() {
        this.getMessages();
    }

    getMessages = () => {
        getMessages().then(data => {
            this.setState({
                messages: data
            });
        });
    }

    setAllMessagesRead = (data) => {
        this.setState({
            messages: data
        });
    }

    render() {
        const {messages} = this.state;

        return <Page class="account messages-center">
            <div className="d-flex justify-center mx-auto w-100">
                <Messages notificationId={NOTIFICATION_GET_MESSAGES}/>
            </div>
            <MessagesCenterHeader setAllMessagesRead={this.setAllMessagesRead} messages={messages}/>
            <MessagesList messages={messages}/>
        </Page>
    }
}

export {MessagesCenter};

