import React, {Component, Fragment} from 'react';
import TransportationImage from '../../../resources/assets/account/requestReservation/transportation.jpg';
import {FormattedMessage} from "react-intl";

class Transportation extends Component {
    render() {
        return (
            <Fragment>
                <div className="transportation-title-container">
                    <h1 className="transportation-title">
                        <FormattedMessage id="components.account.reservationSummary.oneMoreStep.you_have_added" />
                    </h1>
                </div>
                <div className="transportation-container">
                    <div className="transportation-image-container">
                        <img className="transportation-image" src={TransportationImage} alt="" />
                    </div>
                    <div className="transportation-info-container">
                        <div className="transportation-info">
                            <p className="transportation-subtitle">
                                <FormattedMessage id="components.account.reservationSummary.oneMoreStep.transportation" />
                            </p>
                            <p className="transportation-text">
                                <FormattedMessage id="components.account.reservationSummary.oneMoreStep.transportation_text" />
                            </p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export { Transportation };
