import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { getParamFromUrl } from "../../api/helper";
import { activateAccount } from '../../api/user';
import { Messages, Routes } from '../../config';
import { Loader } from '../../components/general';
import { history } from '../../store/store';
import { displayNotification } from '../../store/actions/notification';
import { NOTIFICATION_ERROR } from '../../store/actions/types';

class VerifyEmailComponent extends React.Component {
    componentDidMount() {
        let { id, token } = this.props.match.params;

        let expires = getParamFromUrl( 'expires', { location: this.props.location } );
        let signature = getParamFromUrl( 'signature', { location: this.props.location } );

        activateAccount( {
            id: id,
            token: token,
            data: {
                expires: expires,
                signature: signature
            }
        } ).then( response => {
            if (response.status === 200) {
                history.push( Routes.ROUTE_VERIFY_EMAIL_SUCCESS );
            } else {
                this.props.displayNotification(NOTIFICATION_ERROR, Messages.VERIFICATION_CODE_INVALID);
                history.push( Routes.ROUTE_RESEND_EMAIL_VERIFICATION );
            }
        } );
    }

    render() {
        return <Fragment>
            loading !== 0 ? <Loader/> : null
        </Fragment>

    }
};

const mapStateToProps = state => ( {
    loading: state.loading.pendingRequests
} );

const VerifyEmail = connect( mapStateToProps, {displayNotification} )( withRouter( VerifyEmailComponent ) );

export {VerifyEmail, VerifyEmailComponent}
