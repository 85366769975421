import React, { Component } from 'react';
import Page from './layout/Page';
import { RequestReservationForm } from '../../components/account/requestReservation/RequestReservationForm';

class RequestReservation extends Component {
    render() {

        return <Page class="account request-reservation">
            <RequestReservationForm/>
        </Page>
    }
}

export { RequestReservation };

