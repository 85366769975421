import * as types from './types';

export const displayGlobalNotification = (type, message, sent) => ({
    type: types.DISPLAY_GLOBAL_NOTIFICATION,
    payload: {
        type,
        message,
        sent
    }
});

export const showGlobalNotification = (type, message, sent) => ({
    type: types.SHOW_GLOBAL_NOTIFICATION,
    payload: {
        type,
        message,
        sent
    }
});

export const hideGlobalNotification = () => ({
    type: types.HIDE_GLOBAL_NOTIFICATION,
});
