import React, {Component} from 'react';
import {Section} from "../../layout/Section";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {FormattedMessage} from "react-intl";

class ClubFreedomBenefitsSection extends Component {
    render() {
        return (

            <div style={{width: '100%'}}>
                <Section color="grey">
                <div className="club-section">
                        {<img className="logo" src={`${process.env.PUBLIC_URL}/img/destinations/Club-Freedom.png`} alt=""/> }
                </div>
                </Section>
                <Section color="white">
                <div className="club-section">
                    <p><FormattedMessage
                        id="components.account.club_freedom_benefits.club_freedom_benefits_section.title"/></p>
                    <button className="club-read-more">
                        <a href="https://goclubfreedom.com" target="_blank" rel="noopener noreferrer">
                            <FormattedMessage id="components.account.club_freedom_benefits.club_freedom_benefits_section.btn"/>
                            <ChevronRightIcon/>
                        </a>
                    </button>
                </div>
                </Section>
            </div>
        )
    }
}

export {ClubFreedomBenefitsSection}
