import React, {Component, Fragment} from "react";
import {Formik, ErrorMessage, Form} from "formik";
import {SelectTraveler} from "./SelectTraveler";
import CustomTextField from "../../general/CustomTextField";
import {FormattedMessage} from "react-intl";
import Grid from '@material-ui/core/Grid';
import {FormTitle} from "./FormTitle";
import {AdditionalTravelerSchema} from "../../../helpers/schemas";

class TravelerForm extends Component {

    updateField = (props, name, value) => {
        this.props.onUpdate(this.props.number, name, value);
        props.setFieldValue(name, value);
    }

    renderForm = (props) => {
        if (!this.props.traveler.show) {
            return null;
        }

        let {errors, touched} = props;

        return (
            <div id={`additional-traveller-${this.props.number + 1}`}>
                <FormTitle number={this.props.number + 1}/>
                <Form autoComplete="off">
                    <SelectTraveler
                        value={props.values.age}
                        onChange={e => this.updateField(props, 'age', e.target.value)}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <div className="custom-field">
                                <FormattedMessage
                                    id="globals.first_name">
                                    {firstName =>
                                        <CustomTextField
                                            type="text"
                                            name="first_name"
                                            error={errors.first_name && touched.first_name}
                                            class="reservation-summary-form"
                                            label={firstName}
                                            onChange={e => this.updateField(props, 'first_name', e.target.value)}
                                            handleBlur={props.handleBlur}
                                            value={props.values.first_name || ''}
                                        />
                                    }
                                </FormattedMessage>
                                <ErrorMessage name="first_name" component="div" className="field-error"/>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <div className="custom-field">
                                <FormattedMessage
                                    id="globals.last_name">
                                    {lastName =>
                                        <CustomTextField
                                            type="text"
                                            name="last_name"
                                            error={errors.last_name && touched.last_name}
                                            class="reservation-summary-form"
                                            label={lastName}
                                            onChange={e => this.updateField(props, 'last_name', e.target.value)}
                                            handleBlur={props.handleBlur}
                                            value={props.values.last_name || ''}
                                        />
                                    }
                                </FormattedMessage>
                                <ErrorMessage name="last_name" component="div" className="field-error"/>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <div className="custom-field">
                                <FormattedMessage
                                    id="globals.email_address">
                                    {email =>
                                        <CustomTextField
                                            type="text"
                                            name="email"
                                            error={errors.email && touched.email}
                                            class="reservation-summary-form"
                                            label={email}
                                            onChange={e => this.updateField(props, 'email', e.target.value)}
                                            handleBlur={props.handleBlur}
                                            value={props.values.email || ''}
                                        />
                                    }
                                </FormattedMessage>
                                <ErrorMessage name="email" component="div" className="field-error"/>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" className="additional-button-container">
                        <Grid item xs={5} sm={6}>
                            <button
                                className="cancel-button"
                                onClick={() => this.props.onCancel(this.props.traveler, this.props.number)}
                                type="button"
                            >
                                <FormattedMessage id="components.account.reservationSummary.oneMoreStep.cancel"/>
                            </button>
                        </Grid>

                        <Grid item xs={7} sm={6}>
                            <button id={`traveller-form-submit-${this.props.number}`} className="add-save-button"
                                    type="submit">
                                <FormattedMessage
                                    id="components.account.reservationSummary.oneMoreStep.save_add_traveler"/>
                            </button>
                        </Grid>
                    </Grid>
                </Form>
            </div>
        )
    }

    render() {
        return <Fragment>
            <Formik
                initialValues={
                    {
                        index: this.props.number,
                        age: this.props.traveler.age,
                        first_name: this.props.traveler.first_name,
                        last_name: this.props.traveler.last_name,
                        email: this.props.traveler.email,
                    }
                }
                validationSchema={AdditionalTravelerSchema}
                enableReinitialize={true}
                validateOnBlur={true}
                onSubmit={() => this.props.onSubmit(this.props.number)}
            >
                {this.renderForm}
            </Formik>
        </Fragment>
    }
};

export {TravelerForm}
