import React, { Component } from 'react';
import { Section } from "../../../layout/Section";
import Slider from "react-slick";
import { ContractItem } from "./ContractItem";
import { Responsive } from "../../../../config/constants";
import { FormattedMessage } from 'react-intl';

class ContractCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = { contracts: [] };
    }

    setContractsState = (contracts) => {
        this.setState({
                contracts: contracts || []
            },
            () => {
                if (typeof this.props.onChange === 'function') {
                    this.props.onChange(contracts)
                }
            }
        );
    }

    componentDidMount() {
        this.setContractsState(this.props.contracts && this.props.contracts[0]);
        window.addEventListener('resize', this.setSliderArrowPosition);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setSliderArrowPosition);
    }

    afterChangeHandler = (index) => {
        if ( !this.props.contracts) {
            return;
        }

        this.props.contracts.map((contract, key) => {
            return key === index ? this.setContractsState(contract) : ''
        });
    };

    setSliderArrowPosition = () => {
        let currentElement = document.querySelector('.slick-current');
        let slickPrev = document.querySelector('.slick-prev');
        let slickNext = document.querySelector('.slick-next');
        let slider = document.querySelector('.slick-list');

        setTimeout(() => {
            if (currentElement && slickPrev && slickNext && slider) {
                let leftPosition = currentElement.getBoundingClientRect().left - slider.getBoundingClientRect().left - 12;
                let rightPosition = slider.getBoundingClientRect().right - currentElement.getBoundingClientRect().right - 12;
                slickPrev.style.left = `${ leftPosition }px`;
                slickNext.style.right = `${ rightPosition }px`;

                if (this.props.device.isMobile) {
                    slickNext.style.top = '42px';
                    slickPrev.style.top = '42px';
                }

                if (this.props.device.isTablet) {
                    slickNext.style.top = '47px';
                    slickPrev.style.top = '47px';
                }

                if (this.props.device.isDesktop) {
                    slickNext.style.top = '47px';
                    slickPrev.style.top = '47px';
                }
            }
        }, 200);
    }

    render() {
        this.setSliderArrowPosition();

        const settings = {
            className: "center",
            centerMode: true,
            infinite: true,
            arrows: true,
            slidesToShow: 3,
            centerPadding: 0,
            speed: 500,
            responsive: [
                {
                    breakpoint: Responsive.MOBILE,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: "60px",
                    }
                },
            ],
        };

        return (
            <Section color="bg-dark-grey" className="contract-carousel">
                {
                    !this.props.contracts.length &&
                    <div className="no-contracts-available">
                        <span><FormattedMessage id="components.account.myAccount.contract.no_items"/></span>
                    </div>
                }

                <Slider { ...settings } afterChange={ this.afterChangeHandler }>
                    {
                        this.props.contracts && this.props.contracts.map(
                            (contract, key) =>
                                <ContractItem contract={ contract } key={ key }/>
                        )
                    }
                </Slider>
            </Section>
        );
    }
}

ContractCarousel.defaultProps = {
    onChange: null
};

export { ContractCarousel }
