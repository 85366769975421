import React, { Fragment } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { ResetPasswordSchema } from "../../helpers/schemas";
import { FormattedMessage } from "react-intl";
import CustomPasswordField from '../general/CustomPasswordField';
import CustomTextField from '../general/CustomTextField';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Grid from '@material-ui/core/Grid';
import { resetPassword } from '../../api/user';
import { Messages } from '../general/Messages';
import { ROUTE_RESET_PASSWORD_SUCCESS } from '../../config/routes';
import { history } from '../../store/store';

class ResetPassword extends React.Component {

    submit = (data) => {
        const { password, password_confirmation, verification_code } = data;
        resetPassword( {
            token: this.props.token,
            data: {
                verification_code: verification_code,
                password: password,
                password_confirmation: password_confirmation
            }
        } ).then( response => {
            if (response.status === 204) {
                history.push( ROUTE_RESET_PASSWORD_SUCCESS );
            }
        } )
    };

    renderForm = (props) => {
        const { touched, errors, isValid } = props;

        return (
            <Form className="reset-form" autoComplete="off">
                <Grid container direction="column">
                    <Grid item className="custom-field">
                        <FormattedMessage id="reset.verification_code">
                            {verification_code =>
                                <CustomTextField
                                    name="verification_code"
                                    label={verification_code}
                                    error={errors.verification_code && touched.verification_code}
                                    onChange={e => props.setFieldValue( 'verification_code', e.target.value )}
                                    handleBlur={props.handleBlur}
                                />
                            }
                        </FormattedMessage>
                        <ErrorMessage name="verification_code" component="div" className="field-error"/>
                    </Grid>
                    <Grid item className="custom-field">
                        <FormattedMessage id="globals.password">
                            {password =>
                                <CustomPasswordField
                                    name="password"
                                    label={password}
                                    error={errors.password && touched.password}
                                    onChange={e => props.setFieldValue( 'password', e.target.value )}
                                    handleBlur={props.handleBlur}
                                />
                            }
                        </FormattedMessage>
                        <ErrorMessage name="password" component="div" className="field-error"/>
                    </Grid>
                    <Grid item className="custom-field">
                        <FormattedMessage id="globals.confirm_password">
                            {passwordConfirmation =>
                                <CustomPasswordField
                                    name="password_confirmation"
                                    label={passwordConfirmation}
                                    error={errors.password_confirmation && touched.password_confirmation}
                                    onChange={e => props.setFieldValue( 'password_confirmation', e.target.value )}
                                    handleBlur={props.handleBlur}
                                />
                            }
                        </FormattedMessage>
                        <ErrorMessage name="password_confirmation" component="div" className="field-error"/>
                    </Grid>
                    <Grid container alignItems="center">
                        <button
                            name="resetPassword"
                            type="submit"
                            disabled={! isValid}>
                            <FormattedMessage id="globals.submit" />
                            <span><ChevronRightIcon fontSize="small"/></span>
                        </button>
                    </Grid>
                </Grid>
            </Form>
        );
    };

    render() {
        return (
            <Fragment>
                <Formik
                    initialValues={{ password: '', password_confirmation: '', verification_code: '' }}
                    validationSchema={ResetPasswordSchema}
                    onSubmit={this.submit}
                    enableReinitialize={true}
                >
                    {this.renderForm}
                </Formik>
                <Messages/>
            </Fragment>
        )
    }
};

export { ResetPassword }
