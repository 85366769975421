import React, {Component} from 'react';
import {Section} from '../../layout/Section';
import {connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import {FormattedMessage} from 'react-intl';
import {CustomDatePicker} from '../../general/CustomDatePicker';
import {ErrorMessage, Form, Formik} from "formik";
import moment from "moment";
import CustomTextField from '../../general/CustomTextField';
import AddCircle from '../../../resources/assets/account/requestReservation/add-circle.svg';
import SubstractCircle from '../../../resources/assets/account/requestReservation/substract-circle.svg';
import {SubmitButton} from '../../general/SubmitButton';
import {Toggle} from '../../general';
import {RequestReservationSchema} from '../../../helpers/schemas';
import {withRouter} from "react-router";
import CustomSelect from "../../general/CustomSelect";
import {ROUTE_RESERVATION_SUMMARY} from "../../../config/routes";

class RequestReservationFormComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unitTypeName: '',
        };

    }

    submit = (data) => {
        // Change local moment lang, for api validation
        let enArrivingData = moment(data.arrivingDate);
        let enDepartingData = moment(data.departingDate);

        data.hotel = this.getSelectedHotel(data.destination).name;
        data.arrivingDate = enArrivingData.locale('en').format('ll');
        data.departingDate = enDepartingData.locale('en').format('ll');

        localStorage.setItem('reservation', JSON.stringify(data));
        
        this.props.history.push({
            pathname: ROUTE_RESERVATION_SUMMARY,
            state: {data: data}
        })
    };

    modifyGuests = (type, props) => {
        let guests = props.values.guests;
        if (guests === 1 && type === 'substract') {
            return;
        }

        if (type === 'add') {
            guests++;
        } else {
            guests--;
        }

        props.setFieldValue('guests', guests);
    }

    getSelectedHotel = hotelId => {
        let selectedHotel;

        selectedHotel = this.props.hotels.filter(item => item.id === hotelId);

        return selectedHotel[0];
    }

    renderForm = (props) => {
        let {errors, touched} = props;
        let items = [];

        if (props.values.destination) {
            items = this.getSelectedHotel(props.values.destination).units;
        }
        
        return (
            <Form autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <CustomSelect
                            name="destination"
                            label={<FormattedMessage
                                id="components.account.requestReservation.requestReservationForm.select_destination"/>}
                            items={this.props.hotels}
                            textColor="black"
                            class="request-reservation"
                            error={errors.destination && touched.destination}
                            onChange={e => {
                                props.setFieldValue('unitType', '');
                                this.setState({
                                    unitTypeName: ''
                                });

                                setTimeout(() => {
                                    props.setFieldValue('destination', e.target.value);
                                    this.getSelectedHotel(e.target.value);
                                });
                            }}
                            value={props.values.destination ? props.values.destination : this.props.location.state ? this.props.location.state.data.destination : ''}
                        />
                        <ErrorMessage name="destination" component="div" className="field-error mt-2"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Grid container alignItems="center" className="guests-container">
                            <Grid item xs={9} md={10} className="custom-field">
                                <FormattedMessage
                                    id="components.account.requestReservation.requestReservationForm.guests">
                                    {guests_text =>
                                        <CustomTextField
                                            type="text"
                                            name="guests"
                                            class="request-reservation"
                                            label={guests_text}
                                            onChange={e => props.setFieldValue('guests', e.target.value)}
                                            handleBlur={props.handleBlur}
                                            value={props.values.guests}
                                            disabled={true}
                                        />
                                    }
                                </FormattedMessage>
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <img
                                    className="substract"
                                    src={SubstractCircle}
                                    alt=''
                                    onClick={() => this.modifyGuests('substract', props)}
                                />
                                <img
                                    className="add"
                                    src={AddCircle}
                                    alt=''
                                    onClick={() => this.modifyGuests('add', props)}
                                />
                            </Grid>
                        </Grid>
                        <ErrorMessage name="guests" component="div" className="field-error mt-2"/>
                    </Grid>

                    <Grid item xs={6}>
                        <CustomDatePicker
                            isTablet={this.props.isTablet}
                            class="request-reservation"
                            value={props.values.arrivingDate ? props.values.arrivingDate : this.props.location.state ? this.props.location.state.data.arrivingDate : ''}
                            error={errors.arrivingDate && touched.arrivingDate}
                            label={<FormattedMessage
                            id="components.account.requestReservation.requestReservationForm.arriving"/>}
                        onChange={date => props.setFieldValue('arrivingDate', date)}

                        />
                        <ErrorMessage name="arrivingDate" component="div" className="field-error mt-2"/>
                    </Grid>

                    <Grid item xs={6}>
                        <CustomDatePicker
                            isTablet={this.props.isTablet}
                            class="request-reservation"
                            value={props.values.departingDate ? props.values.departingDate : this.props.location.state ? this.props.location.state.data.departingDate : ''}
                            error={errors.departingDate && touched.departingDate}
                            label={<FormattedMessage
                            id="components.account.requestReservation.requestReservationForm.departing"/>}
                        onChange={date => props.setFieldValue('departingDate', date)}
                        />
                        <ErrorMessage name="departingDate" component="div" className="field-error mt-2"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <CustomSelect
                            name="unitType"
                            items={items}
                            label={<FormattedMessage
                                id="components.account.requestReservation.requestReservationForm.unit_type"/>}
                            textColor="black"
                            class="request-reservation"
                            error={errors.unitType && touched.unitType}
                            onChange={e => {
                                var name = this.getSelectedHotel(props.values.destination).units.filter(item => item.id === e.target.value)[0].name;
                                this.setState({
                                    unitTypeName: name
                                }, () => {
                                    props.setFieldValue('unitType', e.target.value);
                                    props.setFieldValue('unitTypeName', name);
                                });
                            }}
                            value={props.values.unitType}
                        />
                        <ErrorMessage name="unitType" component="div" className="field-error mt-2"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <div className="custom-field">
                            <FormattedMessage
                                id="components.account.requestReservation.requestReservationForm.promo_code">
                                {promoCode_text =>
                                    <CustomTextField
                                        type="text"
                                        name="promoCode"
                                        class="request-reservation"
                                        label={promoCode_text}
                                        onChange={e => props.setFieldValue('promoCode', e.target.value)}
                                        handleBlur={props.handleBlur}
                                        value={props.values.promoCode}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </Grid>

                    <Grid container className="toggle-container request-reservation">
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <Toggle
                                name="addTransportation"
                                onChange={e => props.setFieldValue('addTransportation', e.target.checked)}
                                value={props.values.addTransportation}
                                text={<FormattedMessage
                                    id="components.account.requestReservation.requestReservationForm.add_transportation"/>}
                            >
                            </Toggle>
                        </Grid> */}

                        <Grid item xs={12} sm={6} md={6}>
                            <Toggle
                                name="flexibleDestination"
                                onChange={e => props.setFieldValue('flexibleDestination', e.target.checked)}
                                value={props.values.flexibleDestination}
                                text={<FormattedMessage
                                    id="components.account.requestReservation.requestReservationForm.flexible_destination"/>}
                            >
                            </Toggle>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Toggle
                                name="flexibleDates"
                                onChange={e => props.setFieldValue('flexibleDates', e.target.checked)}
                                value={props.values.flexibleDates}
                                text={<FormattedMessage
                                    id="components.account.requestReservation.requestReservationForm.flexible_dates"/>}
                            >
                            </Toggle>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <SubmitButton id="globals.request"/>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        
        const itemStorage = localStorage.getItem('reservation');
        let userInfo = null;
        
        if(itemStorage != null && itemStorage != undefined){
            userInfo = JSON.parse(itemStorage);
        }

        return <Section color="grey">
            <div className="request-reservation-form">
                <h3 className="title">
                    <FormattedMessage id="components.account.requestReservation.requestReservationForm.title"/>
                </h3>

                <Formik
                    initialValues={
                        {
                            destination: userInfo?.destination ? userInfo.destination : this.props.location.state ? this.props.location.state.data.destination : '',
                            guests: userInfo?.guests ? userInfo.guests : this.props.location.state ? this.props.location.state.data.guests ? this.props.location.state.data.guests : 1 : 1,
                            arrivingDate: userInfo?.arrivingDate ? userInfo.arrivingDate : this.props.location.state ? this.props.location.state.data.arrivingDate : '',
                            departingDate: userInfo?.departingDate ? userInfo.departingDate : this.props.location.state ? this.props.location.state.data.departingDate : '',
                            unitType: userInfo?.unitType ? userInfo.unitType : '',
                            unitTypeName: userInfo?.unitTypeName ? userInfo.unitTypeName : '',
                            promoCode: userInfo?.promoCode ? userInfo.promoCode : '',
                            addTransportation: userInfo?.addTransportation ?? false,
                            flexibleDestination: userInfo?.flexibleDestination ?? true,
                            flexibleDates: userInfo?.flexibleDates ?? true
                        }
                    }
                    validationSchema={RequestReservationSchema}
                    onSubmit={this.submit}
                    enableReinitialize={true}
                    validateOnBlur={true}
                >
                    {this.renderForm}
                </Formik>
            </div>

        </Section>
    }
}

const mapStateToProps = (state) => ({
    userProfile: state.user.profile,
    hotels: state.hotels.hotels,
})

const RequestReservationForm = withRouter(connect(mapStateToProps, null)(RequestReservationFormComponent));

export {RequestReservationForm, RequestReservationFormComponent};
