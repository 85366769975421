import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import {FormattedMessage} from "react-intl";
import visit_website_img from "../../../resources/assets/account/exchanges/visit_website_img.svg";

class SingleExchangeCard extends Component {
    getGritItemContainerClass = () => {
        let containerClass = '';
        switch (this.props.companies.length) {
            case 1: {
                containerClass = 'one-item';
                break;
            }
            case 2: {
                containerClass = 'two-items';
                break;
            }
            default :  containerClass = '';
        }

        return containerClass;
    }

    getGridItemWidth = () => {
        let gridWidth = 12;
        switch (this.props.companies.length) {
            case 1: {
                gridWidth = 12;
                break;
            }
            case 2: {
                gridWidth = 6;
                break;
            }
            case 3: {
                gridWidth = 4;
                break;
            }
            default : gridWidth = 12;
        }

        return gridWidth;
    }

    renderSingleCompany = (exchange, index) => {
        return (
            <Grid key={index} item xs={this.getGridItemWidth()}  className={`single-card-container ${this.getGritItemContainerClass()}`}>
                <div className="card-content">
                    <div className="card-img">
                        <img className={`logo ${exchange.className}`} src={exchange.img} alt=""/>
                    </div>
                    {/* <div className="card-text">
                        {exchange.subtitle}
                    </div> */}
                    <div className="card-button-container">
                        <div className="card-button">
                            <a className={'link-btn'} href={exchange.link} target="_blank" rel="noopener noreferrer">
                                <img className="visit-website-img" src={visit_website_img} alt="visit-website"/>
                                <p className={'visit-website'}>
                                    <FormattedMessage id="components.account.exchanges.visit_website"/>
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </Grid>
        )
    }

    render() {
        return (
            <Grid container justify="center">
                {this.props.companies.map((exchange, index) => this.renderSingleCompany(exchange, index))}
            </Grid>
        )
    }
}

export {SingleExchangeCard};
