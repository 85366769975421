import React, {Component} from 'react';
import Page from './layout/Page';
import {CancelledPaymentContent} from "../../components/account/myVidaVacationsPayments/CancelledPaymentContent";
import {SecondNav} from "../../components/layout/Navbars/header/secondNav/SecondNav";
import {Routes} from "../../config";
import {FormattedMessage} from 'react-intl';

class CancelledPayment extends Component {
    constructor(props) {
        super(props);

        let buff = new Buffer(props.match.params.message, 'base64');
        let message = buff.toString('utf8');

        this.state = {
            message : message,
        };
    }

    render() {
        return <Page class="account my-vida-vacations-payments">
            <SecondNav
                linkText={
                    <FormattedMessage id="pages.account.successPayment.back_text"/>
                }
                link={Routes.ROUTE_MY_VIDA_VACATIONS_PAYMENTS}
                showSaveBtn={false}
                border={false}
            />
            <CancelledPaymentContent message={ this.state.message } />
        </Page>
    }
}

export {CancelledPayment}
