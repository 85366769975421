import React, { Component } from 'react';
import Page from '../account/layout/Page';
import { AboutSection } from '../../components/account/about/AboutSection';
import { AboutListSection } from '../../components/account/about/AboutListSection';
import { AboutHeader } from '../../components/account/about/AboutHeader';
import { AboutVideoSection } from '../../components/account/about/AboutVideoSection';
import {Filters} from '../../components/layout/Navbars/header/Filters';
import {checkDevice} from "../../helpers/checkDevice";
import {SecondNav} from '../../components/layout/Navbars/header/secondNav/SecondNav';
import { FormattedMessage } from "react-intl";
import {Routes} from '../../config';
import { connect } from "react-redux";


class AboutComponent  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            device: checkDevice(),
        }
    }

    componentDidMount() {
        window.scroll(0,0);
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDevice);
    }

    setDevice = () => {
        this.setState({
            device: checkDevice()
        });
    }

    render() {
        return <Page class="account about">
           <Filters />
           {/* <SecondNav
                linkText={
                    <FormattedMessage id="components.account.about.about_header_section.goback"/>
                }
                link={Routes.ROUTE_HOME}
                showSaveBtn={false}
                border={false}
                class="about"
            /> */}
            <AboutHeader />
            <AboutSection />
            <AboutListSection />
            <AboutVideoSection device={this.state.device} language={this.props.userProfile.language_id} />
        </Page>
    }
}

const mapStateToProps = (state) => ( {
    userProfile: state.user.profile
} )

const About = connect( mapStateToProps, null )( AboutComponent );

export { About, AboutComponent };

