import moment from 'moment'
import qs from 'qs';
import { getAxios } from './axios';
import { store } from '../store/store';
import { displayNotification } from '../store/actions/notification';
import { displayGlobalNotification } from '../store/actions/globalNotification';
import { NOTIFICATION_ERROR } from '../store/actions/types';
import { GENERIC_ERROR_MESSAGE } from '../config/messages';
import { userLogout } from '../store/actions/user';

/**
 * Check if the current user is authenticated or not
 * @returns {boolean|*}
 */
export function isAuthenticated() {
    const state = store.getState();

    if (!state.user) {
        return false;
    }

    const token = state.user.token;

    if (!token || typeof (token.access_token) === 'undefined') {
        return false;
    }

    return moment(token.expires_at).isAfter(moment());
}

/**
 * Get access token header and pagination query string for requests
 * @param {object} [paginationData]
 * @param {number} [paginationData.page]
 * @param {number} [paginationData.per_page]
 * @param {string} [paginationData.sort_by]
 * @param {string} [paginationData.sort]
 * @param {string} [paginationData.q] Search
 * @param {string|number} [paginationData.custom]
 * @returns {{}}
 */
export function getTokenHeaders(paginationData) {
    const state = store.getState();
    const authToken = state.user.token.access_token;
    let params = {};

    if (authToken) {
        params.headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
        }

        if (paginationData) {
            params.params = buildQueryString(paginationData, 'object');
        }

        return params;
    }

    return {
        headers: { 'Content-Type': 'application/json' }
    };
}

/**
 * Get data from Axios call
 * @param {object} response
 * @returns {object|null}
 */
export function getResponseData(response) {
    return (response && response.data && response.data.data) || null;
}

/**
 * Get query string from an URL
 * @param {object} location
 * @param {object} location.search
 * @returns {any}
 */
export function getQueryString(location) {
    return qs.parse(location.search, { ignoreQueryPrefix: true });
}

/**
 * Get a specific param from query string from an URL
 * @param {string} name
 * @param {object} props
 * @param {object} props.location
 * @param {object} props.location.search
 * @returns {*}
 */
export function getParamFromUrl(name, props) {
    return getQueryString(props.location)[`${name}`]
}

/**
 * Get default header object for requests with no authentication
 * @returns {{headers: {"Access-Control-Allow-Origin": string, "Access-Control-Allow-Headers": string, "Content-Type": string}}}
 */
export function getDefaultHeaders() {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
        }
    };
}

/**
 * Replace all endpoint markups
 * @param str
 * @param mapObj
 * @returns {*}
 */
export const replaceAllEndpointMarkups = (str, mapObj) => {
    const re = new RegExp(Object.keys(mapObj).join('|').replace(/\[/g, '\\['), 'gi');

    return str.replace(re, function (matched) {
        return mapObj[matched];
    });
};

/**
 * Return if an object is empty or not
 * @param {object} obj
 * @returns {boolean}
 */
export function isEmptyObject(obj) {
    return JSON.stringify(obj) === '{}';
}

/**
 * Axios call for download files
 * @param {string} uri
 * @param {object} headers
 * @param {string} name
 * @returns {Promise<T>}
 */
export const download = (uri, headers, name) => {
    headers.responseType = 'blob';
    return getAxios().get(uri, headers).then(data => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', name); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();

        return data.data;
    });
};

/**
 * Replace parameters from endpoint url
 * @param {string} url
 * @param {string|object} paramName
 * @param {string|null} [value]
 * @param {object} [filters]
 * @param {number} [filters.page]
 * @param {number} [filters.per_page]
 * @param {string} [filters.sort_by]
 * @param {string} [filters.sort]
 * @param {string} [filters.q]
 * @param {string|number} [filters.custom]
 * @returns {string}
 */
export function addParam(url, paramName, value, filters) {
    const acceptedValuesTypes = ['string', 'number'];

    if (typeof url === 'string' && url.length > 0) {
        if (
            typeof paramName === 'string' && paramName.length > 1 &&
            acceptedValuesTypes.indexOf(typeof value) !== -1
        ) {
            return url.replace(paramName, value.toString()).replace(/\/$/g, '').concat(buildQueryString(filters, 'string'));
        } else if (typeof paramName === 'object') {
            const properties = Object.getOwnPropertyNames(paramName);
            properties.forEach(element => {
                if (
                    typeof element === 'string' && element.length > 1 &&
                    acceptedValuesTypes.indexOf(typeof paramName[element]) !== -1
                ) {
                    url = url.replace(element, paramName[element].toString());
                }
            });

            return url.replace(/\/$/g, '').concat(buildQueryString(filters, 'string'));
        }
    }

    return url;
}

/**
 * Build query string filters for a listing endpoint
 * @param {object} filters
 * @param {number} [filters.page]
 * @param {number} [filters.per_page]
 * @param {string} [filters.sort_by]
 * @param {string} [filters.sort]
 * @param {string} [filters.q] Search
 * @param {string} type
 * @param {string|number} [filters.custom]
 *
 * @returns {string|array}
 */
export function buildQueryString(filters, type = 'string') {
    let queryString = '',
        params = {},
        key = 0;

    if (filters && typeof filters === 'object') {
        for (key in filters) {
            if (filters[key] || filters[key] === 0) {
                params[key] = filters[key];

                if (queryString.length === 0) {
                    queryString = `?${key}=${filters[key]}`;
                } else {
                    queryString += `&${key}=${filters[key]}`;
                }
            }
        }
    }

    return type === 'string' ? queryString : params;
}

/**
 * Axios error catcher
 * @param {ExceptionInformation} e
 * @param {object} cases
 * @param {string|null} [notificationId]
 */
export function errorCatch(e, cases, notificationId) {
    if (e.response && e.response.status) {
        if (cases[e.response.status]) {
            if (typeof cases[e.response.status] === 'function') {
                cases[e.response.status]();
            }
            else if (cases[e.response.status] !== 'concat') {
                store.dispatch(displayNotification(NOTIFICATION_ERROR, cases[e.response.status], notificationId));

                if (e.response.status === 401) {
                    store.dispatch(userLogout());
                }
            }
            else {
                let messages;

                if (e && e.response && e.response.data && typeof e.response.data.message === 'string') {
                    messages = e.response.data.message;
                }
                else {
                    let error = '';
                    messages = [];

                    for (error in e.response.data.message) {
                        messages = messages.concat(e.response.data.message[error]);
                    }
                }

                store.dispatch(displayNotification(NOTIFICATION_ERROR, messages, notificationId));
            }
        }
        else {
            store.dispatch(displayNotification(NOTIFICATION_ERROR, GENERIC_ERROR_MESSAGE, notificationId));
        }
    }
    else {
        store.dispatch(displayNotification(NOTIFICATION_ERROR, GENERIC_ERROR_MESSAGE, notificationId));
    }

    return e.response;
}

/**
 * Axios error catcher
 * @param {ExceptionInformation} e
 * @param {object} cases
 * @param {string|null} [notificationId]
 */
export function errorCatchGlobal(e, cases, notificationId) {
    if (e.response && e.response.status) {
        if (cases[e.response.status]) {
            if (typeof cases[e.response.status] === 'function') {
                cases[e.response.status]();
            }
            else if (cases[e.response.status] !== 'concat') {
                store.dispatch(displayNotification(NOTIFICATION_ERROR, cases[e.response.status], notificationId));

                if (e.response.status === 401) {
                    store.dispatch(userLogout());
                }
            }
            else {
                let messages;

                if (e && e.response && e.response.data && typeof e.response.data.message === 'string') {
                    messages = e.response.data.message;
                }
                else {
                    let error = '';
                    messages = [];

                    for (error in e.response.data.message) {
                        messages = messages.concat(e.response.data.message[error]);
                    }
                }

                store.dispatch(displayGlobalNotification(NOTIFICATION_ERROR, messages, notificationId));

            }
        }
        else {
            store.dispatch(displayGlobalNotification(NOTIFICATION_ERROR, GENERIC_ERROR_MESSAGE, notificationId));
        }
    }
    else {
        store.dispatch(displayGlobalNotification(NOTIFICATION_ERROR, GENERIC_ERROR_MESSAGE, notificationId));
    }

    return e.response;
}

/**
 * Get notification id from payload
 * @param {object} payload
 * @param {string} payload.notificationId
 * @returns {null|string}
 */
export function getNotificationId(payload) {
    if (payload && payload.notificationId) {
        return payload.notificationId;
    }

    return null;
}

export function isLoading() {
    const state = store.getState();

    return !!(state && state.loading && state.loading.pendingRequests);
}

export function getImageBase64(file, cb, setError) {
    let reader = new FileReader();
    reader.readAsDataURL( file );
    reader.onload = function () {
        cb( reader.result )
    };
    reader.onerror = function (error) {
        setError( 'Error: ', error );
    };
}
