import {getAxios} from '../axios';
import {ENDPOINT_EXCHANGES} from '../../config/endpoints';
import {
    addParam,
    errorCatch,
    getTokenHeaders,
} from '../helper';
import {Messages} from '../../config';

/**
 * Get Exchanges list
 * @returns {Promise<T>}
 */
export function getExchanges() {
    return getAxios(false).get(
        addParam(
            ENDPOINT_EXCHANGES,
            {
                '{exchange}': ''
            }),
        getTokenHeaders()
    )
        .then(
            data => {
                return data && data.data ? data.data[0] : null
            }
        )
        .catch(
            e => {
                errorCatch(
                    e,
                    {
                        400: 'concat',
                        401: Messages.UNAUTHENTICATED_ERROR,
                        403: Messages.UNAUTHORIZED_ERROR,
                        404: Messages.NOT_FOUND_ERROR,
                    }
                );

                return e.response;
            }
        );
}
