import React from "react";
import Page from "./Page";
import { Title, Text } from "../../components";
import { FormattedMessage } from "react-intl";
import { ResetPassword as Form } from "../../components/auth/ResetPassword";
import Grid from '@material-ui/core/Grid';

class ResetPassword extends React.Component {
    constructor(props) {
        super( props );

        this.state = {
            token: '',
            email: ''
        }
    }

    componentDidMount() {
        // When open this page from email link, a token is present in url, and email does not exist
        // Case 2: Redirect on this page from ForgotPassword page with email and token in location state
        let { token } = this.props.match.params;

        if (! token) {
            token = this.props.location.state ? this.props.location.state.token : '';

            this.setState( {
                token: token,
                email: this.props.location.state ? this.props.location.state.email : '',
            } );
        } else {
            this.setState( {
                token: token
            } );
        }
    }

    render() {
        return (
            <Page class="reset-password-wrapper">
                <Grid container className="reset-password-container" justify="center">
                    <Grid item>
                        {this.state.email && <Title className="center title">
                            <FormattedMessage id={"globals.thank_you"}/>
                        </Title>
                        }
                    </Grid>
                    <Grid item>
                        <Title className="center title">
                            {this.state.email ? <FormattedMessage id={"forgot_password_success.title"}/> :
                                <FormattedMessage id={"forgot_password_success.title_without_email"}/>
                            }
                        </Title>
                    </Grid>
                    {this.state.email && <Grid item>
                        <Text className='center subtitle'>
                            <FormattedMessage
                                id="forgot_password_success.text"
                                values={{
                                    email: <span className="email">{this.state.email}</span>
                                }}
                            />
                        </Text>
                    </Grid>}
                    <Grid item xs={12}>
                        <Form token={this.state.token} autoComplete="off"/>
                    </Grid>
                </Grid>
            </Page>
        )
    }
};

export { ResetPassword }
