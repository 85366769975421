import {getAxios} from '../axios';
import {ENDPOINT_USER_RESERVATIONS} from '../../config/endpoints';
import {
    addParam,
    errorCatch,
    getTokenHeaders,
} from '../helper';
import {Messages} from '../../config';

/**
 * Get user reservations list
 * @param {object} payload
 * @param {string} payload.type
 * @returns {Promise<T>}
 */
export function getReservations(payload) {
    return getAxios(false).get(
        addParam(
            ENDPOINT_USER_RESERVATIONS,
            {
                '{type}': payload.type
            }),
        getTokenHeaders()
    )
        .then(
            data => {
                return data && data.data ? data.data.data : null
            }
        )
        .catch(
            e => {
                errorCatch(
                    e,
                    {
                        400: 'concat',
                        401: Messages.UNAUTHENTICATED_ERROR,
                        403: Messages.UNAUTHORIZED_ERROR,
                        404: Messages.NOT_FOUND_ERROR,
                    }
                );

                return e.response;
            }
        );
}
