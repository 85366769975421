import React, {Component} from 'react';
import {connect} from "react-redux";
import Container from '@material-ui/core/Container';
import ReadMessageItem from "./ReadMessageItem";
import {Section} from "../../layout/Section";
import {ReplyButton} from "./Reply/ReplyButton";
import {ReplyForm} from "./Reply/ReplyForm";
import {ReplyNotification} from "./Reply/ReplyNotification";
import {getMessageById, readMessages} from "../../../api/messages/messages";
import {Messages} from "../../general/Messages";
import {NOTIFICATION_CONTACT_MESSAGE_SENT} from "../../../config/notifications";
import {ShowMessage} from "./ShowMessage";
import {userDetails} from "../../../store/actions/user";
import {MESSAGE_STATUSES} from "../../../config/constants/messages";

class ReadMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            conversations: [],
            selectedItem: '',
            replyState: 0,
            selectedMessage: ''
        }
    }

    componentDidMount() {
        this.getMessageById();
    }

    getMessageById = () => {
        let payload = {
            messageId: this.props.id
        }

        getMessageById(payload).then(data => {
            if (data && data.status) {
                return;
            }

            let conversations = [];
            data.conversations.length && data.conversations.forEach((item) => {
                conversations.push(item);
            });

            let selectedItemId = conversations.length > 0 ? conversations[conversations.length - 1].id : '';

            this.setState({
                selectedItem: 'panel' + selectedItemId,
                conversations: conversations,
                selectedMessage: data
            });

            let payload = {
                message: data
            }

            // If conversation has unread messages, or conversation is unread -> mark messages as read
            if (data.unread_messages.length || !data.read_at) {
                // Mark messages as read
                readMessages(payload).then(() => {
                    // After a message is read, get user profile, to update nr of messages in profile
                    this.props.userDetails(true);
                });
            }
        });
    }

    showMessageNotification = () => {
        this.setState({replyState: 2});
        setTimeout(() => {
            this.setState({replyState: 0});
        }, 5000);
    }

    renderReplyAction = () => {
        switch (this.state.replyState) {
            case 1:
                return <ReplyForm
                    onClick={() => {
                        this.setState({replyState: 2});
                    }}
                    showMessageNotification={this.showMessageNotification}
                    selectedMessage={this.state.selectedMessage}
                    getMessageById={this.getMessageById}
                />
            case 2:
                return <ReplyNotification onClick={() => {
                    this.setState({replyState: 0});
                }}/>
            default:
                return <ReplyButton onClick={() => {
                    this.setState({replyState: 1});
                }}/>
        }
    }

    handleChange = (panel) => () => {
        this.setState({selectedItem: panel});
    };

    render() {
        let message = this.state.selectedMessage;

        return (
            <Section color="greyWhite" className="container-white">
                <Container maxWidth="md" className="write-message accordion">
                    <div className="message-content fixed-message-header">
                        {message && <ShowMessage showSubject={true} from={message.from_name} subject={message.reason}
                                                 to={message.to_name}
                                                 date={message.date} message={message.message}/>
                        }
                    </div>
                    <div className="accordion-container">
                        {
                            this.state.conversations.length &&
                            this.state.conversations.map((message, key) =>
                                <ReadMessageItem message={message} key={key} handleChange={this.handleChange}
                                                 selectedItem={this.state.selectedItem}/>
                            )}
                    </div>
                </Container>
                {/*Show reply action on messages that are different from "Alert" or "Marketing"*/}
                {this.state.selectedMessage.message_type !== MESSAGE_STATUSES.ALERT && this.state.selectedMessage.message_type !== MESSAGE_STATUSES.MARKETING && <div className="switch-container">
                    {this.renderReplyAction()}
                </div>
                }
                <Messages notificationId={NOTIFICATION_CONTACT_MESSAGE_SENT}/>
            </Section>
        );
    }
}

const ReadMessageComponent = connect(null, {userDetails})(ReadMessage);

export {ReadMessageComponent, ReadMessage};
