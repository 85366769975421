import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { FormattedMessage } from "react-intl";

class ShowMessage extends Component {

    render() {
        return (
            <div className="show-message-body">
                {this.props.showSubject && <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <div className="message-subject-read">
                        <h2>{this.props.subject}</h2>
                    </div>
                    <div className="message-header-date">
                        <p>{moment(moment(this.props.date)).calendar()}</p>
                    </div>
                </Grid>
                }
                <Grid container justify="space-between" className="message-header">
                    <Grid item xs={6} className="d-flex">
                        <Grid className="message-header-label">
                            <p><FormattedMessage id="globals.from"/>:</p>
                            <p><FormattedMessage id="globals.to"/>:</p>
                        </Grid>
                        <Grid item xs={12} className="message-header-info">
                            <p>{this.props.from}</p>
                            <p>{this.props.to}</p>
                        </Grid>
                    </Grid>
                    {!this.props.showSubject && <Grid item>
                        <div className="message-header-date">
                            <p>{moment(moment(this.props.date)).calendar()}</p>
                        </div>
                    </Grid>
                    }
                </Grid>
                <Grid container item xs={12} className="message-body">
                    <p>{this.props.message}</p>
                </Grid>
            </div>
        )
    }
}

export {ShowMessage}
