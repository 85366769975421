import React, {Component} from 'react';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import {ROUTE_MY_VIDA_VACATIONS_PAYMENTS} from "../../config/routes";
import CloseIcon from '../../resources/assets/account/contractsVacations/onClose.svg';
import RightWhiteArrow from '../../resources/assets/account/contractsVacations/RightWhiteArrow.svg';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';

class ContractModal extends Component {


    formatLink = (contract) => {
        let link = ROUTE_MY_VIDA_VACATIONS_PAYMENTS;
        return {
            pathname: link,
            state: {
                contract_id: contract.contract_id
            }
        };
    }

    renderContent = () => {
        let {contract} = this.props;
        
        return <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center">
            <Grid item xs={12} sm={6} className="box">
                <label><FormattedMessage id="components.account.myVidaVacationsContracts.resort"/>:</label>
                <p>{contract && contract.resort}</p>
            </Grid>
            <Grid item xs={12} sm={6} className="box">
                <label><FormattedMessage id="components.account.myVidaVacationsContracts.status"/>:</label>
                <p>{contract && contract.contract_status === "Active" ?
                    <FormattedMessage id="components.modals.contract_modal.status.active"/> :
                    <FormattedMessage id="components.account.myVidaVacationsContracts.status_message"/>}</p>
            </Grid>
            <Grid item xs={12} sm={6} className="box">
                <label><FormattedMessage id="components.account.myVidaVacationsContracts.contract_number"/>:</label>
                <p>{contract && contract.contract_number}</p>
            </Grid>
            <Grid item xs={12} sm={6} className="box">
                <label><FormattedMessage id="components.account.myVidaVacationsContracts.usage_fee"/> { new Date().getFullYear() + 1 }:</label>
                <p>${contract && parseFloat(contract.maintenance_fee).toFixed(2)} {contract && contract.currency_id ==  "1" ? "MXN" : "USD"  }</p>
            </Grid>
            <Grid item xs={12} sm={6} className="box">
                <label><FormattedMessage id="components.account.myVidaVacationsContracts.room_type"/>:</label>
                <p>{contract && contract.room_type}</p>
            </Grid>
            <Grid item xs={12} sm={6} className="box">
                <label><FormattedMessage id="components.account.myVidaVacationsContracts.purchase_date"/>:</label>
                <p>{contract && contract.contract_date && moment.utc(contract.contract_date * 1000).format('LL')}</p>
            </Grid>
            <Link to={ this.formatLink(contract) } className="link-button">
            {/* <Link to={ROUTE_MY_VIDA_VACATIONS_PAYMENTS} params={{ testvalue: contract }} className="link-button"> */}
                <FormattedMessage id="components.account.myVidaVacationsContracts.manage_my_payments"/>
                <img src={RightWhiteArrow} alt="submit"/>
            </Link>
        </Grid>
    }

    render() {
        let {open, onClose} = this.props;

        return <Modal
            className="contract-modal"
            open={open}
            onClose={this.props.handleClose}>
            <div className="contract-modal-container">
                <Grid container alignItems="center" justify="space-between" className="modal-title">
                    <Grid item>
                        <h1>
                            <FormattedMessage id="components.account.myVidaVacationsContracts.contract_details"/>
                        </h1>
                    </Grid>
                    <Grid item>
                        <img src={CloseIcon} alt="manage-my-payments" onClick={onClose}/>
                    </Grid>
                </Grid>
                <div className="modal-body">
                    {this.renderContent()}
                </div>
            </div>
        </Modal>
    }
}

ContractModal.defaultProps = {
    open: false,
};

export {ContractModal}
