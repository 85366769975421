import { getAxios } from '../axios';
import { ENDPOINT_USER_CONTRACTS } from '../../config/endpoints';
import {
    addParam,
    errorCatch,
    getTokenHeaders,
} from '../helper';
import { Messages } from '../../config';

/**
 * Get contacts list
 * @returns {Promise<T>}
 */
export function getUserContracts() {
    return getAxios(false).get(
        addParam(
            ENDPOINT_USER_CONTRACTS,
            {
                '{user_contract}': ''
            }),
        getTokenHeaders()
    )
        .then(
            data => {
                return data && data.data ? data.data.data : []
            }
        )
        .catch(
            e => {
                errorCatch(
                    e,
                    {
                        401: Messages.UNAUTHENTICATED_ERROR,
                        403: Messages.UNAUTHORIZED_ERROR,
                        404: Messages.NOT_FOUND_ERROR,
                    }
                );

                return [];
            }
        );
}
