import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {FormattedMessage} from 'react-intl';
import {CreditCardForm} from "./CreditCardForm";
import {BillingForm} from "./BillingForm";

class CardBillingInformation extends Component {
    render() {

        return <Grid
            container
            className={`card-billing-info-container ${this.props.isModal ? 'is-modal' : ''}`}>
            {/* <Grid item xs={12} md={6}>
                <div className="card-details-container">
                    <h3 className="card-billing-title">
                        <FormattedMessage id="components.account.paymentMethod.card_billing_info.card_title"/>
                    </h3>

                    <CreditCardForm
                        formikProps={this.props.formikProps}
                        isUsedInModal={this.props.isModal}
                        setNewCardField={this.props.setNewCardField}
                        isPaymentPage={this.props.isPaymentPage}
                    />
                </div>
            </Grid> */}
            <Grid item >
                <div className="bill-details-container">
                    <h3 className="card-billing-title">
                        <FormattedMessage id="components.account.paymentMethod.card_billing_info.bill_title"/>
                    </h3>

                    <BillingForm
                        isUsedInModal={this.props.isModal}
                        formikProps={this.props.formikProps}
                        setNewCardField={this.props.setNewCardField}
                        isPaymentPage={this.props.isPaymentPage}
                    />
                </div>
            </Grid>
        </Grid>
    }
}

export {CardBillingInformation}
