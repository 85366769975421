import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ErrorIcon from '@material-ui/icons/Error';

class OtherFeesPaymentFormHeaderItem extends Component {
    render() {
        const { text, type, selectedItem, warning } = this.props;
        let selected = selectedItem && selectedItem.length && selectedItem[0].fee_type.toLowerCase().includes(type.toLowerCase());

        return <div
            className={ `other-fees-payment-form-header-item ${ selected ? 'selected' : '' }` }
            onClick={
                () => this.props.chooseOtherFeesPaymentType(type)
            }
        >
            <span className="label">
                <FormattedMessage id={ text }/>
            </span>
            { warning && <div className="attention-container">
                <ErrorIcon className="attention-svg"/>
            </div> }
        </div>
    }
}

export { OtherFeesPaymentFormHeaderItem }
