import React, { Component } from "react";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class Toggle extends Component {

    render() {
        return (
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.props.value}
                            onChange={this.props.onChange}
                            name={this.props.name}
                            classes={{
                                track: 'track',
                                switchBase: 'switch-color-checked',
                                checked: 'checked'
                            }}
                        />
                    }
                    label={this.props.text}
                />
            </FormGroup>
        )
    }
}

export { Toggle }
